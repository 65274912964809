import { ComponentProps, FunctionComponent } from "react";
import { IonFooter } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonFooter> {}

const Footer = styled(IonFooter)``;

const AppHeader: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Footer {...props}>{children}</Footer>;
};

export default AppHeader;
