import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./";
import { TaskState } from "../models/TaskState";

interface TaskStatesState extends EntityState<TaskState> {
  loading: boolean;
  error: string | null;
}

const adapter = createEntityAdapter<TaskState>();

const { selectById } = adapter.getSelectors();

const initialState: TaskStatesState = adapter.getInitialState({
  loading: true,
  error: null,
});

export const taskStatesSlice = createSlice({
  name: "taskStates",
  initialState,
  reducers: {
    setTaskStates: (state, action: PayloadAction<TaskState[]>) => {
      adapter.setAll(state, action.payload);
    },
    setTaskStatesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaskStatesError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setTaskStates, setTaskStatesLoading, setTaskStatesError } =
  taskStatesSlice.actions;

export const selectTaskStates = adapter.getSelectors(
  (state: RootState) => state.taskStates
).selectAll;

export const selectTaskStateEntities = adapter.getSelectors(
  (state: RootState) => state.taskStates
).selectEntities;

export const selectTaskStatesLoading = (state: RootState) =>
  state.taskStates.loading;

export const selectTaskStatesError = (state: RootState) =>
  state.taskStates.error;

export const selectTaskState = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.taskStates,
    (state) => (selectById(state, id!) ? selectById(state, id!)! : null)
  );
};

export const selectProjectTaskStates = (projectId: string | null) => {
  return createSelector(selectTaskStates, (state) =>
    state.filter((item) => item.projectId === projectId)
  );
};

export default taskStatesSlice.reducer;
