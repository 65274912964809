import { HTMLAttributes, FunctionComponent } from "react";
import { IonIcon } from "@ionic/react";
import styled from "styled-components";
import { caretDownSharp } from "ionicons/icons";

interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  helperText?: string;
  optional?: boolean;
  marginBottom?: string;
  width?: string;
  readonly?: boolean;
}

const Content = styled.div`
  display: block;
`;

const Label = styled.div`
  margin-bottom: -11px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  z-index: 10;
  pointer-events: none;
`;

const LabelText = styled.div`
  display: inline-block;
  color: var(--app-color-grey600);
  font-size: 12px;
  background-color: var(--app-color-white);
  padding: 0 6px;
  word-wrap: keep-all;
  white-space: nowrap;
`;

const Wrap = styled.div<{
  readonly?: boolean;
}>`
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid var(--app-color-grey400);
  border-radius: 4px;
  cursor: ${({ readonly }) => (readonly ? "default" : "pointer")};
  align-items: center;
  min-height: 50px;
`;

const Text = styled.div`
  padding: 12px 4px 12px 16px;
`;

const Icon = styled.div`
  margin-left: auto;
  color: var(--app-color-grey400);
  font-size: 12px;
  margin: 0 0 0 auto;
  .ios {
    padding: 0 11px;
  }
  .md {
    padding: 0 9px;
  }
`;

const HelperText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  padding: 0 2px;
  color: var(--app-color-grey600);
`;

const AppFormButton: FunctionComponent<Props> = ({
  label,
  helperText,
  optional,
  children,
  marginBottom,
  style,
  width,
  readonly,
  ...props
}) => {
  return (
    <Content style={{ ...style, marginBottom }}>
      {label && (
        <Label>
          <LabelText>
            {label}
            {optional && "（オプション）"}
          </LabelText>
        </Label>
      )}
      <Wrap {...props} readonly={readonly} style={{ width }}>
        <Text>{children}</Text>
        {readonly !== true && (
          <Icon>
            <IonIcon slot="start" icon={caretDownSharp} />
          </Icon>
        )}
      </Wrap>
      {helperText && <HelperText>{helperText}</HelperText>}
    </Content>
  );
};

export default AppFormButton;
