import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { TaskHistory } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useTaskHistories = ({
  workspaceId,
  projectId,
  taskId,
}: {
  workspaceId: string | null;
  projectId: string | null;
  taskId: string | null;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<TaskHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth || !workspaceId || !projectId || !taskId) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis
      .$readTaskHistories(workspaceId, projectId, taskId)
      .subscribe({
        next: (tasks) => {
          console.log("useTaskHistories");
          setData(tasks);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log(error);
          setData([]);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId, projectId, taskId]);

  return { data, loading, error };
};
