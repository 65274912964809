import { FunctionComponent } from "react";
import {
  businessOutline,
  checkmarkCircle,
  checkmarkOutline,
  closeOutline,
  cubeOutline,
  layersOutline,
  peopleCircleOutline,
  peopleOutline,
  personAddOutline,
  timeOutline,
} from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppFooterButtons,
  AppActionButton,
  AppHeader,
  AppToolbar,
  AppTitle,
  AppButtons,
  AppButton,
  AppIcon,
  AppInnerContent,
  AppPage,
  AppLead,
} from "../..";
import styled from "styled-components";
import { IonIcon } from "@ionic/react";
import { IONIC } from "../../../utilities/UtilStatic";

export interface AppExplainMemberRolesModalProps {
  hide: () => void;
}

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  border: 1px solid var(--app-color-grey300);
  background: var(--app-color-grey300);
  row-gap: 1px;
  column-gap: 1px;
  border-radius: 8px;
  overflow: hidden;
`;

const TableHeader = styled.div`
  padding: 8px 12px;
  text-align: center;
  background: var(--app-color-white);
`;

const Cell = styled.div`
  padding: 8px 12px;
  background: var(--app-color-white);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const CategoryIcon = styled(IonIcon)`
  color: var(--app-color-grey600);
  font-size: 20px;
  display: block;
  @media screen and (max-width: ${IONIC.BREAKPOINT.SM}) {
    display: none;
  }
`;

const RoleIcon = styled(IonIcon)`
  color: var(--ion-color-primary);
  font-size: 28px;
  display: block;
  margin: 0 auto;
`;

const CellTitle = styled.div`
  font-size: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
`;

const CellText = styled.div`
  font-size: 12px;
`;

const AppExplainMemberRolesModal: FunctionComponent<
  AppExplainMemberRolesModalProps
> = ({ hide }) => {
  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>権限表</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <AppLead marginBottom="16px">
            管理者と一般では利用可能な機能が異なります。
          </AppLead>
          {/* 権限表 */}
          <Table>
            {/* ヘッダー */}
            <TableHeader />
            <TableHeader>管理者</TableHeader>
            <TableHeader>メンバー</TableHeader>
            {/* プロジェクト */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={layersOutline} />
                プロジェクトの閲覧
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell>
              <CellText>管理者に承認されたプロジェクトのみ可能</CellText>
            </Cell>
            <Cell>
              <CellTitle>
                <CategoryIcon icon={layersOutline} />
                プロジェクトの作成・更新・削除
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell></Cell>
            {/* タスク */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={checkmarkOutline} />
                タスクの閲覧・作成・更新・削除
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell>
              <CellText>
                管理者に承認されたプロジェクトのタスクのみ可能
              </CellText>
            </Cell>
            {/* オフィス */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={cubeOutline} />
                オフィスの閲覧・入室
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell>
              <CellText>管理者に承認されたオフィスのみ可能</CellText>
            </Cell>
            <Cell>
              <CellTitle>
                <CategoryIcon icon={cubeOutline} />
                オフィスの作成・更新・削除
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell></Cell>
            {/* メンバー */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={peopleOutline} />
                メンバーの更新・削除
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell>
              <CellText>自分のメンバー情報のみ可能</CellText>
            </Cell>
            <Cell>
              <CellTitle>
                <CategoryIcon icon={personAddOutline} />
                ワークスペースに新規メンバーを招待
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell></Cell>
            {/* 作業履歴 */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={timeOutline} />
                作業履歴の閲覧
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell></Cell>
            {/* チーム */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={peopleCircleOutline} />
                チームの作成・更新・削除
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell></Cell>
            {/* ワークスペース */}
            <Cell>
              <CellTitle>
                <CategoryIcon icon={businessOutline} />
                ワークスペースの更新・削除
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell></Cell>
            <Cell>
              <CellTitle>
                <CategoryIcon icon={businessOutline} />
                ワークスペースから脱退
              </CellTitle>
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
            <Cell>
              <RoleIcon icon={checkmarkCircle} />
            </Cell>
          </Table>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.close()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppExplainMemberRolesModal;
