import {
  IonButton,
  IonIcon,
  useIonPopover,
  useIonViewWillEnter,
} from "@ionic/react";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import {
  chevronBackOutline,
  chevronForwardOutline,
  layersOutline,
  peopleOutline,
  personOutline,
} from "ionicons/icons";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppButton,
  AppButtons,
  AppContent,
  AppHeader,
  AppHeaderBackButton,
  AppIcon,
  AppMenuButton,
  AppPage,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppTitle,
  AppToolbar,
} from "../..";
import {
  useError,
  useLoading,
  useMonthlyOfficeMembersHistories,
  useProjects,
} from "../../../hooks";
import { TabId } from "../../../models";
import {
  selectMemberEntities,
  selectMembers,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import {
  selectTeamEntities,
  selectTeams,
  selectTeamsError,
  selectTeamsLoading,
} from "../../../store/teamsSlice";
import {
  calendarDates,
  formatItem,
  formatMember,
} from "../../../utilities/UtilFunction";
import { i18nShortWeekdays } from "../../../utilities/UtilI18nText";
import { appLinks } from "../../../utilities/UtilPage";
import { IONIC, DAYJS, TEXT } from "../../../utilities/UtilStatic";

interface WorkHistory {
  memberId: string;
  name: string;
  avatar: string;
  beginAt: string;
  endAt: string | null;
  order: number;
}

const Content = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: 100%;
  min-height: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

const States = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
`;

const StatesButton = styled(IonButton)`
  margin: 0;
  --border-width: 1px;
  --box-shadow: none;
  &::part(native) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StatesLabel = styled.div`
  font-size: 14px;
`;

const StatesIcon = styled(IonIcon)`
  padding: 0 4px;
`;

const TotalWorkingTime = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
  padding: 0 1px;
`;

const Calendar = styled.div`
  background: var(--app-color-white);
  border-radius: 8px;
  border: 1px solid var(--app-color-grey300);
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 4px 0;
  border-bottom: 1px solid var(--app-color-grey300);
`;

const Weekday = styled.div`
  font-size: 12px;
  color: var(--app-color-grey600);
  text-align: center;
`;

const Dates = styled.div<{ row: number }>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: ${({ row }) => `repeat(${row}, 1fr)`};
  gap: 1px;
  background: var(--app-color-grey300);
  overflow: hidden;
`;

const Date = styled.div`
  background: var(--app-color-white);
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  padding: 4px 8px 8px;
  gap: 4px 0;
  :hover {
    cursor: pointer;
    background: var(--app-color-grey100);
  }
`;

const DateNum = styled.div`
  width: 28px;
  height: 28px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0);
  &.today {
    color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
  }
  &.other-month {
    color: var(--app-color-grey400);
  }
`;

const DailyHistories = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px 0;
  overflow: hidden;
  @media screen and (max-width: ${IONIC.BREAKPOINT.MD}) {
    display: flex;
    flex-flow: row wrap;
    gap: 2px;
  }
`;

const DailyHistory = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0 4px;
`;

const DailyHistoryMemerAvatar = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const DailyHistoryWorkTime = styled.div`
  font-size: 14px;
  color: var(--app-color-grey600);
  line-height: 1.2;
  @media screen and (max-width: ${IONIC.BREAKPOINT.MD}) {
    display: none;
  }
`;

const TabOfficeMembersHistories: FunctionComponent = () => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, memberId, teamId, projectId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    memberId?: string;
    teamId?: string;
    projectId?: string;
  }>();
  const [isTabPage] = useState<boolean>(
    !Boolean(memberId || teamId || projectId)
  );
  // store
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const teams = useSelector(selectTeams);
  const teamEntities = useSelector(selectTeamEntities);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  // states
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(
    memberId ?? null
  );
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(
    teamId ?? null
  );
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    projectId ?? null
  );
  // functions カレンダー
  const [month, setMonth] = useState<Dayjs>(dayjs());
  const dates = useMemo(() => calendarDates(month), [month]);
  const [today, setToday] = useState<string>(dayjs().format(DAYJS.YYYY_MM_DD));
  useIonViewWillEnter(() => setToday(dayjs().format(DAYJS.YYYY_MM_DD)));
  // functions 読み込み
  const projects = useProjects({ workspaceId });
  const officeMembersHistories = useMonthlyOfficeMembersHistories({
    workspaceId,
    month: dayjs(month).format(DAYJS.YYYY_MM),
  });
  const loading = useLoading(
    membersLoading,
    teamsLoading,
    projects.loading,
    officeMembersHistories.loading
  );
  const error = useError(
    membersError,
    teamsError,
    projects.error,
    officeMembersHistories.error
  );
  // functions 条件でフィルターした作業履歴
  const filterOfficeMembersHistories = useMemo(() => {
    return officeMembersHistories.data.filter((officeMembersHistory) => {
      if (selectedMemberId) {
        return selectedMemberId === officeMembersHistory.memberId;
      }
      if (selectedTeamId) {
        const team = teamEntities[selectedTeamId];
        if (team) {
          return team.memberIds.includes(officeMembersHistory.memberId);
        } else {
          return false;
        }
      }
      if (selectedProjectId) {
        return selectedProjectId === officeMembersHistory.projectId;
      }
      return true;
    });
  }, [
    officeMembersHistories.data,
    teamEntities,
    selectedMemberId,
    selectedTeamId,
    selectedProjectId,
  ]);
  // functions カレンダー用にデータ整形
  const monthlyHistories = useMemo(() => {
    const monthlyHistories: {
      [date: string]:
        | { [memberId: string]: WorkHistory | undefined }
        | undefined;
    } = {};
    dates.forEach((date) => {
      if (!date.isOtherMonth) monthlyHistories[date.YYYY_MM_DD] = {};
    });
    filterOfficeMembersHistories.forEach((officeMembersHistory) => {
      const YYYYMMDD = dayjs(officeMembersHistory.beginAt.toDate()).format(
        DAYJS.YYYY_MM_DD
      );
      const dailyHistories = monthlyHistories[YYYYMMDD];
      if (dailyHistories) {
        const existsWorkHistory: WorkHistory | undefined =
          dailyHistories[officeMembersHistory.memberId];
        if (existsWorkHistory) {
          let beginAt = existsWorkHistory.beginAt;
          let endAt = existsWorkHistory.endAt;
          const newBeginAt = dayjs(
            officeMembersHistory.beginAt.toDate()
          ).format(DAYJS.HH_mm);
          const newEndAt = officeMembersHistory.endAt
            ? dayjs(officeMembersHistory.endAt.toDate()).format(DAYJS.HH_mm)
            : null;
          if (beginAt > newBeginAt) beginAt = newBeginAt;
          if (endAt && newEndAt) {
            if (endAt < newBeginAt) endAt = newEndAt;
          } else {
            endAt = null;
          }
          dailyHistories[officeMembersHistory.memberId] = {
            ...existsWorkHistory,
            beginAt,
            endAt,
          };
        } else {
          const { memberId, beginAt, endAt } = officeMembersHistory;
          const { name, avatar, order } = formatMember(
            memberId,
            memberEntities
          );
          dailyHistories[memberId] = {
            memberId,
            name,
            avatar,
            beginAt: dayjs(beginAt.toDate()).format(DAYJS.HH_mm),
            endAt: endAt ? dayjs(endAt.toDate()).format(DAYJS.HH_mm) : null,
            order,
          };
        }
      }
    });
    return monthlyHistories;
  }, [dates, filterOfficeMembersHistories, memberEntities]);

  // functions 今月の合計作業時間
  const totalHours = useMemo(() => {
    let second: number = 0;
    filterOfficeMembersHistories.forEach(({ beginAt, endAt }) => {
      if (endAt) {
        second += dayjs(endAt.toDate()).diff(beginAt.toDate(), DAYJS.SECOND);
      }
    });
    return Math.floor((second / 60 / 60) * 10) / 10;
  }, [filterOfficeMembersHistories]);

  const dailyHistories = useCallback(
    (date: string) => {
      const dailyHistories = monthlyHistories[date];
      if (dailyHistories) {
        return Object.values(dailyHistories)
          .map((dailyHistory) => dailyHistory!)
          .sort((a, b) => a.order - b.order)
          .slice(0, 20); // 20 以上は表示するスペースがないので省略
      } else {
        return [];
      }
    },
    [monthlyHistories]
  );

  // components メンバー選択
  const [showSelectMemberPopover, hideSelectMemberPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: members,
      selectedId: selectedMemberId,
      filterKeywords: ["name", "email"],
      isSetNull: true,
      selectItem: (memberId: string | null) => {
        setSelectedMemberId(memberId);
        setSelectedTeamId(null);
        setSelectedProjectId(null);
        hideSelectMemberPopover();
      },
    } as AppSelectPopoverProps
  );
  // components チーム選択
  const [showSelectTeamPopover, hideSelectTeamPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: teams,
      selectedId: selectedTeamId,
      isSetNull: true,
      selectItem: (teamId: string | null) => {
        setSelectedMemberId(null);
        setSelectedTeamId(teamId);
        setSelectedProjectId(null);
        hideSelectTeamPopover();
      },
    } as AppSelectPopoverProps
  );
  // components プロジェクト選択
  const [showSelectProjectPopover, hideSelectProjectPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: projects.data,
      selectedId: selectedProjectId,
      isSetNull: true,
      selectItem: (projectId: string | null) => {
        setSelectedMemberId(null);
        setSelectedTeamId(null);
        setSelectedProjectId(projectId);
        hideSelectProjectPopover();
      },
    } as AppSelectPopoverProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            {isTabPage ? <AppMenuButton /> : <AppHeaderBackButton />}
          </AppButtons>
          <AppTitle>{dayjs(month).format(DAYJS.YYYY_MM)}</AppTitle>
          <AppButtons slot="end">
            <AppButton
              color="primary"
              onClick={() => setMonth(month.add(-1, DAYJS.MONTH))}
            >
              <AppIcon slot="icon-only" icon={chevronBackOutline} />
            </AppButton>
            <AppButton
              color="primary"
              onClick={() => setMonth(month.add(1, DAYJS.MONTH))}
            >
              <AppIcon slot="icon-only" icon={chevronForwardOutline} />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <Content>
          <Header>
            <States>
              {/* メンバー */}
              <StatesButton
                fill={!selectedMemberId ? "outline" : "solid"}
                shape="round"
                size="small"
                onClick={(e) => {
                  showSelectMemberPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <StatesIcon icon={personOutline} />
                {!selectedMemberId && (
                  <StatesLabel className="ion-hide-sm-down">
                    メンバー
                  </StatesLabel>
                )}
                {selectedMemberId && (
                  <StatesLabel>
                    {formatMember(selectedMemberId, memberEntities).name}
                  </StatesLabel>
                )}
              </StatesButton>
              {/* チーム */}
              <StatesButton
                fill={!selectedTeamId ? "outline" : "solid"}
                shape="round"
                size="small"
                onClick={(e) => {
                  showSelectTeamPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <StatesIcon icon={peopleOutline} />
                {!selectedTeamId && (
                  <StatesLabel className="ion-hide-sm-down">チーム</StatesLabel>
                )}
                {selectedTeamId && (
                  <StatesLabel>
                    {formatItem(selectedTeamId, teams).name}
                  </StatesLabel>
                )}
              </StatesButton>
              {/* プロジェクト */}
              <StatesButton
                fill={!selectedProjectId ? "outline" : "solid"}
                shape="round"
                size="small"
                onClick={(e) => {
                  showSelectProjectPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <StatesIcon icon={layersOutline} />
                {!selectedProjectId && (
                  <StatesLabel className="ion-hide-sm-down">
                    プロジェクト
                  </StatesLabel>
                )}
                {selectedProjectId && (
                  <StatesLabel>
                    {formatItem(selectedProjectId, projects.data).name}
                  </StatesLabel>
                )}
              </StatesButton>
            </States>
            {!loading && !error && (
              <TotalWorkingTime>合計作業時間{totalHours}時間</TotalWorkingTime>
            )}
          </Header>
          <Calendar>
            <Weekdays>
              {i18nShortWeekdays().map((weekday) => (
                <Weekday key={weekday}>{weekday}</Weekday>
              ))}
            </Weekdays>
            <Dates row={dates.length / 7}>
              {dates.map(({ YYYY_MM_DD, num, isOtherMonth }) => {
                return (
                  <Date
                    key={YYYY_MM_DD}
                    onClick={() => {
                      if (selectedMemberId) {
                        history.push(
                          appLinks.dailyOfficeMembersHistoriesOfMember(
                            workspaceId,
                            tabId,
                            YYYY_MM_DD,
                            selectedMemberId
                          )
                        );
                      } else if (selectedTeamId) {
                        history.push(
                          appLinks.dailyOfficeMembersHistoriesOfTeam(
                            workspaceId,
                            tabId,
                            YYYY_MM_DD,
                            selectedTeamId
                          )
                        );
                      } else if (selectedProjectId) {
                        history.push(
                          appLinks.dailyOfficeMembersHistoriesOfProject(
                            workspaceId,
                            tabId,
                            YYYY_MM_DD,
                            selectedProjectId
                          )
                        );
                      } else {
                        history.push(
                          appLinks.dailyOfficeMembersHistories(
                            workspaceId,
                            tabId,
                            YYYY_MM_DD
                          )
                        );
                      }
                    }}
                  >
                    <DateNum
                      className={classNames({
                        today: YYYY_MM_DD === today,
                        "other-month": isOtherMonth,
                      })}
                    >
                      {num}
                    </DateNum>
                    <DailyHistories>
                      {dailyHistories(YYYY_MM_DD).map(
                        ({ avatar, beginAt, endAt }, index) => (
                          <DailyHistory key={index}>
                            <DailyHistoryMemerAvatar src={avatar} />
                            <DailyHistoryWorkTime>
                              {`${beginAt}${TEXT.DATE_DASH}${endAt ?? ""}`}
                            </DailyHistoryWorkTime>
                          </DailyHistory>
                        )
                      )}
                    </DailyHistories>
                  </Date>
                );
              })}
            </Dates>
          </Calendar>
        </Content>
      </AppContent>
    </AppPage>
  );
};

export default TabOfficeMembersHistories;
