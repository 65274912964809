import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  marginBottom?: string;
}

const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 16px 16px 0;
`;

const AppCardHeader: FunctionComponent<Props> = ({
  marginBottom,
  style,
  children,
  ...props
}) => {
  return (
    <Header style={{ marginBottom, ...style }} {...props}>
      {children}
    </Header>
  );
};

export default AppCardHeader;
