import { ComponentProps, FunctionComponent } from "react";
import { IonSegment } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonSegment> {
  label?: string;
  maxWidth?: string;
  marginBottom?: string;
}

const Content = styled.div`
  display: block;
`;

const Wrap = styled.div`
  border: 1px solid var(--app-color-grey400);
  border-radius: 4px;
`;

const Label = styled.div`
  margin-bottom: -11px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  z-index: 10;
  pointer-events: none;
`;

const LabelText = styled.div`
  display: inline-block;
  color: var(--app-color-grey600);
  font-size: 12px;
  background-color: var(--app-color-white);
  padding: 0 6px;
  word-wrap: keep-all;
  white-space: nowrap;
  border-radius: 4px;
`;

const Segment = styled(IonSegment)`
  --border-radius: 4px;
  border-radius: 4px;
  --background: var(--app-color-white);
  /* padding-top: 20px; */
`;

const AppFormSegment: FunctionComponent<Props> = ({
  label,
  maxWidth,
  marginBottom,
  children,
  ...props
}) => {
  return (
    <Content style={{ marginBottom, maxWidth }}>
      {label && (
        <Label>
          <LabelText>{label}</LabelText>
        </Label>
      )}
      <Wrap>
        <Segment mode="ios" {...props}>
          {children}
        </Segment>
      </Wrap>
    </Content>
  );
};

export default AppFormSegment;
