import { ComponentProps, FunctionComponent } from "react";
import { IonChip } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonChip> {}

const Chip = styled(IonChip)``;

const AppChip: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Chip {...props}>{children}</Chip>;
};

export default AppChip;
