import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { StripeSubscription } from "../models";
import { selectWorkspaceId } from "../store/uiSlice";

export const useStripeWorkspaceActiveSubscriptions = () => {
  // store
  const auth = useSelector(selectAuth);
  const workspaceId = useSelector(selectWorkspaceId);
  // states
  const [data, setData] = useState<StripeSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis
      .$readStripeWorkspaceActiveSubscriptions(workspaceId)
      .subscribe({
        next: (data) => {
          console.log("useStripeWorkspaceActiveSubscriptions");
          setData(data);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log(error);
          setData([]);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [auth]);

  return { data, loading, error };
};
