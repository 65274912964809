import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Task } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useListTasks = ({
  workspaceId,
  projectId,
  stateId,
  assignedMemberId,
  milestoneId,
  tagIds,
  orderBy,
  limit,
}: {
  workspaceId: string;
  projectId: string | null;
  stateId: string | "default" | "all";
  assignedMemberId: string | "all" | null;
  milestoneId: string | "all" | null;
  tagIds: string[];
  orderBy: "order" | "createdAt" | "updatedAt" | "limitDate" | "priority";
  limit: number;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth || !projectId) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis
      .$readListTasks(
        workspaceId,
        projectId,
        stateId,
        assignedMemberId,
        milestoneId,
        tagIds,
        orderBy,
        limit
      )
      .subscribe({
        next: (tasks) => {
          console.log("useListTasks");
          setData(tasks);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log("error", error);
          setData([]);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [
    auth,
    workspaceId,
    projectId,
    assignedMemberId,
    milestoneId,
    stateId,
    tagIds,
    orderBy,
    limit,
  ]);

  return { data, loading, error };
};
