import { ComponentProps, forwardRef } from "react";
import { IonPage } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonPage> {}

const Page = styled(IonPage)``;

const AppPage = forwardRef<HTMLElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <Page {...props} ref={ref}>
        {children}
      </Page>
    );
  }
);

export default AppPage;
