import { useEffect, useState } from "react";
import { User } from "../models";
import { appApis } from "../apis/Api";
import { i18nError, i18nErrorToString } from "../utilities/UtilI18nText";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useUser = () => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const subscribe = appApis.$readUser().subscribe({
      next: (user) => {
        if (user) {
          setData(user);
          setLoading(false);
          setError(null);
        } else {
          setData(null);
          setLoading(false);
          setError(i18nError.unauthenticated);
        }
      },
      error: (error) => {
        console.log(error);
        setData(null);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth]);

  return { data, loading, error };
};
