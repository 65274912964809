import styled from "styled-components";
import { OfficeMemberHistory } from "../../models";
import { IonAvatar, useIonPopover } from "@ionic/react";
import dayjs from "dayjs";
import {
  AppCard,
  AppErrorCard,
  AppLoadingCard,
  AppMemberPopover,
  AppMemberPopoverProps,
} from "..";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { TEXT } from "../../utilities/UtilStatic";
import { i18nUnit } from "../../utilities/UtilI18nText";
import { useSelector } from "react-redux";
import { selectMemberEntities, selectMembers } from "../../store/membersSlice";
import { useOfficeMemberHistoriesAtTask } from "../../hooks";
import { formatMember } from "../../utilities/UtilFunction";
import { useHistory } from "react-router";

interface Props {
  workspaceId: string | null;
  projectId: string | null;
  taskId: string | null;
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--app-color-grey300);
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 0 16px;
`;

const MemberCol = styled.div`
  flex: 0 1 32px;
`;

const MainCol = styled.div`
  flex: 1 0 0%;
  align-items: center;
`;

const Avatar = styled(IonAvatar)`
  flex: 0 1 auto;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const Diff = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

const Datetime = styled.div`
  font-size: 14px;
  color: var(--app-color-grey500);
`;

const SumHours = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 12px 16px;
  font-size: 16px;
`;

const Colon = styled.div`
  padding: 0 8px;
`;

const AppTaskOfficeMemberHistory: FunctionComponent<Props> = ({
  workspaceId,
  projectId,
  taskId,
}) => {
  const history = useHistory();
  // store
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  // states
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [showMemberPopover, hideMemberPopover] = useIonPopover(
    AppMemberPopover,
    {
      member: memberEntities[selectedMemberId ?? ""] ?? null,
      showMemberDetails: (link: string) => {
        history.push(link);
        hideMemberPopover();
      },
    } as AppMemberPopoverProps
  );
  // hooks
  const officeMemberHistories = useOfficeMemberHistoriesAtTask({
    workspaceId,
    projectId,
    taskId,
  });
  // functions
  const sumHours = useMemo(() => {
    let hours: number = 0;
    officeMemberHistories.data.forEach((officeMemberHistory) => {
      if (officeMemberHistory.beginAt && officeMemberHistory.endAt) {
        const diff = dayjs(officeMemberHistory.endAt.toDate()).diff(
          officeMemberHistory.beginAt.toDate(),
          "minutes"
        );
        hours += diff;
      }
    });
    return Math.floor((hours / 60) * 10) / 10; // 小数点第一位まで
  }, [officeMemberHistories]);

  const officeMemberHistoryAt = useCallback(
    (officeMember: OfficeMemberHistory) => {
      const beginAt = officeMember.beginAt;
      const endAt = officeMember.endAt;
      let text: string | null = null;
      if (beginAt) {
        const beginYearMonthDay = dayjs(beginAt.toDate()).format(
          "YYYY-MM-DD HH:mm"
        );
        text = `${beginYearMonthDay} ${TEXT.DATE_DASH} `;
      }
      if (endAt) {
        const beginYearMonthDay = dayjs(beginAt?.toDate()).format("YYYY-MM-DD");
        const endYearMonthDay = dayjs(endAt.toDate()).format("YYYY-MM-DD");
        if (beginYearMonthDay !== endYearMonthDay) {
          text += endYearMonthDay;
        } else {
          text += dayjs(endAt.toDate()).format("HH:mm");
        }
      }
      return text;
    },
    [officeMemberHistories]
  );

  return (
    <>
      {officeMemberHistories.loading && <AppLoadingCard />}
      {officeMemberHistories.error && (
        <AppErrorCard>{officeMemberHistories.error}</AppErrorCard>
      )}
      {officeMemberHistories.data.length > 0 && (
        <AppCard>
          {officeMemberHistories.data.map((officeMemberHistory, index) => (
            <Container key={index}>
              <Row>
                <MemberCol>
                  <Avatar
                    onClick={(e) => {
                      if (!memberEntities[officeMemberHistory.memberId]) return;
                      setSelectedMemberId(officeMemberHistory.memberId);
                      showMemberPopover({
                        cssClass: "app-popover-width280",
                        event: {
                          ...e.nativeEvent,
                          target: e.currentTarget,
                        },
                      });
                    }}
                  >
                    <img
                      src={
                        formatMember(
                          officeMemberHistory.memberId,
                          memberEntities
                        ).avatar
                      }
                    />
                  </Avatar>
                </MemberCol>
                <MainCol>
                  {officeMemberHistory.beginAt && officeMemberHistory.endAt && (
                    <Diff>
                      {/* 小数点第一位までHoursで表示 */}
                      {Math.floor(
                        (dayjs(officeMemberHistory.endAt.toDate()).diff(
                          officeMemberHistory.beginAt.toDate(),
                          "minutes"
                        ) /
                          60) *
                          10
                      ) / 10}
                      {i18nUnit.hours()}
                    </Diff>
                  )}
                  <Datetime>
                    {officeMemberHistoryAt(officeMemberHistory)}
                  </Datetime>
                </MainCol>
              </Row>
            </Container>
          ))}
          {officeMemberHistories.data.filter(
            (officeMemberHistory) =>
              officeMemberHistory.beginAt !== null &&
              officeMemberHistory.endAt !== null
          ).length > 0 && (
            <SumHours>
              合計
              <Colon>{TEXT.COLON}</Colon>
              {sumHours + i18nUnit.hours()}
            </SumHours>
          )}
        </AppCard>
      )}
    </>
  );
};

export default AppTaskOfficeMemberHistory;
