import { FunctionComponent, useMemo, useState } from "react";
import { searchOutline } from "ionicons/icons";
import { useSearchTasks } from "../../../hooks";
import {
  AppIcon,
  AppInput,
  AppItem,
  AppLabel,
  AppRadio,
  AppRadioGroup,
} from "../..";

export interface AppSelectMainTaskPopoverProps {
  workspaceId: string | null;
  projectId: string | null;
  mainTaskId: string | null;
  taskId: string | null;
  selectMainTask: (taskId: string | null) => void;
}

const AppSelectMainTaskPopover: FunctionComponent<
  AppSelectMainTaskPopoverProps
> = ({ workspaceId, projectId, mainTaskId, taskId, selectMainTask }) => {
  const [searchWord, setSearchWord] = useState<string>("");

  const tasks = useSearchTasks({
    workspaceId,
    projectId,
    searchWord,
  });

  const searchTasks = useMemo(() => {
    return tasks.data.filter(
      (item) => item.mainTaskId === null && item.id !== taskId
    );
  }, [tasks.data]);

  return (
    <>
      <AppItem
        button={false}
        detail={false}
        style={{ "--background": "var(--app-color-grey100)" }}
        lines="full"
      >
        <AppIcon slot="start" icon={searchOutline} />
        <AppInput
          value={searchWord}
          placeholder="タスク検索"
          clearInput={true}
          clearOnEdit={true}
          onIonChange={(e) => setSearchWord(e.detail.value!)}
          name="search"
        />
      </AppItem>
      <AppRadioGroup value={mainTaskId ?? ""}>
        {searchWord === "" && (
          <AppItem
            button={true}
            detail={false}
            onClick={() => selectMainTask(null)}
          >
            <AppRadio slot="start" value="" mode="md" />
            <AppLabel>-</AppLabel>
          </AppItem>
        )}
        {searchTasks.map((task) => {
          return (
            <AppItem
              key={task.id}
              button={true}
              detail={false}
              onClick={() => selectMainTask(task.id)}
              disabled={task.mainTaskId !== null || task.id === taskId}
            >
              <AppRadio slot="start" value={task.id} mode="md" />
              <AppLabel>{task.title}</AppLabel>
            </AppItem>
          );
        })}
      </AppRadioGroup>
    </>
  );
};

export default AppSelectMainTaskPopover;
