import { ComponentProps, FunctionComponent } from "react";
import { IonBadge } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonBadge> {}

const Badge = styled(IonBadge)``;

const AppBadge: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Badge {...props}>{children}</Badge>;
};

export default AppBadge;
