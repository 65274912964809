import { ComponentProps, FunctionComponent } from "react";
import { IonToolbar } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonToolbar> {}

const Title = styled(IonToolbar)`
  --background: var(--app-color-white);
  .header-ios &:last-of-type {
    --border-width: 0 0 1px;
  }
`;

const AppToolbar: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Title {...props}>{children}</Title>;
};

export default AppToolbar;
