import { useIonAlert, useIonToast } from "@ionic/react";
import { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  AppContent,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppFormInput,
  AppCard,
  AppLead,
  AppHeaderBackButton,
  AppButtons,
  AppCardContent,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
} from "../..";
import { appApis } from "../../../apis/Api";
import { selectAuth, setAuth } from "../../../store/authSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { TabId } from "../../../models";

const UpdateAccountEmail: FunctionComponent = () => {
  // router
  const history = useHistory();
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  // data
  const [email, setEmail] = useState<string>("");
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>Eメール編集</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <AppLead marginBottom="16px">
            Eメールを変更します。新しいEメールを入力してください
          </AppLead>
          <AppCard>
            <AppCardContent>
              <AppFormInput
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                type="text"
                label="新しいEメール"
                name="email"
              />
            </AppCardContent>
          </AppCard>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.account(workspaceId, tabId)} />
        <AppActionButton
          action="ok"
          onClick={() => {
            if (!auth) return;
            Promise.resolve()
              .then(() => appApis.updateEmail(email))
              .then(() => {
                history.goBack();
                dispatch(setAuth({ ...auth, email }));
                showToast({
                  message: i18nText.success.user.update(),
                  duration: 2000,
                });
              })
              .catch((error) => {
                showAlert({ message: i18nErrorToString(error) });
              });
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default UpdateAccountEmail;
