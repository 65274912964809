import { ComponentProps, FunctionComponent } from "react";
import { IonButton } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonButton> {}

const Button = styled(IonButton)`
  --border-width: 1px;
  text-transform: none;
  margin: 0;
  .ios & {
    max-height: 2.4em;
  }
`;

const AppButton: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

export default AppButton;
