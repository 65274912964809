import { ComponentProps, FunctionComponent } from "react";
import { IonSegmentButton } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonSegmentButton> {}

const SegmentButton = styled(IonSegmentButton)`
  --color: var(--app-color-grey400);
  --color-checked: var(--app-color-grey900);
  --color-focused: var(--app-color-grey900);
  --border-radius: 4px;
  --border-width: 0;
  --padding-top: 13px;
  --padding-bottom: 13px;
  margin: 1px -1px;
  --indicator-box-shadow: none;
  font-size: 16px;
  font-weight: normal;
`;

const AppFormSegmentButton: FunctionComponent<Props> = ({
  children,
  ...props
}) => {
  return (
    <SegmentButton mode="ios" {...props}>
      {children}
    </SegmentButton>
  );
};

export default AppFormSegmentButton;
