import { FunctionComponent } from "react";
import styled from "styled-components";
import {
  AppActionButton,
  AppBackButton,
  AppCard,
  AppCardContent,
  AppContent,
  AppFooter,
  AppFooterButtons,
  AppHeader,
  AppInnerContent,
  AppLandingHeader,
  AppPage,
} from "../..";
import { appLinks } from "../../../utilities/UtilPage";
import { PLATFORM } from "../../../utilities/UtilStatic";

const MainTitle = styled.h1`
  font-size: 28px;
  font-weight: normal;
  margin: 0 0 8px;
  padding: 8px 0;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 32px 0 8px;
`;

const Text = styled.div`
  font-size: 16px;
  margin: 0 0 8px;
`;

const Lists = styled.ul`
  margin: 0 0 8px;
  padding: 0 0 0 16px;
`;

const List = styled.li`
  font-size: 16px;
  margin: 0 0 4px;
`;

const Date = styled.div`
  font-size: 16px;
  margin: 24px 0 0;
  color: var(--app-color-grey600);
`;

const PrivacyPolicyPage: FunctionComponent = () => {
  return (
    <AppPage>
      {!PLATFORM.IS_CAPACITOR && <AppLandingHeader />}
      <AppContent style={{ "--padding-top": PLATFORM.IS_IOS ? "48px" : "" }}>
        <AppInnerContent>
          <MainTitle>プライバシーポリシー</MainTitle>
          <AppCard marginBottom="16px">
            <AppCardContent>
              <Title style={{ marginTop: "0" }}>お客様から取得する情報</Title>
              <Text>当社は、お客様から以下の情報を取得します。</Text>
              <Lists>
                <List>氏名(ニックネームやペンネームも含む)</List>
                <List>メールアドレス</List>
                <List>写真や動画</List>
                <List>
                  クレジットカード、銀行口座、電子マネー等のお客様の決済手段に関する情報
                </List>
                <List>Cookie(クッキー)を用いて生成された識別情報</List>
                <List>
                  OSが生成するID、端末の種類、端末識別子等のお客様が利用するOSや端末に関する情報
                </List>
              </Lists>

              <Title>お客様の情報を利用する目的</Title>
              <Text>
                当社は、お客様から取得した情報を、以下の目的のために利用します。
              </Text>
              <Lists>
                <List>
                  当社サービスに関する登録の受付、お客様の本人確認、認証のため
                </List>
                <List>お客様の当社サービスの利用履歴を管理するため</List>
                <List>利用料金の決済のため</List>
                <List>
                  当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため
                </List>
                <List>当社のサービスに関するご案内をするため</List>
                <List>お客様からのお問い合わせに対応するため</List>
                <List>当社の規約や法令に違反する行為に対応するため</List>
                <List>
                  当社サービスの変更、提供中止、終了、契約解除をご連絡するため
                </List>
                <List>当社規約の変更等を通知するため</List>
                <List>
                  以上の他、当社サービスの提供、維持、保護及び改善のため
                </List>
              </Lists>

              <Title>安全管理のために講じた措置</Title>
              <Text>
                当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。
              </Text>

              <Title>第三者提供</Title>
              <Text>
                当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第２条第６項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。
                但し、次の場合は除きます。
              </Text>
              <Lists>
                <List>個人データの取扱いを外部に委託する場合</List>
                <List>当社や当社サービスが買収された場合</List>
                <List>
                  事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を別途公表します。）
                </List>
                <List>
                  その他、法律によって合法的に第三者提供が許されている場合
                </List>
              </Lists>

              <Title>アクセス解析ツール</Title>
              <Text>
                当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。
                <br />
                https://marketingplatform.google.com/about/analytics/terms/jp/
              </Text>

              <Title>プライバシーポリシーの変更</Title>
              <Text>
                当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。
              </Text>

              <Title>お問い合わせ</Title>
              <Text>
                お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のメールアドレスにご連絡ください。
                <br />
                e-mail support@minimaldesign.jp
                <br />
                この場合、必ず、運転免許証のご提示等当社が指定する方法により、ご本人からのご請求であることの確認をさせていただきます。
              </Text>

              <Title>事業者の名称</Title>
              <Text>株式会社ミニマルデザイン</Text>

              <Title>代表者氏名</Title>
              <Text>井上勇</Text>

              <Title>事業者の住所</Title>
              <Text>
                東京都中央区日本橋小網町８－２ ＢＩＺＭＡＲＫＳ日本橋茅場町１Ｆ
              </Text>

              <Date>2023年04月01日 制定</Date>
            </AppCardContent>
          </AppCard>
        </AppInnerContent>
      </AppContent>
      <AppFooter>
        <AppFooterButtons>
          <AppBackButton defaultHref={appLinks.home()} />
        </AppFooterButtons>
      </AppFooter>
    </AppPage>
  );
};

export default PrivacyPolicyPage;
