import {
  IonButton,
  IonIcon,
  IonItem,
  IonRouterLink,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonPopover,
  useIonToast,
} from "@ionic/react";
import {
  arrowDownSharp,
  arrowUpSharp,
  calendarClearOutline,
  checkmarkCircle,
  checkmarkCircleOutline,
  checkmarkSharp,
  createOutline,
  ellipsisVertical,
  hourglassOutline,
  openOutline,
  pricetagOutline,
  star,
  starOutline,
  timeOutline,
} from "ionicons/icons";
import { useState, FunctionComponent } from "react";
import { appLinks } from "../../utilities/UtilPage";
import { appApis } from "../../apis/Api";
import {
  AppWriteTasksModal,
  AppWriteTasksModalProps,
  AppItem,
  AppItemGroup,
  AppLabel,
  AppItemIcon,
  AppSelectPopoverProps,
  AppSelectPopover,
  AppReorder,
  AppPopover,
} from "..";
import styled from "styled-components";
import dayjs from "dayjs";
import { Color, Task } from "../../models";
import { i18nText } from "../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../utilities/UtilI18nText";
import {
  getColor,
  getTimestampNow,
  formatMember,
  formatItems,
  typeOfString,
  getRoot,
} from "../../utilities/UtilFunction";
import { useSelector } from "react-redux";
import { selectTaskNotificationsCount } from "../../store/notificationsSlice";
import { ACTION, ENV, PLATFORM } from "../../utilities/UtilStatic";
import { generateTaskBookmark } from "../../utilities/UtilFunction";
import { selectAuth } from "../../store/authSlice";
import { selectMemberEntities } from "../../store/membersSlice";
import {
  selectProjectTaskStates,
  selectTaskStateEntities,
} from "../../store/taskStatesSlice";
import { selectTaskTags } from "../../store/taskTagsSlice";
import { selectTaskBookmarks } from "../../store/taskBookmarksSlice";
import { selectTabId } from "../../store/uiSlice";

interface AppTaskProps {
  task: Task;
  subTasks?: Task[];
  history: any;
  isSortMode?: boolean;
  orderBy?: "order" | "createdAt" | "updatedAt" | "limitDate" | "priority";
  setProjectId?: (projectId: string) => void;
  routerRef?: HTMLElement;
}

const Item = styled(IonItem)<{ taskColor: Color | null }>`
  --background: ${({ taskColor }) => getColor(taskColor).light};
  --border-color: var(--app-color-grey300-rgba);
  --padding-start: 4px;
  --inner-padding-end: 0;
  --inner-border-width: 0 0 1px;
`;

const ItemDoneButton = styled(IonButton)<{
  done: boolean;
  taskColor: Color | null;
}>`
  width: 32px;
  height: 32px;
  margin: 8px 12px 8px 8px;
  --box-shadow: none;
  --border-radius: 50%;
  &::part(native) {
    border: ${({ done }) =>
      done ? "none" : "1px solid var(--app-color-grey500-rgba)"};
    background: ${({ done }) =>
      done ? "var(--app-color-grey500-rgba)" : "none"};
    color: var(--app-color-grey500);
    padding: 0;
  }
`;

const ItemDoneIcon = styled(IonIcon)<{
  done: boolean;
  taskColor: Color | null;
}>`
  font-size: 20px;
  color: ${({ done, taskColor }) =>
    done
      ? taskColor
        ? getColor(taskColor).light
        : "var(--app-color-white)"
      : "var(--app-color-grey500-rgba)"};
`;

const ItemName = styled.div`
  font-size: 16px;
  white-space: normal;
`;

const ItemPriority = styled(IonIcon)`
  margin-right: 4px;
  position: relative;
  top: 2px;
  color: var(--app-color-grey600-rgba);
`;

const ItemStates = styled.div`
  display: flex;
  flex-flow: row wrap;
  color: var(--app-color-grey600-rgba);
`;

const ItemState = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 4px 12px 0 0;
`;

const ItemStateIcon = styled(IonIcon)`
  margin-right: 4px;
  position: relative;
  top: 1px;
  color: var(--app-color-grey600-rgba);
`;

const ItemStateText = styled.div`
  font-size: 12px;
  &:not(:last-child) {
    margin-right: 4px;
    &::after {
      content: ",";
    }
  }
`;

const ItemAvatar = styled.div`
  width: 32px;
  height: 32px;
  background: var(--app-color-grey200);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const ItemOptionsButton = styled(IonButton)`
  margin: 0;
  height: 40px;
`;

const ItemOptionsButtonIcon = styled(IonIcon)`
  margin: 0;
  color: var(--app-color-grey500-rgba);
`;

const WrapSubTasks = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 4px;
  margin-bottom: -2px;
`;

const SubTask = styled(IonRouterLink)<{ taskColor: Color | null }>`
  font-size: 14px;
  margin-right: 4px;
  margin-bottom: 2px;
  color: ${({ taskColor }) =>
    taskColor ? getColor(taskColor).dark : "var(--app-color-grey600)"};
  display: flex;
`;

const SubTaskIcon = styled(IonIcon)`
  margin-right: 4px;
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
`;

const ItemBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 12px;
  min-width: 22px;
  color: var(--app-color-white);
  background: var(--app-color-notification);
  margin-left: 4px;
`;

const AppTask: FunctionComponent<AppTaskProps> = ({
  task,
  subTasks,
  history,
  isSortMode,
  orderBy,
  setProjectId,
  routerRef,
}) => {
  // store
  const tabId = useSelector(selectTabId);
  const auth = useSelector(selectAuth);
  const projectTaskStates = useSelector(
    selectProjectTaskStates(task.projectId)
  );
  const memberEntities = useSelector(selectMemberEntities);
  const taskTags = useSelector(selectTaskTags);
  const taskStateEntities = useSelector(selectTaskStateEntities);
  const taskBookmarks = useSelector(selectTaskBookmarks);
  // hooks
  const taskNotificationsCount = useSelector(
    selectTaskNotificationsCount(task.id)
  );

  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const [showSelectStatePopover, hideSelectStatePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: projectTaskStates,
      selectedId: task.stateId,
      isSetNull: true,
      selectItem: (stateId: string | null) => {
        hideSelectStatePopover();
        if (stateId === task.stateId || !auth) return;
        Promise.resolve()
          .then(() =>
            appApis.updateTask({
              workspaceId: task.workspaceId,
              id: task.id,
              stateId,
              done: taskStateEntities[stateId ?? ""]?.done === true,
              updatedBy: auth.id,
              updatedAt: getTimestampNow(),
            })
          )
          .then(() => {
            showToast({
              message: i18nText.success.task.update(),
              duration: 2000,
              buttons: [
                {
                  text: i18nText.buttons.detail(),
                  handler: () => {
                    history.push(
                      appLinks.task(
                        task.workspaceId,
                        tabId,
                        task.projectId,
                        task.id
                      )
                    );
                  },
                },
              ],
            });
          })
          .catch((error) => {
            showAlert({
              message: i18nErrorToString(error),
            });
          });
      },
    } as AppSelectPopoverProps
  );

  // components タスク更新modal
  const [showWriteTaskModal, hideWriteTaskModal] = useIonModal(
    AppWriteTasksModal,
    {
      projectId: task.projectId,
      taskId: task.id,
      deleteTasks: (task) => {
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.resolve()
          .then(() => appApis.deleteTask(task.workspaceId, task.id))
          .then(() => {
            hideWriteTaskModal();
            showToast({
              message: i18nText.success.task.delete(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      saveTasks: (taskForms, mainTaskId) => {
        const requests = taskForms.map(({ action, ...task }) => {
          if (action === ACTION.CREATE) {
            return appApis.createTask(task);
          } else if (action === ACTION.UPDATE) {
            return appApis.updateTask(task);
          } else {
            return appApis.deleteTask(task.workspaceId, task.id);
          }
        });
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.all(requests)
          .then(() => {
            hideWriteTaskModal();
            showToast({
              message: i18nText.success.task.update(),
              duration: 2000,
              buttons: [
                {
                  text: i18nText.buttons.detail(),
                  handler: () => {
                    history.push(
                      appLinks.task(
                        task.workspaceId,
                        tabId,
                        task.projectId,
                        mainTaskId
                      )
                    );
                  },
                },
              ],
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      hide: () => hideWriteTaskModal(),
    } as AppWriteTasksModalProps
  );

  // components タスク詳細 popover
  const [taskOptionsPopover, setTaskOptionsPopover] = useState<{
    show: boolean;
    event?: Event;
  }>({ show: false });

  return (
    <Item
      taskColor={task.color}
      key={task.id}
      routerLink={appLinks.task(
        task.workspaceId,
        tabId,
        task.projectId,
        task.id
      )}
      detail={false}
    >
      <ItemDoneButton
        done={task.done}
        taskColor={task.color}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showSelectStatePopover({
            cssClass: ["app-popover-width240", "app-popover-height240"],
            event: {
              ...e.nativeEvent,
              target: e.currentTarget,
            },
          });
          if (setProjectId) {
            setProjectId(task.projectId);
          }
        }}
        slot="start"
      >
        <ItemDoneIcon
          done={task.done}
          taskColor={task.color}
          icon={checkmarkSharp}
          slot="icon-only"
        />
      </ItemDoneButton>
      <AppLabel>
        <ItemName>
          {task.priority === 1 && <ItemPriority icon={arrowUpSharp} />}
          {task.priority === -1 && <ItemPriority icon={arrowDownSharp} />}
          {task.title}
        </ItemName>
        <ItemStates>
          {task.stateId !== null && (
            <ItemState>
              <ItemStateText>
                {taskStateEntities[task.stateId]
                  ? taskStateEntities[task.stateId]?.name
                  : "不明"}
              </ItemStateText>
            </ItemState>
          )}

          {orderBy === "createdAt" && task.createdAt && (
            <ItemState>
              <ItemStateIcon icon={timeOutline} />
              <ItemStateText>
                {dayjs(task.createdAt.toDate()).format("YYYY-MM-DD HH:mm")}
              </ItemStateText>
            </ItemState>
          )}
          {orderBy === "updatedAt" && task.updatedAt && (
            <ItemState>
              <ItemStateIcon icon={timeOutline} />
              <ItemStateText>
                {dayjs(task.updatedAt.toDate()).format("YYYY-MM-DD HH:mm")}
              </ItemStateText>
            </ItemState>
          )}
          {typeOfString(task.limitDate) && (
            <ItemState>
              <ItemStateIcon icon={calendarClearOutline} />
              <ItemStateText>
                {dayjs(task.limitDate as string).format("YYYY-MM-DD")}
              </ItemStateText>
            </ItemState>
          )}
          {task.estimatedHours && (
            <ItemState>
              <ItemStateIcon icon={hourglassOutline} />
              <ItemStateText>{task.estimatedHours}H</ItemStateText>
            </ItemState>
          )}
          {task.tagIds.length > 0 && (
            <ItemState>
              <ItemStateIcon icon={pricetagOutline} />
              {formatItems(task.tagIds, taskTags).map(({ id, name }) => (
                <ItemStateText key={id}>{name}</ItemStateText>
              ))}
            </ItemState>
          )}
        </ItemStates>
        {/* サブタスク */}
        {subTasks &&
          subTasks.filter((subTask) => subTask.mainTaskId === task.id).length >
            0 && (
            <WrapSubTasks>
              {subTasks
                .filter((subTask) => subTask.mainTaskId === task.id)
                .map((subTask) => (
                  <SubTask
                    key={subTask.id}
                    taskColor={task.color}
                    routerLink={appLinks.task(
                      subTask.workspaceId,
                      tabId,
                      subTask.projectId,
                      subTask.id
                    )}
                    onClick={(e) => e.preventDefault()}
                  >
                    <SubTaskIcon
                      icon={
                        subTask.done ? checkmarkCircle : checkmarkCircleOutline
                      }
                    />
                    {subTask.title}
                  </SubTask>
                ))}
            </WrapSubTasks>
          )}
      </AppLabel>
      {isSortMode !== true && (
        <>
          {taskNotificationsCount > 0 && (
            <ItemBadge>{taskNotificationsCount}</ItemBadge>
          )}
          {task.assignedMemberId !== null && (
            <ItemAvatar>
              <img
                src={formatMember(task.assignedMemberId, memberEntities).avatar}
              />
            </ItemAvatar>
          )}
          <ItemOptionsButton
            fill="clear"
            color="medium"
            shape="round"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setTaskOptionsPopover({
                show: true,
                event: {
                  ...e.nativeEvent,
                  target: e.currentTarget,
                },
              });
              if (setProjectId) {
                setProjectId(task.projectId);
              }
            }}
          >
            <ItemOptionsButtonIcon icon={ellipsisVertical} slot="icon-only" />
          </ItemOptionsButton>
        </>
      )}
      <AppReorder slot="end" style={{ marginRight: "8px" }} />
      {/* タスク詳細 popover */}
      <AppPopover
        event={taskOptionsPopover.event}
        isOpen={taskOptionsPopover.show}
        onDidDismiss={() => setTaskOptionsPopover({ show: false })}
        className="app-popover-width200"
      >
        <AppItemGroup>
          <AppItem
            button={true}
            detail={false}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setTaskOptionsPopover({ show: false });
              showWriteTaskModal({
                presentingElement: routerRef,
                canDismiss: true,
              });
            }}
          >
            <AppItemIcon icon={createOutline} />
            <AppLabel>編集</AppLabel>
          </AppItem>
          {taskBookmarks.find(({ taskId }) => taskId === task.id) && (
            <AppItem
              button={true}
              detail={false}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setTaskOptionsPopover({ show: false });
                if (!auth) return;
                Promise.resolve()
                  .then(() =>
                    appApis.deleteTaskBookmark(
                      task.workspaceId,
                      task.id,
                      auth.id
                    )
                  )
                  .then(() => {
                    showToast({
                      message: i18nText.success.taskBookmark.delete(),
                      duration: 1000,
                    });
                  })
                  .catch((error) => {
                    showAlert({
                      message: i18nErrorToString(error),
                    });
                  });
              }}
            >
              <AppItemIcon icon={star} />
              <AppLabel>ブックマーク削除</AppLabel>
            </AppItem>
          )}
          {auth && !taskBookmarks.find(({ taskId }) => taskId === task.id) && (
            <AppItem
              button={true}
              detail={false}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setTaskOptionsPopover({ show: false });
                const taskBookmark = generateTaskBookmark(
                  task.workspaceId,
                  task.projectId,
                  task.id,
                  auth.id
                );
                Promise.resolve()
                  .then(() => appApis.createTaskBookmark(taskBookmark))
                  .then(() => {
                    showToast({
                      message: i18nText.success.taskBookmark.create(),
                      duration: 1000,
                    });
                  })
                  .catch((error) => {
                    showAlert({
                      message: i18nErrorToString(error),
                    });
                  });
              }}
            >
              <AppItemIcon icon={starOutline} />
              <AppLabel>ブックマーク追加</AppLabel>
            </AppItem>
          )}
          {PLATFORM.IS_DESKTOP && (
            <AppItem
              button={true}
              detail={false}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const url =
                  getRoot() +
                  appLinks.task(
                    task.workspaceId,
                    tabId,
                    task.projectId,
                    task.id
                  );
                window.open(url, "_blank");
                setTaskOptionsPopover({ show: false });
              }}
            >
              <AppItemIcon icon={openOutline} />
              <AppLabel>新しいタブで開く</AppLabel>
            </AppItem>
          )}
        </AppItemGroup>
      </AppPopover>
    </Item>
  );
};

export default AppTask;
