import { ComponentProps, FunctionComponent } from "react";
import { IonRadio } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonRadio> {}

const Radio = styled(IonRadio)``;

const AppRadio: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Radio {...props}>{children}</Radio>;
};

export default AppRadio;
