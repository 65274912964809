import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Team } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useTeam = ({
  workspaceId,
  teamId,
}: {
  workspaceId: string;
  teamId: string;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Team | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth || !workspaceId || !teamId) {
      setData(null);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis.$readTeam(workspaceId, teamId).subscribe({
      next: (team) => {
        console.log("useTeam");
        setData(team);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.log(error);
        setData(null);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId, teamId]);

  return { data, loading, error };
};
