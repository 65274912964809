import { ComponentProps, FunctionComponent } from "react";
import { IonRouterLink } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonRouterLink> {}

const RouterLink = styled(IonRouterLink)``;

const AppRouterLink: FunctionComponent<Props> = ({ children, ...props }) => {
  return <RouterLink {...props}>{children}</RouterLink>;
};

export default AppRouterLink;
