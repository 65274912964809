import { ComponentProps, FunctionComponent } from "react";
import { IonAccordion } from "@ionic/react";
import styled from "styled-components";
import { chevronDownOutline } from "ionicons/icons";

interface Props extends ComponentProps<typeof IonAccordion> {}

const Accordion = styled(IonAccordion)`
  .ion-accordion-toggle-icon {
    color: var(--app-color-grey400);
  }
`;

const AppAccordion: FunctionComponent<Props> = ({ children, ...props }) => {
  return (
    <Accordion {...props} toggleIcon={chevronDownOutline}>
      {children}
    </Accordion>
  );
};

export default AppAccordion;
