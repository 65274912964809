import { ComponentProps, FunctionComponent } from "react";
import { IonLabel } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonLabel> {}

const Label = styled(IonLabel)`
  white-space: normal !important;
  font-size: 16px;
`;

const AppLabel: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Label {...props}>{children}</Label>;
};

export default AppLabel;
