import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const InnerContent = styled.div`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
`;

const AppInnerContent: FunctionComponent<Props> = ({ children, ...props }) => {
  return <InnerContent {...props}>{children}</InnerContent>;
};

export default AppInnerContent;
