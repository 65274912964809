import { ComponentProps, FunctionComponent } from "react";
import { IonItemGroup } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonItemGroup> {}

const ItemGroup = styled(IonItemGroup)`
  ion-item:last-child {
    --inner-border-width: 0;
  }
`;

const AppItemGroup: FunctionComponent<Props> = ({ children }) => {
  return <ItemGroup>{children}</ItemGroup>;
};

export default AppItemGroup;
