import {
  IonIcon,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import dayjs from "dayjs";
import { addOutline, linkOutline } from "ionicons/icons";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  AppAddComma,
  AppCard,
  AppContent,
  AppErrorCard,
  AppFabButton,
  AppFabButtons,
  AppItem,
  AppItemGroup,
  AppLead,
  AppLoadingCard,
  AppMenuButton,
  AppCreateInvitationsModal,
  AppCreateInvitationsModalProps,
  AppButtons,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useError, useLoading, useOffices, useProjects } from "../../../hooks";
import { useInvitations } from "../../../hooks";
import { TabId } from "../../../models";
import {
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import {
  selectWorkspace,
  selectWorkspacesError,
  selectWorkspacesLoading,
} from "../../../store/workspacesSlice";
import {
  selectTeams,
  selectTeamsLoading,
  selectTeamsError,
} from "../../../store/teamsSlice";
import {
  selectUser,
  selectUserError,
  selectUserLoading,
} from "../../../store/userSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { ERROR_CODE, TEXT } from "../../../utilities/UtilStatic";
import { i18nText, i18nRoleToText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  formatMember,
  formatItems,
  getRoot,
} from "../../../utilities/UtilFunction";

const Items = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 8px 0;
`;

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ItemTitle = styled.div`
  min-width: 100px;
  flex: 0 1 auto;
  margin-right: 8px;
  color: var(--app-color-grey600);
`;

const ItemText = styled.div`
  flex: 1 0 0%;
  word-break: break-all;
`;

const Link = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding-top: 8px;
  margin-bottom: 16px;
  align-items: center;
`;

const LinkText = styled.div`
  word-break: break-all;
  flex: 1 0 0%;
  user-select: auto;
`;

const LinkIcon = styled(IonIcon)`
  flex: 0 1 auto;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
`;

const UsedMember = styled.div`
  padding-top: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const UsedMemberText = styled.div`
  font-size: 16px;
`;

const UsedMemberIcon = styled.div`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  background: var(--app-color-grey500);
  color: var(--app-color-white);
  margin-right: 8px;
`;

const TabInvitationsPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // stores
  const user = useSelector(selectUser);
  const userLoading = useSelector(selectUserLoading);
  const userError = useSelector(selectUserError);
  const workspace = useSelector(selectWorkspace(workspaceId));
  const workspacesLoading = useSelector(selectWorkspacesLoading);
  const workspacesError = useSelector(selectWorkspacesError);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const teams = useSelector(selectTeams);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  // hooks projectsとofficesはstoreからではなくworkspace内の全てを表示。このページはadminしか表示できないからそうする
  const projects = useProjects({ workspaceId });
  const invitations = useInvitations({ workspaceId });
  const offices = useOffices({ workspaceId });
  // loading & error
  const loading = useLoading(
    userLoading,
    workspacesLoading,
    membersLoading,
    teamsLoading,
    projects.loading,
    invitations.loading,
    offices.loading
  );
  const error = useError(
    userError,
    workspacesError,
    membersError,
    teamsError,
    projects.error,
    invitations.error,
    offices.error
  );
  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const [showAppCreateInvitationsModal, hideAppCreateInvitationsModal] =
    useIonModal(AppCreateInvitationsModal, {
      workspace,
      projects: projects.data,
      offices: offices.data,
      createInvitations: (
        workspaceId,
        projectIds,
        officeIds,
        teamIds,
        role,
        dayToExpire,
        howMany
      ) => {
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.resolve()
          .then(() =>
            appApis.createInvitations(
              workspaceId,
              projectIds,
              officeIds,
              teamIds,
              role,
              dayToExpire,
              howMany
            )
          )
          .then(() => {
            showToast({
              message: i18nText.success.invitation.create(),
              duration: 2000,
            });
            hideAppCreateInvitationsModal();
          })
          .catch((error) => {
            if (error?.code === ERROR_CODE.FUNCTIONS.OUT_OF_RANGE) {
              showAlert({
                message:
                  "フリープランでは5名のメンバーまでご利用頂けます。メンバーが5名を超える場合はビジネスプランへの加入をご検討ください。",
              });
            } else {
              showAlert({ message: i18nErrorToString(error) });
            }
          })
          .finally(() => hideLoading());
      },
      hide: () => hideAppCreateInvitationsModal(),
    } as AppCreateInvitationsModalProps);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppMenuButton />
          </AppButtons>
          <AppTitle>メンバー招待</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent fabButtonCount={1}>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && (
            <>
              {invitations.data.length > 0 && (
                <>
                  <AppLead marginBottom="16px">
                    招待する方にURLをお知らせください。
                  </AppLead>
                  <AppCard>
                    <AppItemGroup>
                      {user &&
                        invitations.data.map((item) => {
                          const url = `${getRoot()}${appLinks.invitation(
                            item.workspaceId,
                            item.id,
                            user.language
                          )}`;
                          return (
                            <AppItem key={item.id}>
                              <Items>
                                {!item.usedBy && (
                                  <Link>
                                    <LinkIcon
                                      icon={linkOutline}
                                      color="primary"
                                      onClick={() => {
                                        Promise.resolve()
                                          .then(() =>
                                            navigator.clipboard.writeText(url)
                                          )
                                          .then(() => {
                                            showToast({
                                              message:
                                                "リンクをクリップボードにコピーしました",
                                              duration: 1000,
                                            });
                                          })
                                          .catch((error) => {});
                                      }}
                                    />
                                    <LinkText>{url}</LinkText>
                                  </Link>
                                )}
                                {item.usedBy && (
                                  <UsedMember>
                                    <UsedMemberIcon>使用済み</UsedMemberIcon>
                                    <UsedMemberText>
                                      {
                                        formatMember(
                                          item.usedBy,
                                          memberEntities
                                        ).name
                                      }
                                    </UsedMemberText>
                                  </UsedMember>
                                )}
                                <Item>
                                  <ItemTitle>プロジェクト</ItemTitle>
                                  {item.projectIds.length === 0 && (
                                    <ItemText>{TEXT.EMPTY}</ItemText>
                                  )}
                                  <ItemText>
                                    {formatItems(
                                      item.projectIds,
                                      projects.data
                                    ).map(({ id, name }) => (
                                      <AppAddComma key={id}>{name}</AppAddComma>
                                    ))}
                                  </ItemText>
                                </Item>
                                <Item>
                                  <ItemTitle>オフィス</ItemTitle>
                                  {item.officeIds.length === 0 && (
                                    <ItemText>-</ItemText>
                                  )}
                                  <ItemText>
                                    {formatItems(
                                      item.officeIds,
                                      offices.data
                                    ).map(({ id, name }) => (
                                      <AppAddComma key={id}>{name}</AppAddComma>
                                    ))}
                                  </ItemText>
                                </Item>
                                <Item>
                                  <ItemTitle>チーム</ItemTitle>
                                  {item.teamIds.length === 0 && (
                                    <ItemText>-</ItemText>
                                  )}
                                  <ItemText>
                                    {formatItems(item.teamIds, teams).map(
                                      ({ id, name }) => (
                                        <AppAddComma key={id}>
                                          {name}
                                        </AppAddComma>
                                      )
                                    )}
                                  </ItemText>
                                </Item>
                                <Item>
                                  <ItemTitle>権限</ItemTitle>
                                  <ItemText>
                                    {i18nRoleToText(item.role)}
                                  </ItemText>
                                </Item>
                                {!item.usedBy && (
                                  <Item>
                                    <ItemTitle>有効期限</ItemTitle>
                                    <ItemText>
                                      {dayjs(item.expiryAt.toDate()).format(
                                        "YYYY-MM-DD HH:mm"
                                      )}
                                    </ItemText>
                                  </Item>
                                )}
                              </Items>
                            </AppItem>
                          );
                        })}
                    </AppItemGroup>
                  </AppCard>
                </>
              )}
              {invitations.data.length === 0 && (
                <AppLead>
                  「招待作成」ボタンを選択しワークスペースへの招待を作成してください。
                </AppLead>
              )}
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFabButtons vertical="bottom" horizontal="end">
        <AppFabButton
          onClick={() => {
            showAppCreateInvitationsModal({
              presentingElement: routerRef,
              canDismiss: true,
            });
          }}
        >
          <IonIcon slot="start" icon={addOutline} />
          招待作成
        </AppFabButton>
      </AppFabButtons>
    </AppPage>
  );
};

export default TabInvitationsPage;
