import { FunctionComponent, useMemo, useState } from "react";
import { searchOutline } from "ionicons/icons";
import { Auth } from "../../../models";
import { uniq, difference } from "lodash";
import { AppCheckbox, AppIcon, AppInput, AppItem, AppLabel } from "../..";
import styled from "styled-components";
import { IonBadge } from "@ionic/react";

const Badge = styled(IonBadge)`
  --background: var(--app-color-grey400);
  margin-left: 8px;
`;

export interface AppSelectNoticeMembersPopoverProps {
  auth: Auth | null;
  collaborateMemberIds: string[];
  items: { id: string; name: string }[];
  selectedIds: string[];
  filterKeywords?: string[];
  selectNoticeMembers: (ids: string[]) => void;
}

const AppSelectNoticeMembersPopover: FunctionComponent<
  AppSelectNoticeMembersPopoverProps
> = ({
  auth,
  collaborateMemberIds,
  items,
  selectedIds,
  filterKeywords = [],
  selectNoticeMembers,
}) => {
  const [filterText, setFilterText] = useState<string>("");
  const filterCollaborateMemberIds = useMemo(() => {
    if (auth) {
      return collaborateMemberIds.filter((id) => id !== auth?.id);
    } else {
      return collaborateMemberIds;
    }
  }, [auth, collaborateMemberIds]);

  const filterItems = useMemo(() => {
    if (filterText === "") {
      return items.filter((item) => item.id !== auth?.id);
    } else {
      return items
        .filter((item: any) => {
          for (const filterKeyword of filterKeywords) {
            const target = item[filterKeyword]
              ? String(item[filterKeyword]).toLowerCase()
              : "";
            if (target.includes(filterText.toLowerCase()) === true) return true;
          }
          return false;
        })
        .filter((item) => item.id !== auth?.id);
    }
  }, [items, filterText]);

  return (
    <>
      {filterKeywords.length > 0 && (
        <AppItem
          button={false}
          detail={false}
          style={{ "--background": "var(--app-color-grey100)" }}
          lines="full"
        >
          <AppIcon slot="start" icon={searchOutline} />
          <AppInput
            value={filterText}
            placeholder="検索"
            clearInput={true}
            clearOnEdit={true}
            onIonChange={(e) => setFilterText(e.detail.value!)}
            name="search"
          />
        </AppItem>
      )}
      {filterText === "" && (
        <>
          <AppItem
            button={true}
            detail={false}
            onClick={() => selectNoticeMembers([])}
          >
            <AppCheckbox
              slot="start"
              checked={selectedIds.length === 0}
              mode="md"
            />
            <AppLabel>-</AppLabel>
          </AppItem>
          {filterCollaborateMemberIds.length > 0 && (
            <AppItem
              button={true}
              detail={false}
              onClick={() => {
                // 関係者を全て選択 or 関係者を全て選択から外す
                if (
                  filterCollaborateMemberIds.every((memberId) =>
                    selectedIds.some((id) => id === memberId)
                  )
                ) {
                  selectNoticeMembers(
                    difference(selectedIds, filterCollaborateMemberIds)
                  );
                } else {
                  selectNoticeMembers(
                    uniq([...selectedIds, ...filterCollaborateMemberIds])
                  );
                }
              }}
            >
              <AppCheckbox
                slot="start"
                checked={filterCollaborateMemberIds.every((memberId) =>
                  selectedIds.some((id) => id === memberId)
                )}
                mode="md"
              />
              <AppLabel>関係者</AppLabel>
              <Badge slot="end">{filterCollaborateMemberIds.length}</Badge>
            </AppItem>
          )}
        </>
      )}
      {filterItems.map((filterItem, index) => {
        return (
          <AppItem
            key={index}
            button={true}
            detail={false}
            onClick={() => {
              const isChecked = selectedIds.some((id) => id === filterItem.id);
              if (isChecked) {
                selectNoticeMembers(
                  selectedIds.filter((id) => id !== filterItem.id)
                );
              } else {
                selectNoticeMembers([...selectedIds, filterItem.id]);
              }
            }}
          >
            <AppCheckbox
              slot="start"
              checked={selectedIds.some((id) => id === filterItem.id)}
              mode="md"
            />
            <AppLabel>{filterItem.name}</AppLabel>
          </AppItem>
        );
      })}
    </>
  );
};

export default AppSelectNoticeMembersPopover;
