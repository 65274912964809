import { IonFab } from "@ionic/react";
import { ComponentProps, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonFab> {}

const Buttons = styled(IonFab)`
  display: flex;
  flex-flow: column nowrap;
`;

const AppFabButtons: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Buttons {...props}>{children}</Buttons>;
};

export default AppFabButtons;
