import { ComponentProps, FunctionComponent } from "react";
import { IonInput } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonInput> {}

const Input = styled(IonInput)``;

const AppInput: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Input {...props}>{children}</Input>;
};

export default AppInput;
