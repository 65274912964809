import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./";
import { Team } from "../models/Team";

interface TeamsState extends EntityState<Team> {
  loading: boolean;
  error: string | null;
}

const adapter = createEntityAdapter<Team>();

const initialState: TeamsState = adapter.getInitialState({
  loading: true,
  error: null,
});

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setTeams: (state, action: PayloadAction<Team[]>) => {
      adapter.setAll(state, action.payload);
    },
    setTeamsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTeamsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setTeams, setTeamsLoading, setTeamsError } = teamsSlice.actions;

export const selectTeams = adapter.getSelectors(
  (state: RootState) => state.teams
).selectAll;

export const selectTeamEntities = adapter.getSelectors(
  (state: RootState) => state.teams
).selectEntities;

export const selectMyTeams = (memberId: string | null) => {
  return createSelector(selectTeams, (teams) => {
    return teams.filter(({ memberIds }) =>
      memberIds.some((id) => id === memberId)
    );
  });
};

export const selectTeamsLoading = (state: RootState) => state.teams.loading;

export const selectTeamsError = (state: RootState) => state.teams.error;

export default teamsSlice.reducer;
