import { useIonAlert, useIonLoading } from "@ionic/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { appApis } from "../../../apis/Api";
import { i18nError } from "../../../utilities/UtilI18nText";
import { appLinks } from "../../../utilities/UtilPage";
import {
  AppFormInput,
  AppButton,
  AppFooterStoreLink,
  AppLogo,
  AppLandingHeader,
  AppActionButton,
  AppContent,
  AppPage,
} from "../..";
import styled from "styled-components";
import { ENV, PAGES, PLATFORM } from "../../../utilities/UtilStatic";
import {
  getEmailAndPassword,
  removeEmailAndPassword,
  setEmailAndPassword,
} from "../../../utilities/UtilFunction";

const AuthContent = styled.div`
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`;

const AuthMain = styled.div`
  width: 100%;
  padding: 0 12px;
  max-width: 400px;
`;

const AuthCard = styled.div`
  width: 100%;
  border: 1px solid var(--app-color-grey300);
  background: white;
  margin: 0 0 8px;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
  color: var(--app-color-grey900);
`;

const AuthLogo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 16px 0;
  background: white;
  margin-bottom: 8px;
`;

const AuthLead = styled.div`
  font-size: 16px;
  color: var(--app-color-grey600);
  letter-spacing: 1px;
  margin-bottom: 4px;
  padding: 0 16px;
`;

const AuthLink = styled.div`
  padding: 8px;
  width: 100%;
  text-align: center;
`;

const AuthLinkText = styled.div`
  font-size: 14px;
  padding-bottom: 8px;
  display: block;
  color: var(--app-color-grey800);
`;

const SignInPage: FunctionComponent = () => {
  // router
  const history = useHistory();
  // page
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // functions
  useEffect(() => {
    if (PLATFORM.IS_CAPACITOR) {
      Promise.resolve()
        .then(() => getEmailAndPassword())
        .then(({ email, password }) => {
          if (email && password) {
            setEmail(email);
            setPassword(password);
          }
        })
        .catch((error) => {});
    }
  }, []);
  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();

  return (
    <AppPage>
      {!PLATFORM.IS_CAPACITOR && <AppLandingHeader />}
      <AppContent>
        <AuthContent>
          <AuthMain>
            <AuthCard>
              <AuthLogo>
                <AppLogo
                  width="240px"
                  height="60px"
                  style={{ marginBottom: "24px" }}
                />
                {PLATFORM.IS_IOS && PLATFORM.IS_CAPACITOR && (
                  <>
                    <AuthLead>基本料金無料</AuthLead>
                    <AuthLead>お気軽にお試しください</AuthLead>
                  </>
                )}
                {ENV.FIREBASE.USE.DEV && <AuthLead>開発環境</AuthLead>}
                {ENV.FIREBASE.USE.TEST && <AuthLead>テスト環境</AuthLead>}
              </AuthLogo>
              <AppFormInput
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                label="Eメール"
                type="email"
                marginBottom="8px"
                name="email"
              />
              <AppFormInput
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
                label="パスワード"
                type="password"
                name="password"
              />
              <div style={{ paddingTop: "20px" }}>
                <AppActionButton
                  action="ok"
                  expand="block"
                  style={{ margin: 0 }}
                  onClick={() => {
                    showLoading({
                      cssClass: "app-loading",
                      backdropDismiss: true,
                    });
                    Promise.resolve()
                      .then(() => appApis.signIn(email, password))
                      .then((userId) => appApis.getUser(userId))
                      .then((user) => appApis.getWorkspaces(user.id))
                      .then((workspaces) => {
                        const workspace = workspaces[0];
                        if (!workspace) {
                          throw i18nError.notFound();
                        }
                        // ローカルストレージにIDとPASSを保存
                        if (PLATFORM.IS_CAPACITOR) {
                          Promise.resolve()
                            .then(() => setEmailAndPassword(email, password))
                            .catch((error) => {});
                        }
                        history.replace(
                          appLinks.tab(workspace.id, PAGES.TABS.PROJECT)
                        );
                        // フォーム初期化
                        setEmail("");
                        setPassword("");
                      })
                      .catch((error) => {
                        showAlert({
                          message: i18nError.authWrongEmailOrPassword(),
                        });
                      })
                      .finally(() => hideLoading());
                  }}
                >
                  サインイン
                </AppActionButton>
              </div>
            </AuthCard>
            {!(PLATFORM.IS_IOS && PLATFORM.IS_CAPACITOR) && (
              <>
                <AuthLink>
                  <AuthLinkText>
                    アカウントをお持ちでない方はこちら
                  </AuthLinkText>
                  <AppButton
                    color="primary"
                    fill="outline"
                    type="submit"
                    routerLink={appLinks.signUp()}
                    onClick={() => {
                      Promise.resolve()
                        .then(() => removeEmailAndPassword())
                        .catch((error) => {});
                    }}
                  >
                    無料で新規登録
                  </AppButton>
                </AuthLink>
              </>
            )}
          </AuthMain>
        </AuthContent>
      </AppContent>
      {!PLATFORM.IS_CAPACITOR && <AppFooterStoreLink />}
    </AppPage>
  );
};

export default SignInPage;
