import { FunctionComponent, RefObject, useEffect } from "react";
import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonBadge,
  useIonToast,
  useIonAlert,
} from "@ionic/react";
import { Route, useHistory, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  appLinks,
  appRouterInsideTabPages,
  appRouterTabPages,
} from "../../../utilities/UtilPage";
import { selectWorkspace } from "../../../store/workspacesSlice";
import { useSetStore } from "../../../hooks";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ja";
import { selectUser } from "../../../store/userSlice";
import styled from "styled-components";
import { selectWorkspaceTaskAndTaskCommentNotificationsCount } from "../../../store/notificationsSlice";
import { AppIcon, AppLabel } from "../..";
import { appApis } from "../../../apis/Api";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import {
  TabId,
  TaskCommentNotification,
  TaskNotification,
} from "../../../models";
import { i18nText } from "../../../utilities/UtilI18nText";
import {
  setEnableSideMenu,
  setTabId,
  setWorkspaceId,
} from "../../../store/uiSlice";
import {
  DAYJS,
  ENV,
  PAGES,
  PLATFORM,
  TEXT,
  VERSIONS,
} from "../../../utilities/UtilStatic";
import { compare } from "compare-versions";
import { AppUpdate } from "@capawesome/capacitor-app-update";

interface Props {
  tabRef: RefObject<InstanceType<typeof IonTabs>>;
  routerRef: HTMLElement;
}

const Badge = styled(IonBadge)`
  background: var(--app-color-notification);
  padding: 2px 4px;
  .ios & {
    min-width: 20px;
  }
  .md & {
    min-width: 15px;
  }
`;

const AppIndex: FunctionComponent<Props> = ({ tabRef, routerRef }) => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  const history = useHistory();
  // store
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const workspace = useSelector(selectWorkspace(workspaceId));
  const workspaceTaskAndTaskCommentNotificationsCount = useSelector(
    selectWorkspaceTaskAndTaskCommentNotificationsCount(workspaceId)
  );
  // components
  const [showToast] = useIonToast();
  const [showAlert, hideAlert] = useIonAlert();

  // functions
  useSetStore(workspaceId); // データをロードしreduxのstoreにセットする

  // スライドメニューを表示or非表示
  useIonViewWillEnter(() => dispatch(setEnableSideMenu(true)));
  useIonViewWillLeave(() => dispatch(setEnableSideMenu(false)));

  // ワークスペースIDをセット
  useEffect(() => {
    dispatch(setWorkspaceId(workspaceId));
  }, [workspaceId]);

  // タブID（選択されているタブのID）をセット（最初だけ。tabの切り替え時はtabId発火しないので）
  useEffect(() => {
    dispatch(setTabId(tabId));
  }, []);

  // サインインしていなければサインイン画面へリダイレクト
  useEffect(() => {
    const subscribe = appApis.$readAuthState().subscribe((user) => {
      if (!user) history.replace(appLinks.signIn());
    });
    return () => subscribe.unsubscribe();
  }, [history]);

  // ライブラリ初期化
  useEffect(() => {
    if (user && workspace) {
      dayjs.locale(user.language);
      dayjs.tz.setDefault(workspace.timezone ?? undefined);
    }
  }, [user, workspace]);

  // メンテナンス中などの情報チェック
  useEffect(() => {
    const subscribe = appApis.$readAppConfig().subscribe({
      next: ({
        underMaintenance,
        maintenanceBeginAt,
        maintenanceEndAt,
        requiredVersion,
      }) => {
        // メンテナンス情報
        if (underMaintenance) {
          const header = "メンテナンス";
          let message = "ただいまメンテナンス中です。";
          if (maintenanceBeginAt) {
            message += TEXT.NEW_LINE;
            message += `開始時間：${dayjs(maintenanceBeginAt.toDate()).format(
              DAYJS.MM_DD_HH_mm
            )}`;
          }
          if (maintenanceEndAt) {
            message += TEXT.NEW_LINE;
            message += `終了時間：${dayjs(maintenanceEndAt.toDate()).format(
              DAYJS.MM_DD_HH_mm
            )}`;
          }
          showAlert({
            header,
            message,
            backdropDismiss: false,
            cssClass: "app-alert-pre-wrap",
          });
          return;
        }
        // アップデートが必要
        if (compare(VERSIONS.APP, requiredVersion, "<")) {
          const header = "アップデート";
          if (PLATFORM.IS_CAPACITOR) {
            const message =
              "プリケーションを最新のバージョンにアップデートしてください。";
            showAlert({
              header,
              message,
              backdropDismiss: false,
              buttons: [
                {
                  text: "OK",
                  handler: () => {
                    Promise.resolve()
                      .then(() => AppUpdate.openAppStore())
                      .catch((error) => console.log("openAppStore()", error));
                  },
                },
              ],
            });
          } else {
            const message = "ブラウザを更新してください。";
            showAlert({
              header,
              message,
              backdropDismiss: false,
              buttons: [
                {
                  text: "OK",
                  handler: () => window.location.reload(),
                },
              ],
            });
          }
          return;
        }
        hideAlert();
      },
      error: (error) => console.log(error),
    });
    return () => subscribe.unsubscribe();
  }, []);

  // プッシュ通知登録
  useEffect(() => {
    if (!PLATFORM.IS_CAPACITOR) return;
    if (!PLATFORM.IS_IOS && !PLATFORM.IS_ANDROID) return;
    const platform = PLATFORM.IS_IOS ? "ios" : "android";
    // プッシュ通知の使用許諾を申請
    // iOSはユーザーにプロンプトを表示し、許可を得たかどうかを返します
    // Androidではプロンプトが表示されることなくそのまま許可されます
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        // Apple / Googleに登録し、APNS/FCMでプッシュを受信する
        PushNotifications.register();
      } else {
        console.log("Error", "PushNotifications.requestPermissions()");
      }
    });
    // 成功すると、通知を受け取ることができるようになるはずです
    PushNotifications.addListener("registration", (result: Token) => {
      Promise.resolve()
        .then(() => appApis.setDevice(result.value, platform))
        .catch((error) => console.log("error setDevice", error));
    });
    // 私たちの設定に問題があり、プッシュが機能しない
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration", JSON.stringify(error));
    });
    // 端末でアプリが開かれている場合、通知のペイロードを表示する
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        const data = notification.data as
          | TaskNotification
          | TaskCommentNotification;
        if (data.type === "task") {
          showToast({
            message: i18nText.push.assignedTask(),
            duration: 6000,
            position: "top",
            color: "navy",
            buttons: [
              {
                text: i18nText.buttons.detail(),
                handler: () => {
                  if (workspaceId === data.workspaceId) {
                    history.push(
                      appLinks.task(
                        data.workspaceId,
                        tabId,
                        data.projectId,
                        data.taskId
                      )
                    );
                  } else {
                    history.replace(
                      appLinks.task(
                        data.workspaceId,
                        tabId,
                        data.projectId,
                        data.taskId
                      )
                    );
                  }
                },
              },
            ],
          });
        }
        if (data.type === "taskComment") {
          const body = String(notification.body);
          showToast({
            message: body.length < 40 ? body : body.substring(0, 40) + "...",
            duration: 6000,
            position: "top",
            color: "navy",
            buttons: [
              {
                text: i18nText.buttons.detail(),
                handler: () => {
                  if (workspaceId === data.workspaceId) {
                    history.push(
                      appLinks.taskComment(
                        data.workspaceId,
                        tabId,
                        data.projectId,
                        data.taskId,
                        data.taskCommentId
                      )
                    );
                  } else {
                    history.replace(
                      appLinks.taskComment(
                        data.workspaceId,
                        tabId,
                        data.projectId,
                        data.taskId,
                        data.taskCommentId
                      )
                    );
                  }
                },
              },
            ],
          });
        }
      }
    );

    // 通知をタップしたときに呼び出されるメソッド
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        const data = notification.notification.data as
          | TaskNotification
          | TaskCommentNotification;
        if (data.type === "task") {
          if (workspaceId === data.workspaceId) {
            history.push(
              appLinks.task(
                data.workspaceId,
                tabId,
                data.projectId,
                data.taskId
              )
            );
          } else {
            history.replace(
              appLinks.task(
                data.workspaceId,
                tabId,
                data.projectId,
                data.taskId
              )
            );
          }
        }
        if (data.type === "taskComment") {
          if (workspaceId === data.workspaceId) {
            history.push(
              appLinks.taskComment(
                data.workspaceId,
                tabId,
                data.projectId,
                data.taskId,
                data.taskCommentId
              )
            );
          } else {
            history.replace(
              appLinks.taskComment(
                data.workspaceId,
                tabId,
                data.projectId,
                data.taskId,
                data.taskCommentId
              )
            );
          }
        }
      }
    );
  }, []);

  return (
    <IonTabs
      ref={tabRef}
      onIonTabsDidChange={(e) => dispatch(setTabId(e.detail.tab as TabId))}
    >
      <IonRouterOutlet>
        {appRouterTabPages().map((page, index) => {
          return (
            <Route
              path={page.path}
              render={() => <page.component routerRef={routerRef} />}
              exact={true}
              key={index}
            />
          );
        })}
        {appRouterInsideTabPages.map((page, index) => {
          return (
            <Route
              path={page.path}
              render={() => <page.component routerRef={routerRef} />}
              exact={true}
              key={index}
            />
          );
        })}
        {/* <Route component={Error404Page} exact /> */}
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="ion-hide-md-up" color="navy">
        {workspaceId &&
          appRouterTabPages().map((page, index) => {
            return (
              <IonTabButton
                tab={page.tab}
                href={appLinks.tab(workspaceId, page.tab)}
                key={index}
                className={page.showTab ? "" : "ion-hide"}
              >
                <AppIcon
                  icon={page.tab === tabId ? page.icon : page.iconOutline}
                />
                <AppLabel>{page.name}</AppLabel>
                {page.tab === PAGES.TABS.PROJECT &&
                  workspaceTaskAndTaskCommentNotificationsCount > 0 && (
                    <Badge>
                      {workspaceTaskAndTaskCommentNotificationsCount}
                    </Badge>
                  )}
              </IonTabButton>
            );
          })}
      </IonTabBar>
    </IonTabs>
  );
};

export default AppIndex;
