import { FunctionComponent, useEffect, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppCard,
  AppFormInput,
  AppItemGroup,
  AppFooterButtons,
  AppActionButton,
  AppHeader,
  AppToolbar,
  AppTitle,
  AppButtons,
  AppButton,
  AppIcon,
  AppText,
  AppItem,
  AppCheckbox,
  AppLabel,
  AppCardContent,
  AppInnerContent,
  AppPage,
} from "../..";
import { Team } from "../../../models";
import { useSelector } from "react-redux";
import { selectMembers } from "../../../store/membersSlice";
import { selectAuth } from "../../../store/authSlice";
import { generateTeam } from "../../../utilities/UtilFunction";
import { cloneDeep } from "lodash";
import { getTimestampNow } from "../../../utilities/UtilFunction";
import { selectWorkspaceId } from "../../../store/uiSlice";

export interface AppWriteTeamModalProps {
  team: Team | null;
  deleteTeam: (workspaceId: string, teamId: string) => void;
  saveTeam?: (team: Team) => void;
  hide: () => void;
}

const AppWriteTeamModal: FunctionComponent<AppWriteTeamModalProps> = ({
  team,
  deleteTeam,
  saveTeam,
  hide,
}) => {
  // store
  const workspaceId = useSelector(selectWorkspaceId);
  const auth = useSelector(selectAuth);
  const members = useSelector(selectMembers);
  // states
  const [form, setForm] = useState<Team | null>(null);
  // functions フォーム初期化
  useEffect(() => {
    if (!auth) return;
    if (team) {
      setForm({
        ...team,
        updatedBy: auth.id,
        updatedAt: getTimestampNow(),
      });
    } else {
      setForm(generateTeam(workspaceId, auth.id));
    }
  }, [auth, team]);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>チーム</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {form && (
            <AppCard>
              <AppCardContent>
                <AppFormInput
                  value={form.name}
                  onIonChange={(e) => {
                    setForm({ ...form, name: e.detail.value ?? "" });
                  }}
                  type="text"
                  label="チーム名"
                  placeholder="例）デザイナー"
                  helperText="チーム名を入力してください"
                  name="name"
                />
                <AppText>チームに追加するメンバーを選択してください</AppText>
                <AppItemGroup>
                  {members.map(({ id, name }) => (
                    <AppItem key={id}>
                      <AppCheckbox
                        slot="start"
                        checked={form.memberIds.some(
                          (memberId) => memberId === id
                        )}
                        onIonChange={(e) => {
                          const checked = e.detail.checked;
                          const clone = cloneDeep(form);
                          if (checked) {
                            clone.memberIds.push(id);
                          } else {
                            clone.memberIds = clone.memberIds.filter(
                              (memberId) => memberId !== id
                            );
                          }
                          setForm(clone);
                        }}
                      ></AppCheckbox>
                      <AppLabel>{name}</AppLabel>
                    </AppItem>
                  ))}
                </AppItemGroup>
              </AppCardContent>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        {team && (
          <AppActionButton
            action="delete"
            onClick={() => deleteTeam(team.workspaceId, team.id)}
          >
            {i18nText.buttons.delete()}
          </AppActionButton>
        )}
        <AppActionButton
          action="ok"
          onClick={() => {
            if (form && saveTeam) saveTeam(form);
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppWriteTeamModal;
