import { FunctionComponent, useEffect, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppFooterButtons,
  AppActionButton,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppButton,
  AppIcon,
  AppTitle,
  AppInnerContent,
  AppPage,
  AppCard,
  AppCardContent,
  AppFormButton,
  AppFormInput,
  AppSelectPopover,
  AppSelectPopoverProps,
} from "../..";
import { Timezone, Workspace } from "../../../models";
import { generateWorkspace } from "../../../utilities/UtilFunction";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../store/authSlice";
import { useIonPopover } from "@ionic/react";
import { appApis } from "../../../apis/Api";
import { TEXT, TIMEZONES } from "../../../utilities/UtilStatic";
import { getTimestampNow } from "../../../utilities/UtilFunction";

export interface AppWriteWorkspaceModalProps {
  workspace: Workspace | null;
  deleteWorkspace: (workspaceId: string) => void;
  saveWorkspace?: (workspace: Workspace) => void;
  hide: () => void;
}

const AppWriteWorkspaceModal: FunctionComponent<
  AppWriteWorkspaceModalProps
> = ({ workspace, hide, deleteWorkspace, saveWorkspace }) => {
  // store
  const auth = useSelector(selectAuth);
  //  states フォーム
  const [form, setForm] = useState<Workspace | null>(null);
  // functions フォーム初期化
  useEffect(() => {
    if (auth) {
      if (workspace) {
        setForm({
          ...workspace,
          updatedBy: auth.id,
          updatedAt: getTimestampNow(),
        });
      } else {
        setForm(generateWorkspace(appApis.createId(), auth.id));
      }
    }
  }, [workspace, auth]);
  // components
  const [showSelectTimezonePopover, hideSelectTimezonePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: TIMEZONES.map((item) => ({ id: item, name: item })),
      selectedId: form?.timezone,
      filterKeywords: ["name"],
      isSetNull: true,
      selectItem: (timezone: Timezone) => {
        if (!form) return;
        setForm({ ...form, timezone });
        hideSelectTimezonePopover();
      },
    } as AppSelectPopoverProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>{i18nText.features.workspace()}</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {form && (
            <AppCard>
              <AppCardContent>
                <AppFormInput
                  value={form.name}
                  onIonChange={(e) => {
                    setForm({ ...form, name: e.detail.value ?? "" });
                  }}
                  type="text"
                  label="ワークスペース名"
                  marginBottom="16px"
                  name="name"
                />
                <AppFormButton
                  label={i18nText.selects.timezone.label()}
                  width="280px"
                  onClick={(e) => {
                    showSelectTimezonePopover({
                      cssClass: [
                        "app-popover-width320",
                        "app-popover-height320",
                      ],
                      event: {
                        ...e.nativeEvent,
                        target: e.currentTarget,
                      },
                    });
                  }}
                >
                  {form.timezone ?? TEXT.EMPTY}
                </AppFormButton>
              </AppCardContent>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        {workspace && (
          <AppActionButton
            action="delete"
            onClick={() => deleteWorkspace(workspace.id)}
          >
            {i18nText.buttons.delete()}
          </AppActionButton>
        )}
        <AppActionButton
          action="ok"
          onClick={() => {
            if (form && saveWorkspace) saveWorkspace(form);
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppWriteWorkspaceModal;
