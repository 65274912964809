import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIonPopover, useIonAlert, IonButton, IonIcon } from "@ionic/react";
import {
  addOutline,
  caretDownSharp,
  caretUpSharp,
  closeOutline,
  removeOutline,
} from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nPriorityToText } from "../../../utilities/UtilI18nText";
import { Color, Priority } from "../../../models";
import {
  AppContent,
  AppCard,
  AppFormInput,
  AppFooterButtons,
  AppActionButton,
  AppFormButton,
  AppSelectColorPopover,
  AppSelectDatePopover,
  AppSelectMainTaskPopover,
  AppSelectColorPopoverProps,
  AppSelectDatePopoverProps,
  AppSelectMainTaskPopoverProps,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppSelectsPopover,
  AppSelectsPopoverProps,
  AppSelectPriorityPopoverProps,
  AppSelectPriorityPopover,
  AppLabel,
  AppWrapFormItem,
  AppButton,
  AppIcon,
  AppHeader,
  AppToolbar,
  AppTitle,
  AppButtons,
  AppCardContent,
  AppInnerContent,
  AppPage,
  AppAddComma,
} from "../..";
import { useSubTasks, useTask } from "../../../hooks";
import dayjs from "dayjs";
import { Task } from "../../../models";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import {
  getColor,
  generateOrder,
  getTimestampNow,
  formatMember,
  formatMembers,
  formatItems,
  typeOfString,
} from "../../../utilities/UtilFunction";
import { $getRoot, EditorState } from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ACTION, LEXICAL_THEME, TEXT } from "../../../utilities/UtilStatic";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../store/authSlice";
import {
  selectMemberEntities,
  selectMembers,
  selectProjectMembers,
} from "../../../store/membersSlice";
import { buildTaskTokenMap } from "../../../utilities/UtilFunction";
import { generateTask } from "../../../utilities/UtilFunction";
import {
  selectProjectTaskStates,
  selectTaskStateEntities,
} from "../../../store/taskStatesSlice";
import { selectProjectTaskTags } from "../../../store/taskTagsSlice";
import { selectProjectTaskMilestones } from "../../../store/taskMilestonesSlice";
import { selectWorkspaceId } from "../../../store/uiSlice";

export interface AppWriteTasksModalProps {
  projectId: string;
  taskId: string | null;
  deleteTasks: (task: Task) => void;
  saveTasks: (tasks: TaskForm[], mainTaskId: string) => void;
  hide: () => void;
}

interface TaskForm extends Task {
  action: "create" | "update" | "delete";
}

const WrapAddSubTaskButton = styled.div`
  display: flex;
  justify-content: center;
`;

const TaskStates = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px 8px;
  &::after {
    content: "";
    flex-grow: 999;
  }
`;

const WrapToggleTaskStatesButton = styled.div`
  display: flex;
  justify-content: center;
`;

const ToggleTaskStatesButton = styled(IonButton)`
  margin: 0 0 0;
  &::part(native) {
    color: var(--app-color-grey500);
  }
`;

const ToggleTaskStatesButtonIcon = styled(IonIcon)`
  font-size: 12px;
  margin-left: 4px;
  color: var(--app-color-grey400);
`;

const SubTask = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

const RemoveSubTaskButton = styled(IonButton)`
  margin: 2px 8px 0 0;
  --border-radius: 50%;
  --box-shadow: none;
  &::part(native) {
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
  }
`;

const RemoveSubTaskButtonIcon = styled(IonIcon)`
  font-size: 16px;
`;

const LexicalContainer = styled.div`
  position: relative;
`;

const LexicalPlaceholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  color: var(--app-color-grey400);
`;

const WriteTaskModal: FunctionComponent<AppWriteTasksModalProps> = ({
  projectId,
  taskId,
  deleteTasks,
  saveTasks,
  hide,
}) => {
  // store
  const workspaceId = useSelector(selectWorkspaceId);
  const auth = useSelector(selectAuth);
  const taskStateEntities = useSelector(selectTaskStateEntities);
  // alert
  const [showAlert] = useIonAlert();
  // data
  const mainTask = useTask({ workspaceId, taskId });
  const subTasks = useSubTasks({ workspaceId, projectId, taskId });
  const [mainTaskForm, setMainTaskForm] = useState<TaskForm | null>(null);
  const [subTaskForms, setSubTaskForms] = useState<TaskForm[]>([]);

  const hasSubTasks = useMemo(() => {
    return !subTasks.loading && !subTasks.error && subTasks.data.length > 0;
  }, [subTasks.data, subTasks.loading, subTasks.error]);

  // 更新用のフォームにデータセット
  useEffect(() => {
    if (!mainTask.loading && !mainTask.error && mainTask.data && auth) {
      setMainTaskForm({
        ...mainTask.data,
        action: ACTION.UPDATE,
        updatedBy: auth.id,
        updatedAt: getTimestampNow(),
      });
    }
  }, [mainTask.loading, mainTask.error, mainTask.data, auth]);

  useEffect(() => {
    if (auth) {
      setSubTaskForms(
        subTasks.data.map((subTask) => {
          return {
            ...subTask,
            action: ACTION.UPDATE,
            updatedBy: auth.id,
            updatedAt: getTimestampNow(),
          };
        })
      );
    }
  }, [subTasks.loading, subTasks.error, subTasks.data, auth]);

  // taskIdがnullで渡されると更新でなくメインタスク新規作成用のデータをセット
  useEffect(() => {
    if (!taskId && auth) {
      setMainTaskForm({
        action: ACTION.CREATE,
        ...generateTask(
          workspaceId,
          projectId,
          auth.id,
          null,
          -generateOrder()
        ),
      });
    }
  }, [workspaceId, projectId, taskId, auth]);

  const save = () => {
    if (!auth || !mainTaskForm) return;
    const mainTaskBefore: Task | null = mainTask.data;
    const mainTaskAfter: TaskForm = mainTaskForm;
    const taskForms: TaskForm[] = [];
    // プッシュ通知する相手
    let unreadNotificationMemberId: string | null = null;
    // 担当者が変わっていなければ通知状況も変えない。変わっていれば通知する
    if (mainTaskBefore?.assignedMemberId === mainTaskAfter.assignedMemberId) {
      unreadNotificationMemberId = mainTaskBefore.unreadNotificationMemberId;
    } else {
      unreadNotificationMemberId = mainTaskAfter.assignedMemberId;
    }
    // 担当者が自分なら通知しない
    if (auth.id === mainTaskAfter.assignedMemberId) {
      unreadNotificationMemberId = null;
    }
    taskForms.push({
      ...mainTaskAfter,
      unreadNotificationMemberId,
      mainTaskId: subTaskForms.length === 0 ? mainTaskAfter.mainTaskId : null, // 仮にサブタスクがある場合はnullにする
    });
    subTaskForms.forEach((subTaskAfter) => {
      const subTaskBefore = subTasks.data.find(
        ({ id }) => id === subTaskAfter.id
      );
      if (!subTaskBefore) {
        taskForms.push(subTaskAfter);
      } else {
        // プッシュ通知する相手
        let unreadNotificationMemberId: string | null = null;
        // 担当者が変わっていなければ通知状況も変えない。変わっていれば通知する
        if (subTaskBefore.assignedMemberId === subTaskAfter.assignedMemberId) {
          unreadNotificationMemberId = subTaskBefore.unreadNotificationMemberId;
        } else {
          unreadNotificationMemberId = subTaskAfter.assignedMemberId;
        }
        // 担当者が自分なら通知しない
        if (auth.id === subTaskAfter.assignedMemberId) {
          unreadNotificationMemberId = null;
        }
        taskForms.push({
          ...subTaskAfter,
          unreadNotificationMemberId,
        });
      }
    });
    saveTasks(
      taskForms.map((taskForm) => {
        return {
          ...taskForm,
          done: taskStateEntities[taskForm.stateId ?? ""]?.done === true,
          tokenMap: buildTaskTokenMap(taskForm),
        };
      }),
      mainTaskForm.id
    );
  };

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>タスク編集</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {mainTaskForm && (
            <TaskForm
              {...{
                formTask: mainTaskForm,
                projectId,
                showMainTask:
                  hasSubTasks || subTaskForms.length > 0 ? false : true,
                isSubTask: false,
                onSet: (task: TaskForm) => setMainTaskForm(task),
                onPressEnterKey: () => save(),
              }}
            />
          )}
          {subTaskForms
            .filter((subTask) => subTask.action !== ACTION.DELETE)
            .map((subTask) => (
              <SubTask key={subTask.id}>
                <RemoveSubTaskButton
                  color="danger"
                  onClick={() => {
                    if (subTask.action === ACTION.CREATE) {
                      setSubTaskForms(
                        subTaskForms.filter((item) => item.id !== subTask.id)
                      );
                    }
                    if (subTask.action === ACTION.UPDATE) {
                      setSubTaskForms(
                        subTaskForms.map((item) => {
                          return item.id === subTask.id
                            ? { ...item, action: ACTION.DELETE }
                            : item;
                        })
                      );
                    }
                  }}
                >
                  <RemoveSubTaskButtonIcon
                    slot="icon-only"
                    icon={removeOutline}
                  />
                </RemoveSubTaskButton>
                <TaskForm
                  {...{
                    formTask: subTask,
                    projectId,
                    showMainTask: false,
                    isSubTask: true,
                    onSet: (task: TaskForm) => {
                      const clonesubTaskForms = cloneDeep(subTaskForms);
                      const index = clonesubTaskForms.findIndex(
                        (item) => item.id === task.id
                      );
                      if (index >= 0) {
                        clonesubTaskForms[index] = task;
                        setSubTaskForms(clonesubTaskForms);
                      }
                    },
                    onPressEnterKey: () => save(),
                  }}
                />
              </SubTask>
            ))}
          <WrapAddSubTaskButton>
            <AppActionButton
              onClick={() => {
                if (!auth || !mainTaskForm) return;
                const subTaskForm: TaskForm | undefined =
                  subTaskForms.slice(-1)[0];
                const order = subTaskForm
                  ? subTaskForm.order + 1
                  : -generateOrder();
                const newSubTask = {
                  action: ACTION.CREATE,
                  ...generateTask(
                    workspaceId,
                    projectId,
                    auth.id,
                    mainTaskForm.id,
                    order
                  ),
                };
                setSubTaskForms([...subTaskForms, newSubTask]);
              }}
            >
              <AppIcon slot="start" icon={addOutline} />
              サブタスク追加
            </AppActionButton>
          </WrapAddSubTaskButton>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        {taskId && (
          <AppActionButton
            action="delete"
            onClick={() => {
              if (!mainTask.data) return;
              showAlert({
                message: "タスクを削除しますか？",
                buttons: [
                  "キャンセル",
                  {
                    text: "Ok",
                    handler: () => deleteTasks(mainTask.data!),
                  },
                ],
              });
            }}
          >
            {i18nText.buttons.delete()}
          </AppActionButton>
        )}
        <AppActionButton action="ok" onClick={() => save()}>
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default WriteTaskModal;

interface TaskFormProps {
  formTask: TaskForm;
  projectId: string;
  showMainTask: boolean;
  isSubTask: boolean;
  onSet: (formTask: TaskForm) => void;
  onPressEnterKey: () => void;
}

const TaskForm: FunctionComponent<TaskFormProps> = ({
  formTask,
  projectId,
  showMainTask,
  isSubTask,
  onSet,
  onPressEnterKey,
}) => {
  //store
  const auth = useSelector(selectAuth);
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  const projectMembers = useSelector(selectProjectMembers(projectId));
  const projectTaskStates = useSelector(selectProjectTaskStates(projectId));
  const projectTaskTags = useSelector(selectProjectTaskTags(projectId));
  const projectTaskMilestones = useSelector(
    selectProjectTaskMilestones(projectId)
  );
  // data
  const mainTask = useTask({
    workspaceId: formTask.workspaceId,
    taskId: formTask.mainTaskId,
  });
  // states
  const [showTaskStates, setShowTaskStates] = useState<Boolean>(!isSubTask);
  // functions
  const selectMeAndMembers: {
    id: string;
    name: string;
  }[] = useMemo(() => {
    if (!auth) return [];
    const members = projectMembers.filter((member) => member.id !== auth.id);
    return [{ id: auth.id, name: "自分" }, ...members];
  }, [projectMembers, auth]);
  // components / ステータス選択
  const [showSelectStatePopover, hideSelectStatePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: projectTaskStates,
      selectedId: formTask.stateId,
      isSetNull: true,
      selectItem: (stateId: string | null) => {
        onSet({ ...formTask, stateId });
        hideSelectStatePopover();
      },
    } as AppSelectPopoverProps
  );
  // components / メンバー選択
  const [showSelectMemberPopover, hideSelectMemberPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: selectMeAndMembers,
      selectedId: formTask.assignedMemberId,
      filterKeywords: ["name", "email"],
      isSetNull: true,
      selectItem: (memberId: string | null) => {
        onSet({ ...formTask, assignedMemberId: memberId });
        hideSelectMemberPopover();
      },
    } as AppSelectPopoverProps
  );
  // components / 開始日選択
  const [showStartDatePopover, hideStartDatePopover] = useIonPopover(
    AppSelectDatePopover,
    {
      selectedDate: typeOfString(formTask.startDate)
        ? formTask.startDate
        : null,
      selectDate: (date: string) => {
        onSet({ ...formTask, startDate: date });
        hideStartDatePopover();
      },
      clearDate: () => {
        onSet({ ...formTask, startDate: [] });
        hideStartDatePopover();
      },
    } as AppSelectDatePopoverProps
  );
  // components / 期限日選択
  const [showLimitDatePopover, hideLimitDatePopover] = useIonPopover(
    AppSelectDatePopover,
    {
      selectedDate: typeOfString(formTask.limitDate)
        ? formTask.limitDate
        : null,
      selectDate: (date: string) => {
        onSet({ ...formTask, limitDate: date });
        hideLimitDatePopover();
      },
      clearDate: () => {
        onSet({ ...formTask, limitDate: [] });
        hideLimitDatePopover();
      },
    } as AppSelectDatePopoverProps
  );
  // components / 優先度選択
  const [showSelectPriorityPopover, hideSelectPriorityPopover] = useIonPopover(
    AppSelectPriorityPopover,
    {
      selectedId: formTask.priority,
      selectPriority: (priority: Priority) => {
        onSet({ ...formTask, priority });
        hideSelectPriorityPopover();
      },
    } as AppSelectPriorityPopoverProps
  );
  // components / カラー選択
  const [showAppSelectColorPopover, hideAppSelectColorPopover] = useIonPopover(
    AppSelectColorPopover,
    {
      color: formTask.color,
      selectColor: (color: Color | null) => {
        onSet({ ...formTask, color });
        hideAppSelectColorPopover();
      },
    } as AppSelectColorPopoverProps
  );
  // components / マイルストーン選択
  const [showSelectMilestonePopover, hideSelectMilestonePopover] =
    useIonPopover(AppSelectPopover, {
      items: projectTaskMilestones,
      selectedId: formTask.milestoneId,
      isSetNull: true,
      filterKeywords: ["name"],
      selectItem: (milestoneId: string | null) => {
        onSet({ ...formTask, milestoneId });
        hideSelectMilestonePopover();
      },
    } as AppSelectPopoverProps);
  // components / タグ選択
  const [showSelectTagsPopover] = useIonPopover(AppSelectsPopover, {
    items: projectTaskTags,
    selectedIds: formTask.tagIds,
    filterKeywords: ["name"],
    selectItems: (tagIds: string[]) => {
      onSet({ ...formTask, tagIds });
    },
  } as AppSelectsPopoverProps);
  // components / 関係者選択
  const [showSelectCollaborateMembersPopover] = useIonPopover(
    AppSelectsPopover,
    {
      items: selectMeAndMembers,
      selectedIds: formTask.collaborateMemberIds,
      selectItems: (memberIds: string[]) => {
        onSet({ ...formTask, collaborateMemberIds: memberIds });
      },
      filterKeywords: ["name", "email"],
    } as AppSelectsPopoverProps
  );
  // components / メインタスク選択
  const [showMainTaskPopover, hideMainTaskPopover] = useIonPopover(
    AppSelectMainTaskPopover,
    {
      workspaceId: formTask.workspaceId,
      projectId: formTask.projectId,
      mainTaskId: formTask.mainTaskId,
      taskId: formTask.id,
      selectMainTask: (mainTaskId: string | null) => {
        onSet({ ...formTask, mainTaskId });
        hideMainTaskPopover();
      },
    } as AppSelectMainTaskPopoverProps
  );

  return (
    <AppCard marginBottom="16px">
      <AppCardContent>
        {/* タスク名 */}
        <AppFormInput
          value={formTask.title}
          onIonChange={(e) => {
            onSet({ ...formTask, title: e.detail.value ?? "" });
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onPressEnterKey();
            }
          }}
          type="text"
          label="タスク名"
          marginBottom="8px"
          name="name"
        />
        {isSubTask && (
          <WrapToggleTaskStatesButton>
            <ToggleTaskStatesButton
              fill="clear"
              color="medium"
              onClick={() => setShowTaskStates(!showTaskStates)}
              style={{ marginBottom: showTaskStates ? 0 : "-12px" }}
            >
              詳細設定
              <ToggleTaskStatesButtonIcon
                icon={showTaskStates ? caretUpSharp : caretDownSharp}
              />
            </ToggleTaskStatesButton>
          </WrapToggleTaskStatesButton>
        )}
        {showTaskStates && (
          <>
            {/* タスク詳細 */}
            <AppWrapFormItem label="説明" marginBottom="8px">
              <LexicalComposer
                initialConfig={{
                  namespace: "taskDescription",
                  theme: LEXICAL_THEME,
                  editorState: formTask.descriptionEditorState
                    ? JSON.stringify(formTask.descriptionEditorState)
                    : undefined,
                  onError: (error) => console.log(error),
                }}
              >
                <LexicalContainer>
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable style={{ outline: 0, minHeight: 60 }} />
                    }
                    placeholder={
                      <LexicalPlaceholder>
                        このタスクの詳細を入力
                      </LexicalPlaceholder>
                    }
                  />
                  <OnChangePlugin
                    onChange={(editorState: EditorState) => {
                      editorState.read(() => {
                        const root = $getRoot();
                        onSet({
                          ...formTask,
                          description: root.getTextContent(),
                          descriptionEditorState: editorState.toJSON(),
                        });
                      });
                    }}
                  />
                  <HistoryPlugin />
                </LexicalContainer>
              </LexicalComposer>
            </AppWrapFormItem>
            <TaskStates>
              {/* 状態 */}
              <AppFormButton
                label="状態"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showSelectStatePopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                {formTask.stateId === null && <AppLabel>{TEXT.EMPTY}</AppLabel>}
                {formTask.stateId !== null && (
                  <AppLabel>
                    {projectTaskStates.find(({ id }) => id === formTask.stateId)
                      ?.name ?? "不明"}
                  </AppLabel>
                )}
              </AppFormButton>
              {/* 担当者 */}
              <AppFormButton
                label="担当者"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showSelectMemberPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <AppLabel>
                  {formatMember(formTask.assignedMemberId, memberEntities).name}
                </AppLabel>
              </AppFormButton>
              {/* 開始日 */}
              <AppFormButton
                label="開始日"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showStartDatePopover({
                    cssClass: ["app-popover-width320", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                {typeOfString(formTask.startDate) ? (
                  <AppLabel>
                    {dayjs(formTask.startDate as string).format("YYYY-MM-DD")}
                  </AppLabel>
                ) : (
                  <AppLabel>{TEXT.EMPTY}</AppLabel>
                )}
              </AppFormButton>
              {/* 期限日 */}
              <AppFormButton
                label="期限日"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showLimitDatePopover({
                    cssClass: ["app-popover-width320", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                {typeOfString(formTask.limitDate) ? (
                  <AppLabel>
                    {dayjs(formTask.limitDate as string).format("YYYY-MM-DD")}
                  </AppLabel>
                ) : (
                  <AppLabel>{TEXT.EMPTY}</AppLabel>
                )}
              </AppFormButton>
              {/* 優先度 */}
              <AppFormButton
                label="優先度"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showSelectPriorityPopover({
                    cssClass: ["app-popover-width160", "app-popover-height160"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <AppLabel>{i18nPriorityToText(formTask.priority)}</AppLabel>
              </AppFormButton>
              {/* カラー */}
              <AppFormButton
                label="カラー"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showAppSelectColorPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                  }}
                >
                  {formTask.color === null && <AppLabel>{TEXT.EMPTY}</AppLabel>}
                  {formTask.color !== null && (
                    <>
                      <div
                        style={{
                          width: "1em",
                          height: "1em",
                          background: getColor(formTask.color).light,
                          borderRadius: "100%",
                          marginRight: "12px",
                        }}
                      />
                      <AppLabel>{getColor(formTask.color).name}</AppLabel>
                    </>
                  )}
                </div>
              </AppFormButton>
              {/* マイルストーン */}
              {projectTaskMilestones.length > 0 && (
                <AppFormButton
                  label="マイルストーン"
                  style={{ flexGrow: 1 }}
                  onClick={(e) => {
                    showSelectMilestonePopover({
                      cssClass: [
                        "app-popover-width240",
                        "app-popover-height320",
                      ],
                      event: { ...e.nativeEvent, target: e.currentTarget },
                    });
                  }}
                >
                  <AppLabel>
                    {formTask.milestoneId === null && (
                      <AppLabel>{TEXT.EMPTY}</AppLabel>
                    )}
                    {formTask.milestoneId !== null && (
                      <AppLabel>
                        {projectTaskMilestones.find(
                          ({ id }) => id === formTask.milestoneId
                        )?.name ?? "不明"}
                      </AppLabel>
                    )}
                  </AppLabel>
                </AppFormButton>
              )}
              {/* タグ */}
              {projectTaskTags.length > 0 && (
                <AppFormButton
                  label="タグ"
                  style={{ flexGrow: 1 }}
                  onClick={(e) => {
                    showSelectTagsPopover({
                      cssClass: [
                        "app-popover-width240",
                        "app-popover-height320",
                      ],
                      event: { ...e.nativeEvent, target: e.currentTarget },
                    });
                  }}
                >
                  {formTask.tagIds.length === 0 && (
                    <AppLabel>{TEXT.EMPTY}</AppLabel>
                  )}
                  {formatItems(formTask.tagIds, projectTaskTags).map(
                    ({ id, name }) => (
                      <AppAddComma key={id}>
                        <AppLabel>{name}</AppLabel>
                      </AppAddComma>
                    )
                  )}
                </AppFormButton>
              )}
              {/* 予定時間 */}
              <AppFormInput
                label="予定時間 (H)"
                value={formTask.estimatedHours}
                type="number"
                inputmode="numeric"
                name="estimatedHours"
                style={{
                  width: "100px",
                  "--placeholder-color": "var(--app-color-grey900)",
                  flexGrow: 1,
                }}
                placeholder="-"
                onIonChange={(e) => {
                  onSet({
                    ...formTask,
                    estimatedHours: Number(e.detail.value)
                      ? Number(e.detail.value)
                      : null,
                  });
                }}
              />
              {/* 関係者 */}
              <AppFormButton
                label="関係者"
                style={{ flexGrow: 1 }}
                onClick={(e) => {
                  showSelectCollaborateMembersPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                {formTask.collaborateMemberIds.length === 0 && (
                  <AppLabel>{TEXT.EMPTY}</AppLabel>
                )}
                {formatMembers(
                  formTask.collaborateMemberIds,
                  memberEntities
                ).map(({ id, name }) => (
                  <AppAddComma key={id}>
                    <AppLabel>{name}</AppLabel>
                  </AppAddComma>
                ))}
              </AppFormButton>
              {/* メインタスク */}
              {showMainTask && (
                <AppFormButton
                  label="メインタスク"
                  style={{ flexGrow: 1 }}
                  onClick={(e) => {
                    showMainTaskPopover({
                      cssClass: [
                        "app-popover-width280",
                        "app-popover-height320",
                      ],
                      event: { ...e.nativeEvent, target: e.currentTarget },
                    });
                  }}
                >
                  {formTask.mainTaskId === null && <AppLabel>-</AppLabel>}
                  {formTask.mainTaskId !== null && (
                    <>
                      <AppLabel>
                        {mainTask.data?.title ? mainTask.data.title : "不明"}
                      </AppLabel>
                    </>
                  )}
                </AppFormButton>
              )}
            </TaskStates>
          </>
        )}
      </AppCardContent>
    </AppCard>
  );
};
