import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Task, TaskComment } from "../models";
import { RootState } from "./";

interface UnreadNotificationsState {
  tasks: Task[];
  taskComments: TaskComment[];
  loading: boolean;
  error: string | null;
}

const initialState: UnreadNotificationsState = {
  tasks: [],
  taskComments: [],
  loading: true,
  error: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setTaskNotifications: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload;
    },
    setTaskCommentNotifications: (
      state,
      action: PayloadAction<TaskComment[]>
    ) => {
      state.taskComments = action.payload;
    },
    setNotificationsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNotificationsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setTaskNotifications,
  setTaskCommentNotifications,
  setNotificationsLoading,
  setNotificationsError,
} = notificationsSlice.actions;

// export const selectTaskNotifications = (state: RootState) =>
//   state.notifications.tasks;
// export const selectTaskCommentNotifications = (state: RootState) =>
//   state.notifications.taskComments;
// export const selectNotificationsCount = (state: RootState) =>
//   state.notifications.tasks.length + state.notifications.taskComments.length;
export const selectNotificationsLoading = (state: RootState) =>
  state.notifications.loading;
export const selectNotificationsError = (state: RootState) =>
  state.notifications.error;

// Workspace
export const selectWorkspaceNotifications = (workspaceId: string | null) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ tasks, taskComments }) => {
      const notification = [
        ...tasks
          .filter((item) => item.workspaceId === workspaceId)
          .map((item) => ({ ...item, kind: "task" })),
        ...taskComments
          .filter((item) => item.workspaceId === workspaceId)
          .map((item) => ({ ...item, kind: "taskComment" })),
      ].sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds);
      if (notification.length > 99) notification.length = 99; // 99以上は切り捨て
      return notification;
    }
  );
};

//  Project
export const selectProjectNotificationsCount = (projectId: string | null) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ tasks, taskComments }) =>
      tasks.filter((item) => item.projectId === projectId).length +
      taskComments.filter((item) => item.projectId === projectId).length
  );
};

// Task
export const selectTaskNotificationsCount = (taskId: string | null) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ tasks, taskComments }) =>
      tasks.filter((item) => item.id === taskId).length +
      taskComments.filter((item) => item.taskId === taskId).length
  );
};

// Workspace / Task
export const selectWorkspaceTaskNotificationsCount = (
  workspaceId: string | null
) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ tasks }) =>
      tasks.filter((item) => item.workspaceId === workspaceId).length
  );
};

// Workspace / TaskComment
export const selectWorkspaceTaskCommentNotificationsCount = (
  workspaceId: string | null
) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ taskComments }) =>
      taskComments.filter((item) => item.workspaceId === workspaceId).length
  );
};

// Workspace / Task & TaskComment
export const selectWorkspaceTaskAndTaskCommentNotificationsCount = (
  workspaceId: string | null
) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ tasks, taskComments }) =>
      tasks.filter((item) => item.workspaceId === workspaceId).length +
      taskComments.filter((item) => item.workspaceId === workspaceId).length
  );
};

// Project / Task & TaskComment
export const selectProjectTaskAndTaskCommentNotificationsCount = (
  projectId: string | null
) => {
  return createSelector(
    (state: RootState) => state.notifications,
    ({ tasks, taskComments }) =>
      tasks.filter((item) => item.projectId === projectId).length +
      taskComments.filter((item) => item.projectId === projectId).length
  );
};

export default notificationsSlice.reducer;
