import { ComponentProps, FunctionComponent } from "react";
import { IonTabBar } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonTabBar> {}

const TabBar = styled(IonTabBar)``;

const AppTabBar: FunctionComponent<Props> = ({ children, ...props }) => {
  return <TabBar {...props}>{children}</TabBar>;
};

export default AppTabBar;
