import { FunctionComponent } from "react";
import { reloadOutline } from "ionicons/icons";
import styled from "styled-components";
import { AppButton, AppIcon } from "..";
import { appApis } from "../../apis/Api";

const Button = styled.div`
  text-align: center;
`;

const AppReloadButton: FunctionComponent = () => {
  return (
    <Button>
      <AppButton
        color="dark"
        fill="outline"
        onClick={() => appApis.reloadApp()}
      >
        <AppIcon slot="start" icon={reloadOutline} />
        再読み込み
      </AppButton>
    </Button>
  );
};

export default AppReloadButton;
