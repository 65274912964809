import { FunctionComponent, useMemo, useState } from "react";
import { searchOutline } from "ionicons/icons";
import { AppCheckbox, AppIcon, AppInput, AppItem, AppLabel } from "../..";
import { TEXT } from "../../../utilities/UtilStatic";

export interface AppSelectsPopoverProps {
  items: { id: string; name: string }[];
  selectedIds: string[];
  filterKeywords?: string[];
  selectItems: (ids: string[]) => void;
  emptyText?: string;
}

const AppSelectsPopover: FunctionComponent<AppSelectsPopoverProps> = ({
  items,
  selectedIds,
  filterKeywords = [],
  selectItems,
  emptyText,
}) => {
  const [filterText, setFilterText] = useState<string>("");

  const filterItems = useMemo(() => {
    if (filterText === "") {
      return items;
    } else {
      return items.filter((item: any) => {
        for (const filterKeyword of filterKeywords) {
          const target = item[filterKeyword]
            ? String(item[filterKeyword]).toLowerCase()
            : "";
          if (target.includes(filterText.toLowerCase()) === true) return true;
        }
        return false;
      });
    }
  }, [items, filterText]);

  return (
    <>
      {filterKeywords.length > 0 && (
        <AppItem
          button={false}
          detail={false}
          style={{ "--background": "var(--app-color-grey100)" }}
          lines="full"
        >
          <AppIcon slot="start" icon={searchOutline} />
          <AppInput
            value={filterText}
            placeholder="検索"
            clearInput={true}
            clearOnEdit={true}
            onIonChange={(e) => setFilterText(e.detail.value!)}
            name="search"
          />
        </AppItem>
      )}
      {filterText === "" && (
        <AppItem button={true} detail={false} onClick={() => selectItems([])}>
          <AppCheckbox
            slot="start"
            checked={selectedIds.length === 0}
            style={{ marginRight: "24px" }}
            mode="md"
          />
          <AppLabel>{emptyText ?? TEXT.EMPTY}</AppLabel>
        </AppItem>
      )}
      {filterItems.map((filterItem, index) => {
        return (
          <AppItem
            key={index}
            button={true}
            detail={false}
            onClick={() => {
              const isChecked = selectedIds.some((id) => id === filterItem.id);
              if (isChecked) {
                selectItems(selectedIds.filter((id) => id !== filterItem.id));
              } else {
                selectItems([...selectedIds, filterItem.id]);
              }
            }}
          >
            <AppCheckbox
              slot="start"
              checked={selectedIds.some((id) => id === filterItem.id)}
              style={{ marginRight: "24px" }}
              mode="md"
            />
            <AppLabel>{filterItem.name}</AppLabel>
          </AppItem>
        );
      })}
    </>
  );
};

export default AppSelectsPopover;
