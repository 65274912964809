import { FunctionComponent } from "react";
import styled from "styled-components";
import {
  AppActionButton,
  AppBackButton,
  AppCard,
  AppCardContent,
  AppContent,
  AppFooter,
  AppFooterButtons,
  AppInnerContent,
  AppLandingHeader,
  AppPage,
} from "../..";
import { appLinks } from "../../../utilities/UtilPage";
import { PLATFORM } from "../../../utilities/UtilStatic";

const MainTitle = styled.h1`
  font-size: 28px;
  font-weight: normal;
  margin: 0 0 8px;
  padding: 8px 0;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 32px 0 8px;
`;

const Text = styled.div`
  font-size: 16px;
  margin: 0 0 8px;
`;

const Date = styled.div`
  font-size: 16px;
  margin: 24px 0 0;
  color: var(--app-color-grey600);
`;

const TermsPage: FunctionComponent = () => {
  return (
    <AppPage>
      {!PLATFORM.IS_CAPACITOR && <AppLandingHeader />}
      <AppContent style={{ "--padding-top": PLATFORM.IS_IOS ? "48px" : "" }}>
        <AppInnerContent>
          <MainTitle>特定商取引法に基づく表記</MainTitle>
          <AppCard marginBottom="16px">
            <AppCardContent>
              <Title style={{ marginTop: "0" }}>事業者の名称</Title>
              <Text>株式会社ミニマルデザイン</Text>

              <Title>事業責任者の氏名</Title>
              <Text>井上勇</Text>

              <Title>住所</Title>
              <Text>
                〒103-0016　東京都中央区日本橋小網町8番2号　BIZMARKS日本橋茅場町1階
              </Text>

              <Title>電話番号</Title>
              <Text>03-6403-9316</Text>

              <Title>メールアドレス</Title>
              <Text>support@minimaldesign.jp</Text>

              <Title>商品の販売価格・サービスの対価</Title>
              <Text>各商品・サービスのご購入ページにて表示する価格</Text>

              <Title>対価以外に必要となる費用</Title>
              <Text>なし</Text>

              <Title>支払方法と支払時期</Title>
              <Text>各商品・サービスのご購入ページに記載</Text>

              <Title>商品の引渡しまたはサービス提供の時期</Title>
              <Text>購入後すぐにご利用頂けます。</Text>

              <Title>返品・キャンセルに関する特約</Title>
              <Text>
                購入手続完了後の返品またはキャンセルはお受けいたしません。
              </Text>

              <Title>動作環境</Title>
              <Text>
                商品のご利用又はサービスの享受に必要となるデバイスの動作環境及び必要スペックはご購入ページに表示します。
              </Text>

              <Title>その他</Title>
              <Text>
                その他特別な販売条件または提供条件がある商品またはサービスについては、それぞれの購入ページにおいて条件を表示します。
              </Text>

              <Date>2023年04月01日 制定</Date>
            </AppCardContent>
          </AppCard>
        </AppInnerContent>
      </AppContent>
      <AppFooter>
        <AppFooterButtons>
          <AppBackButton defaultHref={appLinks.home()} />
        </AppFooterButtons>
      </AppFooter>
    </AppPage>
  );
};

export default TermsPage;
