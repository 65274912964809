import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Invitation } from "../models/Invitation";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useInvitations = ({ workspaceId }: { workspaceId: string }) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis.$readInvitations(workspaceId).subscribe({
      next: (invitation) => {
        console.log("useInvitations");
        setData(invitation);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId]);

  return { data, loading, error };
};
