import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  text?: string;
  helperText?: string;
  optional?: boolean;
  marginBottom?: string;
}

const Content = styled.div`
  display: block;
`;

const Label = styled.div`
  margin-bottom: -11px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  z-index: 10;
  pointer-events: none;
`;

const LabelText = styled.div`
  display: inline-block;
  color: var(--app-color-grey600);
  font-size: 12px;
  background-color: white;
  padding: 0 6px;
  word-wrap: keep-all;
  white-space: nowrap;
`;

const Wrap = styled.div`
  border: 1px solid var(--app-color-grey400);
  border-radius: 4px;
  padding: 16px 16px 12px;
  font-size: 16px;
  color: var(--app-color-grey900);
`;

const HelperText = styled.div`
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 12px;
  padding: 0 2px;
  color: var(--app-color-grey600);
`;

const AppWrapFormItem: FunctionComponent<Props> = ({
  label,
  text,
  helperText,
  optional,
  children,
  marginBottom,
  style,
  ...props
}) => {
  return (
    <Content {...props} style={{ marginBottom, ...style }}>
      {label && (
        <Label>
          <LabelText>
            {label}
            {optional && "（オプション）"}
          </LabelText>
        </Label>
      )}
      <Wrap>{children}</Wrap>
      {helperText && <HelperText>{helperText}</HelperText>}
    </Content>
  );
};

export default AppWrapFormItem;
