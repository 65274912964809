import { FunctionComponent } from "react";
import { HrefProps, IonButton, IonBackButton } from "@ionic/react";
import { JSX } from "@ionic/core";
import { IonicReactProps } from "@ionic/react/dist/types/components/IonicReactProps";
import styled from "styled-components";

interface Props extends HrefProps<JSX.IonBackButton & IonicReactProps> {}

const Button = styled(IonButton)`
  margin: 0;
  &::part(native) {
    border-color: var(--app-color-grey400);
    color: var(--app-color-grey600);
    --background-activated: var(--app-color-grey50);
  }
  .ios & {
    --border-width: 1px;
    --padding-top: 0;
    height: 38px;
    --padding-start: 0;
    --padding-end: 0;
  }
  .md & {
    --padding-start: 0;
    --padding-end: 0;
    --border-width: 1px;
  }
`;

const BackButton = styled(IonBackButton)`
  &::part(text) {
    color: var(--app-color-grey600);
  }
  .ios & {
    --padding-start: 16px;
    --padding-end: 16px;
  }
  .ios &::part(text) {
    font-size: 16px;
  }
  .md &::part(text) {
    font-size: 14px;
  }
`;

const AppBackButton: FunctionComponent<Props> = (props) => {
  return (
    <Button fill="outline">
      <BackButton {...props} text="戻る" icon="" />
    </Button>
  );
};

export default AppBackButton;
