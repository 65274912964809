import { ComponentProps, FunctionComponent } from "react";
import { IonBreadcrumb } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonBreadcrumb> {}

const Breadcrumb = styled(IonBreadcrumb)`
  --color: var(--app-color-grey500-rgba);
  --color-active: var(--app-color-grey700-rgba);
  --color-focused: var(--app-color-grey700-rgba);
  --color-hover: var(--app-color-grey700-rgba);
  &::part(separator) {
    color: var(--app-color-grey500-rgba);
    margin: 0;
  }
`;

const AppBreadcrumb: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Breadcrumb {...props}>{children}</Breadcrumb>;
};

export default AppBreadcrumb;
