import { ComponentProps, FunctionComponent } from "react";
import { IonReorder } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonReorder> {}

const Reorder = styled(IonReorder)`
  margin-left: 0;
`;

const AppReorder: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Reorder {...props}>{children}</Reorder>;
};

export default AppReorder;
