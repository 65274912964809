import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useError, useLoading, useAssignedTasks } from "../../../hooks";
import { appLinks } from "../../../utilities/UtilPage";
import {
  AppContent,
  AppCard,
  AppErrorCard,
  AppLoadingCard,
  AppTask,
  AppItemGroup,
  AppLead,
  AppHeaderBackButton,
  AppPage,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppTitle,
  AppInnerContent,
} from "../..";
import { selectAuth } from "../../../store/authSlice";
import { TabId } from "../../../models";

const MyTasksPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  const [projectId, setProjectId] = useState<string | null>(null);
  // store
  const auth = useSelector(selectAuth);
  // hook
  const tasks = useAssignedTasks({
    workspaceId,
    memberId: auth?.id ?? null,
  });
  const loading = useLoading(tasks.loading);
  const error = useError(tasks.error);

  return (
    <>
      <AppPage>
        <AppHeader>
          <AppToolbar>
            <AppButtons slot="start">
              <AppHeaderBackButton
                defaultHref={appLinks.tab(workspaceId, tabId)}
              />
            </AppButtons>
            <AppTitle>マイタスク</AppTitle>
          </AppToolbar>
        </AppHeader>
        <AppContent>
          <AppInnerContent>
            {/* リスト */}
            {auth && (
              <>
                {loading && <AppLoadingCard />}
                {error && <AppErrorCard>{error}</AppErrorCard>}
                {!loading && !error && (
                  <>
                    {tasks.data.length === 0 && (
                      <AppLead>自分が担当で未完了なタスクがありません</AppLead>
                    )}
                    {tasks.data.length > 0 && (
                      <AppCard>
                        <AppItemGroup>
                          {tasks.data.map((task) => (
                            <AppTask
                              key={task.id}
                              {...{
                                task,
                                history,
                                setProjectId: (projectId) =>
                                  setProjectId(projectId),
                                routerRef,
                              }}
                            />
                          ))}
                        </AppItemGroup>
                      </AppCard>
                    )}
                  </>
                )}
              </>
            )}
          </AppInnerContent>
        </AppContent>
      </AppPage>
    </>
  );
};

export default MyTasksPage;
