import { FunctionComponent } from "react";
import { Color } from "../../../models/types/Color";
import { AppItem, AppLabel, AppRadio, AppRadioGroup } from "../..";
import { COLORS } from "../../../utilities/UtilStatic";
import { getColor } from "../../../utilities/UtilFunction";

export interface AppSelectColorPopoverProps {
  color: Color;
  selectColor: (color: Color | null) => void;
}

const AppSelectColorPopover: FunctionComponent<AppSelectColorPopoverProps> = ({
  color,
  selectColor,
}) => {
  return (
    <AppRadioGroup value={color ?? ""}>
      <AppItem
        button
        detail={false}
        lines="none"
        onClick={() => selectColor(null)}
      >
        <AppRadio
          slot="start"
          value=""
          style={{ "--color": "var(--app-color-grey500-rgba)" }}
          mode="md"
        />
        <AppLabel>-</AppLabel>
      </AppItem>
      {COLORS.map((color) => {
        return (
          <AppItem
            key={color}
            button={true}
            detail={false}
            style={{
              "--background": getColor(color).light,
            }}
            lines="none"
            onClick={() => selectColor(color)}
          >
            <AppRadio
              slot="start"
              value={color}
              style={{
                "--color": "var(--app-color-grey500-rgba)",
                "--color-checked": "var(--app-color-grey700-rgba)",
              }}
              mode="md"
            />
            <AppLabel>{getColor(color).name}</AppLabel>
          </AppItem>
        );
      })}
    </AppRadioGroup>
  );
};

export default AppSelectColorPopover;
