import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nError, i18nErrorToString } from "../utilities/UtilI18nText";
import { Task } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useTask = ({
  workspaceId,
  taskId,
}: {
  workspaceId: string | null;
  taskId: string | null;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Task | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth || !workspaceId || !taskId) {
      setData(null);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis.$readTask(workspaceId, taskId).subscribe({
      next: (task) => {
        console.log("useTask");
        if (task) {
          setData(task);
          setLoading(false);
          setError(null);
        } else {
          setData(null);
          setLoading(false);
          setError(i18nError.notFound());
        }
      },
      error: (error) => {
        console.log(error);
        setData(null);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId, taskId]);

  return { data, loading, error };
};
