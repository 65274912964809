import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

const Text = styled.div`
  font-size: 16px;
  color: var(--app-color-grey600);
`;

const AppCardHeaderText: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return <Text>{children}</Text>;
};

export default AppCardHeaderText;
