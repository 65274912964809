import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Invitation } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { useAuth } from ".";

export const useInvitation = ({
  workspaceId,
  invitationId,
}: {
  workspaceId: string;
  invitationId: string;
}) => {
  // states
  const [data, setData] = useState<Invitation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const subscribe = appApis
      .$readInvitation(workspaceId, invitationId)
      .subscribe({
        next: (invitation) => {
          setData(invitation);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log(error);
          setData(null);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [workspaceId, invitationId]);

  return { data, loading, error };
};
