import { ComponentProps, FunctionComponent } from "react";
import { IonRadioGroup } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonRadioGroup> {}

const RadioGroup = styled(IonRadioGroup)`
  ion-item:last-child {
    --inner-border-width: 0;
  }
`;

const AppRadioGroup: FunctionComponent<Props> = ({ children, ...props }) => {
  return <RadioGroup {...props}>{children}</RadioGroup>;
};

export default AppRadioGroup;
