import { ComponentProps, FunctionComponent } from "react";
import { IonCardTitle } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonCardTitle> {}

const CardTitle = styled(IonCardTitle)``;

const AppCardTitle: FunctionComponent<Props> = ({ children, ...props }) => {
  return <CardTitle {...props}>{children}</CardTitle>;
};

export default AppCardTitle;
