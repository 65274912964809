import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { OfficeMemberHistory } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useMonthlyOfficeMembersHistories = ({
  workspaceId,
  month,
}: {
  workspaceId: string;
  month: string;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<OfficeMemberHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis
      .$readMonthlyOfficeMemberHistories(workspaceId, month)
      .subscribe({
        next: (officeMemberHistories) => {
          console.log("useMonthlyOfficeMembersHistories");
          setData(officeMemberHistories);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log(error);
          setData([]);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId, month]);

  return { data, loading, error };
};
