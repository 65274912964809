import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Task } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useSubTasks = ({
  workspaceId,
  projectId,
  taskId,
  taskIds,
}: {
  workspaceId: string | null;
  projectId: string | null;
  taskId?: string | null;
  taskIds?: string[];
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let ids: string[] = [];
    if (taskId) ids.push(taskId);
    if (taskIds) ids = taskIds;
    if (!auth || !workspaceId || !projectId) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    if (ids.length === 0) {
      setData([]);
      setLoading(false);
      setError(null);
      return;
    }
    const subscribe = appApis
      .$readSubTasks(workspaceId, projectId, ids)
      .subscribe({
        next: (tasks) => {
          console.log("useSubTasks");
          setData(tasks);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log(error);
          setData([]);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId, projectId, taskId, taskIds]);

  return { data, loading, error };
};
