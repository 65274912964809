import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Office } from "../models/Office";
import { RootState } from "./";

interface OfficesState extends EntityState<Office> {
  loading: boolean;
  error: string | null;
}

const adapter = createEntityAdapter<Office>();

const { selectById } = adapter.getSelectors();

const initialState: OfficesState = adapter.getInitialState({
  loading: true,
  error: null,
});

export const officesSlice = createSlice({
  name: "offices",
  initialState,
  reducers: {
    setOffices: (state, action: PayloadAction<Office[]>) => {
      adapter.setAll(state, action.payload);
    },
    setOfficesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOfficesError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setOffices, setOfficesLoading, setOfficesError } =
  officesSlice.actions;

export const selectOffices = adapter.getSelectors(
  (state: RootState) => state.offices
).selectAll;

export const selectOfficesEntities = adapter.getSelectors(
  (state: RootState) => state.offices
).selectEntities;

export const selectOfficesLoading = (state: RootState) => state.offices.loading;

export const selectOfficesError = (state: RootState) => state.offices.error;

export const selectOffice = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.offices,
    (state) => selectById(state, id ?? "") ?? null
  );
};

export default officesSlice.reducer;
