import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Project } from "../models/Project";
import { RootState } from "./";

interface ProjectsState extends EntityState<Project> {
  loading: boolean;
  error: string | null;
}

const adapter = createEntityAdapter<Project>();

const { selectById } = adapter.getSelectors();

const initialState: ProjectsState = adapter.getInitialState({
  loading: true,
  error: null,
});

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      adapter.setAll(state, action.payload);
    },
    setProjectsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setProjectsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setProjects, setProjectsLoading, setProjectsError } =
  projectsSlice.actions;

export const selectProjects = adapter.getSelectors(
  (state: RootState) => state.projects
).selectAll;

export const selectProjectEntities = adapter.getSelectors(
  (state: RootState) => state.projects
).selectEntities;

export const selectProjectsLoading = (state: RootState) =>
  state.projects.loading;

export const selectProjectsError = (state: RootState) => state.projects.error;

export const selectProject = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.projects,
    (state) => (selectById(state, id!) ? selectById(state, id!)! : null)
  );
};

export default projectsSlice.reducer;
