import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  marginBottom?: string;
}

const Wrap = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  background: var(--app-color-white);
  border-radius: 8px;
  color: var(--app-color-primary700);
  padding: 8px 16px;
  border: 1px solid var(--app-color-primary100);
  background: var(--app-color-primary50);
`;

const AppLead: FunctionComponent<Props> = ({
  marginBottom,
  children,
  ...props
}) => {
  return (
    <Wrap style={{ marginBottom }}>
      <Content {...props}>{children}</Content>
    </Wrap>
  );
};

export default AppLead;
