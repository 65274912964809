import { ComponentProps, FunctionComponent } from "react";
import { IonSegmentButton } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonSegmentButton> {}

const SegmentButton = styled(IonSegmentButton)`
  --border-width: 1px;
  --border-color: var(--app-color-grey300);
  --indicator-box-shadow: 0 0 0 1px var(--app-color-grey300);
  --color: var(--app-color-grey500);
  --indicator-color: var(--app-color-white);
  --color-checked: var(--app-color-grey600);
  --color-focused: var(--app-color-grey600);
  margin: 0 -2px;
  min-width: auto;
  &::part(native) {
    &::before {
      display: none;
    }
  }
`;

const AppSegmentButton: FunctionComponent<Props> = ({ children, ...props }) => {
  return (
    <SegmentButton mode="ios" {...props}>
      {children}
    </SegmentButton>
  );
};

export default AppSegmentButton;
