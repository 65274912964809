import {
  IonButton,
  IonIcon,
  IonSearchbar,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonPopover,
  useIonToast,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";
import {
  addOutline,
  arrowDownOutline,
  arrowUpOutline,
  calendarOutline,
  checkmarkOutline,
  closeOutline,
  personOutline,
  pricetagOutline,
  searchOutline,
  swapVerticalOutline,
} from "ionicons/icons";
import { FunctionComponent, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  useError,
  useListTasks,
  useLoading,
  useProject,
  useSearchTasks,
  useSubTasks,
} from "../../../hooks";
import {
  selectProjectTaskTags,
  selectTaskTagEntities,
  selectTaskTags,
  selectTaskTagsError,
  selectTaskTagsLoading,
} from "../../../store/taskTagsSlice";
import {
  selectProjectTaskMilestones,
  selectTaskMilestoneEntities,
  selectTaskMilestonesError,
  selectTaskMilestonesLoading,
} from "../../../store/taskMilestonesSlice";
import {
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
  selectProjectMembers,
} from "../../../store/membersSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { appApis } from "../../../apis/Api";
import {
  AppContent,
  AppFabButtons,
  AppFabButton,
  AppCard,
  AppWriteTasksModal,
  AppWriteTasksModalProps,
  AppButton,
  AppLabel,
  AppErrorCard,
  AppLoadingCard,
  AppReorderGroup,
  AppLead,
  AppTask,
  AppHeaderBackButton,
  AppPage,
  AppButtons,
  AppHeader,
  AppTitle,
  AppToolbar,
  AppIcon,
  AppInnerContent,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppSelectsPopover,
  AppSelectsPopoverProps,
  AppItemGroup,
  AppAddComma,
} from "../..";
import {
  selectProjectTaskStates,
  selectTaskStateEntities,
  selectTaskStatesError,
  selectTaskStatesLoading,
} from "../../../store/taskStatesSlice";
import { selectAuth } from "../../../store/authSlice";
import styled from "styled-components";
import { TabId, Task } from "../../../models";
import { i18nTaskOrders, i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  selectTaskBookmarks,
  selectTaskBookmarksError,
  selectTaskBookmarksLoading,
} from "../../../store/taskBookmarksSlice";
import { ACTION } from "../../../utilities/UtilStatic";
import { formatMember, formatItems } from "../../../utilities/UtilFunction";

const WrapSeachBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  .ios & {
    border-bottom: 1px solid var(--app-color-grey300);
  }
`;

const SeachBar = styled(IonSearchbar)`
  --background: var(--app-color-grey100);
  --placeholder-color: var(--app-color-grey600);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .md & {
    height: 56px;
  }
  .ios & {
    height: 43px;
  }
`;

const LimitButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
`;

const StatesButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  margin-bottom: 8px;
`;

const StatesButton = styled(IonButton)`
  margin: 0;
  --border-width: 1px;
  --box-shadow: none;
  &::part(native) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StatesLabel = styled.div`
  font-size: 14px;
`;

const StatesIcon = styled(IonIcon)`
  padding: 0 4px;
`;

const TasksPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, projectId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    projectId: string;
  }>();

  // store
  const auth = useSelector(selectAuth);
  const projectMembers = useSelector(selectProjectMembers(projectId));
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const projectTaskTags = useSelector(selectProjectTaskTags(projectId));
  const taskTags = useSelector(selectTaskTags);
  const taskTagEntities = useSelector(selectTaskTagEntities);
  const taskTagsLoading = useSelector(selectTaskTagsLoading);
  const taskTagsError = useSelector(selectTaskTagsError);
  const projectTaskMilestones = useSelector(
    selectProjectTaskMilestones(projectId)
  );
  const taskMilestoneEntities = useSelector(selectTaskMilestoneEntities);
  const taskMilestonesLoading = useSelector(selectTaskMilestonesLoading);
  const taskMilestonesError = useSelector(selectTaskMilestonesError);
  const projectTaskStates = useSelector(selectProjectTaskStates(projectId));
  const taskStateEntities = useSelector(selectTaskStateEntities);
  const taskStatesLoading = useSelector(selectTaskStatesLoading);
  const taskStatesError = useSelector(selectTaskStatesError);
  const taskBookmarks = useSelector(selectTaskBookmarks);
  const taskBookmarksLoading = useSelector(selectTaskBookmarksLoading);
  const taskBookmarksError = useSelector(selectTaskBookmarksError);
  // taskQueries
  const [stateId, setStateId] = useState<string | "default" | "all">("default");
  const [assignedMemberId, setAssignedMemberId] = useState<
    string | "all" | null
  >("all");
  const [milestoneId, setMilestoneId] = useState<string | "all" | null>("all");
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState<
    "order" | "createdAt" | "updatedAt" | "limitDate" | "priority"
  >("order");
  const [limit, setLimit] = useState<number>(50);
  // hook
  const project = useProject({ workspaceId, projectId });
  const tasks = useListTasks({
    workspaceId,
    projectId,
    assignedMemberId,
    stateId,
    milestoneId,
    tagIds,
    orderBy,
    limit,
  });
  const taskIds = useMemo(() => tasks.data.map(({ id }) => id), [tasks.data]);
  const subTasks = useSubTasks({ workspaceId, projectId, taskIds });
  const loading = useLoading(
    tasks.loading,
    membersLoading,
    taskTagsLoading,
    taskMilestonesLoading,
    taskStatesLoading,
    taskBookmarksLoading
  );
  const error = useError(
    tasks.error,
    membersError,
    taskTagsError,
    taskMilestonesError,
    taskStatesError,
    taskBookmarksError
  );
  // page data
  const [isSortMode, setIsSortMode] = useState<boolean>(false);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const [searchWord, setSearchWord] = useState<string>("");
  const searchTasks = useSearchTasks({
    workspaceId,
    projectId,
    searchWord,
  });
  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  // components / 状態
  const [showSelectStatePopover, hideSelectStatePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: [
        { id: "default", name: "完了以外" },
        { id: "all", name: "すべて" },
        ...projectTaskStates.map(({ id, name }) => ({ id, name })),
      ],
      selectedId: stateId,
      selectItem: (id: string) => {
        setStateId(id);
        hideSelectStatePopover();
      },
      isSetNull: false,
    } as AppSelectPopoverProps
  );
  // components / 担当者
  const [showSelectAssignedMemberPopover, hideSelectAssignedMemberPopover] =
    useIonPopover(AppSelectPopover, {
      items: [
        { id: "all", name: "すべて" },
        { id: null, name: "未設定" },
        { id: auth?.id, name: "自分" },
        ...projectMembers
          .filter((item) => item.id !== auth?.id)
          .map(({ id, name }) => ({ id, name })),
      ],
      selectedId: assignedMemberId,
      selectItem: (id: string | "all" | null) => {
        setAssignedMemberId(id);
        hideSelectAssignedMemberPopover();
      },
      isSetNull: false,
    } as AppSelectPopoverProps);
  // components / マイルストーン
  const [showSelectMilestonePopover, hideSelectMilestonePopover] =
    useIonPopover(AppSelectPopover, {
      items: [
        { id: "all", name: "すべて" },
        { id: null, name: "未設定" },
        ...projectTaskMilestones.map(({ id, name }) => ({
          id,
          name,
        })),
      ],
      selectedId: milestoneId,
      selectItem: (id: string | "all" | null) => {
        setMilestoneId(id);
        hideSelectMilestonePopover();
      },
      isSetNull: false,
    } as AppSelectPopoverProps);
  // components / タグ
  const [showSelectTagsPopover, hideSelectTagsPopover] = useIonPopover(
    AppSelectsPopover,
    {
      items: projectTaskTags.map((item) => ({
        id: item.id,
        name: item.name,
      })),
      selectedIds: tagIds,
      selectItems: (tagIds: string[]) => {
        setTagIds(tagIds);
        hideSelectTagsPopover();
      },
      emptyText: "すべて",
    } as AppSelectsPopoverProps
  );
  // components / 表示順
  const [showSelectOrderPopover, hideSelectOrderPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: i18nTaskOrders().map((item) => ({
        id: item.key,
        name: item.name,
      })),
      selectedId: orderBy,
      selectItem: (
        id: "order" | "createdAt" | "updatedAt" | "limitDate" | "priority"
      ) => {
        setOrderBy(id);
        hideSelectOrderPopover();
      },
      isSetNull: false,
    } as AppSelectPopoverProps
  );
  // components タスク更新modal
  const [showWriteTaskModal, hideWriteTaskModal] = useIonModal(
    AppWriteTasksModal,
    {
      projectId,
      taskId: selectedTask ? selectedTask.id : null,
      deleteTasks: (task) => {
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.resolve()
          .then(() => appApis.deleteTask(task.workspaceId, task.id))
          .then(() => {
            hideWriteTaskModal();
            showToast({
              message: i18nText.success.task.delete(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      saveTasks: (taskForms, mainTaskId) => {
        if (!auth) return;
        const requests = taskForms.map((taskForm) => {
          if (taskForm.action === ACTION.CREATE) {
            const { action, ...task } = taskForm;
            return appApis.createTask(task);
          } else if (taskForm.action === ACTION.UPDATE) {
            const { action, ...task } = taskForm;
            return appApis.updateTask(task);
          } else {
            const { action, ...task } = taskForm;
            return appApis.deleteTask(task.workspaceId, task.id);
          }
        });
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.all(requests)
          .then(() => {
            hideWriteTaskModal();
            showToast({
              message: i18nText.success.task.update(),
              duration: 2000,
              buttons: [
                {
                  text: i18nText.buttons.detail(),
                  handler: () => {
                    history.push(
                      appLinks.task(workspaceId, tabId, projectId, mainTaskId)
                    );
                  },
                },
              ],
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      hide: () => hideWriteTaskModal(),
    } as AppWriteTasksModalProps
  );

  return (
    <>
      <AppPage>
        <AppHeader>
          {!isSearchMode && (
            <AppToolbar>
              <AppButtons slot="start">
                <AppHeaderBackButton
                  defaultHref={appLinks.project(workspaceId, tabId, projectId)}
                />
              </AppButtons>
              <AppTitle>{project.data && project.data.name}</AppTitle>
              <AppButtons slot="end">
                {isSortMode && (
                  <AppButton
                    color="primary"
                    onClick={() => setIsSortMode(false)}
                  >
                    完了
                  </AppButton>
                )}
                {!isSortMode && (
                  <>
                    {orderBy === "order" && (
                      <AppButton
                        color="primary"
                        onClick={() => setIsSortMode(true)}
                      >
                        <AppIcon slot="icon-only" icon={swapVerticalOutline} />
                      </AppButton>
                    )}
                    <AppButton
                      color="primary"
                      onClick={() => {
                        setIsSearchMode(true);
                        setTimeout(() => searchBarRef.current?.setFocus(), 100);
                      }}
                    >
                      <AppIcon slot="icon-only" icon={searchOutline} />
                    </AppButton>
                  </>
                )}
              </AppButtons>
            </AppToolbar>
          )}
          {isSearchMode && (
            <AppToolbar>
              <WrapSeachBar>
                <SeachBar
                  value={searchWord}
                  placeholder="検索"
                  mode="ios"
                  ref={searchBarRef}
                  inputMode="search"
                  showClearButton="never"
                  onIonChange={(e) => setSearchWord(e.detail.value ?? "")}
                />
                <AppButtons slot="end">
                  <AppButton
                    color="medium"
                    onClick={() => {
                      setIsSearchMode(false);
                      setSearchWord("");
                    }}
                    style={{ margin: 0 }}
                  >
                    <AppIcon slot="icon-only" icon={closeOutline} />
                  </AppButton>
                </AppButtons>
              </WrapSeachBar>
            </AppToolbar>
          )}
        </AppHeader>
        <AppContent fabButtonCount={1}>
          <AppInnerContent>
            {auth && (
              <>
                {/* リスト */}
                {!isSearchMode && (
                  <>
                    {/* state */}
                    <StatesButtons>
                      <StatesButton
                        fill={stateId === "default" ? "outline" : "solid"}
                        shape="round"
                        size="small"
                        onClick={(e) => {
                          showSelectStatePopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                      >
                        <StatesIcon icon={checkmarkOutline} />
                        {stateId === "default" && (
                          <StatesLabel className="ion-hide-sm-down">
                            状態
                          </StatesLabel>
                        )}
                        {stateId === "all" && <StatesLabel>すべて</StatesLabel>}
                        {taskStateEntities[stateId ?? ""] && (
                          <StatesLabel>
                            {taskStateEntities[stateId ?? ""]?.name}
                          </StatesLabel>
                        )}
                      </StatesButton>
                      {/* assignedMember */}
                      <StatesButton
                        fill={assignedMemberId === "all" ? "outline" : "solid"}
                        shape="round"
                        size="small"
                        onClick={(e) => {
                          showSelectAssignedMemberPopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                      >
                        <StatesIcon icon={personOutline} />
                        {assignedMemberId === "all" && (
                          <StatesLabel className="ion-hide-sm-down">
                            担当者
                          </StatesLabel>
                        )}
                        {assignedMemberId !== "all" && (
                          <StatesLabel>
                            {
                              formatMember(assignedMemberId, memberEntities)
                                .name
                            }
                          </StatesLabel>
                        )}
                      </StatesButton>
                      {/* milestone */}
                      {projectTaskMilestones.length > 0 && (
                        <StatesButton
                          fill={milestoneId === "all" ? "outline" : "solid"}
                          shape="round"
                          size="small"
                          onClick={(e) => {
                            showSelectMilestonePopover({
                              cssClass: [
                                "app-popover-width240",
                                "app-popover-height320",
                              ],
                              event: {
                                ...e.nativeEvent,
                                target: e.currentTarget,
                              },
                            });
                          }}
                        >
                          <StatesIcon icon={calendarOutline} />
                          {milestoneId === "all" && (
                            <StatesLabel className="ion-hide-sm-down">
                              マイルストーン
                            </StatesLabel>
                          )}
                          {milestoneId === null && (
                            <StatesLabel>未設定</StatesLabel>
                          )}
                          {taskMilestoneEntities[milestoneId ?? ""] && (
                            <StatesLabel>
                              {taskMilestoneEntities[milestoneId ?? ""]?.name ??
                                "不明"}
                            </StatesLabel>
                          )}
                        </StatesButton>
                      )}
                      {projectTaskTags.length > 0 && (
                        <StatesButton
                          fill={tagIds.length === 0 ? "outline" : "solid"}
                          shape="round"
                          size="small"
                          onClick={(e) => {
                            showSelectTagsPopover({
                              cssClass: [
                                "app-popover-width240",
                                "app-popover-height320",
                              ],
                              event: {
                                ...e.nativeEvent,
                                target: e.currentTarget,
                              },
                            });
                          }}
                        >
                          <StatesIcon icon={pricetagOutline} />
                          <StatesLabel className="ion-hide-sm-down">
                            {tagIds.length === 0 && "タグ"}
                          </StatesLabel>
                          <StatesLabel>
                            {formatItems(tagIds, taskTags).map(
                              ({ id, name }) => (
                                <AppAddComma key={id}>{name}</AppAddComma>
                              )
                            )}
                          </StatesLabel>
                        </StatesButton>
                      )}
                      <StatesButton
                        fill={orderBy === "order" ? "outline" : "solid"}
                        shape="round"
                        size="small"
                        onClick={(e) => {
                          showSelectOrderPopover({
                            cssClass: [
                              "app-popover-width200",
                              "app-popover-height240",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                      >
                        <StatesIcon icon={arrowUpOutline} />
                        {orderBy === "order" && (
                          <StatesLabel className="ion-hide-sm-down">
                            表示順
                          </StatesLabel>
                        )}
                        {orderBy !== "order" && (
                          <StatesLabel>
                            {i18nTaskOrders().find(({ key }) => key === orderBy)
                              ?.name ?? "不明"}
                          </StatesLabel>
                        )}
                      </StatesButton>
                    </StatesButtons>
                    {loading && <AppLoadingCard />}
                    {error && <AppErrorCard>{error}</AppErrorCard>}
                    {!loading && !error && tasks.data.length > 0 && (
                      <AppCard>
                        <AppReorderGroup
                          disabled={!isSortMode}
                          onIonItemReorder={(
                            event: CustomEvent<ItemReorderEventDetail>
                          ) => {
                            appApis.reorderTasks(
                              workspaceId,
                              tasks.data,
                              event.detail.from,
                              event.detail.to
                            );
                            event.detail.complete();
                          }}
                        >
                          {tasks.data.map((task) => (
                            <AppTask
                              key={task.id}
                              {...{
                                task,
                                subTasks: subTasks.data,
                                history,
                                isSortMode,
                                orderBy,
                                routerRef,
                              }}
                            />
                          ))}
                        </AppReorderGroup>
                      </AppCard>
                    )}
                    {tasks.data.length >= limit && (
                      <LimitButton>
                        <AppButton
                          fill="clear"
                          onClick={() => setLimit(limit + 50)}
                        >
                          <AppIcon
                            icon={arrowDownOutline}
                            style={{ marginRight: "4px" }}
                          />
                          <AppLabel style={{ fontSize: "14px" }}>
                            さらに読み込む
                          </AppLabel>
                        </AppButton>
                      </LimitButton>
                    )}
                  </>
                )}
                {/* 検索結果 */}
                {isSearchMode && (
                  <>
                    {searchWord.length < 2 ? (
                      <AppLead>
                        タスクを検索します。キーワードを入力してください
                      </AppLead>
                    ) : (
                      <>
                        {searchTasks.loading && <AppLoadingCard />}
                        {searchTasks.error && (
                          <AppErrorCard>{searchTasks.error}</AppErrorCard>
                        )}
                        {!searchTasks.loading && !searchTasks.error && (
                          <>
                            {searchTasks.data.length === 0 && (
                              <AppLead>検索結果がありません</AppLead>
                            )}
                            {searchTasks.data.length > 0 && (
                              <AppCard>
                                <AppItemGroup>
                                  {searchTasks.data.map((task) => (
                                    <AppTask
                                      key={task.id}
                                      {...{
                                        task,
                                        subTasks: subTasks.data,
                                        history,
                                        isSortMode,
                                        auth,
                                        projectMembers,
                                        taskBookmarks,
                                        projectTaskStates,
                                        projectTaskTags,
                                        projectTaskMilestones,
                                        taskTagEntities,
                                        taskStateEntities,
                                        memberEntities,
                                        routerRef,
                                      }}
                                    />
                                  ))}
                                </AppItemGroup>
                              </AppCard>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </AppInnerContent>
        </AppContent>
        <AppFabButtons vertical="bottom" horizontal="end">
          <AppFabButton
            onClick={() => {
              setSelectedTask(null);
              showWriteTaskModal({
                presentingElement: routerRef,
                canDismiss: true,
              });
            }}
          >
            <AppIcon slot="start" icon={addOutline} />
            タスク作成
          </AppFabButton>
        </AppFabButtons>
      </AppPage>
    </>
  );
};

export default TasksPage;
