import { ComponentProps, FunctionComponent } from "react";
import { IonReorderGroup } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonReorderGroup> {}

const ReorderGroup = styled(IonReorderGroup)`
  ion-item:last-child {
    --inner-border-width: 0;
  }
`;

const AppReorderGroup: FunctionComponent<Props> = ({ children, ...props }) => {
  return <ReorderGroup {...props}>{children}</ReorderGroup>;
};

export default AppReorderGroup;
