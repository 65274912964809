import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./";
import { TabId } from "../models";
import { PAGES } from "../utilities/UtilStatic";

interface UiState {
  enableSideMenu: boolean;
  workspaceId: string;
  tabId: TabId;
}

const initialState: UiState = {
  enableSideMenu: false,
  workspaceId: "",
  tabId: PAGES.TABS.PROJECT,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setEnableSideMenu: (state, action: PayloadAction<boolean>) => {
      state.enableSideMenu = action.payload;
    },
    setWorkspaceId: (state, action: PayloadAction<string>) => {
      state.workspaceId = action.payload;
    },
    setTabId: (state, action: PayloadAction<TabId>) => {
      state.tabId = action.payload;
    },
  },
});

export const { setEnableSideMenu, setWorkspaceId, setTabId } = uiSlice.actions;

export const selectEnableSideMenu = (state: RootState) =>
  state.ui.enableSideMenu;
export const selectWorkspaceId = (state: RootState) => state.ui.workspaceId;
export const selectTabId = (state: RootState) => state.ui.tabId;

export default uiSlice.reducer;
