import { useIonAlert, useIonToast } from "@ionic/react";
import { FunctionComponent, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  AppContent,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppFormInput,
  AppCard,
  AppLead,
  AppHeaderBackButton,
  AppButtons,
  AppCardContent,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
} from "../..";
import { appApis } from "../../../apis/Api";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { TabId } from "../../../models";

const UpdateAccountPassword: FunctionComponent = () => {
  // router
  const history = useHistory();
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // data
  const [password01, setPassword01] = useState<string>("");
  const [password02, setPassword02] = useState<string>("");
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.account(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>パスワード編集</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <AppLead marginBottom="16px">
            パスワードを変更します。新しいパスワードを2回入力してください
          </AppLead>
          <AppCard>
            <AppCardContent>
              <AppFormInput
                value={password01}
                onIonChange={(e) => setPassword01(e.detail.value!)}
                type="password"
                label="新しいパスワード"
                marginBottom="8px"
                name="password01"
              />
              <AppFormInput
                value={password02}
                onIonChange={(e) => setPassword02(e.detail.value!)}
                type="password"
                label="新しいパスワード（確認）"
                name="password02"
              />
            </AppCardContent>
          </AppCard>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.account(workspaceId, tabId)} />
        <AppActionButton
          action="ok"
          onClick={() => {
            if (password01 !== password02) {
              return showAlert({ message: "同じパスワードを入力してください" });
            }
            Promise.resolve()
              .then(() => appApis.updatePassword(password01))
              .then(() => {
                history.goBack();
                showToast({
                  message: i18nText.success.user.update(),
                  duration: 2000,
                });
              })
              .catch((error) => {
                showAlert({ message: i18nErrorToString(error) });
              });
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default UpdateAccountPassword;
