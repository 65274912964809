import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  ItemReorderEventDetail,
  useIonAlert,
  useIonPopover,
} from "@ionic/react";
import { addOutline, closeOutline, removeCircle } from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppFooterButtons,
  AppActionButton,
  AppTitle,
  AppToolbar,
  AppHeader,
  AppButton,
  AppButtons,
  AppIcon,
  AppInnerContent,
  AppPage,
  AppAddComma,
  AppCard,
  AppCardContent,
  AppDecorateOffice,
  AppFormButton,
  AppFormInput,
  AppLabel,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppSelectsPopover,
  AppSelectsPopoverProps,
  AppCardHeader,
  AppCardHeaderText,
  AppReorderGroup,
  AppItem,
  AppReorder,
  AppSelectColorPopover,
  AppSelectColorPopoverProps,
} from "../..";
import { Color, Office } from "../../../models";
import {
  formatMembers,
  formatItems,
  generateOffice,
  getColor,
} from "../../../utilities/UtilFunction";
import { appApis } from "../../../apis/Api";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../store/authSlice";
import styled from "styled-components";
import {
  selectMemberEntities,
  selectMembers,
} from "../../../store/membersSlice";
import { selectTeams } from "../../../store/teamsSlice";
import { TEXT } from "../../../utilities/UtilStatic";
import {
  generateAllowMemberIds,
  getTimestampNow,
} from "../../../utilities/UtilFunction";
import { selectWorkspaceId } from "../../../store/uiSlice";
import { cloneDeep } from "lodash";

export interface AppWriteOfficeModalProps {
  office: Office | null;
  saveOffice: (office: Office) => void;
  deleteOffice?: (workspaceId: string, officeId: string) => void;
  hide: () => void;
}

const Cells = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px 8px;
  &::after {
    content: "";
    flex-grow: 999;
  }
`;

const Inputs = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding: 8px 0;
`;

const InputName = styled.div`
  flex: 1 1 auto;
`;

const WrapAddButton = styled.div`
  padding: 8px 0 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const AppWriteOfficeModal: FunctionComponent<AppWriteOfficeModalProps> = ({
  office,
  hide,
  saveOffice,
  deleteOffice,
}) => {
  // store
  const workspaceId = useSelector(selectWorkspaceId);
  const auth = useSelector(selectAuth);
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  const teams = useSelector(selectTeams);
  // data
  const [form, setForm] = useState<Office | null>(null);
  // functions フォームに初期値をセット
  useEffect(() => {
    if (!auth) return;
    if (office === null) {
      const defaultOffice = generateOffice(
        workspaceId,
        appApis.createId(),
        auth.id
      );
      setForm(defaultOffice);
    } else {
      setForm({
        ...office,
        updatedBy: auth.id,
        updatedAt: getTimestampNow(),
      });
    }
  }, [workspaceId, auth, office]);
  // components
  const [showAlert] = useIonAlert();
  const pageRef = useRef<HTMLElement>(null);
  // components / 公開選択
  const [showSelectPublicPopover, hideSelectPublicPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: [
        { id: true, name: "ワークスペース内全てのメンバー" },
        { id: false, name: "選択したメンバー・チームのみ" },
      ],
      selectedId: form?.public,
      selectItem: (id) => {
        if (!form) return;
        setForm({ ...form, public: Boolean(id) });
        hideSelectPublicPopover();
      },
      isSetNull: false,
    } as AppSelectPopoverProps
  );
  // components / メンバー選択
  const [showSelectMembersPopover] = useIonPopover(AppSelectsPopover, {
    items: members,
    selectedIds: form?.memberIds,
    selectItems: (memberIds: string[]) => {
      if (!form) return;
      setForm({ ...form, memberIds });
    },
    filterKeywords: ["name", "email"],
  } as AppSelectsPopoverProps);
  // components / チーム選択
  const [showSelectTeamsPopover] = useIonPopover(AppSelectsPopover, {
    items: teams,
    selectedIds: form?.teamIds,
    selectItems: (teamIds: string[]) => {
      if (!form) return;
      setForm({ ...form, teamIds });
    },
    filterKeywords: ["name"],
  } as AppSelectsPopoverProps);
  // components / カラー選択
  const [selectedMemberStateIndex, setSelectedMemberStateIndex] =
    useState<number>(0);
  const [showAppSelectColorPopover, hideAppSelectColorPopover] = useIonPopover(
    AppSelectColorPopover,
    {
      color: office?.memberStates[selectedMemberStateIndex]?.color,
      selectColor: (color: Color | null) => {
        if (!form) return;
        const cloneOfficeForm = cloneDeep(form);
        cloneOfficeForm.memberStates[selectedMemberStateIndex].color = color;
        setForm(cloneOfficeForm);
        hideAppSelectColorPopover();
      },
    } as AppSelectColorPopoverProps
  );

  return (
    <AppPage ref={pageRef}>
      <AppHeader>
        <AppToolbar>
          <AppTitle>オフィス編集</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {form && (
            <>
              <AppCard marginBottom="16px">
                <AppCardContent>
                  {/* オフィス名 */}
                  <AppFormInput
                    value={form.name}
                    onIonChange={(e) => {
                      if (!form) return;
                      setForm({
                        ...form,
                        name: e.detail.value ?? "",
                      });
                    }}
                    type="text"
                    label="オフィス名"
                    marginBottom="8px"
                    name="name"
                  />
                  {/* アクセス可能なメンバー */}
                  <AppFormButton
                    label="アクセス可能なメンバー"
                    onClick={(e) => {
                      showSelectPublicPopover({
                        cssClass: ["app-popover-width320"],
                        event: { ...e.nativeEvent, target: e.currentTarget },
                      });
                    }}
                    marginBottom="8px"
                  >
                    <AppLabel>
                      {form.public
                        ? "ワークスペース内全てのメンバー"
                        : "選択したメンバー・チームのみ"}
                    </AppLabel>
                  </AppFormButton>
                  {form.public === false && (
                    <>
                      {/* メンバー選択 */}
                      <AppFormButton
                        label="アクセス可能なメンバー"
                        onClick={(e) => {
                          showSelectMembersPopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                        helperText="アクセス可能なメンバーを選択してください"
                        marginBottom="8px"
                      >
                        {form.memberIds.length === 0 && (
                          <AppLabel>{TEXT.EMPTY}</AppLabel>
                        )}
                        {formatMembers(form.memberIds, memberEntities).map(
                          ({ id, name }) => (
                            <AppAddComma key={id}>
                              <AppLabel>{name}</AppLabel>
                            </AppAddComma>
                          )
                        )}
                      </AppFormButton>
                      {/* チーム選択 */}
                      <AppFormButton
                        label="アクセス可能なチーム"
                        onClick={(e) => {
                          showSelectTeamsPopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                        helperText="アクセス可能なチームを選択してください"
                        marginBottom="8px"
                      >
                        {form.teamIds.length === 0 && (
                          <AppLabel>{TEXT.EMPTY}</AppLabel>
                        )}
                        {formatItems(form.teamIds, teams).map(
                          ({ id, name }) => (
                            <AppAddComma key={id}>
                              <AppLabel>{name}</AppLabel>
                            </AppAddComma>
                          )
                        )}
                      </AppFormButton>
                    </>
                  )}
                  {/* 面積 */}
                  <Cells>
                    {/* 横幅 */}
                    <AppFormInput
                      label="横幅"
                      value={form.col}
                      type="number"
                      inputmode="numeric"
                      name="col"
                      style={{
                        width: "80px",
                        "--placeholder-color": "var(--app-color-grey900)",
                        flexGrow: 1,
                      }}
                      onIonChange={(e) => {
                        if (!form) return;
                        const value = Number(e.detail.value ?? 1);
                        setForm({
                          ...form,
                          col: value > 20 ? 20 : value,
                        });
                      }}
                    />
                    {/* 縦幅 */}
                    <AppFormInput
                      label="縦幅"
                      value={form.row}
                      type="number"
                      inputmode="numeric"
                      name="row"
                      style={{
                        width: "80px",
                        "--placeholder-color": "var(--app-color-grey900)",
                        flexGrow: 1,
                      }}
                      onIonChange={(e) => {
                        if (!form) return;
                        const value = Number(e.detail.value ?? 1);
                        setForm({
                          ...form,
                          row: value > 20 ? 20 : value,
                        });
                      }}
                    />
                  </Cells>
                </AppCardContent>
              </AppCard>
              <AppDecorateOffice
                office={form}
                onSet={(form) => setForm(form)}
                routerRef={pageRef.current ?? undefined}
                readonly={false}
              />
              {/* memberStates */}
              <AppCard>
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>メンバーステータス</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={false}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    const cloneOfficeForm = cloneDeep(form);
                    event.detail.complete(cloneOfficeForm.memberStates);
                    setForm(cloneOfficeForm);
                  }}
                >
                  {form.memberStates.map(({ id, name, color }, index) => (
                    <AppItem key={id} lines="none">
                      <AppIcon
                        slot="start"
                        icon={removeCircle}
                        color="danger"
                        onClick={() => {
                          const cloneOfficeForm = cloneDeep(form);
                          cloneOfficeForm.memberStates.splice(index, 1);
                          setForm(cloneOfficeForm);
                        }}
                        style={{ marginRight: "16px" }}
                      />
                      <Inputs>
                        <InputName>
                          <AppFormInput
                            value={name}
                            label="ステータス名"
                            onIonChange={(e) => {
                              const cloneOfficeForm = cloneDeep(form);
                              const cloneMemberState =
                                cloneOfficeForm.memberStates[index];
                              if (cloneMemberState === undefined) return;
                              cloneMemberState.name = e.detail.value ?? "";
                              setForm(cloneOfficeForm);
                            }}
                            type="text"
                            name="taskTagName"
                          />
                        </InputName>
                        {/* カラー */}
                        <AppFormButton
                          label="カラー"
                          style={{ flex: "0 1 auto" }}
                          onClick={(e) => {
                            setSelectedMemberStateIndex(index);
                            showAppSelectColorPopover({
                              cssClass: [
                                "app-popover-width240",
                                "app-popover-height320",
                              ],
                              event: {
                                ...e.nativeEvent,
                                target: e.currentTarget,
                              },
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexFlow: "row nowrap",
                              alignItems: "center",
                            }}
                          >
                            {color === null && (
                              <AppLabel>{TEXT.EMPTY}</AppLabel>
                            )}
                            {color !== null && (
                              <>
                                <div
                                  style={{
                                    width: "1em",
                                    height: "1em",
                                    background: getColor(color).light,
                                    borderRadius: "100%",
                                    marginRight: "12px",
                                  }}
                                />
                                <AppLabel>{getColor(color).name}</AppLabel>
                              </>
                            )}
                          </div>
                        </AppFormButton>
                      </Inputs>
                      <AppReorder slot="end" style={{ marginLeft: "16px" }} />
                    </AppItem>
                  ))}
                </AppReorderGroup>
                <WrapAddButton>
                  <AppActionButton
                    onClick={() => {
                      const cloneOfficeForm = cloneDeep(form);
                      cloneOfficeForm.memberStates.push({
                        id: appApis.createId(),
                        name: "",
                        color: null,
                      });
                      setForm(cloneOfficeForm);
                    }}
                  >
                    <AppIcon slot="start" icon={addOutline} />
                    追加
                  </AppActionButton>
                </WrapAddButton>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        {office && (
          <AppActionButton
            action="delete"
            onClick={() => {
              showAlert({
                message: "オフィスを削除しますか？",
                buttons: [
                  "キャンセル",
                  {
                    text: "Ok",
                    handler: () => {
                      if (deleteOffice) {
                        deleteOffice(office.workspaceId, office.id);
                      }
                    },
                  },
                ],
              });
            }}
          >
            {i18nText.buttons.delete()}
          </AppActionButton>
        )}
        <AppActionButton
          action="ok"
          onClick={() => {
            if (!form || !auth) return;
            saveOffice({
              ...form,
              ...generateAllowMemberIds(
                form.public,
                form.memberIds,
                form.teamIds,
                members,
                teams
              ),
            });
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppWriteOfficeModal;
