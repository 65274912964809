import { ComponentProps, FunctionComponent } from "react";
import { IonBreadcrumbs } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonBreadcrumbs> {}

const Breadcrumbs = styled(IonBreadcrumbs)`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 4px 0;
`;

const AppBreadcrumbs: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Breadcrumbs {...props}>{children}</Breadcrumbs>;
};

export default AppBreadcrumbs;
