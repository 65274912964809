import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./";
import { Member } from "../models/Member";
import { selectProject } from "./projectsSlice";
import { ROLE } from "../utilities/UtilStatic";

interface MembersState extends EntityState<Member> {
  loading: boolean;
  error: string | null;
  selectedId: string | null;
}

const adapter = createEntityAdapter<Member>();

const { selectById } = adapter.getSelectors();

const initialState: MembersState = adapter.getInitialState({
  loading: true,
  error: null,
  selectedId: null,
});

export const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setMember: (state, action: PayloadAction<Member>) => {
      adapter.setOne(state, action.payload);
    },
    setMembers: (state, action: PayloadAction<Member[]>) => {
      adapter.setAll(state, action.payload);
    },
    setMembersLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMembersError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setMembersSelectedId: (state, action: PayloadAction<string | null>) => {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setMember,
  setMembers,
  setMembersLoading,
  setMembersError,
  setMembersSelectedId,
} = membersSlice.actions;

export const selectMembers = adapter.getSelectors(
  (state: RootState) => state.members
).selectAll;

export const selectMemberEntities = adapter.getSelectors(
  (state: RootState) => state.members
).selectEntities;

export const selectMembersLoading = (state: RootState) => state.members.loading;

export const selectMembersError = (state: RootState) => state.members.error;

export const selectMember = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.members,
    (state) => (selectById(state, id!) ? selectById(state, id!)! : null)
  );
};

export const selectProjectMembers = (projectId: string | null) => {
  return createSelector(
    selectMembers,
    selectProject(projectId),
    (members, project) => {
      if (!project) return [];
      return members.filter((member) =>
        project.allowMemberIds.some((memberId) => memberId === member.id)
      );
    }
  );
};

export const selectMyMember = (state: RootState) => {
  return selectById(state.members, state.auth.data?.id ?? "") ?? null;
};

export const selectIsWorkspaceAdmin = (state: RootState) => {
  return (
    selectById(state.members, state.auth.data?.id ?? "")?.role === ROLE.ADMIN
  );
};

export const selectWorkspaceAdminsIgnoredMe = createSelector(
  (state: RootState) => state.auth.data,
  selectMembers,
  (auth, members) => {
    return members
      .filter(({ id }) => id !== auth?.id)
      .filter(({ role }) => role === ROLE.ADMIN);
  }
);

export default membersSlice.reducer;
