import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Office } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useOffice = ({
  workspaceId,
  officeId,
}: {
  workspaceId: string;
  officeId: string;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Office | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData(null);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis.$readOffice(workspaceId, officeId).subscribe({
      next: (project) => {
        console.log("useOffice");
        setData(project);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.log(error);
        setData(null);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId, officeId]);

  return { data, loading, error };
};
