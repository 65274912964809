import { useIonAlert, useIonModal, useIonToast } from "@ionic/react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { appApis } from "../../../apis/Api";
import { useError, useLoading, useTeam } from "../../../hooks";
import { selectAuth } from "../../../store/authSlice";
import {
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppCard,
  AppItemGroup,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppErrorCard,
  AppLoadingCard,
  AppHeaderBackButton,
  AppPage,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppTitle,
  AppItem,
  AppInnerContent,
  AppFormItem,
  AppIcon,
  AppAddComma,
  AppWriteTeamModal,
  AppWriteTeamModalProps,
} from "../..";
import { peopleCircleOutline, peopleOutline } from "ionicons/icons";
import { formatMembers } from "../../../utilities/UtilFunction";
import { TEXT } from "../../../utilities/UtilStatic";
import { TabId } from "../../../models";

const TeamDetailsPage: FunctionComponent<{
  routerRef: HTMLElement;
}> = ({ routerRef }) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, teamId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    teamId: string;
  }>();
  // store
  const auth = useSelector(selectAuth);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  // hooks
  const team = useTeam({ workspaceId, teamId });
  // loading & error
  const loading = useLoading(membersLoading, team.loading);
  const error = useError(membersError, team.error);
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showUpdateTeamModal, hideUpdateTeamModal] = useIonModal(
    AppWriteTeamModal,
    {
      team: team.data,
      saveTeam: (form) => {
        if (!form || !auth) return;
        Promise.resolve()
          .then(() => {
            return appApis.updateTeam(form);
          })
          .then(() => {
            showToast({
              message: i18nText.success.team.update(),
              duration: 2000,
            });
            hideUpdateTeamModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      deleteTeam: (workspaceId, teamId) => {
        showAlert({
          message: "チームを削除しますか？",
          buttons: [
            "キャンセル",
            {
              text: "Ok",
              handler: () => {
                Promise.resolve()
                  .then(() => appApis.deleteTeam(workspaceId, teamId))
                  .then(() => {
                    showToast({
                      message: i18nText.success.team.delete(),
                      duration: 2000,
                    });
                    hideUpdateTeamModal();
                    history.goBack();
                  })
                  .catch((error) => {
                    showAlert({ message: i18nErrorToString(error) });
                  });
              },
            },
          ],
        });
      },
      hide: () => hideUpdateTeamModal(),
      routerRef,
    } as AppWriteTeamModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>チーム</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && team.data && (
            <AppCard>
              <AppItemGroup>
                <AppItem>
                  <AppIcon
                    icon={peopleCircleOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="チーム名">{team.data.name}</AppFormItem>
                </AppItem>
                <AppItem>
                  <AppIcon
                    icon={peopleOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="所属メンバー">
                    {team.data.memberIds.length === 0 && TEXT.EMPTY}
                    {formatMembers(team.data.memberIds, memberEntities).map(
                      ({ id, name }) => (
                        <AppAddComma key={id}>{name}</AppAddComma>
                      )
                    )}
                  </AppFormItem>
                </AppItem>
              </AppItemGroup>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.tab(workspaceId, tabId)} />
        <AppActionButton
          action="ok"
          onClick={() => {
            showUpdateTeamModal({
              presentingElement: routerRef,
              canDismiss: true,
            });
          }}
        >
          {i18nText.buttons.edit()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default TeamDetailsPage;
