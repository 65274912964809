import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  helperText?: string;
  optional?: boolean;
}

const Content = styled.div`
  display: block;
  padding: 10px 0;
`;

const Label = styled.div`
  font-size: 12px;
  color: var(--app-color-grey600);
  margin-bottom: 4px;
  padding: 0 1px;
`;

const Text = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
`;

const HelperText = styled.div`
  font-size: 12px;
  color: var(--app-color-grey600);
  margin-top: 2px;
  padding: 0 2px;
`;

const AppFormItem: FunctionComponent<Props> = ({
  label,
  helperText,
  optional,
  children,
  ...props
}) => {
  return (
    <Content {...props}>
      {label && (
        <Label>
          {label}
          {optional && "（オプション）"}
        </Label>
      )}
      <Text>{children}</Text>
      {helperText && <HelperText>{helperText}</HelperText>}
    </Content>
  );
};

export default AppFormItem;
