import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  AppContent,
  AppCard,
  AppMenuButton,
  AppLabel,
  AppErrorCard,
  AppLoadingCard,
  AppAvatar,
  AppButtons,
  AppChip,
  AppHeader,
  AppItem,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
  AppReorder,
  AppReorderGroup,
  AppButton,
  AppIcon,
} from "../..";
import { useLoading, useError } from "../../../hooks";
import {
  selectMembers,
  selectMembersLoading,
  selectMembersError,
} from "../../../store/membersSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nRoleToText } from "../../../utilities/UtilI18nText";
import { TabId } from "../../../models";
import { ItemReorderEventDetail } from "@ionic/react";
import { appApis } from "../../../apis/Api";
import { swapVerticalOutline } from "ionicons/icons";
import { AVATAR } from "../../../utilities/UtilStatic";

const TabMemberPage: FunctionComponent = () => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const members = useSelector(selectMembers);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const loading = useLoading(membersLoading);
  const error = useError(membersError);
  // states
  const [isSortMode, setIsSortMode] = useState<boolean>(false);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppMenuButton />
          </AppButtons>
          <AppTitle>メンバー</AppTitle>
          <AppButtons slot="end">
            {isSortMode && (
              <AppButton color="primary" onClick={() => setIsSortMode(false)}>
                完了
              </AppButton>
            )}
            {!isSortMode && members.length > 1 && (
              <AppButton color="primary" onClick={() => setIsSortMode(true)}>
                <AppIcon slot="icon-only" icon={swapVerticalOutline} />
              </AppButton>
            )}
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && (
            <AppCard>
              <AppReorderGroup
                disabled={!isSortMode}
                onIonItemReorder={(
                  event: CustomEvent<ItemReorderEventDetail>
                ) => {
                  Promise.resolve()
                    .then(() =>
                      appApis.reorderMembers(
                        workspaceId,
                        members,
                        event.detail.from,
                        event.detail.to
                      )
                    )
                    .catch((error) => {});
                  event.detail.complete();
                }}
              >
                {members.map((item) => (
                  <AppItem
                    routerLink={appLinks.memberDetails(
                      workspaceId,
                      tabId,
                      item.id
                    )}
                    detail={!isSortMode}
                    key={item.id}
                  >
                    <AppAvatar slot="start">
                      <img src={item.avatar ?? AVATAR.DEFAULT} />
                    </AppAvatar>
                    <AppLabel>{item.name}</AppLabel>
                    <AppChip slot="end" color="medium">
                      <AppLabel>{i18nRoleToText(item.role)}</AppLabel>
                    </AppChip>
                    <AppReorder slot="end" />
                  </AppItem>
                ))}
              </AppReorderGroup>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default TabMemberPage;
