import { Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
// 追加
import { useEffect, useRef } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJson from "./locales/en.json";
import jaJson from "./locales/ja.json";
import { appIndexPath, appRouterPages } from "./utilities/UtilPage";
import languageDetector from "i18next-browser-languagedetector";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";
import styled from "styled-components";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// オリジナル
import "./theme/global.css";
import "./theme/lexical-editor.css";

import { IonTabs } from "@ionic/react";
import { AppMenu, AppIndex, Error404Page } from "./components";
import { useSelector } from "react-redux";
import { selectUser } from "./store/userSlice";
import { selectAuth } from "./store/authSlice";
import i18next from "i18next";
import { FunctionComponent } from "react";

setupIonicReact({ backButtonText: "" });

const SplitPane = styled(IonSplitPane)`
  --border: none;
  --side-max-width: 240px;
  --side-min-width: 240px;
  --side-width: 240px;
`;

const App: FunctionComponent = () => {
  // store
  const user = useSelector(selectUser);

  // data
  const tabRef = useRef<InstanceType<typeof IonTabs>>(null);
  const routerRef = useRef<HTMLIonRouterOutletElement>(null);

  // 多言語対応
  useEffect(() => {
    // dayjs
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(localeData);
    // 多言語対応
    i18n
      .use(languageDetector)
      .use(initReactI18next)
      .init({
        resources: {
          en: { translation: enJson },
          ja: { translation: jaJson },
        },
        fallbackLng: "ja",
        supportedLngs: ["en", "ja"],
      });
  }, []);

  // 多言語対応
  useEffect(() => {
    if (user) {
      i18next.changeLanguage(user.language);
    }
  }, [user]);

  return (
    <IonApp>
      <IonReactRouter>
        <SplitPane contentId="main" when="md">
          <AppMenu
            {...{
              tabRef: tabRef.current!,
              routerRef: routerRef.current as HTMLElement,
            }}
          />
          <IonRouterOutlet
            id="main"
            style={{ backgroundColor: "var(--app-color-blue-grey50)" }}
            ref={routerRef}
            animated={false}
          >
            {appRouterPages().map((routerPage, index) => (
              <Route
                path={routerPage.path}
                component={routerPage.component}
                exact={true}
                key={index}
              />
            ))}
            <Route
              path={appIndexPath}
              render={() => (
                <AppIndex
                  {...{
                    tabRef: tabRef,
                    routerRef: routerRef.current as HTMLElement,
                  }}
                />
              )}
            />
            <Route component={Error404Page} />
          </IonRouterOutlet>
        </SplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
