import { FunctionComponent, useEffect, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppCard,
  AppFormInput,
  AppFooterButtons,
  AppActionButton,
  AppLead,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppButton,
  AppIcon,
  AppTitle,
  AppCardContent,
  AppInnerContent,
  AppPage,
} from "../..";

export interface AppSignInModalProps {
  email?: string;
  signIn: (email: string, password: string) => void;
  hide: () => void;
}

const AppSignInModal: FunctionComponent<AppSignInModalProps> = ({
  email,
  signIn,
  hide,
}) => {
  // form
  const [form, setForm] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  // functions フォーム初期化
  useEffect(() => {
    if (email) {
      setForm({ ...form, email });
    }
  }, [email]);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>{i18nText.features.signin()}</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {!email && (
            <AppLead marginBottom="16px">
              Eメールとパスワードを入力してください
            </AppLead>
          )}
          {email && (
            <AppLead marginBottom="16px">パスワードを入力してください</AppLead>
          )}
          <AppCard>
            <AppCardContent>
              {!email && (
                <AppFormInput
                  value={form.email}
                  onIonChange={(e) =>
                    setForm({ ...form, email: e.detail.value ?? "" })
                  }
                  label="Eメール"
                  type="email"
                  marginBottom="8px"
                  name="email"
                />
              )}
              <AppFormInput
                value={form.password}
                onIonChange={(e) =>
                  setForm({ ...form, password: e.detail.value ?? "" })
                }
                label="パスワード"
                type="password"
                name="password"
              />
            </AppCardContent>
          </AppCard>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        <AppActionButton
          action="ok"
          onClick={() => signIn(form.email, form.password)}
        >
          {i18nText.buttons.signIn()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppSignInModal;
