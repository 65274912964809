import { IonTitle, useIonPopover } from "@ionic/react";
import dayjs, { Dayjs } from "dayjs";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { FunctionComponent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppButton,
  AppButtons,
  AppCard,
  AppCardContent,
  AppContent,
  AppErrorCard,
  AppHeader,
  AppHeaderBackButton,
  AppIcon,
  AppInnerContent,
  AppItem,
  AppItemGroup,
  AppLabel,
  AppLoadingCard,
  AppMemberPopover,
  AppMemberPopoverProps,
  AppPage,
  AppTask,
  AppToolbar,
} from "../..";
import {
  useDailyOfficeMemberHistories,
  useError,
  useLoading,
  useProjects,
  useTasks,
} from "../../../hooks";
import { TabId, Task } from "../../../models";
import {
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import { formatMember } from "../../../utilities/UtilFunction";
import { appLinks } from "../../../utilities/UtilPage";
import { DAYJS, TEXT } from "../../../utilities/UtilStatic";

const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

const HeaderMember = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0 8px;
  cursor: pointer;
`;

const HeaderMemberAvator = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const HeaderMemberName = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
`;

const HeaderTotalWorkingTime = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
  padding: 0 1px;
`;

const Timelines = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 20px 0;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -13px;
    left: 24px;
    bottom: -13px;
    width: 1px;
    border-radius: 3px;
    background: var(--app-color-grey300);
  }
  .ios &::before {
    top: -20px;
  }
  .md &::before {
    top: -13px;
  }
`;

const Timeline = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px 0;
  padding-left: 32px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 19px;
    width: 11px;
    height: 11px;
    margin-top: 6px;
    border-radius: 50%;
    background: var(--app-color-grey300);
  }
`;

const TimelineDateTime = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
`;

const DailyOfficeMembersHistories: FunctionComponent = () => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, date, memberId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    date: string;
    memberId: string;
  }>();
  // store
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  // states
  const [day, setDay] = useState<Dayjs>(dayjs(date));

  // functions 読み込み
  const projects = useProjects({ workspaceId });
  const officeMemberHistories = useDailyOfficeMemberHistories({
    workspaceId,
    date: day.format(DAYJS.YYYY_MM_DD),
    memberId,
  });
  const loading = useLoading(
    membersLoading,
    projects.loading,
    officeMemberHistories.loading
  );
  const error = useError(
    membersError,
    projects.error,
    officeMemberHistories.error
  );

  // functions データ成形
  const histories = useMemo(() => {
    return officeMemberHistories.data.map((officeMemberHistory) => {
      const beginAt: Dayjs = dayjs(officeMemberHistory.beginAt.toDate());
      const taskId = officeMemberHistory.taskId;
      if (officeMemberHistory.endAt) {
        const endAt: Dayjs = dayjs(officeMemberHistory.endAt.toDate());
        if (
          beginAt.year() === endAt.year() &&
          beginAt.month() === endAt.month() &&
          beginAt.date() === endAt.date()
        ) {
          return {
            beginAt: beginAt.format(DAYJS.HH_mm),
            endAt: endAt.format(DAYJS.HH_mm),
            taskId,
          };
        } else {
          return {
            beginAt: beginAt.format(DAYJS.YYYY_MM_DD_HH_mm),
            endAt: endAt.format(DAYJS.YYYY_MM_DD_HH_mm),
            taskId,
          };
        }
      } else {
        return {
          beginAt: beginAt.format(DAYJS.HH_mm),
          endAt: null,
          taskId,
        };
      }
    });
  }, [officeMemberHistories.data]);

  const taskIds = useMemo(() => {
    return officeMemberHistories.data
      .filter(({ taskId }) => taskId !== null)
      .map(({ taskId }) => taskId!);
  }, [officeMemberHistories.data]);

  const tasks = useTasks({ workspaceId, taskIds });

  // functions 今月の合計作業時間
  const totalHours = useMemo(() => {
    let second: number = 0;
    officeMemberHistories.data.forEach(({ beginAt, endAt }) => {
      if (endAt) {
        second += dayjs(endAt.toDate()).diff(beginAt.toDate(), DAYJS.SECOND);
      }
    });
    return Math.floor((second / 60 / 60) * 10) / 10;
  }, [officeMemberHistories.data]);

  // components
  const [showMemberPopover, hideMemberPopover] = useIonPopover(
    AppMemberPopover,
    {
      member: memberEntities[memberId] ?? null,
      showMemberDetails: (link: string) => {
        history.push(link);
        hideMemberPopover();
      },
    } as AppMemberPopoverProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.dailyOfficeMembersHistories(
                workspaceId,
                tabId,
                date
              )}
            />
          </AppButtons>
          <IonTitle>{day.format(DAYJS.YYYY_MM_DD)}</IonTitle>
          <AppButtons slot="end">
            <AppButton
              color="primary"
              onClick={() => setDay(day.add(-1, DAYJS.DAY))}
            >
              <AppIcon slot="icon-only" icon={chevronBackOutline} />
            </AppButton>
            <AppButton
              color="primary"
              onClick={() => setDay(day.add(1, DAYJS.DAY))}
            >
              <AppIcon slot="icon-only" icon={chevronForwardOutline} />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {!loading && !error && (
            <Header>
              <HeaderMember
                onClick={(e) => {
                  showMemberPopover({
                    cssClass: "app-popover-width280",
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
              >
                <HeaderMemberAvator
                  src={formatMember(memberId, memberEntities).avatar}
                />
                <HeaderMemberName>
                  {formatMember(memberId, memberEntities).name}
                </HeaderMemberName>
              </HeaderMember>
              <HeaderTotalWorkingTime>
                合計作業時間{totalHours}時間
              </HeaderTotalWorkingTime>
            </Header>
          )}
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && officeMemberHistories.data.length > 0 && (
            <AppCard style={{ position: "relative" }}>
              <AppCardContent>
                <Timelines>
                  {histories.map(({ beginAt, endAt, taskId }, index) => (
                    <Timeline key={index}>
                      <TimelineDateTime>
                        {`${beginAt} ${TEXT.DATE_DASH} ${endAt ?? ""}`}
                      </TimelineDateTime>
                      {taskId && (
                        <AppCard>
                          <AppItemGroup>
                            <OfficeMemberHistoryTask
                              {...{
                                taskId,
                                tasks: tasks.data,
                                history,
                              }}
                            />
                          </AppItemGroup>
                        </AppCard>
                      )}
                    </Timeline>
                  ))}
                </Timelines>
              </AppCardContent>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

const OfficeMemberHistoryTask: FunctionComponent<{
  taskId: string | null;
  tasks: Task[];
  history: any;
  routerRef?: HTMLElement;
}> = ({ taskId, tasks, history, routerRef }) => {
  const task = useMemo(() => tasks.find(({ id }) => id === taskId), [tasks]);
  return (
    <>
      {task && <AppTask isSortMode={true} {...{ task, history, routerRef }} />}
      {!task && (
        <AppItem>
          <AppLabel color="medium">不明</AppLabel>
        </AppItem>
      )}
    </>
  );
};

export default DailyOfficeMembersHistories;
