import styled from "styled-components";
import { TaskComment } from "../../models";
import { IonAvatar, IonIcon, useIonPopover } from "@ionic/react";
import dayjs from "dayjs";
import { AppMemberPopover, AppMemberPopoverProps } from "..";
import { checkmarkSharp } from "ionicons/icons";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { useSelector } from "react-redux";
import { selectMemberEntities, selectMembers } from "../../store/membersSlice";
import { formatMember, formatMembers } from "../../utilities/UtilFunction";
import { selectAuth } from "../../store/authSlice";
import { useHistory } from "react-router";
import { selectTabId, selectWorkspaceId } from "../../store/uiSlice";

interface Props {
  taskComment: TaskComment;
  isFocus: boolean;
  setRef: (ref: React.RefObject<HTMLDivElement>) => void;
}

// comments
const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 0 12px;
  gap: 0 8px;
`;

const Avatar = styled(IonAvatar)`
  flex: 0 1 auto;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const Direction = styled.div`
  flex: 1 0 0%;
  display: flex;
  overflow: hidden;
`;

const CommentWrap = styled.div``;

const Comment = styled.div<{ isFocus: boolean }>`
  background: ${({ isFocus }) =>
    isFocus ? `var(--app-color-primary50)` : `var(--app-color-white)`};
  padding: 12px 16px;
  border-radius: 20px;
  border: 1px solid var(--app-color-grey300-rgba);
  overflow: hidden;
  margin-bottom: 4px;
`;

const CommentMargin = styled.div`
  flex: 0 1 32px;
`;

const Name = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  color: var(--app-color-grey600);
`;

const Text = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  & p {
    margin: 0 0 2px;
  }
`;

const Notifications = styled.div`
  display: flex;
  margin-bottom: 4px;
  padding: 0 3px;
  gap: 0 4px;
  cursor: pointer;
`;

const Notification = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const NotificationReadIcon = styled(IonIcon)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  color: var(--app-color-white);
  pointer-events: none;
`;

const Date = styled.div`
  display: flex;
  font-size: 14px;
  color: var(--app-color-grey500);
  padding: 0 2px;
`;

const AppTaskComment: FunctionComponent<Props> = ({
  taskComment,
  isFocus,
  setRef,
}) => {
  const history = useHistory();
  // store
  const auth = useSelector(selectAuth);
  const memberEntities = useSelector(selectMemberEntities);
  // states
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [showMemberPopover, hideMemberPopover] = useIonPopover(
    AppMemberPopover,
    {
      member: memberEntities[selectedMemberId ?? ""] ?? null,
      showMemberDetails: (link: string) => {
        history.push(link);
        hideMemberPopover();
      },
    } as AppMemberPopoverProps
  );

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => setRef(ref), [ref, setRef]);

  return (
    <Content
      key={taskComment.id}
      style={{
        flexDirection:
          auth?.id === taskComment.createdBy ? "row-reverse" : "row",
      }}
      ref={ref}
    >
      <Avatar
        onClick={(e) => {
          if (!memberEntities[taskComment.createdBy]) return;
          setSelectedMemberId(taskComment.createdBy);
          showMemberPopover({
            cssClass: "app-popover-width280",
            event: {
              ...e.nativeEvent,
              target: e.currentTarget,
            },
          });
        }}
      >
        <img src={formatMember(taskComment.createdBy, memberEntities).avatar} />
      </Avatar>
      <Direction
        style={{
          flexDirection:
            auth?.id === taskComment.createdBy ? "row-reverse" : "row",
        }}
      >
        <CommentWrap>
          <Comment isFocus={isFocus}>
            <Name>
              {formatMember(taskComment.createdBy, memberEntities).name}
            </Name>
            <Text>
              {taskComment.commentEditorState && (
                <LexicalComposer
                  initialConfig={{
                    namespace: "taskCommentText",
                    editorState: JSON.stringify(taskComment.commentEditorState),
                    onError: (error) => console.log(error),
                    editable: false,
                  }}
                >
                  <RichTextPlugin
                    contentEditable={<ContentEditable />}
                    placeholder=""
                  />
                </LexicalComposer>
              )}
            </Text>
          </Comment>
          {taskComment.notifiedMemberIds.length > 0 && (
            <Notifications
              style={{
                flexDirection:
                  auth?.id === taskComment.createdBy ? "row-reverse" : "row",
              }}
            >
              {formatMembers(taskComment.notifiedMemberIds, memberEntities).map(
                ({ id, avatar }) => (
                  <Notification
                    key={id}
                    onClick={(e) => {
                      setSelectedMemberId(id);
                      showMemberPopover({
                        cssClass: "app-popover-width280",
                        event: {
                          ...e.nativeEvent,
                          target: e.currentTarget,
                        },
                      });
                    }}
                  >
                    {taskComment.unreadMemberIds.includes(id) === false && (
                      <NotificationReadIcon icon={checkmarkSharp} />
                    )}
                    <img src={avatar} />
                  </Notification>
                )
              )}
            </Notifications>
          )}
          <Date
            style={{
              flexDirection:
                auth?.id === taskComment.createdBy ? "row-reverse" : "row",
            }}
          >
            <div>
              {dayjs(taskComment.createdAt.toDate()).format("YYYY-MM-DD HH:mm")}
              {!taskComment.createdAt.isEqual(taskComment.updatedAt) && (
                <span style={{ marginLeft: 4 }}>
                  {`(${dayjs(taskComment.updatedAt.toDate()).format(
                    "YYYY-MM-DD HH:mm"
                  )})`}
                </span>
              )}
            </div>
          </Date>
        </CommentWrap>
      </Direction>
      <CommentMargin />
    </Content>
  );
};

export default AppTaskComment;
