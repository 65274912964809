import { FunctionComponent } from "react";
import { Priority } from "../../../models";
import { AppItem, AppLabel, AppRadio, AppRadioGroup } from "../..";

export interface AppSelectPriorityPopoverProps {
  selectedId: Priority;
  selectPriority: (id: Priority) => void;
}

const AppSelectPriorityPopover: FunctionComponent<
  AppSelectPriorityPopoverProps
> = ({ selectedId, selectPriority }) => {
  return (
    <AppRadioGroup value={selectedId}>
      <AppItem button detail={false} onClick={() => selectPriority(0)}>
        <AppRadio slot="start" value={0} mode="md" />
        <AppLabel>-</AppLabel>
      </AppItem>
      <AppItem button detail={false} onClick={() => selectPriority(1)}>
        <AppRadio slot="start" value={1} mode="md" />
        <AppLabel>高</AppLabel>
      </AppItem>
      <AppItem button detail={false} onClick={() => selectPriority(-1)}>
        <AppRadio slot="start" value={-1} mode="md" />
        <AppLabel>低</AppLabel>
      </AppItem>
    </AppRadioGroup>
  );
};

export default AppSelectPriorityPopover;
