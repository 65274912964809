import { ComponentProps, FunctionComponent } from "react";
import { IonIcon } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonIcon> {}

const Icon = styled(IonIcon)`
  margin-right: 16px;
  color: var(--app-color-grey600);
`;

const AppItemIcon: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Icon {...props}>{children}</Icon>;
};

export default AppItemIcon;
