import dayjs from "dayjs";
import { SerializedEditorState } from "lexical";
import { Dictionary } from "@reduxjs/toolkit";
import {
  Project,
  Office,
  Workspace,
  TaskState,
  OfficeMember,
  Color,
  User,
  Timezone,
  Team,
  TaskBookmark,
  Task,
  Device,
  TaskMilestone,
  TaskTag,
  TaskComment,
  Invitation,
  Role,
  Timestamp,
  Member,
} from "../models";
import { appApis } from "../apis/Api";
import { AVATAR, COLOR, DOMAIN, PLATFORM, TEXT } from "./UtilStatic";
import {
  documentOutline,
  documentTextOutline,
  imageOutline,
  playCircleOutline,
} from "ionicons/icons";
import { Timestamp as _Timestamp } from "firebase/firestore";
import { uniq } from "lodash";
import { VERSIONS } from "./UtilStatic";
import { i18nText } from "./UtilI18nText";
import { Preferences } from "@capacitor/preferences";
import { Dayjs } from "dayjs";
import { DAYJS } from "./UtilStatic";

export const sliceByNumber = <T>(array: T[], number: number) => {
  const length = Math.ceil(array.length / number);
  return new Array(length)
    .fill(null)
    .map((_, i) => array.slice(i * number, (i + 1) * number));
};

export const formatMember = (
  memberId: string | null,
  memberEntities: Dictionary<Member>
) => {
  if (memberId === null) {
    return {
      id: appApis.createId(),
      name: TEXT.EMPTY,
      avatar: AVATAR.DEFAULT,
      order: 9999999999,
    };
  } else {
    const member: Member | undefined = memberEntities[memberId];
    if (member) {
      return {
        id: member.id,
        name: member.name,
        avatar: member.avatar ?? AVATAR.DEFAULT,
        order: member.order,
      };
    } else {
      return {
        id: appApis.createId(),
        name: "不明",
        avatar: AVATAR.DEFAULT,
        order: 9999999999,
      };
    }
  }
};

export const formatMembers = (
  memberIds: string[],
  memberEntities: Dictionary<Member>
) => {
  return memberIds.map((memberId) => formatMember(memberId, memberEntities));
};

export const formatItem = (
  itemId: string | null,
  items: { id: string; name: string }[]
) => {
  if (itemId === null) {
    return { id: appApis.createId(), name: TEXT.EMPTY };
  } else {
    const item = items.find(({ id }) => id === itemId);
    if (item) {
      return { id: item.id, name: item.name };
    } else {
      return { id: appApis.createId(), name: "不明" };
    }
  }
};

export const formatItems = (
  itemIds: string[],
  items: { id: string; name: string }[]
) => {
  return itemIds.map((itemId) => formatItem(itemId, items));
};

export const generateOrder = () => Math.floor(Date.now() / 1000);

export const getTimezone = () => {
  return dayjs.tz.guess() as Timezone;
};

export const getTimestampNow = () => {
  return _Timestamp.now();
};

export const convertDateToTimestamp = (date: Date) => {
  return _Timestamp.fromDate(date);
};

export const typeOfString = (text: any) => {
  return typeof text === "string";
};

export const getFileIcon = (contentType: string | null) => {
  if (contentType?.includes("image/")) return imageOutline;
  if (contentType?.includes("video/")) return playCircleOutline;
  if (contentType?.includes("pdf")) return documentTextOutline;
  return documentOutline;
};

export const calcByteToMegaByte = (byte: number) => {
  const megaByte = byte / 1048576;
  const rounding = Math.round(megaByte * 10) / 10;
  return rounding === 0 ? "0.1MB" : rounding + "MB";
};

export const getColor = (color?: Color | null) => {
  // A100, A200, 800
  if (color === "red") {
    return {
      name: "レッド",
      light: "#FF8A80",
      medium: "#FF5252",
      dark: "#C62828",
    };
  }
  if (color === "pink") {
    return {
      name: "ピンク",
      light: "#FF80AB",
      medium: "#FF4081",
      dark: "#AD1457",
    };
  }
  if (color === "purple") {
    return {
      name: "パープル",
      light: "#EA80FC",
      medium: "#E040FB",
      dark: "#6A1B9A",
    };
  }
  if (color === "deepPurple") {
    return {
      name: "ディープパープル",
      light: "#B388FF",
      medium: "#7C4DFF",
      dark: "#4527A0",
    };
  }
  if (color === "indigo") {
    return {
      name: "インディゴ",
      light: "#8C9EFF",
      medium: "#6200EA",
      dark: "#283593",
    };
  }
  if (color === "blue") {
    return {
      name: "ブルー",
      light: "#82B1FF",
      medium: "#448AFF",
      dark: "#1565C0",
    };
  }
  if (color === "lightBlue") {
    return {
      name: "ライトブルー",
      light: "#80D8FF",
      medium: "#448AFF",
      dark: "#0277BD",
    };
  }
  if (color === "cyan") {
    return {
      name: "シアン",
      light: "#84FFFF",
      medium: "#18FFFF",
      dark: "#00838F",
    };
  }
  if (color === "teal") {
    return {
      name: "ティール",
      light: "#A7FFEB",
      medium: "#64FFDA",
      dark: "#00695C",
    };
  }
  if (color === "green") {
    return {
      name: "グリーン",
      light: "#B9F6CA",
      medium: "#69F0AE",
      dark: "#2E7D32",
    };
  }
  if (color === "lightGreen") {
    return {
      name: "ライトグリーン",
      light: "#CCFF90",
      medium: "#69F0AE",
      dark: "#558B2F",
    };
  }
  if (color === "lime") {
    return {
      name: "ライム",
      light: "#F4FF81",
      medium: "#B2FF59",
      dark: "#9E9D24",
    };
  }
  if (color === "yellow") {
    return {
      name: "イエロー",
      light: "#FFFF8D",
      medium: "#FFFF00",
      dark: "#AD8543", // Yellow 700 * Grey 700
    };
  }
  if (color === "amber") {
    return {
      name: "アンバー",
      light: "#FFE57F",
      medium: "#FFD740",
      dark: "#B07831", // Amber 800 * Grey 700
    };
  }
  if (color === "orange") {
    return {
      name: "オレンジ",
      light: "#FFD180",
      medium: "#FFAB40",
      dark: "#A86731", // Orange 800 * Grey 700
    };
  }
  if (color === "deepOrange") {
    return {
      name: "ディープオレンジ",
      light: "#FF9E80",
      medium: "#FF6E40",
      dark: "#D84315",
    };
  }
  if (color === "brown") {
    return {
      name: "ブラウン",
      light: "#D7CCC8",
      medium: "#A1887F",
      dark: "#5D4037", // 100,300,700
    };
  }
  if (color === "grey") {
    return {
      name: "グレイ",
      light: "#E0E0E0",
      medium: "#9E9E9E",
      dark: "#616161", // 300,500,700
    };
  }
  if (color === "blueGrey") {
    return {
      name: "ブルーグレイ",
      light: "#CFD8DC",
      medium: "#607D8B",
      dark: "#455A64", // 100,500,700
    };
  }
  return {
    name: TEXT.EMPTY,
    light: "",
    medium: "",
    dark: "",
  };
};

export const generateAllowMemberIds = (
  _public: boolean,
  _memberIds: string[],
  _teamIds: string[],
  members: Member[],
  teams: Team[]
) => {
  let allowMemberIds: string[] = [];
  let memberIds: string[] = [];
  let teamIds: string[] = [];
  if (_public === true) {
    allowMemberIds = members.map(({ id }) => id);
    memberIds = [];
    teamIds = [];
  } else {
    allowMemberIds = _memberIds;
    teams
      .filter(({ id }) => _teamIds.some((teamId) => teamId === id))
      .forEach((team) => {
        allowMemberIds = [...allowMemberIds, ...team.memberIds];
      });
    allowMemberIds = uniq(allowMemberIds);
    memberIds = _memberIds;
    teamIds = _teamIds;
  }
  return { allowMemberIds, memberIds, teamIds };
};

export const generateUser = (userId: string, timezone: Timezone) => {
  const user: User = {
    id: userId,
    language: "ja",
    version: VERSIONS.USER,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return user;
};

export const generateWorkspace = (workspaceId: string, userId: string) => {
  const workspace: Workspace = {
    id: workspaceId,
    name: "",
    timezone: "Asia/Tokyo",
    plan: "free",
    memberCount: 0,
    projectCount: 0,
    officeCount: 0,
    officeMemberCount: 0,
    officeMemberHistoryCount: 0,
    taskCount: 0,
    taskHistoryCount: 0,
    taskCommentCount: 0,
    taskStateCount: 0,
    taskMilestoneCount: 0,
    taskTagCount: 0,
    teamCount: 0,
    taskBookmarkCount: 0,
    storageFileSize: 0,
    storageFileCount: 0,
    version: VERSIONS.WORKSPACE,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return workspace;
};

export const generateProject = (
  workspaceId: string,
  projectId: string,
  userId: string,
  memberIds: string[]
) => {
  const project: Project = {
    id: projectId,
    workspaceId,
    name: "",
    public: true,
    memberIds: [],
    teamIds: [],
    allowMemberIds: memberIds,
    order: generateOrder(),
    version: VERSIONS.PROJECT,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return project;
};

export const generateTaskState = (
  workspaceId: string,
  projectId: string,
  userId: string
) => {
  const taskState: TaskState = {
    workspaceId,
    projectId,
    id: appApis.createId(),
    name: "",
    done: false,
    order: generateOrder(),
    version: VERSIONS.TASK_STATE,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return taskState;
};

export const generateTaskStates = (
  workspaceId: string,
  projectId: string,
  userId: string,
  taskStateIds: [string, string, string]
) => {
  const taskStates: TaskState[] = [
    {
      workspaceId,
      projectId,
      id: taskStateIds[0],
      name: "未対応",
      done: false,
      order: 0,
      version: VERSIONS.TASK_STATE,
      createdBy: userId,
      updatedBy: userId,
      createdAt: getTimestampNow(),
      updatedAt: getTimestampNow(),
    },
    {
      workspaceId,
      projectId,
      id: taskStateIds[1],
      name: "処理中",
      done: false,
      order: 1,
      version: VERSIONS.TASK_STATE,
      createdBy: userId,
      updatedBy: userId,
      createdAt: getTimestampNow(),
      updatedAt: getTimestampNow(),
    },
    {
      workspaceId,
      projectId,
      id: taskStateIds[2],
      name: "完了",
      done: true,
      order: 2,
      version: VERSIONS.TASK_STATE,
      createdBy: userId,
      updatedBy: userId,
      createdAt: getTimestampNow(),
      updatedAt: getTimestampNow(),
    },
  ];
  return taskStates;
};

export const generateTaskMilestone = (
  workspaceId: string,
  projectId: string,
  userId: string
) => {
  const taskMilestone: TaskMilestone = {
    workspaceId,
    projectId,
    id: appApis.createId(),
    name: "",
    date: null,
    order: generateOrder(),
    version: VERSIONS.TASK_MILESTONE,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return taskMilestone;
};

export const generateTaskTag = (
  workspaceId: string,
  projectId: string,
  userId: string
) => {
  const taskTag: TaskTag = {
    workspaceId,
    projectId,
    id: appApis.createId(),
    name: "",
    order: generateOrder(),
    version: VERSIONS.TASK_TAG,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return taskTag;
};

export const generateOffice = (
  workspaceId: string,
  officeId: string,
  userId: string
) => {
  const office: Office = {
    workspaceId,
    id: officeId,
    name: "",
    public: true,
    memberIds: [],
    teamIds: [],
    allowMemberIds: [],
    order: generateOrder(),
    col: 4,
    row: 4,
    memberStates: [
      {
        id: appApis.createId(),
        name: i18nText.initialize.office.memberState.working(),
        color: COLOR.BLUE,
      },
      {
        id: appApis.createId(),
        name: i18nText.initialize.office.memberState.busy(),
        color: COLOR.PINK,
      },
      {
        id: appApis.createId(),
        name: i18nText.initialize.office.memberState.beRightBack(),
        color: COLOR.BLUE_GREY,
      },
    ],
    layouts: {
      "0-0": { tile: null, items: ["KFJWCCFzYeSFCyTI1WV0"] },
      "0-3": { tile: null, items: ["KFJWCCFzYeSFCyTI1WV0"] },
      "1-1": {
        tile: null,
        items: [
          "u0BGFLewNkpJMBdik2nn",
          "QqVAn6rQX2J6SwBVnf8t",
          "OQpvEr3XJlQMM9TtPQKm",
        ],
      },
      "1-2": {
        tile: null,
        items: [
          "u0BGFLewNkpJMBdik2nn",
          "QqVAn6rQX2J6SwBVnf8t",
          "OQpvEr3XJlQMM9TtPQKm",
        ],
      },
      "2-1": {
        tile: null,
        items: [
          "mjdQ9N51XEPaJEFfYsCi",
          "t0D2vc9sx2lagDovn5FV",
          "oGTxXsW3lswpuUZElygt",
        ],
      },
      "2-2": {
        tile: null,
        items: [
          "mjdQ9N51XEPaJEFfYsCi",
          "t0D2vc9sx2lagDovn5FV",
          "oGTxXsW3lswpuUZElygt",
        ],
      },
      "3-0": { tile: null, items: ["KFJWCCFzYeSFCyTI1WV0"] },
      "3-3": { tile: null, items: ["KFJWCCFzYeSFCyTI1WV0"] },
    },
    version: VERSIONS.OFFICE,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return office;
};

export const generateOfficeMember = (workspaceId: string, memberId: string) => {
  const officeMember: OfficeMember = {
    workspaceId,
    officeId: null,
    id: memberId,
    stateId: null,
    taskIds: [],
    taskId: null,
    projectId: null,
    col: 0,
    row: 0,
    version: VERSIONS.OFFICE_MEMBER,
    createdBy: memberId,
    updatedBy: memberId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return officeMember;
};

export const generateTeam = (workspaceId: string, userId: string) => {
  const team: Team = {
    workspaceId,
    id: appApis.createId(),
    name: "",
    memberIds: [],
    order: generateOrder(),
    version: VERSIONS.TEAM,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return team;
};

export const generateTaskBookmark = (
  workspaceId: string,
  projectId: string,
  taskId: string,
  userId: string
) => {
  const taskBookmark: TaskBookmark = {
    workspaceId,
    id: appApis.createId(),
    projectId,
    taskId,
    memberId: userId,
    order: generateOrder(),
    version: VERSIONS.TASK_BOOKMARK,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return taskBookmark;
};

export const generateTask = (
  workspaceId: string,
  projectId: string,
  userId: string,
  mainTaskId: string | null,
  order: number
) => {
  const task: Task = {
    workspaceId,
    projectId,
    id: appApis.createId(),
    title: "",
    description: "",
    descriptionEditorState: null,
    assignedMemberId: null,
    unreadNotificationMemberId: null,
    collaborateMemberIds: [],
    priority: 0,
    stateId: null,
    done: false,
    milestoneId: null,
    tagIds: [],
    color: null,
    mainTaskId,
    startDate: [],
    limitDate: [],
    estimatedHours: null,
    tokenMap: {},
    order,
    version: VERSIONS.TASK,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return task;
};

export const generateTaskComment = (
  workspaceId: string,
  projectId: string,
  taskId: string,
  userId: string,
  comment: string,
  commentEditorState: SerializedEditorState | null,
  taskTitle: string,
  notifiedMemberIds: string[],
  unreadMemberIds: string[]
) => {
  const id = appApis.createId();
  const taskComment: TaskComment = {
    workspaceId,
    projectId,
    taskId,
    id,
    comment,
    commentEditorState,
    tokenMap: buildTokenMap(comment, id),
    taskTitle,
    notifiedMemberIds,
    unreadMemberIds,
    version: VERSIONS.TASK_COMMENT,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return taskComment;
};

export const generateDevice = (
  token: string,
  userId: string,
  platform: "ios" | "android"
) => {
  const device: Device = {
    id: token,
    userId,
    platform,
    version: VERSIONS.DEVICE,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return device;
};

export const generateInvitation = (
  workspaceId: string,
  workspaceName: string,
  userId: string,
  projectIds: string[],
  officeIds: string[],
  teamIds: string[],
  role: Role,
  expiryAt: Timestamp
) => {
  const invitation: Invitation = {
    id: appApis.createId(),
    workspaceId,
    workspaceName,
    projectIds,
    officeIds,
    teamIds,
    role,
    expiryAt,
    usedBy: null,
    version: VERSIONS.INVITATION,
    createdBy: userId,
    updatedBy: userId,
    createdAt: getTimestampNow(),
    updatedAt: getTimestampNow(),
  };
  return invitation;
};

const ngram = (n: number) => {
  return (value: string) => {
    const nGrams: string[] = [];
    let index = 0;
    index = value.length - n + 1;
    if (index < 1) return nGrams;
    while (index--) {
      nGrams[index] = value.slice(index, index + n);
    }
    return nGrams;
  };
};

const bigram = ngram(2);

const halfWiden = (text: string) =>
  text
    .replace(/[！-～]/g, (match) =>
      String.fromCharCode(match.charCodeAt(0) - 0xfee0)
    )
    .replace(/[“”]/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/[〜～]/g, "~")
    .replace(/[―─－]/g, "-");

const hira2kata = (text: string) =>
  text.replace(/[\u3041-\u3096]/g, (match) =>
    String.fromCharCode(match.charCodeAt(0) + 0x60)
  );

const kanaFullWiden = (text: string) => {
  const kanaMap = {
    ｶﾞ: "ガ",
    ｷﾞ: "ギ",
    ｸﾞ: "グ",
    ｹﾞ: "ゲ",
    ｺﾞ: "ゴ",
    ｻﾞ: "ザ",
    ｼﾞ: "ジ",
    ｽﾞ: "ズ",
    ｾﾞ: "ゼ",
    ｿﾞ: "ゾ",
    ﾀﾞ: "ダ",
    ﾁﾞ: "ヂ",
    ﾂﾞ: "ヅ",
    ﾃﾞ: "デ",
    ﾄﾞ: "ド",
    ﾊﾞ: "バ",
    ﾋﾞ: "ビ",
    ﾌﾞ: "ブ",
    ﾍﾞ: "ベ",
    ﾎﾞ: "ボ",
    ﾊﾟ: "パ",
    ﾋﾟ: "ピ",
    ﾌﾟ: "プ",
    ﾍﾟ: "ペ",
    ﾎﾟ: "ポ",
    ｳﾞ: "ヴ",
    ﾜﾞ: "ヷ",
    ｦﾞ: "ヺ",
    ｱ: "ア",
    ｲ: "イ",
    ｳ: "ウ",
    ｴ: "エ",
    ｵ: "オ",
    ｶ: "カ",
    ｷ: "キ",
    ｸ: "ク",
    ｹ: "ケ",
    ｺ: "コ",
    ｻ: "サ",
    ｼ: "シ",
    ｽ: "ス",
    ｾ: "セ",
    ｿ: "ソ",
    ﾀ: "タ",
    ﾁ: "チ",
    ﾂ: "ツ",
    ﾃ: "テ",
    ﾄ: "ト",
    ﾅ: "ナ",
    ﾆ: "ニ",
    ﾇ: "ヌ",
    ﾈ: "ネ",
    ﾉ: "ノ",
    ﾊ: "ハ",
    ﾋ: "ヒ",
    ﾌ: "フ",
    ﾍ: "ヘ",
    ﾎ: "ホ",
    ﾏ: "マ",
    ﾐ: "ミ",
    ﾑ: "ム",
    ﾒ: "メ",
    ﾓ: "モ",
    ﾔ: "ヤ",
    ﾕ: "ユ",
    ﾖ: "ヨ",
    ﾗ: "ラ",
    ﾘ: "リ",
    ﾙ: "ル",
    ﾚ: "レ",
    ﾛ: "ロ",
    ﾜ: "ワ",
    ｦ: "ヲ",
    ﾝ: "ン",
    ｧ: "ァ",
    ｨ: "ィ",
    ｩ: "ゥ",
    ｪ: "ェ",
    ｫ: "ォ",
    ｯ: "ッ",
    ｬ: "ャ",
    ｭ: "ュ",
    ｮ: "ョ",
    "｡": "。",
    "､": "、",
    ｰ: "ー",
    "｢": "「",
    "｣": "」",
    "･": "・",
  };
  const pattern = new RegExp(`(${Object.keys(kanaMap).join("|")})`, "g");
  return text
    .replace(pattern, (match) => kanaMap[match as keyof typeof kanaMap])
    .replace(/ﾞ/g, "゛")
    .replace(/ﾟ/g, "゜");
};

const chopChink = (text: string) =>
  text
    .replace(/[-/&!?@_,.:;"'~]/g, " ")
    .replace(/[−‐―／＆！？＿，．：；“”‘’〜～]/g, " ")
    .replace(/[♪、。]/g, " ")
    .replace(/[・×☆★*＊]/g, "")
    .replace(/\(([^)]*)\)/g, " $1")
    .replace(/（([^〕]*)）/g, " $1")
    .replace(/〔([^〕]*)〕/g, " $1")
    .replace(/「([^」]*)」/g, " $1")
    .replace(/『([^』]*)』/g, " $1")
    .replace(/【([^】]*)】/g, " $1")
    .replace(/\[([^\]]*)\]/g, " $1")
    .replace(/\s{2,}/g, " ")
    .trim();

export const tokenize = (...words: string[]) => {
  const resultArr: string[] = [];
  let preVal = "";
  halfWiden(hira2kata(kanaFullWiden(chopChink(words.join(" ")))))
    .toLowerCase()
    .split(" ")
    .forEach((val) => {
      if (val.match(/^[0-9a-z+]+$/)) {
        if (val.length > 3 && val.match(/[sS]$/)) {
          resultArr.push(val.substring(0, val.length - 1));
        } else {
          resultArr.push(val);
        }
        preVal = "";
      } else if (val.length === 1 && /^[亜-黑]+$/u.test(val)) {
        preVal = val;
      } else {
        bigram(`${preVal}${val}`).forEach((cut) => resultArr.push(cut));
        preVal = "";
      }
    });

  return uniq(resultArr);
};

export const buildTokenMap = (...words: string[]) => {
  const tokenMap: { [k: string]: true } = {};
  tokenize(...words).forEach((token) => {
    tokenMap[token] = true;
  });
  return tokenMap;
};

export const buildTaskTokenMap = (task: Task) => {
  const words: string[] = task.description.split(/\r\n|\n/) ?? [];
  words.push(task.title);
  words.push(task.id);
  const tokenMap: { [key: string]: true } = {};
  tokenize(...words).forEach((token) => {
    tokenMap[token] = true;
  });
  return tokenMap;
};

export const getRoot = () => {
  if (PLATFORM.IS_CAPACITOR) {
    return DOMAIN;
  } else {
    return window.location.origin;
  }
};

export const setEmailAndPassword = async (email: string, password: string) => {
  await Promise.all([
    Preferences.set({ key: "email", value: email }),
    Preferences.set({ key: "password", value: password }),
  ]);
};

export const getEmailAndPassword = async () => {
  const [email, password] = await Promise.all([
    Preferences.get({ key: "email" }).then(({ value }) => value),
    Preferences.get({ key: "password" }).then(({ value }) => value),
  ]);
  return { email, password };
};

export const removeEmailAndPassword = async () => {
  await Promise.all([
    Preferences.remove({ key: "email" }),
    Preferences.remove({ key: "password" }),
  ]);
};

export const calendarDates = (month: Dayjs) => {
  const dates: {
    YYYY_MM_DD: string;
    num: number;
    isOtherMonth: boolean;
  }[] = [];
  const start = month.startOf(DAYJS.MONTH).startOf(DAYJS.WEEK);
  const end = month.endOf(DAYJS.MONTH).endOf(DAYJS.WEEK);
  for (let i = 0; start.add(i, DAYJS.DAY).isBefore(end); i++) {
    const day: Dayjs = start.add(i, DAYJS.DAY);
    const YYYY_MM_DD = day.format(DAYJS.YYYY_MM_DD);
    const num = day.date();
    const isOtherMonth = !day.isSame(month, DAYJS.MONTH);
    dates.push({ YYYY_MM_DD, num, isOtherMonth });
  }
  return dates;
};
