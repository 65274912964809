import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { Auth } from "../models/Auth";

export const useAuth = () => {
  //states
  const [data, setData] = useState<Auth | null>(null);

  useEffect(() => {
    const subscribe = appApis.$readAuthState().subscribe({
      next: (user) => {
        console.log("useAuth");
        if (user) {
          setData({ id: user.uid, email: user.email });
        } else {
          setData(null);
        }
      },
      error: (error) => {
        setData(null);
      },
    });
    return () => subscribe.unsubscribe();
  }, []);

  return data;
};
