import { ComponentProps, FunctionComponent } from "react";
import { IonButton } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonButton> {}

const Button = styled(IonButton)`
  --border-radius: 18px;
  margin-top: 8px;
  --box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  .ios & {
    --border-radius: 22px;
  }
`;

const AppFabButton: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

export default AppFabButton;
