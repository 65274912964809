import { IonFooter } from "@ionic/react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { LINK } from "../../utilities/UtilStatic";

const Footer = styled(IonFooter)`
  background: var(--ion-color-navy);
`;

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 8px 0;
`;

const Link = styled.a`
  width: 140px;
  height: auto;
  margin: 0 4px;
  &:hover img {
    opacity: 0.8;
  }
`;

const Image = styled.img`
  vertical-align: top;
  border-radius: 6px;
`;

const AppFooterStoreLink: FunctionComponent = (props) => {
  return (
    <Footer>
      <Content>
        <Link href={LINK.APP_STORE} target="blank">
          <Image src="/assets/images/appstore.jpg" />
        </Link>
        <Link href={LINK.GOOGLE_PLAY} target="blank">
          <Image src="/assets/images/googleplay.jpg" />
        </Link>
      </Content>
    </Footer>
  );
};

export default AppFooterStoreLink;
