import { ComponentProps, FunctionComponent } from "react";
import { IonPopover } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonPopover> {}

const Popover = styled(IonPopover)``;

const AppPopover: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Popover {...props}>{children}</Popover>;
};

export default AppPopover;
