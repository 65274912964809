import { ComponentProps, FunctionComponent } from "react";
import { IonItem } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonItem> {}

const Item = styled(IonItem)<{ lines?: "none" | "full" | "inset" }>`
  --detail-icon-opacity: 1;
  --detail-icon-color: var(--app-color-grey400-rgba);
  --inner-padding-end: 8px;
  --inner-border-width: ${({ lines }) => {
    if (lines === "none") return "0px";
    if (lines === "full") return "0px";
    if (lines === "inset") return "0 0 1px";
    return "0 0 1px";
  }};
`;

const AppItem: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Item {...props}>{children}</Item>;
};

export default AppItem;
