import {
  useIonAlert,
  useIonModal,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";
import {
  addOutline,
  cubeOutline,
  layersOutline,
  swapVerticalOutline,
  peopleCircleOutline,
  businessOutline,
} from "ionicons/icons";
import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  AppContent,
  AppCard,
  AppItemGroup,
  AppMenuButton,
  AppItem,
  AppCardHeader,
  AppCardHeaderText,
  AppItemIcon,
  AppErrorCard,
  AppLoadingCard,
  AppReorderGroup,
  AppWriteProjectModal,
  AppWriteProjectModalProps,
  AppWriteOfficeModal,
  AppWriteOfficeModalProps,
  AppLabel,
  AppWriteTeamModal,
  AppWriteTeamModalProps,
  AppButton,
  AppButtons,
  AppHeader,
  AppIcon,
  AppPage,
  AppReorder,
  AppTitle,
  AppToolbar,
  AppInnerContent,
} from "../..";
import { useOffices, useProjects, useError, useLoading } from "../../../hooks";
import {
  selectWorkspace,
  selectWorkspacesError,
  selectWorkspacesLoading,
  setWorkspaces,
} from "../../../store/workspacesSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { appApis } from "../../../apis/Api";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  selectMembers,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import {
  selectTeams,
  selectTeamsError,
  selectTeamsLoading,
} from "../../../store/teamsSlice";
import { TabId } from "../../../models";
import { useDispatch } from "react-redux";

const TabMasterPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const dispatch = useDispatch();
  const members = useSelector(selectMembers);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const teams = useSelector(selectTeams);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  const workspace = useSelector(selectWorkspace(workspaceId));
  const workspacesLoading = useSelector(selectWorkspacesLoading);
  const workspacesError = useSelector(selectWorkspacesError);
  // hooks
  const projects = useProjects({ workspaceId });
  const offices = useOffices({ workspaceId });
  // loading
  const loading = useLoading(
    membersLoading,
    teamsLoading,
    workspacesLoading,
    projects.loading,
    offices.loading
  );
  // error
  const error = useError(
    membersError,
    teamsError,
    workspacesError,
    projects.error,
    offices.error
  );
  const [isSortMode, setIsSortMode] = useState<boolean>(false);
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showCreateProjectModal, hideCreateProjectModal] = useIonModal(
    AppWriteProjectModal,
    {
      project: null,
      saveProject: (projectForm) => {
        Promise.resolve()
          .then(() => appApis.writeProject(projectForm))
          .then(() => {
            showToast({
              message: i18nText.success.project.create(),
              duration: 2000,
            });
            hideCreateProjectModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideCreateProjectModal(),
    } as AppWriteProjectModalProps
  );

  const [showCreateOfficeModal, hideCreateOfficeModal] = useIonModal(
    AppWriteOfficeModal,
    {
      office: null,
      saveOffice: (office) => {
        Promise.resolve()
          .then(() => appApis.createOffice(office, members, teams))
          .then(() => {
            showToast({
              message: i18nText.success.office.create(),
              duration: 2000,
            });
            hideCreateOfficeModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideCreateOfficeModal(),
    } as AppWriteOfficeModalProps
  );

  const [showAppCreateTeamModal, hideAppCreateTeamModal] = useIonModal(
    AppWriteTeamModal,
    {
      team: null,
      saveTeam: (team) => {
        Promise.resolve()
          .then(() => appApis.createTeam(team))
          .then(() => {
            hideAppCreateTeamModal();
            showToast({
              message: i18nText.success.team.create(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideAppCreateTeamModal(),
    } as AppWriteTeamModalProps
  );

  // functions
  // functions / ワークススペースを最新に更新
  useIonViewWillEnter(() => {
    Promise.resolve()
      .then(() => appApis.getMyWorkspaces())
      .then((workspaces) => dispatch(setWorkspaces(workspaces)))
      .catch((error) => {});
  });

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppMenuButton />
          </AppButtons>
          <AppTitle>各種設定</AppTitle>
          <AppButtons slot="end">
            {isSortMode && (
              <AppButton color="primary" onClick={() => setIsSortMode(false)}>
                完了
              </AppButton>
            )}
            {!isSortMode && projects.data.length > 1 && (
              <AppButton color="primary" onClick={() => setIsSortMode(true)}>
                <AppIcon slot="icon-only" icon={swapVerticalOutline} />
              </AppButton>
            )}
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && (
            <>
              {workspace && (
                <AppCard marginBottom="12px">
                  <AppCardHeader marginBottom="8px">
                    <AppCardHeaderText>ワークスペース</AppCardHeaderText>
                  </AppCardHeader>
                  <AppItemGroup>
                    <AppItem
                      button={true}
                      detail={true}
                      routerLink={appLinks.workspaceDetails(workspaceId, tabId)}
                    >
                      <AppItemIcon icon={businessOutline} slot="start" />
                      <AppLabel>{workspace.name}</AppLabel>
                    </AppItem>
                  </AppItemGroup>
                </AppCard>
              )}
              {/* プロジェクト */}
              <AppCard marginBottom="12px">
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>プロジェクト</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={!isSortMode}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    appApis.reorderProjects(
                      workspaceId,
                      projects.data,
                      event.detail.from,
                      event.detail.to
                    );
                    event.detail.complete();
                  }}
                >
                  {projects.data.map((project) => (
                    <AppItem
                      key={project.id}
                      routerLink={appLinks.projectDetails(
                        workspaceId,
                        tabId,
                        project.id
                      )}
                      detail={!isSortMode}
                    >
                      <AppItemIcon icon={layersOutline} slot="start" />
                      <AppLabel>{project.name}</AppLabel>
                      <AppReorder slot="end" />
                    </AppItem>
                  ))}
                  <AppItem
                    lines="none"
                    button={true}
                    detail={false}
                    onClick={() => {
                      showCreateProjectModal({
                        presentingElement: routerRef,
                        canDismiss: true,
                      });
                    }}
                  >
                    <AppItemIcon icon={addOutline} slot="start" />
                    <AppLabel>プロジェクト作成</AppLabel>
                  </AppItem>
                </AppReorderGroup>
              </AppCard>
              {/* オフィス */}
              <AppCard marginBottom="12px">
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>オフィス</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={!isSortMode}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    appApis.reorderOffices(
                      workspaceId,
                      offices.data,
                      event.detail.from,
                      event.detail.to
                    );
                    event.detail.complete();
                  }}
                >
                  {offices.data.map((office) => (
                    <AppItem
                      key={office.id}
                      routerLink={appLinks.officeDetails(
                        workspaceId,
                        tabId,
                        office.id
                      )}
                      detail={!isSortMode}
                    >
                      <AppItemIcon icon={cubeOutline} slot="start" />
                      <AppLabel>{office.name}</AppLabel>
                      <AppReorder slot="end" />
                    </AppItem>
                  ))}
                  <AppItem
                    lines="none"
                    button={true}
                    detail={false}
                    onClick={() => {
                      showCreateOfficeModal({
                        presentingElement: routerRef,
                        canDismiss: true,
                      });
                    }}
                  >
                    <AppItemIcon icon={addOutline} slot="start" />
                    <AppLabel>オフィス作成</AppLabel>
                  </AppItem>
                </AppReorderGroup>
              </AppCard>
              {/* チーム */}
              <AppCard>
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>チーム</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={!isSortMode}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    Promise.resolve()
                      .then(() =>
                        appApis.reorderTeams(
                          workspaceId,
                          teams,
                          event.detail.from,
                          event.detail.to
                        )
                      )
                      .catch((error) => {});
                    event.detail.complete();
                  }}
                >
                  {teams.map((team) => (
                    <AppItem
                      key={team.id}
                      routerLink={appLinks.teamDetails(
                        workspaceId,
                        tabId,
                        team.id
                      )}
                      detail={!isSortMode}
                    >
                      <AppItemIcon icon={peopleCircleOutline} slot="start" />
                      <AppLabel>{team.name}</AppLabel>
                      <AppReorder slot="end" />
                    </AppItem>
                  ))}
                  <AppItem
                    lines="none"
                    button={true}
                    detail={false}
                    onClick={() => {
                      showAppCreateTeamModal({
                        presentingElement: routerRef,
                        canDismiss: true,
                      });
                    }}
                  >
                    <AppItemIcon icon={addOutline} slot="start" />
                    <AppLabel>チーム作成</AppLabel>
                  </AppItem>
                </AppReorderGroup>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default TabMasterPage;
