import { ComponentProps, FunctionComponent } from "react";
import { IonMenuButton } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonMenuButton> {}

const MenuButton = styled(IonMenuButton)`
  &::part(native) {
    color: var(--app-color-grey600);
  }
`;

const AppMenuButton: FunctionComponent<Props> = (props) => {
  return <MenuButton {...props} />;
};

export default AppMenuButton;
