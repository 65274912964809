import { FunctionComponent, useState } from "react";
import { useIonModal, useIonPopover } from "@ionic/react";
import { closeOutline, informationCircleOutline } from "ionicons/icons";
import { i18nText, i18nRoleToText } from "../../../utilities/UtilI18nText";
import { Role } from "../../../models/types/Role";
import {
  AppContent,
  AppCard,
  AppFooterButtons,
  AppActionButton,
  AppLead,
  AppSelectsPopover,
  AppSelectsPopoverProps,
  AppFormButton,
  AppLabel,
  AppAddComma,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppButton,
  AppButtons,
  AppTitle,
  AppToolbar,
  AppHeader,
  AppIcon,
  AppCardContent,
  AppPage,
  AppExplainMemberRolesModalProps,
  AppExplainMemberRolesModal,
} from "../..";
import { Project, Office, Workspace } from "../../../models";
import { i18nRoles } from "../../../utilities/UtilI18nText";
import AppInnerContent from "../../level1-atoms/AppInnerContent";
import { useSelector } from "react-redux";
import { selectTeams } from "../../../store/teamsSlice";
import { formatItems } from "../../../utilities/UtilFunction";
import { ROLE } from "../../../utilities/UtilStatic";

export interface AppCreateInvitationsModalProps {
  workspace: Workspace | null;
  projects: Project[];
  offices: Office[];
  createInvitations: (
    workspaceId: string,
    projectIds: string[],
    officeIds: string[],
    teamIds: string[],
    role: Role,
    dayToExpire: number,
    howMany: number
  ) => void;
  hide: () => void;
}

const AppCreateInvitationsModal: FunctionComponent<
  AppCreateInvitationsModalProps
> = ({ workspace, projects, offices, createInvitations, hide }) => {
  // store
  const teams = useSelector(selectTeams);
  // states
  const [projectIds, setProjectIds] = useState<string[]>([]);
  const [officeIds, setOfficeIds] = useState<string[]>([]);
  const [teamIds, setTeamIds] = useState<string[]>([]);
  const [role, setRole] = useState<Role>(ROLE.MEMBER);
  const [dayToExpire] = useState<number>(1);
  const [howMany, setHowMany] = useState<number>(1);
  // components プロジェクト選択
  const [showSelectProjectsPopover] = useIonPopover(AppSelectsPopover, {
    items: projects,
    selectedIds: projectIds,
    selectItems: (ids: string[]) => setProjectIds(ids),
  } as AppSelectsPopoverProps);
  // components オフィス選択
  const [showSelectOfficesPopover] = useIonPopover(AppSelectsPopover, {
    items: offices,
    selectedIds: officeIds,
    selectItems: (ids: string[]) => setOfficeIds(ids),
  } as AppSelectsPopoverProps);
  // components チーム選択
  const [showSelectTeamsPopover] = useIonPopover(AppSelectsPopover, {
    items: teams,
    selectedIds: teamIds,
    selectItems: (ids: string[]) => setTeamIds(ids),
  } as AppSelectsPopoverProps);
  // components 権限選択
  const [showSelectRolePopover, hideSelectRolePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: i18nRoles(),
      selectedId: role,
      isSetNull: false,
      selectItem: (id: Role) => {
        setRole(id);
        hideSelectRolePopover();
      },
    } as AppSelectPopoverProps
  );
  // components 招待数選択
  const [showSelectHowManyPopover, hideSelectHowManyPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: [...Array(100)].map((_, i) => ({
        id: i + 1,
        name: String(i + 1),
      })),
      selectedId: howMany,
      isSetNull: false,
      selectItem: (id: number) => {
        setHowMany(id);
        hideSelectHowManyPopover();
      },
    } as AppSelectPopoverProps
  );
  // components 権限説明
  const [showExplainMemberRolesModal, hideExplainMemberRolesModal] =
    useIonModal(AppExplainMemberRolesModal, {
      hide: () => hideExplainMemberRolesModal(),
    } as AppExplainMemberRolesModalProps);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>メンバー招待</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <AppLead marginBottom="16px">
            ワークスペースにメンバーを招待します。参加するプロジェクトや権限などを選択してください。
          </AppLead>
          <AppCard>
            <AppCardContent>
              <AppFormButton
                label="プロジェクト"
                helperText="参加するプロジェクトを選択してください"
                onClick={(e) => {
                  showSelectProjectsPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
                marginBottom="8px"
              >
                <AppLabel>
                  {formatItems(projectIds, projects).map(({ id, name }) => (
                    <AppAddComma key={id}>{name}</AppAddComma>
                  ))}
                </AppLabel>
              </AppFormButton>

              <AppFormButton
                label="チーム"
                helperText="参加するチームを選択してください"
                onClick={(e) => {
                  showSelectTeamsPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
                marginBottom="8px"
              >
                <AppLabel>
                  {formatItems(teamIds, teams).map(({ id, name }) => (
                    <AppAddComma key={id}>{name}</AppAddComma>
                  ))}
                </AppLabel>
              </AppFormButton>

              <AppFormButton
                label="オフィス"
                helperText="入室可能なオフィスを選択してください"
                onClick={(e) => {
                  showSelectOfficesPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
                marginBottom="8px"
              >
                <AppLabel>
                  {formatItems(officeIds, offices).map(({ id, name }) => (
                    <AppAddComma key={id}>{name}</AppAddComma>
                  ))}
                </AppLabel>
              </AppFormButton>

              <AppFormButton
                label="権限"
                helperText="権限を選択してください"
                onClick={(e) => {
                  showSelectRolePopover({
                    cssClass: ["app-popover-width200", "app-popover-height120"],
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
                marginBottom="8px"
                width="160px"
              >
                <AppLabel>{i18nRoleToText(role)}</AppLabel>
              </AppFormButton>

              <AppFormButton
                label="招待数"
                helperText="招待する人数を選択してください"
                onClick={(e) => {
                  showSelectHowManyPopover({
                    cssClass: ["app-popover-width160", "app-popover-height320"],
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
                width="100px"
              >
                <AppLabel>{howMany}</AppLabel>
              </AppFormButton>
            </AppCardContent>
          </AppCard>
          <AppButton
            color="medium"
            fill="clear"
            onClick={() => {
              showExplainMemberRolesModal({ canDismiss: true });
            }}
          >
            <AppIcon
              slot="start"
              icon={informationCircleOutline}
              style={{ fontSize: 18 }}
            />
            <AppLabel style={{ fontSize: 14 }}>
              管理者と一般の権限の違い
            </AppLabel>
          </AppButton>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>閉じる</AppActionButton>
        <AppActionButton
          action="ok"
          onClick={() => {
            if (!workspace) return;
            createInvitations(
              workspace.id,
              projectIds,
              officeIds,
              teamIds,
              role,
              dayToExpire,
              howMany
            );
          }}
        >
          {i18nText.buttons.createInvitation()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppCreateInvitationsModal;
