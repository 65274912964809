import { ComponentProps, FunctionComponent } from "react";
import { IonSpinner } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonSpinner> {}

const Spinner = styled(IonSpinner)``;

const AppSpinner: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Spinner {...props}>{children}</Spinner>;
};

export default AppSpinner;
