import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { IonIcon, useIonModal } from "@ionic/react";
import styled from "styled-components";
import { cloneDeep, isEqual, range } from "lodash";
import {
  AppCard,
  AppContent,
  AppFooterButtons,
  AppActionButton,
  AppIcon,
  AppCardContent,
  AppHeader,
  AppToolbar,
  AppTitle,
  AppButtons,
  AppButton,
  AppInnerContent,
  AppSegment,
  AppSegmentButton,
  AppLead,
} from "..";
import { Office, OfficeItemCategory } from "../../models";
import {
  ISOMETRIC_OFFICE,
  OFFICE_ITEMS,
  OFFICE_DEFAULT_ITEMS,
  OFFICE_TILES,
} from "../../utilities/UtilStatic";
import {
  addOutline,
  checkmarkOutline,
  closeOutline,
  removeOutline,
} from "ionicons/icons";
import { i18nOfficeSegments, i18nText } from "../../utilities/UtilI18nText";

interface OfficeFormProps {
  office: Office;
  onSet?: (office: Office) => void;
  routerRef?: HTMLElement;
  readonly?: boolean;
}

type Segment = "item" | "tile" | "items";

const IsometricScaleButtons = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  display: flex;
  flex-flow: column nowrap;
`;

const IsometricScrollView = styled.div`
  overflow: auto;
`;

const WrapIsometricContent = styled.div<{
  width: number;
  height: number;
  scale: number;
}>`
  width: ${({ width, scale }) => `${width * scale}px`};
  height: ${({ height, scale }) => `${height * scale}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
`;

const IsometricContent = styled.div<{
  width: number;
  height: number;
  scale: number;
}>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  transform: ${({ scale }) => `scale(${scale})`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Isometric = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: relative;
  transform-origin: center center;
  transform-style: preserve-3d;
  transform: rotateX(55deg) rotateZ(45deg);
  will-change: transform;
  box-shadow: 0px 0px 0 1px var(--app-color-grey200-rgba);
  * {
    transform-style: preserve-3d;
  }
`;

const IsometricTile = styled.div.attrs<{
  col: number;
  row: number;
  image: string | null;
}>(({ col, row, image }) => ({
  style: {
    left: `${ISOMETRIC_OFFICE.TILE_SIZE * col}px`,
    top: `${ISOMETRIC_OFFICE.TILE_SIZE * row}px`,
    backgroundImage: image ? `url(${image})` : `none`,
  },
}))<{
  col: number;
  row: number;
  image: string | null;
}>`
  width: 100px;
  height: 100px;
  position: absolute;
  background: var(--app-color-white);
  background-size: cover;
  position: absolute;
  box-shadow: 0px 0px 0 1px var(--app-color-grey200-rgba) inset;
  cursor: pointer;
  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--app-color-grey300-rgba);
    pointer-events: none;
  }
`;

const IsometricItem = styled.div.attrs<{
  col: number;
  row: number;
  image: string;
}>(({ col, row, image }) => ({
  style: {
    left: `${ISOMETRIC_OFFICE.TILE_SIZE * col}px`,
    top: `${ISOMETRIC_OFFICE.TILE_SIZE * row}px`,
    backgroundImage: image ? `url(${image})` : `none`,
    transform: `rotate(-45deg) scaleY(${ISOMETRIC_OFFICE.SCALE_Y}) translateZ(0.001px) scale(1)`,
  },
}))<{
  col: number;
  row: number;
  image: string | null;
}>`
  pointer-events: none;
  position: absolute;
  width: 212.1315px; // $boxSize = 150px * 1.41421（200pxはアイテムのサイズ）
  height: 212.1315px; // $boxSize = 150px * 1.41421（200pxはアイテムのサイズ）
  margin-left: -56.06575px; // -($boxSize - 100px)/2（100pxはタイルのサイズ）
  margin-top: -56.06575px; // -($boxSize - 100px)/2（100pxはタイルのサイズ）
  background-size: cover;
`;

const SelectTiles = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

const SelectTile = styled.div<{
  image: string | null;
}>`
  position: relative;
  background-image: ${({ image }) => `url(${image})`};
  width: 80px;
  height: 80px;
  background-size: cover;
  box-shadow: 0 0 0 1px var(--app-color-grey300-rgba) inset;
  border-radius: 8px;
  cursor: pointer;
`;

const SelectObjects = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

const SelectObject = styled.div<{
  images: string[];
}>`
  position: relative;
  background-image: ${({ images }) => {
    const url = images
      .slice()
      .reverse()
      .map((image) => `url(${ISOMETRIC_OFFICE.ITEMS_DIRECTORY}${image}.png)`)
      .join();
    return url;
  }};
  background-color: var(--app-color-grey50);
  width: 80px;
  height: 80px;
  background-size: 110px; // 160pxでピッタリ
  background-position: 50% 36%;
  box-shadow: 0 0 0 1px var(--app-color-grey300-rgba) inset;
  border-radius: 8px;
  cursor: pointer;
`;

const Selected = styled(IonIcon)`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  background: var(--ion-color-primary);
  color: var(--app-color-white);
  border-radius: 50%;
  padding: 4px;
`;

const SegmentLabel = styled.div`
  font-size: 16px;
  font-weight: normal;
`;

const AppDecorateOffice: FunctionComponent<OfficeFormProps> = ({
  office,
  onSet,
  routerRef,
  readonly,
}) => {
  // functions
  const isometricSize = useMemo(() => {
    const tileWidth = ISOMETRIC_OFFICE.TILE_SIZE * office.col;
    const tileHeight = ISOMETRIC_OFFICE.TILE_SIZE * office.row;
    return (
      tileHeight * Math.cos(Math.PI / 4) + tileWidth * Math.cos(Math.PI / 4)
    );
  }, [office]);

  const [selectedCell, setSelectedCell] = useState({ col: 0, row: 0 });
  const [selectedSegment, setSelectedSegment] = useState<Segment>("item");
  const [showSelectDecorationModal, hideSelectDecorationModal] = useIonModal(
    SelectDecorationModal,
    {
      cell: selectedCell,
      segment: selectedSegment,
      tile:
        office?.layouts[`${selectedCell.col}-${selectedCell.row}`]?.tile ??
        null,
      items:
        office?.layouts[`${selectedCell.col}-${selectedCell.row}`]?.items ?? [],
      onHide: () => hideSelectDecorationModal(),
      onSelect: (cell, segment, tile, items) => {
        setSelectedSegment(segment);
        const layouts = cloneDeep(office.layouts);
        const key = `${cell.col}-${cell.row}`;
        if (tile === null && items.length === 0) {
          delete layouts[key];
        } else {
          layouts[key] = { tile, items };
        }
        if (onSet) onSet({ ...office, layouts });
        hideSelectDecorationModal();
      },
    } as SelectDecorationModalProps
  );

  // 拡大縮小
  const [scale, setScale] = useState<number>(1);

  const contentRef = useRef<HTMLDivElement>(null);

  const { isometricWidth, isometricHeight } = useMemo(() => {
    if (!office) return { isometricWidth: 1, isometricHeight: 1 };
    const tileWidth = ISOMETRIC_OFFICE.TILE_SIZE * office.col;
    const tileHeight = ISOMETRIC_OFFICE.TILE_SIZE * office.row;
    const isometricWidth =
      tileHeight * Math.cos(Math.PI / 4) + tileWidth * Math.cos(Math.PI / 4);
    const isometricHeight = isometricWidth / ISOMETRIC_OFFICE.SCALE_Y;
    return { isometricWidth, isometricHeight };
  }, [office]);

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current?.clientWidth) {
        const contentWidth = contentRef.current.clientWidth;
        const scale = contentWidth / isometricWidth;
        if (scale < 1) setScale(scale);
      }
    }, 100);
  }, [contentRef.current?.clientWidth]);

  return (
    <AppCard
      style={{ position: "relative", background: "var(--app-color-grey50)" }}
      marginBottom="16px"
      ref={contentRef}
    >
      <IsometricScrollView>
        <WrapIsometricContent
          width={isometricSize}
          height={isometricHeight}
          scale={scale}
        >
          <IsometricContent
            width={isometricSize}
            height={isometricHeight}
            scale={scale}
          >
            <Isometric
              width={ISOMETRIC_OFFICE.TILE_SIZE * office.col}
              height={ISOMETRIC_OFFICE.TILE_SIZE * office.row}
            >
              {range(office.col).map((col) =>
                range(office.row).map((row) => (
                  <IsometricTile
                    key={`${col}-${row}`}
                    col={col}
                    row={row}
                    image={
                      office.layouts[`${col}-${row}`]?.tile
                        ? `${ISOMETRIC_OFFICE.TILES_DIRECTORY}${
                            office.layouts[`${col}-${row}`].tile
                          }.png`
                        : null
                    }
                    onClick={() => {
                      setSelectedCell({ col, row });
                      showSelectDecorationModal({
                        presentingElement: routerRef,
                        canDismiss: true,
                      });
                    }}
                    style={{ pointerEvents: readonly ? "none" : "auto" }}
                  />
                ))
              )}
              {Object.keys(office.layouts)
                .map((key) => {
                  const decoration = office.layouts[key];
                  const [col, row] = key.split("-").map((i) => Number(i));
                  const images = decoration.items.map(
                    (item) => `${ISOMETRIC_OFFICE.ITEMS_DIRECTORY}${item}.png`
                  );
                  return { col, row, images };
                })
                .sort((a, b) => a.row - b.row)
                .sort((a, b) => a.col - b.col)
                .map(({ col, row, images }) =>
                  images.map((image, index) => (
                    <IsometricItem
                      key={`${col}-${row}-${index}`}
                      {...{ col, row, image }}
                    />
                  ))
                )}
            </Isometric>
          </IsometricContent>
        </WrapIsometricContent>
      </IsometricScrollView>
      {!readonly && (
        <IsometricScaleButtons>
          <AppButton
            color="white"
            fill="clear"
            style={{ "--padding-start": "8px", "--padding-end": "8px" }}
            disabled={scale >= 1}
            onClick={(e) => setScale(scale + 0.1)}
          >
            <AppIcon
              slot="icon-only"
              icon={addOutline}
              style={{ color: "var(--app-color-grey600)" }}
            />
          </AppButton>
          <AppButton
            color="white"
            fill="clear"
            style={{ "--padding-start": "8px", "--padding-end": "8px" }}
            disabled={scale - 0.1 <= 0.1}
            onClick={(e) => setScale(scale - 0.1)}
          >
            <AppIcon
              slot="icon-only"
              icon={removeOutline}
              style={{ color: "var(--app-color-grey600)" }}
            />
          </AppButton>
        </IsometricScaleButtons>
      )}
    </AppCard>
  );
};

interface SelectDecorationModalProps {
  cell: { col: number; row: number };
  segment: Segment;
  tile: string | null;
  items: string[];
  onSelect: (
    cell: { col: number; row: number },
    segment: Segment,
    tile: string | null,
    item: string[]
  ) => void;
  onHide: () => void;
}

const SelectDecorationModal: FunctionComponent<SelectDecorationModalProps> = ({
  cell,
  segment,
  tile,
  items,
  onSelect,
  onHide,
}) => {
  const [selectedSegment, setSelectedSegment] = useState<Segment>(segment);
  const [category] = useState<OfficeItemCategory>("officeWork");
  const [selectedTile, setSelectedTile] = useState<string | null>(tile);
  const [selectedItems, setSelectedItems] = useState<string[]>(items);

  return (
    <>
      <AppHeader>
        <AppToolbar>
          <AppTitle>装飾</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => onHide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {/* セグメント */}
          <AppSegment
            value={selectedSegment}
            style={{ marginBottom: "16px" }}
            onIonChange={(e) => setSelectedSegment(e.detail.value as Segment)}
          >
            {i18nOfficeSegments().map(({ id, name }) => (
              <AppSegmentButton key={id} value={id}>
                <SegmentLabel>{name}</SegmentLabel>
              </AppSegmentButton>
            ))}
          </AppSegment>
          {/* segment item */}
          {selectedSegment === "item" && (
            <AppCard>
              <AppCardContent>
                <SelectObjects>
                  <SelectObject
                    images={[]}
                    onClick={() => {
                      setSelectedItems([]);
                      onSelect(cell, selectedSegment, selectedTile, []);
                    }}
                  >
                    {selectedItems.length === 0 && (
                      <Selected icon={checkmarkOutline} />
                    )}
                  </SelectObject>
                  {OFFICE_DEFAULT_ITEMS.filter(({ categories }) =>
                    categories.includes(category)
                  ).map((item, index) => (
                    <SelectObject
                      key={index}
                      images={item.ids}
                      onClick={() => {
                        const isChecked = isEqual(item.ids, selectedItems);
                        if (isChecked) {
                          setSelectedItems([]);
                          onSelect(cell, selectedSegment, selectedTile, []);
                        } else {
                          setSelectedItems(item.ids);
                          onSelect(
                            cell,
                            selectedSegment,
                            selectedTile,
                            item.ids
                          );
                        }
                      }}
                    >
                      {isEqual(item.ids, selectedItems) && (
                        <Selected icon={checkmarkOutline} />
                      )}
                    </SelectObject>
                  ))}
                </SelectObjects>
              </AppCardContent>
            </AppCard>
          )}
          {/* segment tile */}
          {selectedSegment === "tile" && (
            <AppCard>
              <AppCardContent>
                <SelectTiles>
                  <SelectTile
                    image={null}
                    onClick={() => {
                      setSelectedTile(null);
                      onSelect(cell, selectedSegment, null, selectedItems);
                    }}
                  >
                    {selectedTile === null && (
                      <Selected icon={checkmarkOutline} />
                    )}
                  </SelectTile>
                  {OFFICE_TILES.filter(({ categories }) =>
                    categories.includes(category)
                  ).map((item) => (
                    <SelectTile
                      key={item.id}
                      image={`${ISOMETRIC_OFFICE.TILES_DIRECTORY}${item.id}.png`}
                      onClick={() => {
                        const isChecked = item.id === selectedTile;
                        if (isChecked) {
                          setSelectedTile(item.id);
                          onSelect(cell, selectedSegment, null, selectedItems);
                        } else {
                          setSelectedTile(item.id);
                          onSelect(
                            cell,
                            selectedSegment,
                            item.id,
                            selectedItems
                          );
                        }
                      }}
                    >
                      {item.id === selectedTile && (
                        <Selected icon={checkmarkOutline} />
                      )}
                    </SelectTile>
                  ))}
                </SelectTiles>
              </AppCardContent>
            </AppCard>
          )}
          {/* segment items */}
          {selectedSegment === "items" && (
            <>
              <AppLead marginBottom="16px">
                アイテムを複数選択可能です。選択後はOKボタンを押してください。
              </AppLead>
              <AppCard>
                <AppCardContent>
                  <SelectObjects>
                    {OFFICE_ITEMS.filter(({ categories }) =>
                      categories.includes(category)
                    ).map((item, index) => (
                      <SelectObject
                        key={index}
                        images={item.ids}
                        onClick={() => {
                          if (selectedItems.includes(item.ids[0])) {
                            setSelectedItems(
                              selectedItems.filter((id) => id !== item.ids[0])
                            );
                          } else {
                            setSelectedItems([...selectedItems, ...item.ids]);
                          }
                        }}
                      >
                        {selectedItems.includes(item.ids[0]) && (
                          <Selected icon={checkmarkOutline} />
                        )}
                      </SelectObject>
                    ))}
                  </SelectObjects>
                </AppCardContent>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => onHide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        <AppActionButton
          action="clear"
          onClick={() => onSelect(cell, selectedSegment, null, [])}
        >
          {i18nText.buttons.clear()}
        </AppActionButton>
        {selectedSegment === "items" && (
          <AppActionButton
            action="ok"
            onClick={() =>
              onSelect(cell, selectedSegment, selectedTile, selectedItems)
            }
          >
            OK
          </AppActionButton>
        )}
      </AppFooterButtons>
    </>
  );
};

export default AppDecorateOffice;
