import { ComponentProps, FunctionComponent } from "react";
import { IonButtons } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonButtons> {}

const Buttons = styled(IonButtons)``;

const AppButtons: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Buttons {...props}>{children}</Buttons>;
};

export default AppButtons;
