import { FunctionComponent, useMemo, useState } from "react";
import { searchOutline } from "ionicons/icons";
import {
  AppIcon,
  AppItem,
  AppLabel,
  AppRadio,
  AppRadioGroup,
  AppInput,
} from "../..";

export interface AppSelectPopoverProps {
  items: { id: string | number | boolean; name: string }[];
  selectedId: string | number | boolean | null;
  filterKeywords?: string[];
  isSetNull?: boolean;
  selectItem: (id: string | number | boolean | null) => void;
}

const AppSelectPopover: FunctionComponent<AppSelectPopoverProps> = ({
  items,
  selectedId,
  filterKeywords = [],
  isSetNull,
  selectItem,
}) => {
  const [filterText, setFilterText] = useState<string>("");

  const filterItems = useMemo(() => {
    if (filterText === "") {
      return items;
    } else {
      return items.filter((item: any) => {
        for (const filterKeyword of filterKeywords) {
          const target = item[filterKeyword]
            ? String(item[filterKeyword]).toLowerCase()
            : "";
          if (target.includes(filterText.toLowerCase()) === true) {
            return true;
          }
        }
        return false;
      });
    }
  }, [items, filterText, filterKeywords]);

  return (
    <>
      {filterKeywords.length > 0 && (
        <AppItem
          button={false}
          detail={false}
          style={{ "--background": "var(--app-color-grey100)" }}
          lines="full"
        >
          <AppIcon slot="start" icon={searchOutline} />
          <AppInput
            value={filterText}
            placeholder="検索"
            clearInput={true}
            clearOnEdit={true}
            onIonChange={(e) => setFilterText(e.detail.value!)}
            name="search"
          />
        </AppItem>
      )}
      <AppRadioGroup value={selectedId ?? ""}>
        {filterText === "" && isSetNull && (
          <AppItem
            button={true}
            detail={false}
            onClick={() => selectItem(null)}
          >
            <AppRadio
              slot="start"
              value=""
              style={{ marginRight: "24px" }}
              mode="md"
            />
            <AppLabel>-</AppLabel>
          </AppItem>
        )}
        {filterItems.map(({ id, name }, index) => {
          return (
            <AppItem
              key={index}
              button={true}
              detail={false}
              onClick={() => selectItem(id)}
            >
              <AppRadio
                slot="start"
                value={id ?? ""}
                style={{ marginRight: "24px" }}
                mode="md"
              />
              <AppLabel>{name ?? "不明"}</AppLabel>
            </AppItem>
          );
        })}
      </AppRadioGroup>
    </>
  );
};

export default AppSelectPopover;
