import { FunctionComponent, ReactNode } from "react";
import { IonToolbar } from "@ionic/react";
import styled from "styled-components";
import { AppFooter } from "..";

const Footer = styled(IonToolbar)`
  --background: var(--app-color-white);
  .ios & {
    padding: 2px 0;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 4px 0;
  gap: 0 8px;
`;

const AppFooterButtons: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <AppFooter>
      <Footer>
        <Buttons>{children}</Buttons>
      </Footer>
    </AppFooter>
  );
};

export default AppFooterButtons;
