import { FunctionComponent } from "react";
import { AppCard } from "..";
import styled from "styled-components";
import { IonSpinner } from "@ionic/react";

const Content = styled.div`
  padding: 10px;
  color: var(--app-color-grey100);
  display: flex;
  justify-content: center;
`;

const Spinner = styled(IonSpinner)`
  --color: var(--app-color-grey400);
`;

const AppLoadingCard: FunctionComponent = () => {
  return (
    <AppCard>
      <Content>
        <Spinner name="dots" />
      </Content>
    </AppCard>
  );
};

export default AppLoadingCard;
