import { ComponentProps, forwardRef } from "react";
import { IonHeader } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonHeader> {}

const Header = styled(IonHeader)``;

const AppHeader = forwardRef<HTMLIonHeaderElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <Header {...props} ref={ref}>
        {children}
      </Header>
    );
  }
);

export default AppHeader;
