import i18next from "i18next";
import { Priority, Role } from "../models";
import { ERROR_CODE, ROLE } from "./UtilStatic";

export const i18nText = {
  pages: {
    signUp: {
      // description: () => i18next.t("pages.signUp.description02"),
      hasAccount: () => "既にアカウントをお持ちですか？",
    },
    signIn: {
      // description: () => i18next.t("pages.signIn.description"),
    },
  },
  features: {
    workspace: () => "ワークスペース",
    project: () => "プロジェクト",
    signin: () => "サインイン",
  },
  push: {
    assignedTask: () => "担当者になりました",
    // updatedAssignedTask: () => "担当のタスクが更新されました",
  },
  initialize: {
    workspace: {
      name: () => "マイワークスペース", // i18next.t("initialize.workspace.name")
    },
    member: {
      name: () => "メンバー", // i18next.t("initialize.workspace.name")
    },
    office: {
      memberState: {
        working: () => "仕事中",
        busy: () => "取り込み中",
        beRightBack: () => "離席中",
      },
    },
  },
  success: {
    user: {
      update: () => "ユーザーを更新しました",
    },
    auth: {
      signin: () => "サインインしました",
      signup: () => "サインアップしました",
    },
    workspace: {
      create: () => "ワークスペースを作成しました",
      update: () => "ワークスペースを更新しました",
      delete: () => "ワークスペースを削除しました",
      leave: () => "ワークスペースから抜けました",
    },
    project: {
      create: () => "プロジェクトを作成しました",
      update: () => "プロジェクトを更新しました",
      delete: () => "プロジェクトを削除しました",
    },
    task: {
      create: () => "タスクを作成しました",
      update: () => "タスクを更新しました",
      delete: () => "タスクを削除しました",
    },
    taskComment: {
      create: () => "コメントを作成しました",
      update: () => "コメントを更新しました",
      delete: () => "コメントを削除しました",
    },
    member: {
      create: () => "メンバーを作成しました",
      update: () => "メンバーを更新しました",
      delete: () => "メンバーを削除しました",
    },
    team: {
      create: () => "チームを作成しました",
      update: () => "チームを更新しました",
      delete: () => "チームを削除しました",
    },
    invitation: {
      create: () => "招待を作成しました",
      delete: () => "招待を削除しました",
    },
    file: {
      upload: () => "ファイルをアップロードしました",
      delete: () => "ファイルを削除しました",
    },
    taskBookmark: {
      create: () => "ブックマークに追加しました",
      delete: () => "ブックマークを削除しました",
    },
    office: {
      create: () => "オフィスを作成しました",
      update: () => "オフィスを更新しました",
      delete: () => "オフィスを削除しました",
      enter: () => "オフィスに入室しました",
      leave: () => "オフィスを退室しました",
    },
    officeMemberState: {
      create: () => "メンバーステータスを作成しました",
      update: () => "ステータスを更新しました",
      delete: () => "メンバーステータスを削除しました",
    },
  },
  buttons: {
    ok: () => "OK",
    edit: () => "編集",
    cancel: () => "キャンセル",
    close: () => "閉じる",
    clear: () => "クリア",
    signIn: () => "サインイン",
    delete: () => "削除",
    save: () => "保存",
    detail: () => "詳細",
    createUser: () => "登録",
    createInvitation: () => "招待を作成",
  },
  inputs: {
    workspaceName: {
      label: () => "ワークスペース名",
      placeholder: () => "組織名または個人名などを入力してください",
      helper: () => "ワークスペース名を入力してください",
    },
  },
  selects: {
    language: {
      label: () => "言語",
      helper: () => "言語を選択してください",
    },
    timezone: {
      label: () => "タイムゾーン",
      helper: () => "タイムゾーンを選択してください",
    },
  },
  sidemenu: {
    createWorkspace: () => "ワークスペース作成",
  },
};

export const i18nRoleToText = (role: Role) => {
  if (role === `admin`) return `管理者`;
  if (role === `member`) return `一般`;
  return "-";
};

export const i18nUnit = {
  price: () => "円",
  minutes: () => "分",
  hours: () => "時間",
};

export const i18nPrice = (currency: "jpy", amount: number) => {
  if (currency === "jpy") {
    return `${amount}円`;
  } else {
    return "";
  }
};

export const i18nPriorities: () => { id: Priority; name: string }[] = () => [
  { id: -1, name: "低" },
  { id: 0, name: "中" },
  { id: 1, name: "高" },
];

export const i18nPriority = {
  high: () => "高",
  low: () => "低",
  middle: () => "-",
};

export const i18nPriorityToText = (priority: number | undefined) => {
  if (priority === -1) return "低";
  if (priority === 1) return "高";
  return "-";
};

export const i18nRoles: () => { id: Role; name: string }[] = () => [
  { id: ROLE.ADMIN, name: "管理者" },
  { id: ROLE.MEMBER, name: "一般" },
];

export const i18nTaskOrders: () => {
  key: "order" | "createdAt" | "updatedAt" | "limitDate" | "priority";
  name: string;
}[] = () => [
  { key: "order", name: "基本" },
  { key: "createdAt", name: "登録日" },
  { key: "updatedAt", name: "更新日" },
  { key: "limitDate", name: "期限日" },
  { key: "priority", name: "優先度" },
];

export const i18nOfficeCategories: () => {
  id: string;
  name: string;
}[] = () => [
  { id: "officeWork", name: "オフィス" },
  { id: "meeting", name: "打ち合わせ" },
];

export const i18nOfficeSegments: () => {
  id: string;
  name: string;
}[] = () => [
  { id: "item", name: "アイテム" },
  { id: "tile", name: "タイル" },
  { id: "items", name: "複数設定" },
];

export const i18nError = {
  // firebase
  cancelled: () => "操作はキャンセルされました。",
  unknown: () => "エラーが発生しました。",
  invalidArgument: () => "無効な引数です。",
  deadlineExceeded: () => "期限切れです。",
  notFound: () => "ドキュメントが見つかりませんでした。",
  alreadyExists: () => "ドキュメントは作成済みです。",
  permissionDenied: () => "権限がありません。",
  resourceExhausted: () =>
    "一部のリソースが使い果たされました。システム全体の容量が不足している可能性があります。",
  failedPrecondition: () => "準備中です。",
  aborted: () =>
    "対象ドキュメントが操作中のため実行が中止されました（トランザクションエラー）。",
  outOfRange: () => "有効な範囲を超えています。",
  unimplemented: () => "未実装または有効化されていません。",
  internal: () => "内部エラーが発生しました。",
  unavailable: () => "サービスは現在利用できません。",
  dataLoss: () => "破損エラーが発生しました。",
  unauthenticated: () => "ユーザ情報が確認できません。",
  // firebase auth
  authWeakPassword: () => "弱いパスワードです。",
  authInvalidEmail: () => "無効なEメールです。",
  authWrongPassword: () => "パスワードが違います。",
  // original
  canNotDeleteMemberBecauseWorkspaceWillNotHaveAdmin: () =>
    "管理者が居なくなるのでメンバーを削除できません。",
  authWrongEmailOrPassword: () => "Eメールまたはパスワードが違います。",
  // other
  default: () => "エラーが発生しました。", //
  // cancelled: () => i18next.t("error.cancelled"), // 操作はキャンセルされました。
  // unknown: () => i18next.t("error.unknown"), // エラーが発生しました。
  // invalidArgument: () => i18next.t("error.invalid-argument"), // 無効な引数です。
  // deadlineExceeded: () => i18next.t("error.deadline-exceeded"), // 期限切れです。
  // notFound: () => i18next.t("error.not-found"), // ドキュメントが見つかりませんでした。
  // alreadyExists: () => i18next.t("error.already-exists"), // ドキュメントは作成済みです。
  // permissionDenied: () => i18next.t("error.permission-denied"), // 権限がありません。
  // resourceExhausted: () => i18next.t("error.resource-exhausted"), // 一部のリソースが使い果たされました。システム全体の容量が不足している可能性があります。
  // failedPrecondition: () => i18next.t("error.failed-precondition"), // 準備中です。
  // aborted: () => i18next.t("error.aborted"), // 対象ドキュメントが操作中のため実行が中止されました（トランザクションエラー）。
  // outOfRange: () => i18next.t("error.out-of-range"), // 有効な範囲を超えています。
  // unimplemented: () => i18next.t("error.unimplemented"), // 未実装または有効化されていません。
  // internal: () => i18next.t("error.internal"), // 内部エラーが発生しました。
  // unavailable: () => i18next.t("error.unavailable"), // サービスは現在利用できません。
  // dataLoss: () => i18next.t("error.data-loss"), // 破損エラーが発生しました。
  // unauthenticated: () => i18next.t("error.unauthenticated"), // ユーザ情報が確認できません。
  // // firebase auth
  // authWeakPassword: () => i18next.t("error.auth-weak-password"),
  // authInvalidEmail: () => i18next.t("error.auth-invalid-email"),
  // authWrongPassword: () => i18next.t("error.auth-wrong-password"),
  // // original
  // canNotDeleteMemberBecauseWorkspaceWillNotHaveAdmin: () =>
  //   "管理者が居なくなるのでメンバーを削除できません。",
  // // other
  // default: () => i18next.t("error.default"), // エラーが発生しました
};

export const i18nErrorToString = (error: any): string => {
  if (typeof error === "string") return error;
  // code
  const errorCode: string | undefined = error?.code;
  const errorMessage: string | undefined = error?.message;
  if (errorCode) {
    if (
      errorCode === ERROR_CODE.CANCELLED ||
      errorCode === ERROR_CODE.FUNCTIONS.CANCELLED
    ) {
      return i18nError.cancelled();
    }
    if (
      errorCode === ERROR_CODE.UNKNOWN ||
      errorCode === ERROR_CODE.FUNCTIONS.UNKNOWN
    ) {
      return i18nError.unknown();
    }
    if (
      errorCode === ERROR_CODE.INVALID_ARGUMENT ||
      errorCode === ERROR_CODE.FUNCTIONS.INVALID_ARGUMENT
    ) {
      return i18nError.invalidArgument();
    }
    if (
      errorCode === ERROR_CODE.DEADLINE_EXCEEDED ||
      errorCode === ERROR_CODE.FUNCTIONS.DEADLINE_EXCEEDED
    ) {
      return i18nError.deadlineExceeded();
    }
    if (
      errorCode === ERROR_CODE.NOT_FOUND ||
      errorCode === ERROR_CODE.FUNCTIONS.NOT_FOUND
    ) {
      return i18nError.notFound();
    }
    if (
      errorCode === ERROR_CODE.ALREADY_EXISTS ||
      errorCode === ERROR_CODE.FUNCTIONS.ALREADY_EXISTS
    ) {
      return i18nError.alreadyExists();
    }
    if (
      errorCode === ERROR_CODE.PERMISSION_DENIED ||
      errorCode === ERROR_CODE.FUNCTIONS.PERMISSION_DENIED
    ) {
      return i18nError.permissionDenied();
    }
    if (
      errorCode === ERROR_CODE.RESOURCE_EXHAUSTED ||
      errorCode === ERROR_CODE.FUNCTIONS.RESOURCE_EXHAUSTED
    ) {
      return i18nError.resourceExhausted();
    }
    if (
      errorCode === ERROR_CODE.FAILED_PRECONDITION ||
      errorCode === ERROR_CODE.FUNCTIONS.FAILED_PRECONDITION
    ) {
      return i18nError.failedPrecondition();
    }
    if (
      errorCode === ERROR_CODE.ABORTED ||
      errorCode === ERROR_CODE.FUNCTIONS.ABORTED
    ) {
      return i18nError.aborted();
    }
    if (
      errorCode === ERROR_CODE.OUT_OF_RANGE ||
      errorCode === ERROR_CODE.FUNCTIONS.OUT_OF_RANGE
    ) {
      return i18nError.outOfRange();
    }
    if (
      errorCode === ERROR_CODE.UNIMPLEMENTED ||
      errorCode === ERROR_CODE.FUNCTIONS.UNIMPLEMENTED
    ) {
      return i18nError.unimplemented();
    }
    if (
      errorCode === ERROR_CODE.INTERNAL ||
      errorCode === ERROR_CODE.FUNCTIONS.INTERNAL
    ) {
      return i18nError.internal();
    }
    if (
      errorCode === ERROR_CODE.UNAVAILABLE ||
      errorCode === ERROR_CODE.FUNCTIONS.UNAVAILABLE
    ) {
      return i18nError.unavailable();
    }
    if (
      errorCode === ERROR_CODE.DATA_LOSS ||
      errorCode === ERROR_CODE.FUNCTIONS.DATA_LOSS
    ) {
      return i18nError.dataLoss();
    }
    if (
      errorCode === ERROR_CODE.UNAUTHENTICATED ||
      errorCode === ERROR_CODE.FUNCTIONS.UNAUTHENTICATED
    ) {
      return i18nError.unauthenticated();
    }
    // auth
    if (errorCode === ERROR_CODE.AUTH.WEAK_PASSWORD) {
      return i18nError.authWeakPassword();
    }
    if (errorCode === ERROR_CODE.AUTH.INVALID_EMAIL) {
      return i18nError.authInvalidEmail();
    }
    if (errorCode === ERROR_CODE.AUTH.WRONG_PASSWORD) {
      return i18nError.authWrongPassword();
    }
    // auth
    if (errorCode === ERROR_CODE.AUTH.WEAK_PASSWORD) {
      return i18nError.authWeakPassword();
    }
    if (errorCode === ERROR_CODE.AUTH.INVALID_EMAIL) {
      return i18nError.authInvalidEmail();
    }
    if (errorCode === ERROR_CODE.AUTH.WRONG_PASSWORD) {
      return i18nError.authWrongPassword();
    }
    // default
    return i18nError.default();
  } else if (errorMessage) {
    return errorMessage;
  }
  return i18nError.unknown();
};

export const i18nShortWeekdays: () => string[] = () => [
  "日",
  "月",
  "火",
  "水",
  "木",
  "金",
  "土",
];
