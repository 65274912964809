import { ComponentProps, FunctionComponent } from "react";
import { IonSegment } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonSegment> {}

const Segment = styled(IonSegment)`
  --background: var(--app-color-grey50);
  border: 1px solid var(--app-color-grey300);
`;

const AppSegment: FunctionComponent<Props> = ({ children, ...props }) => {
  return (
    <Segment mode="ios" {...props}>
      {children}
    </Segment>
  );
};

export default AppSegment;
