import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { TaskFile } from "../models";

export const useTaskFiles = ({
  workspaceId,
  projectId,
  taskId,
  reload,
}: {
  workspaceId: string;
  projectId: string;
  taskId: string;
  reload: boolean;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<TaskFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    Promise.resolve()
      .then(() => {
        return appApis.getTaskFiles(workspaceId, projectId, taskId);
      })
      .then((data) => {
        setData(data);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(i18nErrorToString(error));
      });
  }, [auth, reload, workspaceId, projectId, taskId]);

  return { data, loading, error };
};
