import { FunctionComponent } from "react";
import { HrefProps, IonBackButton } from "@ionic/react";
import { JSX } from "@ionic/core";
import { IonicReactProps } from "@ionic/react/dist/types/components/IonicReactProps";
import styled from "styled-components";

interface Props
  extends HrefProps<Omit<JSX.IonBackButton, "icon"> & IonicReactProps> {}

const BackButton = styled(IonBackButton)`
  &::part(native) {
    color: var(--app-color-grey600);
  }
`;

const AppHeaderBackButton: FunctionComponent<Props> = (props) => {
  return <BackButton {...props} text="" />;
};

export default AppHeaderBackButton;
