import {
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import {
  keyOutline,
  languageOutline,
  mailOutline,
  personOutline,
  personRemoveOutline,
} from "ionicons/icons";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppButton,
  AppButtons,
  AppCard,
  AppContent,
  AppErrorCard,
  AppHeader,
  AppHeaderBackButton,
  AppInnerContent,
  AppItem,
  AppItemGroup,
  AppItemIcon,
  AppLabel,
  AppLoadingCard,
  AppPage,
  AppSignInModal,
  AppSignInModalProps,
  AppTitle,
  AppToolbar,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useError, useLoading } from "../../../hooks";
import { selectAuth } from "../../../store/authSlice";
import {
  selectUser,
  selectUserError,
  selectUserLoading,
} from "../../../store/userSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nError, i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  selectWorkspace,
  selectWorkspaces,
} from "../../../store/workspacesSlice";
import { ERROR_CODE, TEXT } from "../../../utilities/UtilStatic";
import { i18nText } from "../../../utilities/UtilI18nText";
import { TabId } from "../../../models";
import { selectWorkspaceAdminsIgnoredMe } from "../../../store/membersSlice";

const ItemLabel = styled(AppLabel)`
  max-width: 112px;
  margin-right: 8px;
`;

const AccountPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();

  // store
  const auth = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const userLoading = useSelector(selectUserLoading);
  const userError = useSelector(selectUserError);
  const workspace = useSelector(selectWorkspace(workspaceId));
  const workspaces = useSelector(selectWorkspaces);
  const workspaceAdminsIgnoredMe = useSelector(selectWorkspaceAdminsIgnoredMe);
  const loading = useLoading(userLoading);
  const error = useError(userError);
  // components
  const [showLoading, hideLoading] = useIonLoading();
  const [showAlert] = useIonAlert();
  const [showAlert2] = useIonAlert();
  const [showToast] = useIonToast();
  const [emailModalPresent, emailModalDismiss] = useIonModal(AppSignInModal, {
    email: auth ? auth.email : undefined,
    signIn: (email, password) => {
      Promise.resolve()
        .then(() => appApis.signIn(email, password))
        .then(() => {
          history.push(appLinks.emailUpdate(workspaceId, tabId));
          emailModalDismiss();
        })
        .catch((error) => {
          showAlert({
            message: i18nErrorToString(error),
          });
        });
    },
    hide: () => emailModalDismiss(),
  } as AppSignInModalProps);
  const [passwordModalPresent, passwordModalDismiss] = useIonModal(
    AppSignInModal,
    {
      email: auth ? auth.email : undefined,
      signIn: (email, password) => {
        Promise.resolve()
          .then(() => appApis.signIn(email, password))
          .then(() => {
            history.push(appLinks.passwordUpdate(workspaceId, tabId));
            passwordModalDismiss();
          })
          .catch((error) => {
            showAlert({
              message: i18nErrorToString(error),
            });
          });
      },
      hide: () => passwordModalDismiss(),
    } as AppSignInModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>アカウント</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && (
            <>
              {user && (
                <AppCard marginBottom="16px">
                  <AppItemGroup>
                    <AppItem>
                      <AppItemIcon icon={mailOutline} slot="start" />
                      <ItemLabel>Eメール</ItemLabel>
                      <AppLabel>{auth && auth.email}</AppLabel>
                      <AppButton
                        slot="end"
                        fill="clear"
                        size="default"
                        onClick={() => {
                          emailModalPresent({
                            presentingElement: routerRef,
                            canDismiss: true,
                          });
                        }}
                      >
                        変更
                      </AppButton>
                    </AppItem>
                    <AppItem>
                      <AppItemIcon icon={keyOutline} slot="start" />
                      <ItemLabel>パスワード</ItemLabel>
                      <AppLabel>{TEXT.INVISIBLE_PASSWORD}</AppLabel>
                      <AppButton
                        slot="end"
                        fill="clear"
                        size="default"
                        onClick={() => {
                          passwordModalPresent({
                            presentingElement: routerRef,
                            canDismiss: true,
                          });
                        }}
                      >
                        変更
                      </AppButton>
                    </AppItem>
                    <AppItem>
                      <AppItemIcon icon={languageOutline} slot="start" />
                      <ItemLabel>言語</ItemLabel>
                      <AppLabel>{user.language}</AppLabel>
                    </AppItem>
                  </AppItemGroup>
                </AppCard>
              )}
              <AppCard marginBottom="16px">
                <AppItemGroup>
                  {workspaces.length > 1 && (
                    <AppItem
                      button
                      onClick={() => {
                        if (!auth) return;
                        showAlert({
                          message: `ワークスペースから脱退しますか？${
                            workspaceAdminsIgnoredMe.length === 0
                              ? "管理者のいなくなったワークスペースは削除されます。"
                              : ""
                          }`,
                          buttons: [
                            "キャンセル",
                            {
                              text: "Ok",
                              handler: () => {
                                showLoading({
                                  cssClass: "app-loading",
                                  backdropDismiss: true,
                                });
                                Promise.resolve()
                                  .then(() =>
                                    appApis.deleteMember(workspaceId, auth.id)
                                  )
                                  .then(() => {
                                    showToast({
                                      message:
                                        i18nText.success.workspace.leave(),
                                      duration: 2000,
                                    });
                                    appApis.signOut().then(() => {
                                      history.replace(appLinks.signIn());
                                    });
                                  })
                                  .catch((error) => {
                                    if (
                                      error?.code ===
                                      ERROR_CODE.FUNCTIONS.UNKNOWN
                                    ) {
                                      showAlert2({
                                        message:
                                          i18nError.canNotDeleteMemberBecauseWorkspaceWillNotHaveAdmin(),
                                        buttons: [{ text: "OK" }],
                                      });
                                    } else {
                                      showAlert2({
                                        message: i18nErrorToString(error),
                                        buttons: [{ text: "OK" }],
                                      });
                                    }
                                  })
                                  .finally(() => hideLoading());
                              },
                            },
                          ],
                        });
                      }}
                    >
                      <AppItemIcon icon={personOutline} slot="start" />
                      <AppLabel>{`${workspace?.name}から脱退`}</AppLabel>
                    </AppItem>
                  )}
                  <AppItem
                    button
                    onClick={() => {
                      showAlert({
                        header: "退会",
                        message: `Jectから退会しますか？アカウントと関連データは削除されます。${
                          workspaceAdminsIgnoredMe.length === 0
                            ? "管理者のいなくなったワークスペースは削除されます。"
                            : ""
                        }`,
                        buttons: [
                          "キャンセル",
                          {
                            text: "Ok",
                            handler: () => {
                              showLoading({
                                cssClass: "app-loading",
                                backdropDismiss: true,
                              });
                              Promise.resolve()
                                .then(() => appApis.deleteAccount())
                                .then(() => history.replace(appLinks.signIn()))
                                .catch((error) => {
                                  showAlert({
                                    message: i18nErrorToString(error),
                                  });
                                })
                                .finally(() => hideLoading());
                            },
                          },
                        ],
                      });
                    }}
                  >
                    <AppItemIcon icon={personRemoveOutline} slot="start" />
                    <AppLabel>Jectから退会</AppLabel>
                  </AppItem>
                </AppItemGroup>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default AccountPage;
