import { FunctionComponent, useMemo, useState } from "react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import { AppButton, AppIcon } from "../..";
import { i18nShortWeekdays } from "../../../utilities/UtilI18nText";
import { DAYJS } from "../../../utilities/UtilStatic";
import { calendarDates } from "../../../utilities/UtilFunction";

export interface AppSelectDatePopoverProps {
  selectedDate: string | null;
  selectDate: (date: string) => void;
  clearDate: () => void;
}

const Calendar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 320px;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

const Month = styled.div`
  font-size: 16px;
  margin-left: 10px;
  margin-right: auto;
`;

const Weekdays = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 4px 0;
  border-top: 1px solid var(--app-color-grey300);
  border-bottom: 1px solid var(--app-color-grey300);
`;

const Weekday = styled.div`
  flex: 1 0 0%;
  font-size: 12px;
  color: var(--app-color-grey600);
  text-align: center;
`;

const Dates = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 8px 0;
`;

const Date = styled.div`
  cursor: pointer;
  border-radius: 8px;
  :hover {
    background: var(--app-color-grey100);
  }
`;

const DateNum = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  &.today {
    color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
  }
  &.other-month {
    color: var(--app-color-grey400);
  }
  &.selected {
    color: var(--app-color-white);
    background: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const AppSelectDatePopover: FunctionComponent<AppSelectDatePopoverProps> = ({
  selectedDate,
  selectDate,
  clearDate,
}) => {
  const [month, setMonth] = useState<Dayjs>(dayjs(selectedDate ?? undefined));
  const dates = useMemo(() => calendarDates(month), [month]);
  const [today] = useState<string>(dayjs().format(DAYJS.YYYY_MM_DD));

  return (
    <Calendar>
      <Header>
        <Month>{month.format("YYYY年M月")}</Month>
        <AppButton
          fill="clear"
          size="small"
          color="grey"
          onClick={() => setMonth(month.add(-1, DAYJS.MONTH))}
        >
          <AppIcon slot="icon-only" icon={chevronBackOutline} />
        </AppButton>
        <AppButton
          fill="clear"
          size="small"
          color="grey"
          onClick={() => setMonth(month.add(1, DAYJS.MONTH))}
        >
          <AppIcon slot="icon-only" icon={chevronForwardOutline} />
        </AppButton>
      </Header>
      <Weekdays>
        {i18nShortWeekdays().map((weekday) => (
          <Weekday key={weekday}>{weekday}</Weekday>
        ))}
      </Weekdays>
      <Dates>
        {dates.map(({ YYYY_MM_DD, num, isOtherMonth }) => {
          return (
            <Date key={YYYY_MM_DD} onClick={() => selectDate(YYYY_MM_DD)}>
              <DateNum
                className={classNames({
                  today: YYYY_MM_DD === today,
                  selected: YYYY_MM_DD === selectedDate,
                  "other-month": isOtherMonth,
                })}
              >
                {num}
              </DateNum>
            </Date>
          );
        })}
      </Dates>
      <Footer>
        <AppButton fill="clear" size="small" onClick={() => clearDate()}>
          クリア
        </AppButton>
      </Footer>
    </Calendar>
  );
};

export default AppSelectDatePopover;
