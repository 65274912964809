import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

const Content = styled.div`
  margin-left: auto;
`;

const AppCardHeaderButtons: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return <Content>{children}</Content>;
};

export default AppCardHeaderButtons;
