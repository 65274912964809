import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./";
import { TaskBookmark } from "../models";

interface TaskBookmarksState extends EntityState<TaskBookmark> {
  loading: boolean;
  error: string | null;
  selectedId: string | null;
}

const adapter = createEntityAdapter<TaskBookmark>();

const initialState: TaskBookmarksState = adapter.getInitialState({
  loading: true,
  error: null,
  selectedId: null,
});

export const taskBookmarksSlice = createSlice({
  name: "taskBookmarks",
  initialState,
  reducers: {
    setTaskBookmarks: (state, action: PayloadAction<TaskBookmark[]>) => {
      adapter.setAll(state, action.payload);
    },
    setTaskBookmarksLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaskBookmarksError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setTaskBookmarksSelectedId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setTaskBookmarks,
  setTaskBookmarksLoading,
  setTaskBookmarksError,
  setTaskBookmarksSelectedId,
} = taskBookmarksSlice.actions;

export const selectTaskBookmarks = adapter.getSelectors(
  (state: RootState) => state.taskBookmarks
).selectAll;

export const selectTaskBookmarkEntities = adapter.getSelectors(
  (state: RootState) => state.taskBookmarks
).selectEntities;

export const selectTaskBookmarksLoading = (state: RootState) =>
  state.taskBookmarks.loading;

export const selectTaskBookmarksError = (state: RootState) =>
  state.taskBookmarks.error;

export const selectTaskBookmark = (taskId: string | null) => {
  return createSelector(selectTaskBookmarks, (taskBookmarks) => {
    const taskBookmark = taskBookmarks.find((item) => item.taskId === taskId);
    return taskBookmark ? taskBookmark : null;
  });
};

export default taskBookmarksSlice.reducer;
