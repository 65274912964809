import {
  IonAvatar,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { appApis } from "../../../apis/Api";
import {
  selectIsWorkspaceAdmin,
  selectMember,
  selectMyMember,
} from "../../../store/membersSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nRoleToText, i18nText } from "../../../utilities/UtilI18nText";
import { i18nError, i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppCard,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppItem,
  AppItemGroup,
  AppHeaderBackButton,
  AppButtons,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
  AppIcon,
  AppFormItem,
  AppAddComma,
  AppUpdateMemberModal,
  AppUpdateMemberModalProps,
} from "../..";
import {
  useLoading,
  useError,
  useMemberOffices,
  useMemberProjects,
} from "../../../hooks";
import styled from "styled-components";
import { selectAuth } from "../../../store/authSlice";
import {
  cubeOutline,
  layersOutline,
  peopleCircleOutline,
} from "ionicons/icons";
import { selectMyTeams } from "../../../store/teamsSlice";
import { AVATAR, ERROR_CODE, TEXT } from "../../../utilities/UtilStatic";
import { FirebaseError } from "firebase/app";
import { TabId } from "../../../models";

const MemberContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
`;

const Avatar = styled(IonAvatar)`
  width: 56px;
  height: 56px;
  flex: 0 1 auto;
  margin: 8px 12px 8px 0;
`;

const MemberInfo = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 4px 0;
`;

const MemberName = styled.div`
  font-size: 16px;
  line-height: 1.2;
`;

const MemberRole = styled.div`
  color: var(--app-color-grey600);
  font-size: 14px;
  line-height: 1.2;
`;

const MemberDetailsPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, memberId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    memberId: string;
  }>();
  // store
  const auth = useSelector(selectAuth);
  const member = useSelector(selectMember(memberId));
  const me = useSelector(selectMyMember);
  const memberTeams = useSelector(selectMyTeams(memberId));
  const isAdmin = useSelector(selectIsWorkspaceAdmin);
  const isMe = useMemo(() => me?.id === memberId, [me]);
  // loading
  const memberProjects = useMemberProjects({ workspaceId, memberId });
  const memberOffices = useMemberOffices({ workspaceId, memberId });
  // loading & error
  const loading = useLoading(memberOffices.loading, memberProjects.loading);
  const error = useError(memberOffices.error, memberProjects.error);
  // components
  const [showLoading, hideLoading] = useIonLoading();
  const [showAlert] = useIonAlert();
  const [showAlert2] = useIonAlert();
  const [showToast] = useIonToast();
  const [showUpdateMemberModal, hideUpdateMemberModal] = useIonModal(
    AppUpdateMemberModal,
    {
      member,
      saveMember: (form) => {
        if (!form || !auth) return;
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.resolve()
          .then(() => appApis.updateMember(form))
          .then(() => {
            showToast({
              message: i18nText.success.member.update(),
              duration: 2000,
            });
            hideUpdateMemberModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      deleteMember: (workspaceId, memberId) => {
        showAlert({
          message: "メンバ－を削除しますか？",
          buttons: [
            "キャンセル",
            {
              text: "Ok",
              handler: () => {
                showLoading({ cssClass: "app-loading", backdropDismiss: true });
                Promise.resolve()
                  .then(() => appApis.deleteMember(workspaceId, memberId))
                  .then(() => {
                    showToast({
                      message: i18nText.success.member.delete(),
                      duration: 2000,
                    });
                    hideUpdateMemberModal();
                    history.goBack();
                  })
                  .catch((error: FirebaseError) => {
                    if (error?.code === ERROR_CODE.FUNCTIONS.UNKNOWN) {
                      showAlert2({
                        message:
                          i18nError.canNotDeleteMemberBecauseWorkspaceWillNotHaveAdmin(),
                        buttons: [{ text: "OK" }],
                      });
                    } else {
                      showAlert2({
                        message: i18nErrorToString(error),
                        buttons: [{ text: "OK" }],
                      });
                    }
                  })
                  .finally(() => hideLoading());
              },
            },
          ],
        });
      },
      hide: () => hideUpdateMemberModal(),
    } as AppUpdateMemberModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>メンバー</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {member && (
            <AppCard marginBottom="12px">
              <AppItemGroup>
                <AppItem>
                  <MemberContent>
                    <Avatar>
                      <img src={member.avatar ?? AVATAR.DEFAULT} />
                    </Avatar>
                    <MemberInfo>
                      <MemberName>{member.name}</MemberName>
                      <MemberRole>{i18nRoleToText(member.role)}</MemberRole>
                    </MemberInfo>
                  </MemberContent>
                </AppItem>
                <AppItem>
                  <AppIcon
                    icon={peopleCircleOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="所属チーム">
                    {memberTeams.length === 0 && TEXT.EMPTY}
                    {memberTeams.map(({ id, name }) => (
                      <AppAddComma key={id}>{name}</AppAddComma>
                    ))}
                  </AppFormItem>
                </AppItem>
                {(isAdmin || isMe) && !loading && !error && (
                  <>
                    <AppItem>
                      <AppIcon
                        icon={layersOutline}
                        style={{ marginRight: "16px" }}
                        color="medium"
                        slot="start"
                      />
                      <AppFormItem label="所属プロジェクト">
                        {memberProjects.data.length === 0 && TEXT.EMPTY}
                        {memberProjects.data.map(({ id, name }) => (
                          <AppAddComma key={id}>{name}</AppAddComma>
                        ))}
                      </AppFormItem>
                    </AppItem>
                    <AppItem>
                      <AppIcon
                        icon={cubeOutline}
                        style={{ marginRight: "16px" }}
                        color="medium"
                        slot="start"
                      />
                      <AppFormItem label="所属オフィス">
                        {memberOffices.data.length === 0 && TEXT.EMPTY}
                        {memberOffices.data.map(({ id, name }) => (
                          <AppAddComma key={id}>{name}</AppAddComma>
                        ))}
                      </AppFormItem>
                    </AppItem>
                  </>
                )}
              </AppItemGroup>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.tab(workspaceId, tabId)} />
        {(isAdmin || isMe) && (
          <AppActionButton
            action="ok"
            onClick={() => {
              showUpdateMemberModal({
                presentingElement: routerRef,
                canDismiss: true,
              });
            }}
          >
            {i18nText.buttons.edit()}
          </AppActionButton>
        )}
      </AppFooterButtons>
    </AppPage>
  );
};

export default MemberDetailsPage;
