import { ComponentProps, FunctionComponent } from "react";
import { IonTextarea } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonTextarea> {
  label?: string;
  helperText?: string;
  optional?: boolean;
  marginBottom?: string;
}

const Content = styled.div`
  display: block;
`;

const Label = styled.div`
  margin-bottom: -11px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  z-index: 10;
  pointer-events: none;
`;

const LabelText = styled.div`
  display: inline-block;
  color: var(--app-color-grey600);
  font-size: 12px;
  background-color: white;
  padding: 0 6px;
  word-wrap: keep-all;
  white-space: nowrap;
`;

const Textarea = styled(IonTextarea)`
  border: 1px solid var(--app-color-grey400);
  border-radius: 4px;
  padding: 8px 0 4px;
  margin: 0;
  color: var(--app-color-grey900);
  font-size: 16px;
  --placeholder-color: var(--app-color-grey400);
  --placeholder-opacity: 1;
  --padding-start: 16px;
  --padding-end: 8px;
  --padding-top: 12px;
  --padding-bottom: 12px;
`;

const HelperText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  padding: 0 2px;
  color: var(--app-color-grey600);
`;

const AppFormTextarea: FunctionComponent<Props> = ({
  label,
  helperText,
  optional,
  marginBottom,
  ...props
}) => {
  return (
    <Content style={{ marginBottom }}>
      {label && (
        <Label>
          <LabelText>
            {label}
            {optional && "（オプション）"}
          </LabelText>
        </Label>
      )}
      <Textarea {...props} />
      {helperText && <HelperText>{helperText}</HelperText>}
    </Content>
  );
};

export default AppFormTextarea;
