import { ComponentProps, FunctionComponent } from "react";
import { IonAvatar } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonAvatar> {}

const Avatar = styled(IonAvatar)``;

const AppAvatar: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Avatar {...props}>{children}</Avatar>;
};

export default AppAvatar;
