import { IonAvatar } from "@ionic/react";
import { checkmarkOutline, personOutline, starOutline } from "ionicons/icons";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";
import {
  AppButtons,
  AppCard,
  AppContent,
  AppErrorCard,
  AppHeader,
  AppInnerContent,
  AppItem,
  AppItemGroup,
  AppItemIcon,
  AppLabel,
  AppLoadingCard,
  AppMenuButton,
  AppPage,
  AppTitle,
  AppToolbar,
} from "../..";
import { useError, useLoading } from "../../../hooks";
import { TabId } from "../../../models";
import { selectAuth } from "../../../store/authSlice";
import {
  selectMember,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { AVATAR } from "../../../utilities/UtilStatic";

const Avatar = styled(IonAvatar)`
  width: 28px;
  height: 28px;
  .ios & {
    margin-right: 16px;
  }
  .md & {
    margin-right: 12px;
  }
`;

const TabMypagePage: FunctionComponent = () => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const auth = useSelector(selectAuth);
  const member = useSelector(selectMember(auth ? auth.id : null));
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const loading = useLoading(membersLoading);
  const error = useError(membersError);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppMenuButton />
          </AppButtons>
          <AppTitle>マイページ</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && auth && (
            <>
              <AppCard marginBottom="16px">
                <AppItemGroup>
                  <AppItem
                    button={true}
                    detail={true}
                    routerLink={appLinks.myTaskBookmarks(workspaceId, tabId)}
                  >
                    <AppItemIcon icon={starOutline} />
                    <AppLabel>タスクブックマーク</AppLabel>
                  </AppItem>
                  <AppItem
                    button={true}
                    detail={true}
                    routerLink={appLinks.myTasks(workspaceId, tabId)}
                  >
                    <AppItemIcon icon={checkmarkOutline} />
                    <AppLabel>マイタスク</AppLabel>
                  </AppItem>
                  <AppItem
                    button={true}
                    detail={true}
                    routerLink={appLinks.memberDetails(
                      workspaceId,
                      tabId,
                      auth.id
                    )}
                  >
                    <Avatar slot="start">
                      <img src={member?.avatar ?? AVATAR.DEFAULT} />
                    </Avatar>
                    <AppLabel>プロフィール</AppLabel>
                  </AppItem>
                </AppItemGroup>
              </AppCard>
              <AppCard>
                <AppItemGroup>
                  <AppItem
                    button={true}
                    detail={true}
                    routerLink={appLinks.account(workspaceId, tabId)}
                  >
                    <AppItemIcon icon={personOutline} />
                    <AppLabel>アカウント</AppLabel>
                  </AppItem>
                </AppItemGroup>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default TabMypagePage;
