import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonRouterLink,
  IonItem,
  IonLabel,
  useIonAlert,
  useIonModal,
  useIonLoading,
  useIonToast,
  useIonPopover,
} from "@ionic/react";
import {
  addOutline,
  arrowDownOutline,
  arrowUpOutline,
  attachOutline,
  calendarClearOutline,
  calendarOutline,
  checkmarkCircle,
  checkmarkCircleOutline,
  checkmarkOutline,
  checkmarkSharp,
  hourglassOutline,
  peopleOutline,
  personOutline,
  pricetagOutline,
  star,
  starOutline,
  timeOutline,
} from "ionicons/icons";
import { Browser } from "@capacitor/browser";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppContent,
  AppCard,
  AppButton,
  AppFabButton,
  AppFabButtons,
  AppSegment,
  AppSegmentButton,
  AppWriteTasksModal,
  AppWriteTasksModalProps,
  AppTaskComment,
  AppTaskHistories,
  AppErrorCard,
  AppLoadingCard,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppAddComma,
  AppMemberPopover,
  AppMemberPopoverProps,
  AppHeaderBackButton,
  AppSelectNoticeMembersPopover,
  AppSelectNoticeMembersPopoverProps,
  AppPage,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppTitle,
  AppIcon,
  AppCardContent,
  AppFooter,
  AppTaskOfficeMemberHistories,
} from "../..";
import {
  useError,
  useLoading,
  useProject,
  useSubTasks,
  useTask,
  useTaskComments,
  useTaskFiles,
} from "../../../hooks";
import { selectAuth } from "../../../store/authSlice";
import { appLinks } from "../../../utilities/UtilPage";
import dayjs from "dayjs";
import {
  i18nText,
  i18nUnit,
  i18nPriorityToText,
  i18nError,
} from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  selectProjectTaskStates,
  selectTaskStateEntities,
} from "../../../store/taskStatesSlice";
import { selectTaskMilestoneEntities } from "../../../store/taskMilestonesSlice";
import { selectTaskTags } from "../../../store/taskTagsSlice";
import {
  selectIsWorkspaceAdmin,
  selectMemberEntities,
  selectProjectMembers,
} from "../../../store/membersSlice";
import { appApis } from "../../../apis/Api";
import {
  formatMember,
  formatMembers,
  formatItems,
  typeOfString,
} from "../../../utilities/UtilFunction";
import { Color, TabId } from "../../../models";
import {
  calcByteToMegaByte,
  getColor,
  getFileIcon,
  getTimestampNow,
} from "../../../utilities/UtilFunction";
import { selectTaskBookmark } from "../../../store/taskBookmarksSlice";
import AppInnerContent from "../../level1-atoms/AppInnerContent";

import { $getRoot, EditorState, SerializedEditorState } from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ACTION, LEXICAL_THEME, PLATFORM } from "../../../utilities/UtilStatic";
import {
  generateTaskBookmark,
  generateTaskComment,
} from "../../../utilities/UtilFunction";
import { FilePicker } from "@capawesome/capacitor-file-picker";

const TaskTitleContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const TaskTitle = styled.div`
  font-size: 20px;
  color: var(--app-color-grey900);
  flex: 1 0 0%;
`;

const ItemDoneButton = styled(IonButton)<{
  done: boolean;
  taskColor: Color | null;
}>`
  flex: 0 1 auto;
  width: 32px;
  height: 32px;
  margin: 8px 12px 8px 0;
  --box-shadow: none;
  --border-radius: 50%;
  &::part(native) {
    border: ${({ done }) =>
      done ? "none" : "1px solid var(--app-color-grey500-rgba)"};
    background: ${({ done }) =>
      done ? "var(--app-color-grey500-rgba)" : "none"};
    color: var(--app-color-grey500);
    padding: 0;
  }
`;

const ItemDoneIcon = styled(IonIcon)<{
  done: boolean;
  taskColor: Color | null;
}>`
  font-size: 20px;
  color: ${({ done, taskColor }) => {
    if (done && taskColor) {
      return getColor(taskColor).light;
    }
    if (done && !taskColor) {
      return "var(--app-color-white)";
    }
    return "var(--app-color-grey500-rgba)";
  }};
`;

const TaskStates = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const TaskState = styled.div`
  flex: 1 0 320px;
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid var(--app-color-grey300-rgba);
  padding: 8px 16px;
`;

const TaskStateTitle = styled.div`
  min-width: 128px;
  font-size: 14px;
  color: var(--app-color-grey600-rgba);
  line-height: 1.2;
  flex: 0 1 120px;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const TaskStateIcon = styled(IonIcon)`
  margin-right: 8px;
  width: 14px;
  height: 14px;
`;

const TaskStateText = styled.div`
  font-size: 14px;
  color: var(--app-color-grey600-rgba);
  line-height: 1.2;
  flex: 1 0 0%;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  display: flex;
  flex-flow: row nowrap;
`;

const TaskTopContentMemberAvator = styled(IonAvatar)`
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 4px;
`;

const MainTaskLink = styled(IonRouterLink)<{ taskColor: Color | null }>`
  color: ${({ taskColor }) => (taskColor ? getColor(taskColor).dark : "")};
`;

const SubTaskLink = styled(IonRouterLink)<{ taskColor: Color | null }>`
  margin-right: 8px;
  margin-bottom: 8px;
  color: ${({ taskColor }) => (taskColor ? getColor(taskColor).dark : "")};
`;

const SubTaskStateIcon = styled(IonIcon)`
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

const TaskFooter = styled.div`
  padding: 8px 0;
`;

const TaskFooterContent = styled.div`
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
`;

// files
const TaskFileItem = styled(IonItem)`
  --min-height: auto;
  &:last-child {
    --border-style: none;
  }
  &::part(native) {
    padding-left: 16px;
  }
`;

const TaskFileLabel = styled(IonLabel)`
  height: auto !important;
  white-space: normal !important;
  font-size: 14px !important;
  margin-top: 8px;
  margin-bottom: 8px;
  --color: var(--app-color-grey600) !important;
  display: flex !important;
  flex-flow: row wrap !important;
  gap: 0 4px;
`;

const TaskFileSize = styled.span`
  font-size: 12px;
  color: var(--app-color-grey500);
  margin-left: 4px;
  border: 1px solid var(--app-color-grey300);
  padding: 2px 6px;
  border-radius: 8px;
`;

const TaskFileIcon = styled(IonIcon)`
  font-size: 14px !important;
  margin-right: 4px;
  color: var(--app-color-grey500);
`;

const TaskFileDeleteButton = styled(IonButton)``;

const TaskFileMemberIcon = styled(IonAvatar)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 8px;
`;

const AddComment = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid var(--app-color-grey300);
  border-radius: 8px;
  margin: 0 8px;
`;

const AddCommentEditorWrap = styled.div`
  padding: 16px;
`;

const AddCommentFooter = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-top: 1px solid var(--app-color-grey300);
  padding-left: 4px;
`;

const AddCommentButtons = styled.div`
  margin-left: auto;
  padding: 8px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
`;

const AddCommentBadge = styled.div`
  background: var(--ion-color-primary);
  color: var(--app-color-white);
  font-size: 12px;
  border-radius: 14px;
  padding: 2px 4px;
  margin-left: 4px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const LexicalContainer = styled.div`
  position: relative;
  & p {
    margin: 0 0 2px;
  }
`;

const LexicalPlaceholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  color: var(--app-color-grey400);
`;

const TaskPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, projectId, taskId, taskCommentId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    projectId: string;
    taskId: string;
    taskCommentId?: string;
  }>();
  // store
  const auth = useSelector(selectAuth);
  const projectMembers = useSelector(selectProjectMembers(projectId));
  const memberEntities = useSelector(selectMemberEntities);
  const projectTaskStates = useSelector(selectProjectTaskStates(projectId));
  const taskStateEntities = useSelector(selectTaskStateEntities);
  const taskTags = useSelector(selectTaskTags);
  const taskMilestoneEntities = useSelector(selectTaskMilestoneEntities);
  const isWorkspaceAdmin = useSelector(selectIsWorkspaceAdmin);
  // states
  const [reload, setReload] = useState<boolean>(true);
  const reloadTaskFiles = () => setReload(!reload);
  // page data
  const project = useProject({ workspaceId, projectId });
  const task = useTask({ workspaceId, taskId });
  const taskBookmark = useSelector(selectTaskBookmark(task.data?.id ?? null));
  const mainTask = useTask({
    workspaceId: task.data?.workspaceId ?? null,
    taskId: task.data?.mainTaskId ?? null,
  });
  const subTasks = useSubTasks({ workspaceId, projectId, taskId });
  const taskFiles = useTaskFiles({
    workspaceId,
    projectId,
    taskId,
    reload,
  });
  const taskComments = useTaskComments({
    workspaceId: task.data ? task.data.workspaceId : null,
    projectId: task.data ? task.data.projectId : null,
    taskId: task.data ? task.data.id : null,
  });

  const [descriptionEditorState, setDescriptionEditorState] =
    useState<SerializedEditorState | null>(null);

  useEffect(() => {
    const descriptionEditorState = task.data?.descriptionEditorState ?? null;
    setDescriptionEditorState(descriptionEditorState);
  }, [task.data]);

  const [segment, setSegment] = useState<
    "comments" | "taskHistories" | "officeMemberHistories"
  >("comments");

  const uploadButton = useRef<HTMLInputElement>(null);
  const [showCommentForm, setShowCommentForm] = useState(false);

  const [comment, setComment] = useState<string>("");
  const [commentEditorState, setCommentEditorState] =
    useState<SerializedEditorState | null>(null);

  const [commentNotifiedMemberIds, setCommentNotifiedMemberIds] = useState<
    string[]
  >([]);
  const loading = useLoading(
    project.loading,
    task.loading,
    subTasks.loading,
    taskComments.loading,
    taskFiles.loading
    // mainTask.loading  mainTaskは存在しない場合があるから含めない
  );
  const error = useError(
    project.error,
    task.error,
    subTasks.error,
    taskComments.error,
    taskFiles.error
    // mainTask.error mainTaskは存在しない場合があるから含めない
  );

  // functions
  // 未読のタスクコメントを既読にアップデート
  useEffect(() => {
    const unreadTaskComments = taskComments.data
      .filter((taskComment) =>
        taskComment.notifiedMemberIds.some((id) => id === auth?.id)
      )
      .filter((taskComment) =>
        taskComment.unreadMemberIds.some((id) => id === auth?.id)
      );
    if (unreadTaskComments.length > 0) {
      Promise.all(
        unreadTaskComments.map((unreadTaskComment) =>
          appApis.updateTaskCommentAlreadyRead(
            unreadTaskComment.workspaceId,
            unreadTaskComment.id
          )
        )
      ).catch((error) => console.log(error));
    }
  }, [taskComments.data, auth]);

  // 未読のタスクを既読にアップデート
  useEffect(() => {
    if (!task.data || !auth) return;
    if (task.data.unreadNotificationMemberId === auth.id) {
      appApis
        .updateTaskAlreadyRead(task.data.workspaceId, task.data.id)
        .catch((error) => console.log(error));
    }
  }, [task.data, auth]);

  const fabCount = useMemo(() => {
    if (showCommentForm) return 0;
    if (segment === "taskHistories") return 0;
    if (segment === "officeMemberHistories") return 0;
    if (PLATFORM.IS_IOS) return 3;
    return 2;
  }, [showCommentForm, segment]);

  //components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const [showSelectStatePopover, hideSelectStatePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: projectTaskStates,
      selectedId: task.data?.stateId ?? null,
      isSetNull: true,
      selectItem: (stateId: string | null) => {
        hideSelectStatePopover();
        if (!task.data || !auth) return;
        if (stateId === task.data.stateId) return;
        appApis
          .updateTask({
            workspaceId: task.data.workspaceId,
            id: task.data.id,
            stateId,
            done: taskStateEntities[stateId ?? ""]?.done === true,
            updatedBy: auth.id,
            updatedAt: getTimestampNow(),
          })
          .catch((error) => {
            showAlert({
              message: i18nErrorToString(error),
            });
          });
      },
    } as AppSelectPopoverProps
  );

  // modal タスク更新
  const [showWriteTaskModal, hideWriteTaskModal] = useIonModal(
    AppWriteTasksModal,
    {
      projectId,
      taskId,
      deleteTasks: (task) => {
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.resolve()
          .then(() => appApis.deleteTask(task.workspaceId, task.id))
          .then(() => {
            history.goBack();
            hideWriteTaskModal();
            showToast({
              message: i18nText.success.task.delete(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      saveTasks: (taskForms) => {
        if (!auth) return;
        const requests = taskForms.map(({ action, ...task }) => {
          if (action === ACTION.CREATE) {
            return appApis.createTask(task);
          } else if (action === ACTION.UPDATE) {
            return appApis.updateTask(task);
          } else {
            return appApis.deleteTask(task.workspaceId, task.id);
          }
        });
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.all(requests)
          .then(() => {
            setDescriptionEditorState(null); // Lexicalの再レンダリングに必要
            hideWriteTaskModal();
            showToast({
              message: i18nText.success.task.update(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      hide: () => hideWriteTaskModal(),
    } as AppWriteTasksModalProps
  );
  // 通知メンバー選択
  const [showSelectNoticeMembersPopover] = useIonPopover(
    AppSelectNoticeMembersPopover,
    {
      auth,
      collaborateMemberIds: task.data ? task.data.collaborateMemberIds : [],
      items: projectMembers,
      selectedIds: commentNotifiedMemberIds,
      selectNoticeMembers: (memberIds: string[]) =>
        setCommentNotifiedMemberIds(memberIds),
      filterKeywords: ["name", "email"],
    } as AppSelectNoticeMembersPopoverProps
  );
  // メンバーpopover
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [showMemberPopover, hideMemberPopover] = useIonPopover(
    AppMemberPopover,
    {
      member: memberEntities[selectedMemberId ?? ""] ?? null,
      showMemberDetails: (link: string) => {
        history.push(link);
        hideMemberPopover();
      },
    } as AppMemberPopoverProps
  );

  return (
    <>
      <AppPage>
        <AppHeader>
          <AppToolbar>
            <AppButtons slot="start">
              <AppHeaderBackButton
                defaultHref={appLinks.tasks(workspaceId, tabId, projectId)}
              />
            </AppButtons>
            <AppTitle>{project.data?.name}</AppTitle>
            {!loading && !error && (
              <AppButtons slot="end">
                {taskBookmark && (
                  <AppButton
                    color="primary"
                    onClick={() => {
                      if (!auth) return;
                      Promise.resolve()
                        .then(() =>
                          appApis.deleteTaskBookmark(
                            workspaceId,
                            taskId,
                            auth.id
                          )
                        )
                        .then(() => {
                          showToast({
                            message: i18nText.success.taskBookmark.delete(),
                            duration: 1000,
                          });
                        })
                        .catch((error) => {
                          showAlert({ message: i18nErrorToString(error) });
                        });
                    }}
                  >
                    <AppIcon slot="icon-only" icon={star} />
                  </AppButton>
                )}
                {!taskBookmark && (
                  <AppButton
                    color="primary"
                    onClick={() => {
                      if (!auth) return;
                      const taskBookmark = generateTaskBookmark(
                        workspaceId,
                        projectId,
                        taskId,
                        auth.id
                      );
                      Promise.resolve()
                        .then(() => appApis.createTaskBookmark(taskBookmark))
                        .then(() => {
                          showToast({
                            message: i18nText.success.taskBookmark.create(),
                            duration: 1000,
                          });
                        })
                        .catch((error) => {
                          showAlert({ message: i18nErrorToString(error) });
                        });
                    }}
                  >
                    <IonIcon slot="icon-only" icon={starOutline} />
                  </AppButton>
                )}
                <IonButton
                  color="primary"
                  onClick={() =>
                    showWriteTaskModal({
                      presentingElement: routerRef,
                      canDismiss: true,
                    })
                  }
                >
                  <IonLabel>編集</IonLabel>
                </IonButton>
              </AppButtons>
            )}
          </AppToolbar>
        </AppHeader>
        <AppContent fabButtonCount={fabCount}>
          <AppInnerContent>
            {loading && <AppLoadingCard />}
            {error && <AppErrorCard>{error}</AppErrorCard>}
            {!loading && !error && task.data && (
              <>
                <AppCard
                  style={{
                    background: getColor(task.data.color).light,
                    borderColor: getColor(task.data.color).light,
                  }}
                  marginBottom="12px"
                >
                  <AppCardContent>
                    <TaskTitleContent>
                      <ItemDoneButton
                        done={task.data.done}
                        taskColor={task.data.color}
                        onClick={(e) => {
                          showSelectStatePopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height240",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                        slot="start"
                      >
                        <ItemDoneIcon
                          done={task.data.done}
                          taskColor={task.data.color}
                          icon={checkmarkSharp}
                          slot="icon-only"
                        />
                      </ItemDoneButton>
                      <TaskTitle>{task.data.title}</TaskTitle>
                    </TaskTitleContent>
                    {descriptionEditorState && (
                      <div style={{ marginTop: "8px" }}>
                        <LexicalComposer
                          initialConfig={{
                            namespace: "taskDescription",
                            editorState: JSON.stringify(
                              task.data.descriptionEditorState
                            ),
                            onError: (error) => console.log(error),
                            editable: false,
                          }}
                        >
                          <RichTextPlugin
                            contentEditable={<ContentEditable />}
                            placeholder=""
                          />
                        </LexicalComposer>
                      </div>
                    )}
                  </AppCardContent>
                  {/* タスクステータス */}
                  <TaskStates>
                    {/* 状態 */}
                    {task.data.stateId && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={checkmarkCircleOutline} />
                          状態
                        </TaskStateTitle>
                        <TaskStateText>
                          {task.data.stateId &&
                            (taskStateEntities[task.data.stateId]
                              ? taskStateEntities[task.data.stateId]?.name
                              : "不明")}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* 担当者 */}
                    {task.data.assignedMemberId && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={personOutline} />
                          担当者
                        </TaskStateTitle>
                        <TaskStateText
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setSelectedMemberId(
                              task.data?.assignedMemberId ?? null
                            );
                            showMemberPopover({
                              cssClass: "app-popover-width280",
                              event: {
                                ...e.nativeEvent,
                                target: e.currentTarget,
                              },
                            });
                          }}
                        >
                          <TaskTopContentMemberAvator>
                            <img
                              src={
                                formatMember(
                                  task.data.assignedMemberId,
                                  memberEntities
                                ).avatar
                              }
                            />
                          </TaskTopContentMemberAvator>
                          {
                            formatMember(
                              task.data.assignedMemberId,
                              memberEntities
                            ).name
                          }
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* 開始日 */}
                    {typeOfString(task.data.startDate) && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={calendarClearOutline} />
                          開始日
                        </TaskStateTitle>
                        <TaskStateText>
                          {dayjs(task.data.startDate as string).format(
                            "YYYY-MM-DD"
                          )}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* 期限日 */}
                    {typeOfString(task.data.limitDate) && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={calendarClearOutline} />
                          期限日
                        </TaskStateTitle>
                        <TaskStateText>
                          {dayjs(task.data.limitDate as string).format(
                            "YYYY-MM-DD"
                          )}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* 優先度 */}
                    {task.data.priority !== 0 && (
                      <TaskState>
                        <TaskStateTitle>
                          {task.data.priority === 1 && (
                            <TaskStateIcon icon={arrowUpOutline} />
                          )}
                          {task.data.priority === -1 && (
                            <TaskStateIcon icon={arrowDownOutline} />
                          )}
                          優先度
                        </TaskStateTitle>
                        <TaskStateText>
                          {i18nPriorityToText(task.data.priority)}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* マイルストーン */}
                    {task.data.milestoneId && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={calendarOutline} />
                          マイルストーン
                        </TaskStateTitle>
                        <TaskStateText>
                          {task.data.milestoneId &&
                            (taskMilestoneEntities[task.data.milestoneId]
                              ? taskMilestoneEntities[task.data.milestoneId]
                                  ?.name
                              : "不明")}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* タグ */}
                    {task.data.tagIds.length > 0 && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={pricetagOutline} />
                          タグ
                        </TaskStateTitle>
                        <TaskStateText>
                          {formatItems(task.data.tagIds, taskTags).map(
                            ({ id, name }) => (
                              <AppAddComma key={id}>{name}</AppAddComma>
                            )
                          )}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* 予定時間 */}
                    {task.data.estimatedHours && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={hourglassOutline} />
                          予定時間
                        </TaskStateTitle>
                        <TaskStateText>
                          {task.data.estimatedHours + i18nUnit.hours()}
                        </TaskStateText>
                      </TaskState>
                    )}
                    {/* メインタスク */}
                    {task.data.mainTaskId && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={checkmarkCircleOutline} />
                          メインタスク
                        </TaskStateTitle>
                        <TaskStateText>
                          {mainTask.data && (
                            <MainTaskLink
                              taskColor={task.data.color}
                              routerLink={appLinks.task(
                                mainTask.data.workspaceId,
                                tabId,
                                mainTask.data.projectId,
                                mainTask.data.id
                              )}
                            >
                              {mainTask.data.title}
                            </MainTaskLink>
                          )}
                        </TaskStateText>
                      </TaskState>
                    )}
                  </TaskStates>
                  <TaskStates>
                    {/* 関係者 */}
                    {task.data.collaborateMemberIds.length > 0 && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={peopleOutline} />
                          関係者
                        </TaskStateTitle>
                        <TaskStateText>
                          {formatMembers(
                            task.data.collaborateMemberIds,
                            memberEntities
                          ).map(({ id, name }) => (
                            <AppAddComma key={id}>{name}</AppAddComma>
                          ))}
                        </TaskStateText>
                      </TaskState>
                    )}
                  </TaskStates>
                  <TaskStates>
                    {/* サブタスク */}
                    {subTasks.data.length > 0 && (
                      <TaskState>
                        <TaskStateTitle>
                          <TaskStateIcon icon={checkmarkOutline} />
                          サブタスク
                        </TaskStateTitle>
                        <TaskStateText style={{ marginBottom: "-8px" }}>
                          {subTasks.data.map((item) => {
                            return (
                              <SubTaskLink
                                key={item.id}
                                taskColor={task.data ? task.data.color : null}
                                routerLink={appLinks.task(
                                  item.workspaceId,
                                  tabId,
                                  item.projectId,
                                  item.id
                                )}
                              >
                                <SubTaskStateIcon
                                  icon={
                                    item.done
                                      ? checkmarkCircle
                                      : checkmarkCircleOutline
                                  }
                                />
                                {item.title}
                              </SubTaskLink>
                            );
                          })}
                        </TaskStateText>
                      </TaskState>
                    )}
                  </TaskStates>
                  <TaskStates>
                    {/* 登録日 */}
                    <TaskState>
                      <TaskStateTitle>
                        <TaskStateIcon icon={timeOutline} />
                        登録日
                      </TaskStateTitle>
                      <TaskStateText>
                        {dayjs(task.data.createdAt.toDate()).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </TaskStateText>
                    </TaskState>
                    {/* 更新日 */}
                    <TaskState>
                      <TaskStateTitle>
                        <TaskStateIcon icon={timeOutline} />
                        更新日
                      </TaskStateTitle>
                      <TaskStateText>
                        {dayjs(task.data.updatedAt.toDate()).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </TaskStateText>
                    </TaskState>
                    {/* /タスクステータス */}
                  </TaskStates>
                </AppCard>
                {/* ファイル */}
                {taskFiles.data.length > 0 && (
                  <AppCard marginBottom="12px">
                    <TaskFileItem detail={false} lines="full">
                      <TaskFileIcon icon={attachOutline} slot="start" />
                      <TaskFileLabel>ファイル</TaskFileLabel>
                    </TaskFileItem>
                    {taskFiles.data.map(
                      ({ name, url, path, size, createdBy }, index) => (
                        <TaskFileItem
                          key={index}
                          button={true}
                          detail={false}
                          style={{ "--inner-padding-end": 0 }}
                          onClick={() => {
                            Promise.resolve()
                              .then(() => {
                                console.log(url);
                                return Browser.open({
                                  url,
                                  presentationStyle: "popover",
                                });
                              })
                              .catch((error) =>
                                showAlert({
                                  message: i18nErrorToString(error),
                                })
                              );
                          }}
                        >
                          <TaskFileMemberIcon>
                            <img
                              src={
                                formatMember(createdBy, memberEntities).avatar
                              }
                            />
                          </TaskFileMemberIcon>

                          <TaskFileLabel>
                            {name}
                            <TaskFileSize>
                              {calcByteToMegaByte(size)}
                            </TaskFileSize>
                          </TaskFileLabel>
                          <TaskFileDeleteButton
                            slot="end"
                            fill="clear"
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              showAlert({
                                message: "ファイルを削除しますか？",
                                buttons: [
                                  "キャンセル",
                                  {
                                    text: "Ok",
                                    handler: () => {
                                      showLoading({ backdropDismiss: true });
                                      Promise.resolve()
                                        .then(() =>
                                          appApis.deleteTaskFile(path)
                                        )
                                        .then(() => {
                                          reloadTaskFiles();
                                          hideLoading();
                                          showToast({
                                            message:
                                              i18nText.success.file.delete(),
                                            duration: 2000,
                                          });
                                        })
                                        .catch((error) => {
                                          hideLoading();
                                          showAlert({
                                            message: i18nErrorToString(error),
                                          });
                                        });
                                    },
                                  },
                                ],
                              });
                            }}
                          >
                            削除
                          </TaskFileDeleteButton>
                        </TaskFileItem>
                      )
                    )}
                  </AppCard>
                )}
                {/* セグメント */}
                <AppSegment
                  value={segment}
                  onIonChange={(e) =>
                    setSegment(e.detail.value! as "comments" | "taskHistories")
                  }
                  style={{ marginBottom: "12px" }}
                >
                  <AppSegmentButton value="comments">
                    <IonLabel>コメント</IonLabel>
                  </AppSegmentButton>
                  <AppSegmentButton value="taskHistories">
                    <IonLabel>変更履歴</IonLabel>
                  </AppSegmentButton>
                  {isWorkspaceAdmin && (
                    <AppSegmentButton value="officeMemberHistories">
                      <IonLabel>作業履歴</IonLabel>
                    </AppSegmentButton>
                  )}
                </AppSegment>
                {/* コメント */}
                {segment === "comments" &&
                  taskComments.data.map((taskComment) => (
                    <AppTaskComment
                      key={taskComment.id}
                      {...{
                        taskComment,
                        isFocus: taskComment.id === taskCommentId,
                        setRef: (ref) => {
                          if (taskComment.id === taskCommentId) {
                            ref.current?.scrollIntoView();
                          }
                        },
                      }}
                    />
                  ))}
                {/* 変更履歴 */}
                {segment === "taskHistories" && (
                  <AppTaskHistories
                    {...{
                      workspaceId,
                      projectId,
                      taskId,
                    }}
                  />
                )}
                {/* 作業履歴 */}
                {segment === "officeMemberHistories" && (
                  <AppTaskOfficeMemberHistories
                    {...{
                      workspaceId,
                      projectId,
                      taskId,
                    }}
                  />
                )}
              </>
            )}
          </AppInnerContent>
        </AppContent>
        {!showCommentForm && (
          <AppFabButtons vertical="bottom" horizontal="end">
            {segment === "comments" && (
              <>
                <AppFabButton
                  onClick={() => {
                    if (PLATFORM.IS_CAPACITOR) {
                      if (!auth) return;
                      showLoading({ backdropDismiss: true });
                      Promise.resolve()
                        .then(() => {
                          return FilePicker.pickFiles({
                            multiple: true,
                            readData: true,
                          });
                        })
                        .then(({ files }) => {
                          return Promise.all(
                            files.map((file) => {
                              return appApis.putTaskFileBase64(
                                workspaceId,
                                projectId,
                                taskId,
                                auth.id,
                                file.name,
                                file.data!,
                                file.mimeType
                              );
                            })
                          );
                        })
                        .then(() => {
                          reloadTaskFiles();
                          showToast({
                            message: i18nText.success.file.upload(),
                            duration: 2000,
                          });
                        })
                        .catch((error) => {})
                        .finally(() => hideLoading());
                    } else {
                      uploadButton.current?.click();
                    }
                  }}
                >
                  <IonIcon slot="start" icon={addOutline} />
                  ファイル追加
                </AppFabButton>
                {PLATFORM.IS_IOS && (
                  <AppFabButton
                    onClick={() => {
                      if (PLATFORM.IS_CAPACITOR) {
                        if (!auth) return;
                        showLoading({ backdropDismiss: true });
                        Promise.resolve()
                          .then(() => {
                            return FilePicker.pickMedia({
                              multiple: true,
                              readData: true,
                            });
                          })
                          .then(({ files }) => {
                            return Promise.all(
                              files.map((file) => {
                                return appApis.putTaskFileBase64(
                                  workspaceId,
                                  projectId,
                                  taskId,
                                  auth.id,
                                  file.name,
                                  file.data!,
                                  file.mimeType
                                );
                              })
                            );
                          })
                          .then(() => {
                            reloadTaskFiles();
                            showToast({
                              message: i18nText.success.file.upload(),
                              duration: 2000,
                            });
                          })
                          .catch((error) => {})
                          .finally(() => hideLoading());
                      } else {
                        uploadButton.current?.click();
                      }
                    }}
                  >
                    <IonIcon slot="start" icon={addOutline} />
                    イメージ追加
                  </AppFabButton>
                )}
                <AppFabButton onClick={() => setShowCommentForm(true)}>
                  <IonIcon slot="start" icon={addOutline} />
                  コメント追加
                </AppFabButton>
              </>
            )}
          </AppFabButtons>
        )}
        {showCommentForm && (
          <AppFooter>
            <TaskFooter>
              <TaskFooterContent>
                <AddComment>
                  <AddCommentEditorWrap>
                    <LexicalComposer
                      initialConfig={{
                        namespace: "taskComment",
                        theme: LEXICAL_THEME,
                        onError: (error) => console.log(error),
                        editorState: (editor) => {
                          setTimeout(() => editor.focus(), 100);
                        },
                      }}
                    >
                      <LexicalContainer>
                        <RichTextPlugin
                          contentEditable={
                            <ContentEditable
                              style={{
                                outline: 0,
                                minHeight: 60,
                                maxHeight: 280,
                                overflow: "auto",
                              }}
                            />
                          }
                          placeholder={
                            <LexicalPlaceholder>
                              コメントを入力
                            </LexicalPlaceholder>
                          }
                        />
                        <OnChangePlugin
                          onChange={(editorState: EditorState) => {
                            editorState.read(() => {
                              const root = $getRoot();
                              setComment(root.getTextContent());
                              setCommentEditorState(editorState.toJSON());
                            });
                          }}
                        />
                        <HistoryPlugin />
                      </LexicalContainer>
                    </LexicalComposer>
                  </AddCommentEditorWrap>
                  <AddCommentFooter>
                    <AddCommentButtons>
                      <AppButton
                        fill="outline"
                        style={{ margin: "0 auto 0 0" }}
                        onClick={(e) => {
                          showSelectNoticeMembersPopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                      >
                        通知
                        {commentNotifiedMemberIds.length > 0 && (
                          <AddCommentBadge>
                            {commentNotifiedMemberIds.length}
                          </AddCommentBadge>
                        )}
                      </AppButton>
                      <AppButton
                        fill="clear"
                        style={{ margin: 0 }}
                        onClick={() => setShowCommentForm(false)}
                      >
                        キャンセル
                      </AppButton>
                      <AppButton
                        style={{
                          margin: 0,
                          "--box-shadow": "none",
                        }}
                        onClick={() => {
                          if (!task.data || !auth) return;
                          const { workspaceId, projectId, id, title } =
                            task.data;
                          appApis
                            .createTaskComment(
                              generateTaskComment(
                                workspaceId,
                                projectId,
                                id,
                                auth.id,
                                comment,
                                commentEditorState,
                                title,
                                commentNotifiedMemberIds,
                                commentNotifiedMemberIds
                              )
                            )
                            .then(() => {
                              // formの初期化
                              setCommentNotifiedMemberIds([]);
                              setComment("");
                              setCommentEditorState(null);
                              setShowCommentForm(false);
                            })
                            .catch((error) => {
                              showAlert({
                                message: i18nErrorToString(error),
                              });
                            });
                        }}
                      >
                        保存
                      </AppButton>
                    </AddCommentButtons>
                  </AddCommentFooter>
                </AddComment>
              </TaskFooterContent>
            </TaskFooter>
          </AppFooter>
        )}
      </AppPage>
      <HiddenInput
        type="file"
        multiple={true}
        ref={uploadButton}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!auth) return;
          // file
          const targetFiles: FileList | null = e.target.files;
          if (!targetFiles) return;
          const files: File[] = [...Array(targetFiles.length)].map(
            (_, i) => targetFiles[i]
          );
          // アップロード
          showLoading({ backdropDismiss: true });
          Promise.all(
            files.map((file) => {
              return appApis.putTaskFile(
                workspaceId,
                projectId,
                taskId,
                auth.id,
                file.name,
                file
              );
            })
          )
            .then(() => {
              reloadTaskFiles();
              showToast({
                message: i18nText.success.file.upload(),
                duration: 2000,
              });
            })
            .catch((error) => {
              showAlert({ message: i18nErrorToString(error) });
            })
            .finally(() => hideLoading());
          e.target.value = ""; // この行によって同じファイルがセットされてもonChangeが発火する
        }}
      />
    </>
  );
};

export default TaskPage;
