import styled from "styled-components";
import dayjs from "dayjs";
import {
  arrowForwardSharp,
  arrowUpOutline,
  calendarClearOutline,
  calendarOutline,
  checkmarkCircleOutline,
  checkmarkOutline,
  hourglassOutline,
  peopleOutline,
  personOutline,
  pricetagOutline,
} from "ionicons/icons";
import { useSelector } from "react-redux";
import { selectMemberEntities, selectMembers } from "../../store/membersSlice";
import {
  selectTaskStateEntities,
  selectTaskStates,
} from "../../store/taskStatesSlice";
import {
  selectTaskTagEntities,
  selectTaskTags,
} from "../../store/taskTagsSlice";
import {
  selectTaskMilestoneEntities,
  selectTaskMilestones,
} from "../../store/taskMilestonesSlice";
import { FunctionComponent, useMemo, useState } from "react";
import { useTaskHistories, useTasks } from "../../hooks";
import {
  AppAddComma,
  AppErrorCard,
  AppLoadingCard,
  AppMemberPopover,
  AppMemberPopoverProps,
} from "..";
import { IonAvatar, IonIcon, useIonPopover } from "@ionic/react";
import { xor } from "lodash";
import AppCard from "../level1-atoms/AppCard";
import { i18nPriorityToText } from "../../utilities/UtilI18nText";
import {
  formatMember,
  formatMembers,
  formatItems,
  typeOfString,
} from "../../utilities/UtilFunction";
import { TEXT } from "../../utilities/UtilStatic";
import { useHistory } from "react-router";

interface Props {
  workspaceId: string | null;
  projectId: string | null;
  taskId: string | null;
}

const History = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 16px;
  border-bottom: 1px solid var(--app-color-grey300);
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 0 16px;
`;

const MemberContainer = styled.div`
  flex: 0 1 32px;
`;

const MainContainer = styled.div`
  flex: 1 0 0%;
`;

const Avatar = styled(IonAvatar)`
  flex: 0 1 auto;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const UpdateAt = styled.div`
  color: var(--app-color-grey500);
  font-size: 14px;
  margin: 8px 0 0;
  padding: 0 1px;
`;

const Item = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 16px;
`;

const TitleText = styled.div`
  font-size: 14px;
  color: var(--app-color-grey600);
`;

const StateIcon = styled(IonIcon)`
  font-size: 16px;
  margin-right: 8px;
  color: var(--app-color-grey600);
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const BeforeText = styled.div`
  font-size: 14px;
`;

const AfterText = styled.div`
  font-size: 14px;
`;

const ArrowIcon = styled(IonIcon)`
  color: var(--app-color-grey400);
  margin: 0 8px;
`;

const AppTaskHistories: FunctionComponent<Props> = ({
  workspaceId,
  projectId,
  taskId,
}) => {
  const history = useHistory();
  // store
  const memberEntities = useSelector(selectMemberEntities);
  const taskStateEntities = useSelector(selectTaskStateEntities);
  const taskTags = useSelector(selectTaskTags);
  const taskMilestoneEntities = useSelector(selectTaskMilestoneEntities);
  // states
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [showMemberPopover, hideMemberPopover] = useIonPopover(
    AppMemberPopover,
    {
      member: memberEntities[selectedMemberId ?? ""] ?? null,
      showMemberDetails: (link: string) => {
        history.push(link);
        hideMemberPopover();
      },
    } as AppMemberPopoverProps
  );
  // hook
  const taskHistories = useTaskHistories({
    workspaceId,
    projectId,
    taskId,
  });
  // functions
  const before = (index: number) => taskHistories.data[index - 1];
  const after = (index: number) => taskHistories.data[index];

  const mainTaskIds = useMemo(() => {
    const ids: string[] = [];
    taskHistories.data.forEach(
      (task) => task.mainTaskId && ids.push(task.mainTaskId)
    );
    return ids;
  }, [taskHistories.data]);

  const mainTasks = useTasks({
    workspaceId,
    taskIds: mainTaskIds,
  });

  const formatDate = (date: string | []) => {
    if (typeOfString(date)) {
      return dayjs(date as string).format("YYYY-MM-DD");
    } else {
      return TEXT.EMPTY;
    }
  };

  const isEqualTaskDate = (date01: string | [], date02: string | []) => {
    return String(date01) === String(date02);
  };

  return (
    <>
      {taskHistories.loading && <AppLoadingCard />}
      {taskHistories.error && (
        <AppErrorCard>{taskHistories.error}</AppErrorCard>
      )}
      {taskHistories.data.length > 0 && (
        <AppCard>
          {taskHistories.data.map((history, index) => (
            <div key={index} style={{ marginBottom: -1 }}>
              {
                <History>
                  <Container>
                    <MemberContainer>
                      <Avatar
                        onClick={(e) => {
                          if (!memberEntities[history.createdBy]) return;
                          setSelectedMemberId(history.createdBy);
                          showMemberPopover({
                            cssClass: "app-popover-width280",
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                      >
                        <img
                          src={
                            formatMember(history.createdBy, memberEntities)
                              .avatar
                          }
                        />
                      </Avatar>
                    </MemberContainer>

                    <MainContainer>
                      {index === 0 && (
                        <Item>
                          <Title>
                            <TitleText>タスクが作成されました</TitleText>
                          </Title>
                        </Item>
                      )}
                      {index !== 0 && (
                        <>
                          {/* 名前 */}
                          {before(index).title !== after(index).title && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={checkmarkCircleOutline}
                                  slot="start"
                                />
                                <TitleText>名前</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {before(index).title || "-"}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {after(index).title || "-"}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 説明 */}
                          {before(index).description !==
                            after(index).description && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={checkmarkCircleOutline}
                                  slot="start"
                                />
                                <TitleText>説明</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {before(index).description || "-"}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {after(index).description || "-"}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 状態 */}
                          {before(index).stateId !== after(index).stateId && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={checkmarkOutline}
                                  slot="start"
                                />
                                <TitleText>状態</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {(before(index).stateId != null &&
                                    taskStateEntities[before(index).stateId!]
                                      ?.name) ||
                                    "-"}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {(after(index).stateId != null &&
                                    taskStateEntities[after(index).stateId!]
                                      ?.name) ||
                                    "-"}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 担当者 */}
                          {before(index).assignedMemberId !==
                            after(index).assignedMemberId && (
                            <Item>
                              <Title>
                                <StateIcon icon={personOutline} slot="start" />
                                <TitleText>担当者</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {
                                    formatMember(
                                      before(index).assignedMemberId,
                                      memberEntities
                                    ).name
                                  }
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {
                                    formatMember(
                                      after(index).assignedMemberId,
                                      memberEntities
                                    ).name
                                  }
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 開始日 */}
                          {!isEqualTaskDate(
                            before(index).startDate,
                            after(index).startDate
                          ) && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={calendarClearOutline}
                                  slot="start"
                                />
                                <TitleText>開始日</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {formatDate(before(index).startDate)}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {formatDate(after(index).startDate)}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 期限日 */}
                          {!isEqualTaskDate(
                            before(index).limitDate,
                            after(index).limitDate
                          ) && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={calendarClearOutline}
                                  slot="start"
                                />
                                <TitleText>期限日</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {formatDate(before(index).limitDate)}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {formatDate(after(index).limitDate)}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 優先度 */}
                          {before(index).priority !== after(index).priority && (
                            <Item>
                              <Title>
                                <StateIcon icon={arrowUpOutline} slot="start" />
                                <TitleText>優先度</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {i18nPriorityToText(before(index).priority)}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {i18nPriorityToText(after(index).priority)}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* マイルストーン */}
                          {before(index).milestoneId !==
                            after(index).milestoneId && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={calendarOutline}
                                  slot="start"
                                />
                                <TitleText>マイルストーン</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {taskMilestoneEntities[
                                    before(index).milestoneId ?? ""
                                  ]?.name ?? "-"}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {taskMilestoneEntities[
                                    after(index).milestoneId ?? ""
                                  ]?.name ?? "-"}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* タグ */}
                          {xor(before(index).tagIds, after(index).tagIds)
                            .length > 0 && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={pricetagOutline}
                                  slot="start"
                                />
                                <TitleText>タグ</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {before(index).tagIds.length === 0 &&
                                    TEXT.EMPTY}
                                  {formatItems(
                                    before(index).tagIds,
                                    taskTags
                                  ).map(({ id, name }) => (
                                    <AppAddComma key={id}>{name}</AppAddComma>
                                  ))}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {after(index).tagIds.length === 0 &&
                                    TEXT.EMPTY}
                                  {formatItems(
                                    after(index).tagIds,
                                    taskTags
                                  ).map(({ id, name }) => (
                                    <AppAddComma key={id}>{name}</AppAddComma>
                                  ))}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 予定時間 */}
                          {before(index).estimatedHours !==
                            after(index).estimatedHours && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={hourglassOutline}
                                  slot="start"
                                />
                                <TitleText>予定時間</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {before(index).estimatedHours
                                    ? `${before(index).estimatedHours}H`
                                    : "-"}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {after(index).estimatedHours
                                    ? `${after(index).estimatedHours}H`
                                    : "-"}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* 関係者 */}
                          {xor(
                            before(index).collaborateMemberIds,
                            after(index).collaborateMemberIds
                          ).length > 0 && (
                            <Item>
                              <Title>
                                <StateIcon icon={peopleOutline} slot="start" />
                                <TitleText>関係者</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {before(index).collaborateMemberIds.length ===
                                    0 && TEXT.EMPTY}
                                  {formatMembers(
                                    before(index).collaborateMemberIds,
                                    memberEntities
                                  ).map(({ id, name }) => (
                                    <AppAddComma key={id}>{name}</AppAddComma>
                                  ))}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {after(index).collaborateMemberIds.length ===
                                    0 && TEXT.EMPTY}
                                  {formatMembers(
                                    after(index).collaborateMemberIds,
                                    memberEntities
                                  ).map(({ id, name }) => (
                                    <AppAddComma key={id}>{name}</AppAddComma>
                                  ))}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                          {/* メインタスク */}
                          {before(index).mainTaskId !==
                            after(index).mainTaskId && (
                            <Item>
                              <Title>
                                <StateIcon
                                  icon={checkmarkOutline}
                                  slot="start"
                                />
                                <TitleText>メインタスク</TitleText>
                              </Title>
                              <Content>
                                <BeforeText>
                                  {mainTasks.data.find(
                                    ({ id }) => id === before(index).mainTaskId
                                  )?.title ?? "-"}
                                </BeforeText>
                                <ArrowIcon icon={arrowForwardSharp} />
                                <AfterText>
                                  {mainTasks.data.find(
                                    ({ id }) => id === after(index).mainTaskId
                                  )?.title ?? "-"}
                                </AfterText>
                              </Content>
                            </Item>
                          )}
                        </>
                      )}
                      {/* 更新時間 */}
                      <UpdateAt>
                        {dayjs(after(index).createdAt?.toDate()).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </UpdateAt>
                    </MainContainer>
                  </Container>
                </History>
              }
            </div>
          ))}
        </AppCard>
      )}
    </>
  );
};

export default AppTaskHistories;
