import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useAuth,
  useUser,
  useMyWorkspaces,
  useMemberProjects,
  useMembers,
  useTaskStates,
  useTaskMilestones,
  useTaskTags,
  useTeams,
  useTaskBookmarks,
  useMemberOffices,
  useUnreadNotifications,
} from ".";
import { setAuth } from "../store/authSlice";
import {
  setProjects,
  setProjectsLoading,
  setProjectsError,
} from "../store/projectsSlice";
import { setTeams, setTeamsLoading, setTeamsError } from "../store/teamsSlice";
import { setUser, setUserError, setUserLoading } from "../store/userSlice";
import {
  setWorkspaces,
  setWorkspacesLoading,
  setWorkspacesError,
} from "../store/workspacesSlice";
import {
  setTaskTags,
  setTaskTagsLoading,
  setTaskTagsError,
} from "../store/taskTagsSlice";
import {
  setTaskMilestones,
  setTaskMilestonesLoading,
  setTaskMilestonesError,
} from "../store/taskMilestonesSlice";
import {
  setTaskStates,
  setTaskStatesError,
  setTaskStatesLoading,
} from "../store/taskStatesSlice";
import {
  setMembers,
  setMembersLoading,
  setMembersError,
  setMembersSelectedId,
} from "../store/membersSlice";
import {
  setTaskBookmarks,
  setTaskBookmarksError,
  setTaskBookmarksLoading,
} from "../store/taskBookmarksSlice";
import {
  setOffices,
  setOfficesError,
  setOfficesLoading,
} from "../store/officesSlice";
import {
  setNotificationsError,
  setNotificationsLoading,
  setTaskCommentNotifications,
  setTaskNotifications,
} from "../store/notificationsSlice";

export const useSetStore = (workspaceId: string) => {
  // store
  const dispatch = useDispatch();

  // auth
  const auth = useAuth();
  useEffect(() => {
    dispatch(setAuth(auth));
    dispatch(setMembersSelectedId(auth?.id ?? null));
  }, [auth]);

  // user
  const user = useUser();
  useEffect(() => {
    dispatch(setUser(user.data));
  }, [user.data]);
  useEffect(() => {
    dispatch(setUserLoading(user.loading));
  }, [user.loading]);
  useEffect(() => {
    dispatch(setUserError(user.error));
  }, [user.error]);

  // workspaces
  const workspaces = useMyWorkspaces();
  useEffect(() => {
    dispatch(setWorkspaces(workspaces.data));
  }, [workspaces.data]);
  useEffect(() => {
    dispatch(setWorkspacesLoading(workspaces.loading));
  }, [workspaces.loading]);
  useEffect(() => {
    dispatch(setWorkspacesError(workspaces.error));
  }, [workspaces.error]);

  // projects
  const projects = useMemberProjects({
    workspaceId,
    memberId: auth?.id ?? null,
  });
  useEffect(() => {
    dispatch(setProjects(projects.data));
  }, [projects.data]);
  useEffect(() => {
    dispatch(setProjectsLoading(projects.loading));
  }, [projects.loading]);
  useEffect(() => {
    dispatch(setProjectsError(projects.error));
  }, [projects.error]);

  // members
  const members = useMembers({ workspaceId });
  useEffect(() => {
    dispatch(setMembers(members.data));
  }, [members.data]);
  useEffect(() => {
    dispatch(setMembersLoading(members.loading));
  }, [members.loading]);
  useEffect(() => {
    dispatch(setMembersError(members.error));
  }, [members.error]);

  // taskStates
  const taskStates = useTaskStates({ workspaceId });
  useEffect(() => {
    dispatch(setTaskStates(taskStates.data));
  }, [taskStates.data]);
  useEffect(() => {
    dispatch(setTaskStatesLoading(taskStates.loading));
  }, [taskStates.loading]);
  useEffect(() => {
    dispatch(setTaskStatesError(taskStates.error));
  }, [taskStates.error]);

  // taskMilestones
  const taskMilestones = useTaskMilestones({ workspaceId });
  useEffect(() => {
    dispatch(setTaskMilestones(taskMilestones.data));
  }, [taskMilestones.data]);
  useEffect(() => {
    dispatch(setTaskMilestonesLoading(taskMilestones.loading));
  }, [taskMilestones.loading]);
  useEffect(() => {
    dispatch(setTaskMilestonesError(taskMilestones.error));
  }, [taskMilestones.error]);

  // taskTags
  const taskTags = useTaskTags({ workspaceId });
  useEffect(() => {
    dispatch(setTaskTags(taskTags.data));
  }, [taskTags.data]);
  useEffect(() => {
    dispatch(setTaskTagsLoading(taskTags.loading));
  }, [taskTags.loading]);
  useEffect(() => {
    dispatch(setTaskTagsError(taskTags.error));
  }, [taskTags.error]);

  // teams
  const teams = useTeams({ workspaceId });
  useEffect(() => {
    dispatch(setTeams(teams.data));
  }, [teams.data]);
  useEffect(() => {
    dispatch(setTeamsLoading(teams.loading));
  }, [teams.loading]);
  useEffect(() => {
    dispatch(setTeamsError(teams.error));
  }, [teams.error]);

  // taskBookmarks
  const taskBookmarks = useTaskBookmarks({ workspaceId });
  useEffect(() => {
    dispatch(setTaskBookmarks(taskBookmarks.data));
  }, [taskBookmarks.data]);
  useEffect(() => {
    dispatch(setTaskBookmarksLoading(taskBookmarks.loading));
  }, [taskBookmarks.loading]);
  useEffect(() => {
    dispatch(setTaskBookmarksError(taskBookmarks.error));
  }, [taskBookmarks.error]);

  // offices
  const offices = useMemberOffices({ workspaceId, memberId: auth?.id ?? null });
  useEffect(() => {
    dispatch(setOffices(offices.data));
  }, [offices.data]);
  useEffect(() => {
    dispatch(setOfficesLoading(offices.loading));
  }, [offices.loading]);
  useEffect(() => {
    dispatch(setOfficesError(offices.error));
  }, [offices.error]);

  // notifications
  const notifications = useUnreadNotifications();
  useEffect(() => {
    dispatch(setTaskNotifications(notifications.tasks));
  }, [notifications.tasks]);
  useEffect(() => {
    dispatch(setTaskCommentNotifications(notifications.taskComments));
  }, [notifications.taskComments]);
  useEffect(() => {
    dispatch(setNotificationsLoading(notifications.loading));
  }, [notifications.loading]);
  useEffect(() => {
    dispatch(setNotificationsError(notifications.error));
  }, [notifications.error]);
};
