import { ComponentProps, FunctionComponent } from "react";
import { IonButton } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonButton> {
  action?: "ok" | "delete" | "clear";
}

const Button = styled(IonButton)`
  text-transform: none;
  min-width: 100px;
  --border-width: 1px;
  --box-shadow: none;
  margin: 0px;
  .ios & {
    height: 2.4em;
  }
`;

const ClearButton = styled(IonButton)`
  text-transform: none;
  --border-width: 1px;
  --box-shadow: none;
  margin: 0px;
  .ios & {
    height: 2.4em;
  }
`;

const CancelButton = styled(IonButton)`
  text-transform: none;
  --color: var(--app-color-grey600);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--app-color-grey400);
  --background: var(--app-color-white);
  --box-shadow: none;
  margin: 0px;
  .ios & {
    height: 2.4em;
  }
`;

const AppActionButton: FunctionComponent<Props> = ({
  type,
  children,
  action,
  color,
  ...props
}) => {
  return (
    <>
      {action === "ok" && (
        <Button {...props} type="submit">
          {children}
        </Button>
      )}
      {action === "clear" && (
        <ClearButton {...props} fill="outline">
          {children}
        </ClearButton>
      )}
      {action === "delete" && (
        <Button {...props} color="danger">
          {children}
        </Button>
      )}
      {action === undefined && (
        <CancelButton {...props}>{children}</CancelButton>
      )}
    </>
  );
};

export default AppActionButton;
