import { useIonAlert, useIonModal, useIonToast } from "@ionic/react";
import { addOutline, cubeOutline } from "ionicons/icons";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  AppButtons,
  AppCard,
  AppContent,
  AppErrorCard,
  AppFabButton,
  AppFabButtons,
  AppHeader,
  AppIcon,
  AppInnerContent,
  AppItem,
  AppItemGroup,
  AppItemIcon,
  AppLabel,
  AppLead,
  AppLoadingCard,
  AppMenuButton,
  AppPage,
  AppReorder,
  AppTitle,
  AppToolbar,
  AppWriteOfficeModal,
  AppWriteOfficeModalProps,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useLoading, useError } from "../../../hooks";
import { TabId } from "../../../models";
import {
  selectMembersLoading,
  selectMembersError,
  selectMembers,
  selectIsWorkspaceAdmin,
} from "../../../store/membersSlice";
import {
  selectOffices,
  selectOfficesError,
  selectOfficesLoading,
} from "../../../store/officesSlice";
import {
  selectTeams,
  selectTeamsLoading,
  selectTeamsError,
} from "../../../store/teamsSlice";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { appLinks } from "../../../utilities/UtilPage";

const TabOfficePage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const members = useSelector(selectMembers);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const isWorkspaceAdmin = useSelector(selectIsWorkspaceAdmin);
  const teams = useSelector(selectTeams);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  const offices = useSelector(selectOffices);
  const officesLoading = useSelector(selectOfficesLoading);
  const officesError = useSelector(selectOfficesError);
  const loading = useLoading(membersLoading, teamsLoading, officesLoading);
  const error = useError(membersError, teamsError, officesError);
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showCreateOfficeModal, hideCreateOfficeModal] = useIonModal(
    AppWriteOfficeModal,
    {
      office: null,
      saveOffice: (office) => {
        Promise.resolve()
          .then(() => appApis.createOffice(office, members, teams))
          .then(() => {
            showToast({
              message: i18nText.success.office.create(),
              duration: 2000,
            });
            hideCreateOfficeModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideCreateOfficeModal(),
    } as AppWriteOfficeModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppMenuButton />
          </AppButtons>
          <AppTitle>オフィス</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && (
            <>
              {offices.length === 0 && (
                <AppLead>
                  「オフィス作成」ボタンを選択しオフィスを作成してください。
                </AppLead>
              )}
              {offices.length > 0 && (
                <AppCard>
                  <AppItemGroup>
                    {offices.map((item) => {
                      return (
                        <AppItem
                          key={item.id}
                          button={true}
                          detail={true}
                          routerLink={appLinks.office(
                            workspaceId,
                            tabId,
                            item.id
                          )}
                        >
                          <AppItemIcon icon={cubeOutline} slot="start" />
                          <AppLabel>{item.name}</AppLabel>
                          <AppReorder slot="end" />
                        </AppItem>
                      );
                    })}
                  </AppItemGroup>
                </AppCard>
              )}
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFabButtons vertical="bottom" horizontal="end">
        {isWorkspaceAdmin && (
          <AppFabButton
            onClick={(e) =>
              showCreateOfficeModal({
                presentingElement: routerRef,
                canDismiss: true,
              })
            }
          >
            <AppIcon slot="start" icon={addOutline} />
            オフィス作成
          </AppFabButton>
        )}
      </AppFabButtons>
    </AppPage>
  );
};

export default TabOfficePage;
