import { FunctionComponent, useMemo, useState } from "react";
import { ItemReorderEventDetail } from "@ionic/core";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  AppContent,
  AppCard,
  AppReorderGroup,
  AppTask,
  AppLead,
  AppHeaderBackButton,
  AppButton,
  AppButtons,
  AppHeader,
  AppIcon,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
} from "../..";
import { useError, useLoading, useTasks } from "../../../hooks";
import { selectAuth } from "../../../store/authSlice";
import {
  selectTaskBookmarks,
  selectTaskBookmarksError,
  selectTaskBookmarksLoading,
} from "../../../store/taskBookmarksSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { swapVerticalOutline } from "ionicons/icons";
import { appApis } from "../../../apis/Api";
import { TabId } from "../../../models";

const TaskBookmarks: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  const [projectId, setProjectId] = useState<string | null>(null);
  const history = useHistory();
  // store
  const auth = useSelector(selectAuth);
  const taskBookmarks = useSelector(selectTaskBookmarks);
  const taskBookmarksLoading = useSelector(selectTaskBookmarksLoading);
  const taskBookmarksError = useSelector(selectTaskBookmarksError);

  // hooks
  const taskIds = useMemo(
    () => taskBookmarks.map((item) => item.taskId),
    [taskBookmarks]
  );
  const tasks = useTasks({ workspaceId, taskIds });
  const loading = useLoading(taskBookmarksLoading, tasks.loading);
  const error = useError(taskBookmarksError, tasks.error);

  // page
  const [isSortMode, setIsSortMode] = useState<boolean>(false);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>ブックマーク</AppTitle>
          {taskBookmarks.length >= 2 && (
            <AppButtons slot="end">
              {isSortMode && (
                <AppButton color="primary" onClick={() => setIsSortMode(false)}>
                  完了
                </AppButton>
              )}
              {!isSortMode && (
                <AppButton color="primary" onClick={() => setIsSortMode(true)}>
                  <AppIcon slot="icon-only" icon={swapVerticalOutline} />
                </AppButton>
              )}
            </AppButtons>
          )}
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {auth && (
            <>
              {!loading && !error && (
                <>
                  {tasks.data.length === 0 && (
                    <AppLead>ブックマークしたタスクがありません</AppLead>
                  )}
                  {tasks.data.length > 0 && (
                    <AppCard>
                      <AppReorderGroup
                        disabled={!isSortMode}
                        onIonItemReorder={(
                          event: CustomEvent<ItemReorderEventDetail>
                        ) => {
                          appApis
                            .reorderTaskBookmarks(
                              workspaceId,
                              taskBookmarks,
                              event.detail.from,
                              event.detail.to
                            )
                            .catch(() => {});
                          event.detail.complete();
                        }}
                      >
                        {tasks.data.map((task) => (
                          <AppTask
                            key={task.id}
                            {...{
                              task,
                              history,
                              isSortMode,
                              setProjectId: (projectId) =>
                                setProjectId(projectId),
                              routerRef,
                            }}
                          />
                        ))}
                      </AppReorderGroup>
                    </AppCard>
                  )}
                </>
              )}
            </>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default TaskBookmarks;
