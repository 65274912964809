import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Workspace } from "../models/Workspace";
import { RootState } from "./";

interface WorkspacesState extends EntityState<Workspace> {
  loading: boolean;
  error: string | null;
  selectedId: string | null;
}

const adapter = createEntityAdapter<Workspace>();
const { selectById } = adapter.getSelectors();

const initialState: WorkspacesState = adapter.getInitialState({
  loading: true,
  error: null,
  selectedId: null,
});

export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setWorkspace: (state, action: PayloadAction<Workspace>) => {
      adapter.setOne(state, action.payload);
    },
    setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      adapter.setAll(state, action.payload);
    },
    setWorkspacesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setWorkspacesError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setWorkspacesSelectedId: (state, action: PayloadAction<string | null>) => {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setWorkspace,
  setWorkspaces,
  setWorkspacesLoading,
  setWorkspacesError,
} = workspacesSlice.actions;

export const selectWorkspaces = adapter.getSelectors(
  (state: RootState) => state.workspaces
).selectAll;

export const selectWorkspaceEntities = adapter.getSelectors(
  (state: RootState) => state.workspaces
).selectEntities;

export const selectWorkspacesLoading = (state: RootState) =>
  state.workspaces.loading;

export const selectWorkspacesError = (state: RootState) =>
  state.workspaces.error;

export const selectWorkspace = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.workspaces,
    (state) => (selectById(state, id!) ? selectById(state, id!)! : null)
  );
};

export default workspacesSlice.reducer;
