import { FunctionComponent } from "react";
import {
  AppButton,
  AppHeader,
  AppLogo,
  AppLogomark,
  AppRouterLink,
  AppToolbar,
} from "..";
import { appLinks } from "../../utilities/UtilPage";
import styled from "styled-components";

interface Props {}

const Content = styled.div`
  max-width: 1200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  .ios & {
    padding: 0;
  }
  .md & {
    padding: 0 8px;
  }
`;

const WrapLogo = styled.div`
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0 8px;
`;

const Button = styled(AppButton)`
  --box-shadow: none;
  --padding-start: 12px;
  --padding-end: 12px;
  margin: 0;
  .ios & {
    height: 32px;
  }
`;

const AppLandingHeader: FunctionComponent<Props> = () => {
  return (
    <AppHeader>
      <AppToolbar>
        <Content>
          <WrapLogo>
            <AppRouterLink
              routerLink={appLinks.home()}
              style={{ display: "flex", alignItems: "center" }}
            >
              <AppLogomark
                width="48px"
                height="36px"
                style={{ marginLeft: "4px" }}
                className="ion-hide-sm-up"
              />
              <AppLogo
                width="112px"
                height="28px"
                style={{ marginLeft: "4px", marginBottom: "4px" }}
                className="ion-hide-sm-down"
              />
            </AppRouterLink>
          </WrapLogo>
          <Buttons>
            {/* 言語選択を多言語対応の際に追加 */}
            <Button routerLink={appLinks.signIn()}>サインイン</Button>
            <Button routerLink={appLinks.signUp()}>無料で始める</Button>
          </Buttons>
        </Content>
      </AppToolbar>
    </AppHeader>
  );
};

export default AppLandingHeader;
