import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Text = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
  white-space: normal;
`;

const AppText: FunctionComponent<Props> = ({ children, style, ...props }) => {
  return <Text {...props}>{children}</Text>;
};

export default AppText;
