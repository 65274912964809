import { FunctionComponent } from "react";
import { Member } from "../../../models";
import styled from "styled-components";
import { AppItem, AppItemGroup } from "../..";
import { i18nRoleToText } from "../../../utilities/UtilI18nText";
import { IonAvatar } from "@ionic/react";
import { appLinks } from "../../../utilities/UtilPage";
import { useSelector } from "react-redux";
import { selectTabId, selectWorkspaceId } from "../../../store/uiSlice";
import { AVATAR } from "../../../utilities/UtilStatic";

export interface AppMemberPopoverProps {
  member: Member | null;
  showMemberDetails: (link: string) => void;
}

const MemberContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
`;

const Avatar = styled(IonAvatar)`
  width: 56px;
  height: 56px;
  flex: 0 1 auto;
  margin: 8px 12px 8px 0;
`;

const MemberInfo = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 4px 0;
`;

const MemberName = styled.div`
  font-size: 16px;
  line-height: 1.2;
`;

const MemberRole = styled.div`
  color: var(--app-color-grey600);
  font-size: 14px;
  line-height: 1.2;
`;

const AppMemberPopover: FunctionComponent<AppMemberPopoverProps> = ({
  member,
  showMemberDetails,
}) => {
  const tabId = useSelector(selectTabId);
  const workspaceId = useSelector(selectWorkspaceId);
  return (
    <>
      {member && (
        <AppItemGroup>
          <AppItem
            lines="full"
            button={true}
            detail={true}
            onClick={() =>
              showMemberDetails(
                appLinks.memberDetails(workspaceId, tabId, member.id)
              )
            }
          >
            <MemberContent>
              <Avatar>
                <img src={member.avatar ?? AVATAR.DEFAULT} />
              </Avatar>
              <MemberInfo>
                <MemberName>{member.name}</MemberName>
                <MemberRole>{i18nRoleToText(member.role)}</MemberRole>
              </MemberInfo>
            </MemberContent>
          </AppItem>
        </AppItemGroup>
      )}
    </>
  );
};

export default AppMemberPopover;
