import { useIonAlert, useIonLoading } from "@ionic/react";
import { FunctionComponent, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import {
  AppActionButton,
  AppBackButton,
  AppCard,
  AppCardContent,
  AppContent,
  AppErrorCard,
  AppFooterButtons,
  AppFormInput,
  AppFormTextarea,
  AppInnerContent,
  AppLandingHeader,
  AppPage,
} from "../..";
import { appApis } from "../../../apis/Api";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { appLinks } from "../../../utilities/UtilPage";
import { PLATFORM } from "../../../utilities/UtilStatic";

const MainTitle = styled.h1`
  font-size: 28px;
  font-weight: normal;
  margin: 0 0 8px;
  padding: 8px 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactPage: FunctionComponent = () => {
  // router
  const history = useHistory();
  // states
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();
  return (
    <AppPage>
      {!PLATFORM.IS_CAPACITOR && <AppLandingHeader />}
      <AppContent style={{ "--padding-top": PLATFORM.IS_IOS ? "48px" : "" }}>
        <AppInnerContent>
          <MainTitle>お問い合わせ</MainTitle>
          {error && (
            <AppErrorCard style={{ marginBottom: "16px" }}>
              {error}
            </AppErrorCard>
          )}
          <AppCard marginBottom="16px">
            <AppCardContent>
              <AppFormInput
                value={name}
                onIonChange={(e) => setName(e.detail.value ?? "")}
                type="text"
                label="お名前"
                marginBottom="8px"
                name="name"
              />
              <AppFormInput
                value={email}
                onIonChange={(e) => setEmail(e.detail.value ?? "")}
                type="text"
                label="メールアドレス"
                marginBottom="8px"
                name="email"
              />
              <AppFormTextarea
                value={message}
                onIonChange={(e) => setMessage(e.detail.value ?? "")}
                label="メッセージ"
                marginBottom="16px"
                name="email"
                style={{ minHeight: "200px" }}
              />
              <Buttons>
                <AppActionButton
                  action="ok"
                  onClick={() => {
                    const emailPattern =
                      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;

                    if (!name) {
                      setError("名前を入力してください。");
                    } else if (!email) {
                      setError("メールアドレスを入力してください。");
                    } else if (!emailPattern.test(email)) {
                      setError("メールアドレスの形式が正しくありません。");
                    } else if (!message) {
                      setError("メッセージを入力してください。");
                    } else {
                      setError(null);
                      showLoading({
                        cssClass: "app-loading",
                        backdropDismiss: true,
                      });
                      Promise.resolve()
                        .then(() =>
                          appApis.sendContactEmail(name, email, message)
                        )
                        .then(() => {
                          showAlert({
                            header: "お問い合わせありがとうございます",
                            message: "送信しました。",
                            buttons: [
                              {
                                text: "OK",
                                role: "cancel",
                                handler: () => {
                                  setName("");
                                  setEmail("");
                                  setMessage("");
                                  history.replace(appLinks.home());
                                },
                              },
                            ],
                          });
                        })
                        .catch((error) => {
                          showAlert({ message: i18nErrorToString(error) });
                        })
                        .finally(() => hideLoading());
                    }
                  }}
                >
                  送信
                </AppActionButton>
              </Buttons>
            </AppCardContent>
          </AppCard>
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.home()} />
      </AppFooterButtons>
    </AppPage>
  );
};

export default ContactPage;
