import { ComponentProps, FunctionComponent } from "react";
import { IonAccordionGroup } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonAccordionGroup> {}

const Accordion = styled(IonAccordionGroup)``;

const AppAccordionGroup: FunctionComponent<Props> = ({
  children,
  ...props
}) => {
  return <Accordion {...props}>{children}</Accordion>;
};

export default AppAccordionGroup;
