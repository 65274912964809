import { ComponentProps, FunctionComponent } from "react";
import { IonCheckbox } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonCheckbox> {}

const Checkbox = styled(IonCheckbox)``;

const AppCheckbox: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Checkbox {...props}>{children}</Checkbox>;
};

export default AppCheckbox;
