import { HTMLAttributes, FunctionComponent } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  width: string;
  height: string;
}

const AppLogo: FunctionComponent<Props> = ({
  width,
  height,
  style,
  ...props
}) => {
  return (
    <div style={{ width, height, ...style }} {...props}>
      <img src="/assets/images/logo/icon_typo.png" />
    </div>
  );
};

export default AppLogo;
