import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { TaskTag } from "../models";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useTaskTags = ({
  workspaceId,
}: {
  workspaceId: string | null;
}) => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<TaskTag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth || !workspaceId) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis.$readTaskTags(workspaceId).subscribe({
      next: (data) => {
        console.log("useTaskTags");
        setData(data);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth, workspaceId]);

  return { data, loading, error };
};
