import { useIonAlert, useIonLoading } from "@ionic/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { appApis } from "../../../apis/Api";
import { i18nError, i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { appLinks } from "../../../utilities/UtilPage";
import {
  AppFormInput,
  AppButton,
  AppFooterStoreLink,
  AppLogo,
  AppActionButton,
  AppLandingHeader,
  AppContent,
  AppPage,
  AppRouterLink,
} from "../..";
import styled from "styled-components";
import i18next from "i18next";
import {
  getEmailAndPassword,
  getTimezone,
  setEmailAndPassword,
} from "../../../utilities/UtilFunction";
import { PAGES, PLATFORM } from "../../../utilities/UtilStatic";

const AuthContent = styled.div`
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`;

const AuthMain = styled.div`
  width: 100%;
  padding: 0 12px;
  max-width: 400px;
`;

const AuthCard = styled.div`
  width: 100%;
  border: 1px solid var(--app-color-grey300);
  background: white;
  margin: 0 0 8px;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
  color: var(--app-color-grey900);
`;

const AuthLogo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 16px 0;
  background: white;
  margin-bottom: 8px;
`;

const AuthLead = styled.div`
  font-size: 16px;
  color: var(--app-color-grey600);
  letter-spacing: 1px;
  margin-bottom: 4px;
  padding: 0 16px;
`;

const AuthLink = styled.div`
  padding: 8px;
  width: 100%;
  text-align: center;
`;

const AuthLinkText = styled.div`
  font-size: 14px;
  padding-bottom: 8px;
  display: block;
  color: var(--app-color-grey800);
`;

const Note = styled.div`
  font-size: 12px;
  margin-top: 16px;
  color: var(--app-color-grey600);
  line-height: 1.5;
`;

const SignUpPage: FunctionComponent = () => {
  // router
  const history = useHistory();
  const { language } = useParams<{ language?: string }>();
  // alert
  const [showAlert] = useIonAlert();
  // loading
  const [showLoading, hideLoading] = useIonLoading();
  // form
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (language) i18next.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    // ログインに成功しメールとパスワードが保存済みならサインインページにリダイレクト
    if (PLATFORM.IS_CAPACITOR) {
      Promise.resolve()
        .then(() => getEmailAndPassword())
        .then(({ email, password }) => {
          if (email && password) {
            history.replace(appLinks.signIn());
          }
        })
        .catch((error) => {});
    }
  }, [history]);

  return (
    <AppPage>
      {!PLATFORM.IS_CAPACITOR && <AppLandingHeader />}
      <AppContent>
        <AuthContent>
          <AuthMain>
            <AuthCard>
              <AuthLogo>
                <AppLogo
                  width="240px"
                  height="60px"
                  style={{ marginBottom: "32px" }}
                />
                {/* <Trans i18nKey="pages.signUp.description">
                  <AuthLead />
                  <AuthLead />
                </Trans> */}
                <AuthLead>基本料金無料</AuthLead>
                <AuthLead>お気軽にお試しください</AuthLead>
              </AuthLogo>
              <AppFormInput
                value={name}
                onIonChange={(e) => setName(e.detail.value!)}
                label="お名前"
                type="text"
                marginBottom="8px"
                name="name"
              />
              <AppFormInput
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                label="Eメール"
                type="email"
                marginBottom="8px"
                name="email"
              />
              <AppFormInput
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
                label="パスワード"
                type="password"
                helperText="アルファベットの大文字、小文字、数字をそれぞれ1種類以上含む8文字以上で入力してください"
                name="password"
              />
              <Note>
                アカウントを登録することにより
                <AppRouterLink routerLink={appLinks.terms()}>
                  利用規約
                </AppRouterLink>
                と
                <AppRouterLink routerLink={appLinks.privacyPolicy()}>
                  プライバシーポリシー
                </AppRouterLink>
                に同意したとみなされます。
              </Note>
              <div style={{ paddingTop: "16px" }}>
                <AppActionButton
                  action="ok"
                  expand="block"
                  style={{ margin: 0 }}
                  onClick={() => {
                    Promise.resolve()
                      .then(() =>
                        showLoading({
                          cssClass: "app-loading",
                          backdropDismiss: true,
                        })
                      )
                      .then(() => {
                        // 正規表現：大文字、小文字、数字をそれぞれ1文字以上使用かつ8文字以上
                        const pattern =
                          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/;
                        if (!password.match(pattern)) {
                          throw new Error(i18nError.authWeakPassword());
                        }
                      })
                      .then(() => appApis.signUp(email, password, language))
                      .then(() => appApis.signIn(email, password))
                      .then((userId) =>
                        appApis.createWorkspace(
                          userId,
                          name,
                          appApis.createId(),
                          i18nText.initialize.workspace.name(),
                          getTimezone()
                        )
                      )
                      .then((workspaceId) => {
                        // フォーム初期化
                        setName("");
                        setEmail("");
                        setPassword("");
                        // ローカルストレージにIDとPASSを保存
                        if (PLATFORM.IS_CAPACITOR) {
                          Promise.resolve()
                            .then(() => setEmailAndPassword(email, password))
                            .catch((error) => {});
                        }
                        // appApis.createWorkspace がhook内で反映されてないからリロードする
                        appApis.reloadApp();
                        history.replace(
                          appLinks.tab(workspaceId, PAGES.TABS.PROJECT)
                        );
                      })
                      .catch((error) => {
                        showAlert({ message: i18nErrorToString(error) });
                      })
                      .finally(() => hideLoading());
                  }}
                >
                  {i18nText.buttons.createUser()}
                </AppActionButton>
              </div>
            </AuthCard>

            <AuthLink>
              <AuthLinkText>{i18nText.pages.signUp.hasAccount()}</AuthLinkText>
              <AppButton
                color="primary"
                fill="outline"
                type="submit"
                routerLink={appLinks.signIn()}
              >
                {i18nText.buttons.signIn()}
              </AppButton>
            </AuthLink>
          </AuthMain>
        </AuthContent>
      </AppContent>
      {!PLATFORM.IS_CAPACITOR && <AppFooterStoreLink />}
    </AppPage>
  );
};

export default SignUpPage;
