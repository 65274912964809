import { ComponentProps, FunctionComponent } from "react";
import { IonInput } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonInput> {
  label?: string;
  helperText?: string;
  optional?: boolean;
  placeholder?: string;
  marginBottom?: string;
}

const Content = styled.div`
  display: block;
`;

const Label = styled.div`
  margin-bottom: -11px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  z-index: 10;
  pointer-events: none;
`;

const LabelText = styled.div`
  display: inline-block;
  color: var(--app-color-grey600);
  font-size: 12px;
  background-color: var(--app-color-white);
  padding: 0 6px;
  word-wrap: keep-all;
  white-space: nowrap;
  border-radius: 4px;
`;

const InputWrap = styled.div`
  border: 1px solid var(--app-color-grey400);
  border-radius: 4px;
  overflow: hidden;
`;

const HelperText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  padding: 0 2px;
  color: var(--app-color-grey600);
`;

const Input = styled(IonInput)`
  color: var(--app-color-grey900);
  font-size: 16px;
  --placeholder-color: var(--app-color-grey400);
  --placeholder-opacity: 1;
  --padding-start: 16px !important;
  --padding-end: 8px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  input {
    min-height: 48px;
  }
`;

const AppFormInput: FunctionComponent<Props> = ({
  label,
  optional,
  helperText,
  marginBottom,
  ...props
}) => {
  return (
    <Content style={{ marginBottom }}>
      {label && (
        <Label>
          <LabelText>
            {label}
            {optional && "（オプション）"}
          </LabelText>
        </Label>
      )}
      <InputWrap>
        <Input {...props} />
      </InputWrap>
      {helperText && <HelperText>{helperText}</HelperText>}
    </Content>
  );
};

export default AppFormInput;
