import { ComponentProps, FunctionComponent } from "react";
import { IonIcon } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonIcon> {}

const Icon = styled(IonIcon)``;

const AppIcon: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Icon {...props}>{children}</Icon>;
};

export default AppIcon;
