import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "../models/Auth";
import { RootState } from "./";

interface authState {
  data: Auth | null;
}

const initialState: authState = {
  data: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth | null>) => {
      state.data = action.payload;
    },
  },
});

export const { setAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth.data;

export default authSlice.reducer;
