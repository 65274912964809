import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import userSlice from "./userSlice";
import workspacesSlice from "./workspacesSlice";
import projectsSlice from "./projectsSlice";
import membersSlice from "./membersSlice";
import taskStatesSlice from "./taskStatesSlice";
import taskMilestonesSlice from "./taskMilestonesSlice";
import taskTagsSlice from "./taskTagsSlice";
import teamsSlice from "./teamsSlice";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import taskBookmarksSlice from "./taskBookmarksSlice";
import officesSlice from "./officesSlice";
import notificationsSlice from "./notificationsSlice";
import uiSlice from "./uiSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    workspaces: workspacesSlice,
    projects: projectsSlice,
    members: membersSlice,
    taskStates: taskStatesSlice,
    taskMilestones: taskMilestonesSlice,
    taskTags: taskTagsSlice,
    teams: teamsSlice,
    taskBookmarks: taskBookmarksSlice,
    offices: officesSlice,
    notifications: notificationsSlice,
    ui: uiSlice,
    // ui: combineReducers({
    //   tab: tabSlice,
    //   menu: menuSlice,
    // }),
  },
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
