import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { StripeProduct } from "../models";

export const useStripeProducts = () => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<StripeProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis.$readStripeProducts().subscribe({
      next: (data) => {
        console.log("useStripeProducts");
        setData(data);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.log(error);
        setData([]);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth]);

  return { data, loading, error };
};
