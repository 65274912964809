import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Task, TaskComment } from "../models";
import { combineLatest } from "rxjs";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";

export const useUnreadNotifications = () => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskComments, setTaskComments] = useState<TaskComment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setTasks([]);
      setTaskComments([]);
      setLoading(false);
      setError(null);
      return;
    }
    const subscribe = combineLatest([
      appApis.$readUnreadTaskNotifications(),
      appApis.$readUnreadTaskCommentNotifications(),
    ]).subscribe({
      next: ([tasks, taskComments]) => {
        console.log("useUnreadNotifications");
        setTasks(tasks);
        setTaskComments(taskComments);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.log(error);
        setTasks([]);
        setTaskComments([]);
        setLoading(false);
        setError(i18nErrorToString(error));
      },
    });
    return () => subscribe.unsubscribe();
  }, [auth]);

  return { tasks, taskComments, loading, error };
};
