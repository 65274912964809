import { FunctionComponent, useRef, useState } from "react";
import { closeOutline, informationCircleOutline } from "ionicons/icons";
import { i18nError, i18nText } from "../../../utilities/UtilI18nText";
import {
  AppContent,
  AppCard,
  AppFormInput,
  AppItemGroup,
  AppFooterButtons,
  AppActionButton,
  AppHeader,
  AppToolbar,
  AppTitle,
  AppButtons,
  AppButton,
  AppIcon,
  AppItem,
  AppLabel,
  AppCardContent,
  AppInnerContent,
  AppPage,
  AppFormButton,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppExplainMemberRolesModal,
  AppExplainMemberRolesModalProps,
} from "../..";
import { Member, Role } from "../../../models";
import { useSelector } from "react-redux";
import { selectMyMember } from "../../../store/membersSlice";
import { selectAuth } from "../../../store/authSlice";
import styled from "styled-components";
import {
  IonAvatar,
  useIonAlert,
  useIonModal,
  useIonPopover,
} from "@ionic/react";
import { AVATAR, ENV, PLATFORM, ROLE } from "../../../utilities/UtilStatic";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { FilePicker } from "@capawesome/capacitor-file-picker";

export interface AppUpdateMemberModalProps {
  member: Member | null;
  deleteMember: (workspaceId: string, memberId: string) => void;
  saveMember: (member: Member) => void;
  hide: () => void;
}

const HiddenInput = styled.input`
  display: none;
`;

const TaskTopContentMemberAvator = styled(IonAvatar)`
  margin: 8px 16px 8px 0;
  width: 60px;
  height: 60px;
`;

const Small = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: var(--app-color-grey600);
`;

const AppUpdateMemberModal: FunctionComponent<AppUpdateMemberModalProps> = ({
  member,
  deleteMember,
  saveMember,
  hide,
}) => {
  // store
  const auth = useSelector(selectAuth);
  const myMember = useSelector(selectMyMember);
  // states
  const [form, setForm] = useState<Member | null>(member);
  // functions 画像のサイズ変換
  const resizeImageAndConvertToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = AVATAR.RECOMMEND_WIDTH;
        const height = AVATAR.RECOMMEND_HEIGHT;
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        context?.drawImage(image, 0, 0, width, height);
        resolve(canvas.toDataURL("image/png"));
      };
      image.onerror = reject;
    });
  };

  const resizeBase64Image = (base64: string, type: string) => {
    return new Promise<string>((resolve, reject) => {
      const image = new Image();
      image.src = `data:${type};base64,${base64}`;
      image.onload = () => {
        const width = AVATAR.RECOMMEND_WIDTH;
        const height = AVATAR.RECOMMEND_HEIGHT;
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        context?.drawImage(image, 0, 0, width, height);
        resolve(canvas.toDataURL("image/png"));
      };
      image.onerror = reject;
    });
  };

  // components
  const [showAlert] = useIonAlert();
  const uploadButton = useRef<HTMLInputElement>(null);
  const [showSelectRolePopover, hideSelectRolePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: [
        { id: ROLE.ADMIN, name: "管理者" },
        { id: ROLE.MEMBER, name: "一般" },
      ],
      selectedId: form?.role ?? "",
      selectItem: (id: Role) => {
        if (!form) return;
        setForm({ ...form, role: id });
        hideSelectRolePopover();
      },
    } as AppSelectPopoverProps
  );

  const [showExplainMemberRolesModal, hideExplainMemberRolesModal] =
    useIonModal(AppExplainMemberRolesModal, {
      hide: () => hideExplainMemberRolesModal(),
    } as AppExplainMemberRolesModalProps);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>メンバー</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {form && (
            <>
              <AppCard marginBottom="12px">
                <AppItemGroup>
                  <AppItem
                    button
                    onClick={() => {
                      if (PLATFORM.IS_CAPACITOR) {
                        Promise.resolve().then(() =>
                          FilePicker.pickImages({ readData: true })
                            .then(({ files }) => {
                              const file = files[0];
                              return resizeBase64Image(
                                file.data!,
                                file.mimeType
                              );
                            })
                            .then((base64) => {
                              if (form) setForm({ ...form, avatar: base64 });
                            })
                            .catch((error) => {
                              showAlert({ message: i18nErrorToString(error) });
                            })
                        );
                      } else {
                        uploadButton.current?.click();
                      }
                    }}
                  >
                    <TaskTopContentMemberAvator slot="start">
                      <img src={form.avatar ?? AVATAR.DEFAULT} />
                    </TaskTopContentMemberAvator>
                    <AppLabel>
                      <div>プロフィール画像変更</div>
                      <Small>{`推奨サイズ：横${AVATAR.RECOMMEND_WIDTH}px 縦${AVATAR.RECOMMEND_HEIGHT}px`}</Small>
                    </AppLabel>
                    {form.avatar && (
                      <AppButton
                        slot="end"
                        fill="clear"
                        color="parimary"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setForm({ ...form, avatar: null });
                        }}
                      >
                        クリア
                      </AppButton>
                    )}
                  </AppItem>
                </AppItemGroup>
              </AppCard>
              <AppCard>
                <AppCardContent>
                  <AppFormInput
                    value={form.name}
                    onIonChange={(e) => {
                      if (!form) return;
                      setForm({
                        ...form,
                        name: e.detail.value ?? "",
                      });
                    }}
                    type="text"
                    label="名前"
                    placeholder="例）山田太郎"
                    marginBottom="8px"
                    name="name"
                  />
                  <AppFormButton
                    label="権限"
                    onClick={(e) => {
                      if (myMember?.role !== ROLE.ADMIN) return;
                      showSelectRolePopover({
                        cssClass: [
                          "app-popover-width200",
                          "app-popover-height120",
                        ],
                        event: { ...e.nativeEvent, target: e.currentTarget },
                      });
                    }}
                    width="120px"
                    readonly={myMember?.role !== ROLE.ADMIN}
                  >
                    {/* TODO 管理者権限と一般権限についての説明 */}
                    {form.role === ROLE.ADMIN && <AppLabel>管理者</AppLabel>}
                    {form.role === ROLE.MEMBER && <AppLabel>一般</AppLabel>}
                  </AppFormButton>
                </AppCardContent>
              </AppCard>
              <AppButton
                color="medium"
                fill="clear"
                onClick={() => {
                  showExplainMemberRolesModal({ canDismiss: true });
                }}
              >
                <AppIcon
                  slot="start"
                  icon={informationCircleOutline}
                  style={{ fontSize: 18 }}
                />
                <AppLabel style={{ fontSize: 14 }}>
                  管理者と一般の権限の違い
                </AppLabel>
              </AppButton>
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        {form && member?.id !== auth?.id && (
          <AppActionButton
            action="delete"
            onClick={() => deleteMember(form.workspaceId, form.id)}
          >
            {i18nText.buttons.delete()}
          </AppActionButton>
        )}
        <AppActionButton
          action="ok"
          onClick={() => {
            if (form) saveMember(form);
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>

      <HiddenInput
        type="file"
        accept="image/*"
        ref={uploadButton}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!auth || !member) return;
          // file
          const file = e.target.files ? e.target.files[0] : undefined;
          if (!file) return;
          // storage
          Promise.resolve()
            .then(() => resizeImageAndConvertToBase64(file))
            .then((base64) => {
              if (form) setForm({ ...form, avatar: base64 });
            })
            .catch((error) => {
              showAlert({ message: i18nErrorToString(error) });
            });
          e.target.value = ""; // この行によって同じファイルがセットされてもonChangeが発火する
        }}
      />
    </AppPage>
  );
};

export default AppUpdateMemberModal;
