import { useIonAlert, useIonModal, useIonToast } from "@ionic/react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  AppContent,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppErrorCard,
  AppLoadingCard,
  AppHeaderBackButton,
  AppButtons,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
  AppFormItem,
  AppIcon,
  AppItem,
  AppItemGroup,
  AppCard,
  AppAddComma,
  AppDecorateOffice,
  AppWriteOfficeModal,
  AppWriteOfficeModalProps,
  AppItemIcon,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useError, useLoading, useOffice } from "../../../hooks";
import { TabId } from "../../../models";
import { selectAuth } from "../../../store/authSlice";
import {
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import {
  selectTeams,
  selectTeamsError,
  selectTeamsLoading,
} from "../../../store/teamsSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import {
  cubeOutline,
  ellipse,
  ellipseOutline,
  peopleCircleOutline,
  peopleOutline,
  tabletLandscapeOutline,
  tabletPortraitOutline,
} from "ionicons/icons";
import { TEXT } from "../../../utilities/UtilStatic";
import {
  formatMembers,
  formatItems,
  getColor,
} from "../../../utilities/UtilFunction";

const OfficeDetailsPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, officeId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    officeId: string;
  }>();
  // store
  const auth = useSelector(selectAuth);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const teams = useSelector(selectTeams);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  // hooks
  const office = useOffice({ workspaceId, officeId });
  const loading = useLoading(office.loading, membersLoading, teamsLoading);
  const error = useError(office.error, membersError, teamsError);
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showUpdateOfficeModal, hideUpdateOfficeModal] = useIonModal(
    AppWriteOfficeModal,
    {
      office: office.data,
      saveOffice: (form) => {
        if (!office || !auth) return;
        Promise.resolve()
          .then(() => appApis.updateOffice(form))
          .then(() => {
            showToast({
              message: i18nText.success.office.update(),
              duration: 2000,
            });
            hideUpdateOfficeModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      deleteOffice: (workspaceId, officeId) => {
        Promise.resolve()
          .then(() => appApis.deleteOffice(workspaceId, officeId))
          .then(() => {
            showToast({
              message: i18nText.success.office.delete(),
              duration: 2000,
            });
            hideUpdateOfficeModal();
            history.goBack();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideUpdateOfficeModal(),
      routerRef,
    } as AppWriteOfficeModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>オフィス編集</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && office.data && (
            <>
              <AppCard marginBottom="16px">
                <AppItemGroup>
                  <AppItem>
                    <AppIcon
                      icon={cubeOutline}
                      style={{ marginRight: "16px" }}
                      color="medium"
                      slot="start"
                    />
                    <AppFormItem label="オフィス名">
                      {office.data.name}
                    </AppFormItem>
                  </AppItem>
                  {office.data.public === true && (
                    <AppItem>
                      <AppIcon
                        icon={peopleOutline}
                        style={{ marginRight: "16px" }}
                        color="medium"
                        slot="start"
                      />
                      <AppFormItem label="アクセス可能なメンバー">
                        ワークスペース内全てのメンバー
                      </AppFormItem>
                    </AppItem>
                  )}
                  {office.data.public === false && (
                    <>
                      <AppItem>
                        <AppIcon
                          icon={peopleOutline}
                          style={{ marginRight: "16px" }}
                          color="medium"
                          slot="start"
                        />
                        <AppFormItem label="アクセス可能なメンバー">
                          {office.data.memberIds.length === 0 && TEXT.EMPTY}
                          {formatMembers(
                            office.data.memberIds,
                            memberEntities
                          ).map(({ id, name }) => (
                            <AppAddComma key={id}>{name}</AppAddComma>
                          ))}
                        </AppFormItem>
                      </AppItem>
                      <AppItem>
                        <AppIcon
                          icon={peopleCircleOutline}
                          style={{ marginRight: "16px" }}
                          color="medium"
                          slot="start"
                        />
                        <AppFormItem label="アクセス可能なチーム">
                          {office.data.teamIds.length === 0 && TEXT.EMPTY}
                          {formatItems(office.data.teamIds, teams).map(
                            ({ id, name }) => (
                              <AppAddComma key={id}>{name}</AppAddComma>
                            )
                          )}
                        </AppFormItem>
                      </AppItem>
                    </>
                  )}
                  <AppItem>
                    <AppIcon
                      icon={tabletLandscapeOutline}
                      style={{ marginRight: "16px" }}
                      color="medium"
                      slot="start"
                    />
                    <AppFormItem label="横幅">{office.data.col}</AppFormItem>
                  </AppItem>
                  <AppItem>
                    <AppIcon
                      icon={tabletPortraitOutline}
                      style={{ marginRight: "16px" }}
                      color="medium"
                      slot="start"
                    />
                    <AppFormItem label="縦幅">{office.data.row}</AppFormItem>
                  </AppItem>
                </AppItemGroup>
              </AppCard>
              <AppDecorateOffice office={office.data} readonly={true} />
              {office.data.memberStates.length > 0 && (
                <AppCard marginBottom="16px">
                  <AppItemGroup>
                    {office.data.memberStates.map(({ id, name, color }) => (
                      <AppItem key={id}>
                        <AppItemIcon
                          icon={color ? ellipse : ellipseOutline}
                          slot="start"
                          style={{
                            color: color
                              ? getColor(color).light
                              : "var(--app-color-grey400)",
                          }}
                        />
                        <AppFormItem label="メンバーステータス名">
                          {name}
                        </AppFormItem>
                      </AppItem>
                    ))}
                  </AppItemGroup>
                </AppCard>
              )}
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.tab(workspaceId, tabId)} />
        <AppActionButton
          action="ok"
          routerLink={appLinks.officeUpdate(workspaceId, tabId, officeId)}
        >
          {i18nText.buttons.edit()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default OfficeDetailsPage;
