import { FunctionComponent } from "react";
import { appLinks } from "../../../utilities/UtilPage";
import {
  AppButton,
  AppContent,
  AppIcon,
  AppLandingHeader,
  AppPage,
} from "../..";
import { chevronForwardOutline } from "ionicons/icons";
import styled from "styled-components";
import { IonButton } from "@ionic/react";
import { IONIC, LINK, PLATFORM } from "../../../utilities/UtilStatic";

const Top = styled.section`
  background: radial-gradient(at 0% 0%, #00b0ff, transparent),
    radial-gradient(at 0% 100%, #651fff, transparent),
    radial-gradient(at 150% -20%, #d500f9, transparent),
    radial-gradient(at 100% 100%, #00b0ff, transparent), #2979ff;
  /* background: linear-gradient(#00b0ff, #2979ff); */
`;

const TopContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const TopTitle = styled.h1`
  margin: 0 0 16px;
  padding: 48px 32px 0;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.2;
  text-align: center;
  color: white;
  @media screen and (max-width: ${IONIC.BREAKPOINT.SM}) {
    font-size: 40px;
  }
`;

const MainText = styled.p`
  font-size: 16px;
  margin: 0 0 32px;
  padding: 0 32px;
  text-align: center;
  color: white;
`;

const TopButton = styled(IonButton)`
  margin-bottom: 32px;
  --background: linear-gradient(
    40deg,
    rgba(48, 79, 254, 1) 0%,
    rgba(224, 64, 251, 1) 100%
  );
  --color: var(--app-color-white);
  --box-shadow: none;
  &::part(native) {
    border: 3px solid var(--app-color-white);
  }
`;

const Section = styled.section<{ even?: boolean }>`
  background: ${({ even }) =>
    even ? "var(--app-color-blue-grey50)" : "var(--app-color-white)"};
  padding: 56px 32px;
  display: flex;
  justify-content: center;
`;

const SectionContent = styled.div<{ even?: boolean }>`
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 48px;
  flex-direction: ${({ even }) => (even ? "row-reverse" : "row")};
`;

const SectionImageContent = styled.div`
  width: calc(55% - 24px);
  border-radius: 8px;
  @media screen and (max-width: ${IONIC.BREAKPOINT.LG}) {
    width: 100%;
    max-width: 600px;
  }
`;

const SectionImage = styled.img`
  border-radius: 8px;
`;

const SectionTexts = styled.div`
  width: calc(45% - 24px);
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  @media screen and (max-width: ${IONIC.BREAKPOINT.LG}) {
    width: 100%;
    max-width: 800px;
  }
`;

const SubTitle = styled.h2`
  margin: 0;
  font-size: 32px;
  line-height: 1.2;
  background: -webkit-linear-gradient(#eee, #333);
  color: #2979ff; //非対応のブラウザでの文字色を設定
  background: -webkit-linear-gradient(0deg, #3d5afe, #00b0ff);
  -webkit-background-clip: text; //テキストでくり抜く
  -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
`;

const SubTitleText = styled.p`
  margin: 0;
  color: var(--app-color-grey800);
  font-size: 16px;
  line-height: 2;
`;

const Plans = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const Plan = styled.div`
  flex: 1 0 0%;
  min-width: 240px;
  /* background: var(--ion-color-primary); */
  background: var(--ion-color-primary);
  /* background: radial-gradient(at 0% 0%, #00b0ff, transparent),
    radial-gradient(at 100% 0%, #3d5afe, transparent), #2979ff; */
  border-radius: 8px;
  padding: 32px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: var(--app-color-white);
`;

const PlanName = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const PlanDescription = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

const PlanPrice = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  line-height: 1;
  font-weight: bold;
  border-bottom: 4px solid var(--app-color-white);
  padding: 0 4px 4px;
  margin-bottom: 8px;
`;

const PlanPriceCurrency = styled.div`
  font-size: 24px;
  margin-right: 8px;
`;

const PlanPriceNumeric = styled.div`
  font-size: 64px;
  letter-spacing: 0.05em;
`;

const PlanPriceMonth = styled.div`
  font-size: 20px;
`;

const Footer = styled.div`
  background: var(--ion-color-navy);
  padding: 8px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const Link = styled.a`
  width: 140px;
  height: auto;
  margin: 0 4px;
`;

const AppLinkImage = styled.img`
  vertical-align: top;
  border-radius: 6px;
  &:hover {
    opacity: 0.8;
  }
`;

const HomePage: FunctionComponent = () => {
  return (
    <AppPage>
      {!PLATFORM.IS_CAPACITOR && <AppLandingHeader />}
      <AppContent>
        {/* top */}
        <Top>
          <TopContent>
            <TopTitle>業務を見える化</TopTitle>
            <MainText>
              Jectはタスク管理とバーチャルオフィスで業務を可視化し、スムーズなプロジェクト進行をサポートします。
            </MainText>
            <TopButton
              size="large"
              shape="round"
              routerLink={appLinks.signUp()}
            >
              無料で始める
              <AppIcon slot="end" icon={chevronForwardOutline} />
            </TopButton>
            <img src="/assets/images/home/hero.png" width="900" />
          </TopContent>
        </Top>
        {/* section01 */}
        <Section>
          <SectionContent>
            <SectionImageContent>
              <SectionImage src="/assets/images/home/section01.jpg" />
            </SectionImageContent>
            <SectionTexts>
              <SubTitle>タスク管理で効率的に進める</SubTitle>
              <SubTitleText>
                タスクの割り当てや進捗状況の確認、コメントでのやり取りなどを一元的に管理できます。優先順位や期限などの設定も可能で、作業の優先度を明確にします。
              </SubTitleText>
            </SectionTexts>
          </SectionContent>
        </Section>
        {/* section02 */}
        <Section even={true}>
          <SectionContent even={true}>
            <SectionImageContent>
              <SectionImage src="/assets/images/home/section02.jpg" />
            </SectionImageContent>
            <SectionTexts>
              <SubTitle>バーチャルオフィスでチームがひとつに</SubTitle>
              <SubTitleText>
                自由にカスタマイズ可能なバーチャルオフィスで対応中のタスクなどの状況を共有し、チームの協力を促進します。リモートワークにもオススメです。
              </SubTitleText>
            </SectionTexts>
          </SectionContent>
        </Section>
        {/* section03 */}
        <Section>
          <SectionContent>
            <SectionImageContent>
              <SectionImage src="/assets/images/home/section03.jpg" />
            </SectionImageContent>
            <SectionTexts>
              <SubTitle>作業時間を把握する</SubTitle>
              <SubTitleText>
                メンバーの作業時間、タスク・プロジェクトごとにかかった時間を把握し効率的な時間の使い方をサポートします。また勤怠管理としてもご利用頂けます。
              </SubTitleText>
            </SectionTexts>
          </SectionContent>
        </Section>
        {/* section04 */}
        <Section even={true}>
          <SectionContent even={true} style={{ flexDirection: "row" }}>
            <SectionTexts>
              <SubTitle>基本料金0円</SubTitle>
              <SubTitleText>
                基本無料でご利用頂けます。メンバーが5名を超える場合はビジネスプランへの加入をご検討ください。ビジネスプランへの加入は無料登録後に契約ページより行えます。
              </SubTitleText>
            </SectionTexts>
            <SectionImageContent>
              <Plans>
                <Plan>
                  <PlanName>フリープラン</PlanName>
                  <PlanDescription>メンバー5名まで</PlanDescription>
                  <PlanPrice>
                    <PlanPriceCurrency>¥</PlanPriceCurrency>
                    <PlanPriceNumeric>0</PlanPriceNumeric>
                  </PlanPrice>
                </Plan>
                <Plan>
                  <PlanName>ビジネスプラン</PlanName>
                  <PlanDescription>メンバー1名につき</PlanDescription>
                  <PlanPrice>
                    <PlanPriceCurrency>¥</PlanPriceCurrency>
                    <PlanPriceNumeric>500</PlanPriceNumeric>
                    <PlanPriceMonth>/月</PlanPriceMonth>
                  </PlanPrice>
                </Plan>
              </Plans>
            </SectionImageContent>
          </SectionContent>
        </Section>
        {/* section06 */}
        <Section>
          <SectionContent style={{ width: "100%" }}>
            <SectionTexts style={{ width: "100%" }}>
              <SubTitle>今後さらなる機能も！</SubTitle>
              <SubTitleText>
                通話、チャット、ファイル管理など他にもさまざまな機能を開発中。機能追加を楽しみにお待ちください。
              </SubTitleText>
            </SectionTexts>
          </SectionContent>
        </Section>
        {/* フッター */}
        <Footer>
          <AppButton
            fill="clear"
            size="small"
            color="white"
            routerLink={appLinks.terms()}
            style={{ opacity: 0.8 }}
          >
            利用規約
          </AppButton>
          <AppButton
            fill="clear"
            size="small"
            color="white"
            routerLink={appLinks.privacyPolicy()}
            style={{ opacity: 0.8 }}
          >
            プライバシーポリシー
          </AppButton>
          <AppButton
            fill="clear"
            size="small"
            color="white"
            routerLink={appLinks.commerce()}
            style={{ opacity: 0.8 }}
          >
            特定商取引法に基づく表記
          </AppButton>
          <AppButton
            fill="clear"
            size="small"
            color="white"
            routerLink={appLinks.contact()}
            style={{ opacity: 0.8 }}
          >
            お問い合わせ
          </AppButton>
        </Footer>
      </AppContent>
      <Footer>
        <Link href={LINK.APP_STORE} target="blank">
          <AppLinkImage src="/assets/images/appstore.jpg" />
        </Link>
        <Link href={LINK.GOOGLE_PLAY} target="blank">
          <AppLinkImage src="/assets/images/googleplay.jpg" />
        </Link>
      </Footer>
    </AppPage>
  );
};

export default HomePage;
