import { ComponentProps, FunctionComponent } from "react";
import { IonSearchbar } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonSearchbar> {}

const Searchbar = styled(IonSearchbar)``;

const AppSearchbar: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Searchbar {...props}>{children}</Searchbar>;
};

export default AppSearchbar;
