import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  useIonAlert,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import {
  addOutline,
  ellipsisVertical,
  layersOutline,
  notificationsOffOutline,
  notificationsOutline,
  openOutline,
} from "ionicons/icons";
import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  selectProjects,
  selectProjectsError,
  selectProjectsLoading,
} from "../../../store/projectsSlice";
import { appLinks } from "../../../utilities/UtilPage";
import {
  AppContent,
  AppLoadingCard,
  AppErrorCard,
  AppCard,
  AppFabButtons,
  AppFabButton,
  AppItemGroup,
  AppMenuButton,
  AppItemIcon,
  AppItem,
  AppLead,
  AppLabel,
  AppWriteProjectModal,
  AppWriteProjectModalProps,
  AppPage,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppTitle,
  AppReorder,
  AppPopover,
  AppInnerContent,
  AppIcon,
} from "../..";
import {
  selectIsWorkspaceAdmin,
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import { selectAuth } from "../../../store/authSlice";
import { useLoading, useError } from "../../../hooks";
import { TabId, Task, TaskComment } from "../../../models";
import { appApis } from "../../../apis/Api";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import styled from "styled-components";
import dayjs from "dayjs";
import {
  selectNotificationsError,
  selectNotificationsLoading,
  selectProjectNotificationsCount,
  selectWorkspaceTaskAndTaskCommentNotificationsCount,
  selectWorkspaceNotifications,
} from "../../../store/notificationsSlice";
import { formatMember, getRoot } from "../../../utilities/UtilFunction";
import { ENV, PLATFORM } from "../../../utilities/UtilStatic";

const HeaderButton = styled(IonButton)`
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  right: -4px;
  padding: 2px 4px;
  background: var(--app-color-notification);
  border-radius: 12px;
  font-size: 12px;
  color: var(--app-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  .ios & {
    top: 0;
  }
  .md & {
    top: -4px;
  }
`;

const Avatar = styled(IonAvatar)`
  width: 28px;
  height: 28px;
  .ios & {
    margin-right: 16px;
  }
  .md & {
    margin-right: 12px;
  }
`;

const NotificationItem = styled(IonItem)`
  --inner-padding-end: 0;
`;

const NotificationLimitButton = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemOptionsButton = styled(IonButton)`
  margin: 0;
  height: 40px;
`;

const ItemOptionsButtonIcon = styled(IonIcon)`
  margin: 0;
  color: var(--app-color-grey500-rgba);
`;

const NotificationDate = styled.div`
  font-size: 12px;
  color: var(--app-color-grey600-rgba);
  margin-top: 2px;
`;

const NotificationTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NotificationNote = styled.div`
  font-size: 14px;
  color: var(--app-color-grey600-rgba);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ItemBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 12px;
  min-width: 22px;
  color: var(--app-color-white);
  background: var(--app-color-notification);
  margin-right: 4px;
`;

const TabProjectPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const auth = useSelector(selectAuth);
  const projects = useSelector(selectProjects);
  const projectsLoading = useSelector(selectProjectsLoading);
  const projectsError = useSelector(selectProjectsError);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const isWorkspaceAdmin = useSelector(selectIsWorkspaceAdmin);
  const workspaceTaskAndTaskCommentNotificationsCount = useSelector(
    selectWorkspaceTaskAndTaskCommentNotificationsCount(workspaceId)
  );
  const workspaceNotifications = useSelector(
    selectWorkspaceNotifications(workspaceId)
  );
  const notificationsLoading = useSelector(selectNotificationsLoading);
  const notificationsError = useSelector(selectNotificationsError);
  const loading = useLoading(
    projectsLoading,
    membersLoading,
    notificationsLoading
  );
  const error = useError(projectsError, membersError, notificationsError);
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showCreateProjectModal, hideCreateProjectModal] = useIonModal(
    AppWriteProjectModal,
    {
      project: null,
      saveProject: (projectForm) => {
        Promise.resolve()
          .then(() => appApis.writeProject(projectForm))
          .then(() => {
            showToast({
              message: i18nText.success.project.create(),
              duration: 2000,
            });
            hideCreateProjectModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideCreateProjectModal(),
    } as AppWriteProjectModalProps
  );
  // popover
  const [notificationsPopover, setNotificationsPopover] = useState<{
    show: boolean;
    event?: Event;
  }>({ show: false });

  const [taskNotificationPopover, setTaskNotificationPopover] = useState<{
    show: boolean;
    task?: Task;
    event?: Event;
  }>({ show: false });

  const [taskCommentNotificationPopover, setTaskCommentNotificationPopover] =
    useState<{
      show: boolean;
      taskComment?: TaskComment;
      event?: Event;
    }>({ show: false });

  return (
    <>
      <AppPage>
        <AppHeader>
          <AppToolbar>
            <AppButtons slot="start">
              <AppMenuButton />
            </AppButtons>
            <AppTitle>プロジェクト</AppTitle>
            {workspaceTaskAndTaskCommentNotificationsCount > 0 && (
              <AppButtons slot="end">
                <HeaderButton
                  onClick={(e) => {
                    setNotificationsPopover({
                      show: true,
                      event: e.nativeEvent,
                    });
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    icon={notificationsOutline}
                    color="primary"
                  />
                  <Badge>{workspaceTaskAndTaskCommentNotificationsCount}</Badge>
                </HeaderButton>
              </AppButtons>
            )}
          </AppToolbar>
        </AppHeader>
        <AppContent>
          <AppInnerContent>
            {loading && <AppLoadingCard />}
            {error && <AppErrorCard>{error}</AppErrorCard>}
            {!loading && !error && (
              <>
                {projects.length === 0 && (
                  <AppLead>
                    「プロジェクト作成」ボタンを選択しプロジェクトを作成してください
                  </AppLead>
                )}
                {projects.length > 0 && (
                  <AppCard>
                    <AppItemGroup>
                      {projects.map((project) => {
                        return (
                          <AppItem
                            button={true}
                            detail={true}
                            routerLink={appLinks.project(
                              workspaceId,
                              tabId,
                              project.id
                            )}
                            key={project.id}
                          >
                            <AppItemIcon icon={layersOutline} slot="start" />
                            <AppLabel>{project.name}</AppLabel>
                            <ProjectNotificationsCountBadge
                              projectId={project.id}
                            />
                            <AppReorder slot="end" />
                          </AppItem>
                        );
                      })}
                    </AppItemGroup>
                  </AppCard>
                )}
              </>
            )}
          </AppInnerContent>
        </AppContent>
        <AppFabButtons vertical="bottom" horizontal="end">
          {isWorkspaceAdmin && (
            <AppFabButton
              onClick={(e) =>
                showCreateProjectModal({
                  presentingElement: routerRef,
                  canDismiss: true,
                })
              }
            >
              <AppIcon slot="start" icon={addOutline} />
              プロジェクト作成
            </AppFabButton>
          )}
        </AppFabButtons>
      </AppPage>
      {/* popover */}
      <AppPopover
        isOpen={notificationsPopover.show}
        event={notificationsPopover.event}
        onDidDismiss={() => setNotificationsPopover({ show: false })}
        className="app-popover-width320"
      >
        <AppItemGroup>
          {workspaceNotifications.map((item) => {
            if (item.kind === "task") {
              const task: Task = item as Task;
              return (
                <NotificationItem
                  key={task.id}
                  button={true}
                  detail={false}
                  routerLink={appLinks.task(
                    workspaceId,
                    tabId,
                    task.projectId,
                    task.id
                  )}
                  onClick={() => setNotificationsPopover({ show: false })}
                >
                  <Avatar slot="start">
                    <img
                      src={formatMember(task.updatedBy, memberEntities).avatar}
                    />
                  </Avatar>
                  <AppLabel>
                    <NotificationTitle>{task.title}</NotificationTitle>
                    <NotificationNote>
                      {i18nText.push.assignedTask()}
                    </NotificationNote>
                    {task.updatedAt && (
                      <NotificationDate>
                        {dayjs(task.updatedAt.toDate()).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </NotificationDate>
                    )}
                  </AppLabel>
                  <ItemOptionsButton
                    fill="clear"
                    color="medium"
                    shape="round"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTaskNotificationPopover({
                        show: true,
                        task: task,
                        event: {
                          ...e.nativeEvent,
                          target: e.currentTarget,
                        },
                      });
                    }}
                  >
                    <ItemOptionsButtonIcon
                      icon={ellipsisVertical}
                      slot="icon-only"
                    />
                  </ItemOptionsButton>
                </NotificationItem>
              );
            }
            if (item.kind === "taskComment") {
              const taskComment: TaskComment = item as TaskComment;
              return (
                <NotificationItem
                  key={taskComment.id}
                  button={true}
                  detail={false}
                  routerLink={appLinks.taskComment(
                    workspaceId,
                    tabId,
                    taskComment.projectId,
                    taskComment.taskId,
                    taskComment.id
                  )}
                  onClick={() => setNotificationsPopover({ show: false })}
                >
                  <Avatar slot="start">
                    <img
                      src={
                        formatMember(taskComment.updatedBy, memberEntities)
                          .avatar
                      }
                    />
                  </Avatar>
                  <AppLabel>
                    <NotificationTitle>{taskComment.comment}</NotificationTitle>
                    <NotificationNote>{taskComment.taskTitle}</NotificationNote>
                    {taskComment.updatedAt && (
                      <NotificationDate>
                        {dayjs(taskComment.updatedAt.toDate()).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </NotificationDate>
                    )}
                  </AppLabel>
                  <ItemOptionsButton
                    fill="clear"
                    color="medium"
                    shape="round"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTaskCommentNotificationPopover({
                        show: true,
                        taskComment: taskComment,
                        event: {
                          ...e.nativeEvent,
                          target: e.currentTarget,
                        },
                      });
                    }}
                  >
                    <ItemOptionsButtonIcon
                      icon={ellipsisVertical}
                      slot="icon-only"
                    />
                  </ItemOptionsButton>
                </NotificationItem>
              );
            }
          })}
        </AppItemGroup>
      </AppPopover>

      {/*  Task popover */}
      <AppPopover
        event={taskNotificationPopover.event}
        isOpen={taskNotificationPopover.show}
        onDidDismiss={() => setTaskNotificationPopover({ show: false })}
        className="app-popover-width240"
      >
        {taskNotificationPopover.task && (
          <AppItemGroup>
            {taskNotificationPopover.task.unreadNotificationMemberId !==
              null && (
              <AppItem
                button={true}
                detail={false}
                onClick={() => {
                  setTaskNotificationPopover({ show: false });
                  if (!taskNotificationPopover.task) return;
                  const taskWorkspaceId =
                    taskNotificationPopover.task.workspaceId;
                  const taskId = taskNotificationPopover.task.id;
                  Promise.resolve()
                    .then(() =>
                      appApis.updateTaskAlreadyRead(taskWorkspaceId, taskId)
                    )
                    .catch((error) => {
                      showAlert({ message: i18nErrorToString(error) });
                    });
                }}
              >
                <AppItemIcon icon={notificationsOffOutline} />
                <AppLabel>既読にする</AppLabel>
              </AppItem>
            )}
            {PLATFORM.IS_DESKTOP && (
              <AppItem
                button={true}
                detail={false}
                onClick={() => {
                  if (!taskNotificationPopover.task) return;
                  const { workspaceId, projectId, id } =
                    taskNotificationPopover.task;
                  const link = appLinks.task(workspaceId, tabId, projectId, id);
                  const url = getRoot() + link;
                  window.open(url, "_blank");
                  setTaskNotificationPopover({ show: false });
                }}
              >
                <AppItemIcon icon={openOutline} />
                <AppLabel>新しいタブで開く</AppLabel>
              </AppItem>
            )}
          </AppItemGroup>
        )}
      </AppPopover>
      {/*  TaskComment popover */}
      <AppPopover
        event={taskCommentNotificationPopover.event}
        isOpen={taskCommentNotificationPopover.show}
        onDidDismiss={() => setTaskCommentNotificationPopover({ show: false })}
        className="app-popover-width240"
      >
        {taskCommentNotificationPopover.taskComment && (
          <AppItemGroup>
            {taskCommentNotificationPopover.taskComment.unreadMemberIds.some(
              (id) => id === auth?.id
            ) && (
              <AppItem
                button={true}
                detail={false}
                onClick={() => {
                  setTaskCommentNotificationPopover({ show: false });
                  if (!taskCommentNotificationPopover.taskComment) return;
                  const taskCommentWorkspaceId =
                    taskCommentNotificationPopover.taskComment.workspaceId;
                  const taskCommentId =
                    taskCommentNotificationPopover.taskComment.id;
                  Promise.resolve()
                    .then(() =>
                      appApis.updateTaskCommentAlreadyRead(
                        taskCommentWorkspaceId,
                        taskCommentId
                      )
                    )
                    .catch((error) => {
                      showAlert({ message: i18nErrorToString(error) });
                    });
                }}
              >
                <AppItemIcon icon={notificationsOffOutline} />
                <AppLabel>既読にする</AppLabel>
              </AppItem>
            )}
            {PLATFORM.IS_DESKTOP && (
              <AppItem
                button={true}
                detail={false}
                onClick={() => {
                  if (!taskCommentNotificationPopover.taskComment) return;
                  const { workspaceId, projectId, taskId, id } =
                    taskCommentNotificationPopover.taskComment;
                  const link = appLinks.taskComment(
                    workspaceId,
                    tabId,
                    projectId,
                    taskId,
                    id
                  );

                  const url = getRoot() + link;
                  window.open(url, "_blank");
                  setTaskCommentNotificationPopover({ show: false });
                }}
              >
                <AppItemIcon icon={openOutline} />
                <AppLabel>新しいタブで開く</AppLabel>
              </AppItem>
            )}
          </AppItemGroup>
        )}
      </AppPopover>
    </>
  );
};

export default TabProjectPage;

const ProjectNotificationsCountBadge: FunctionComponent<{
  projectId: string;
}> = ({ projectId }) => {
  const numberOfProjectUnreadNotifications = useSelector(
    selectProjectNotificationsCount(projectId)
  );
  return (
    <>
      {numberOfProjectUnreadNotifications > 0 && (
        <ItemBadge>{numberOfProjectUnreadNotifications}</ItemBadge>
      )}
    </>
  );
};
