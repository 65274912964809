import { useEffect, useState } from "react";
import { appApis } from "../apis/Api";
import { i18nErrorToString } from "../utilities/UtilI18nText";
import { Workspace } from "../models/Workspace";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { switchMap } from "rxjs/operators";

export const useMyWorkspaces = () => {
  // store
  const auth = useSelector(selectAuth);
  // states
  const [data, setData] = useState<Workspace[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth) {
      setData([]);
      setLoading(true);
      setError(null);
      return;
    }
    const subscribe = appApis
      .$readUser()
      .pipe(switchMap(() => appApis.getMyWorkspaces()))
      .subscribe({
        next: (workspaces) => {
          console.log("useMyWorkspaces");
          setData(workspaces);
          setLoading(false);
          setError(null);
        },
        error: (error) => {
          console.log(error);
          setData([]);
          setLoading(false);
          setError(i18nErrorToString(error));
        },
      });
    return () => subscribe.unsubscribe();
  }, [auth]);

  return { data, loading, error };
};
