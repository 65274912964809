import {
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  AppContent,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppErrorCard,
  AppLoadingCard,
  AppHeaderBackButton,
  AppButtons,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
  AppCard,
  AppItemGroup,
  AppItem,
  AppFormItem,
  AppAddComma,
  AppWriteProjectModal,
  AppWriteProjectModalProps,
  AppIcon,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useError, useLoading, useProject } from "../../../hooks";
import { TabId } from "../../../models";
import {
  selectMemberEntities,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import {
  selectProjectTaskMilestones,
  selectTaskMilestonesError,
  selectTaskMilestonesLoading,
} from "../../../store/taskMilestonesSlice";
import {
  selectProjectTaskStates,
  selectTaskStatesLoading,
  selectTaskStatesError,
} from "../../../store/taskStatesSlice";
import {
  selectProjectTaskTags,
  selectTaskTagsLoading,
  selectTaskTagsError,
} from "../../../store/taskTagsSlice";
import {
  selectTeams,
  selectTeamsError,
  selectTeamsLoading,
} from "../../../store/teamsSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { TEXT } from "../../../utilities/UtilStatic";
import {
  calendarOutline,
  checkmarkCircleOutline,
  layersOutline,
  peopleCircleOutline,
  peopleOutline,
  pricetagOutline,
} from "ionicons/icons";
import { formatMembers, formatItems } from "../../../utilities/UtilFunction";

const ProjectDetailsPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, projectId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    projectId: string;
  }>();
  // store
  const taskMilestones = useSelector(selectProjectTaskMilestones(projectId));
  const taskMilestonesLoading = useSelector(selectTaskMilestonesLoading);
  const taskMilestonesError = useSelector(selectTaskMilestonesError);
  const taskStates = useSelector(selectProjectTaskStates(projectId));
  const taskStatesLoading = useSelector(selectTaskStatesLoading);
  const taskStatesError = useSelector(selectTaskStatesError);
  const taskTags = useSelector(selectProjectTaskTags(projectId));
  const taskTagsLoading = useSelector(selectTaskTagsLoading);
  const taskTagsError = useSelector(selectTaskTagsError);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const teams = useSelector(selectTeams);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  // hooks
  const project = useProject({ workspaceId, projectId });
  const loading = useLoading(
    project.loading,
    taskMilestonesLoading,
    taskStatesLoading,
    taskTagsLoading,
    membersLoading,
    teamsLoading
  );
  const error = useError(
    project.error,
    taskMilestonesError,
    taskStatesError,
    taskTagsError,
    membersError,
    teamsError
  );
  // components
  const [showLoading, hideLoading] = useIonLoading();
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showWriteProjectModal, hideWriteProjectModal] = useIonModal(
    AppWriteProjectModal,
    {
      project: project.data,
      deleteProject: (workspaceId, projectId) => {
        showAlert({
          message: "プロジェクトを削除しますか？",
          buttons: [
            "キャンセル",
            {
              text: "Ok",
              handler: () => {
                showLoading({ cssClass: "app-loading", backdropDismiss: true });
                Promise.resolve()
                  .then(() => appApis.deleteProject(workspaceId, projectId))
                  .then(() => {
                    hideWriteProjectModal();
                    showToast({
                      message: i18nText.success.project.delete(),
                      duration: 2000,
                    });
                    history.goBack();
                  })
                  .catch((error) => {
                    showAlert({ message: i18nErrorToString(error) });
                  })
                  .finally(() => hideLoading());
              },
            },
          ],
        });
      },
      saveProject: (projectForm) => {
        Promise.resolve()
          .then(() => appApis.writeProject(projectForm))
          .then(() => {
            showToast({
              message: i18nText.success.project.update(),
              duration: 2000,
            });
            hideWriteProjectModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideWriteProjectModal(),
    } as AppWriteProjectModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>{i18nText.features.project()}</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && project.data && (
            <AppCard marginBottom="16px">
              <AppItemGroup>
                <AppItem>
                  <AppIcon
                    icon={layersOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="プロジェクト名">
                    {project.data.name}
                  </AppFormItem>
                </AppItem>
                {project.data.public === true && (
                  <AppItem>
                    <AppIcon
                      icon={peopleOutline}
                      style={{ marginRight: "16px" }}
                      color="medium"
                      slot="start"
                    />
                    <AppFormItem label="アクセス可能なメンバー">
                      ワークスペース内全てのメンバー
                    </AppFormItem>
                  </AppItem>
                )}
                {project.data.public === false && (
                  <>
                    <AppItem>
                      <AppIcon
                        icon={peopleOutline}
                        style={{ marginRight: "16px" }}
                        color="medium"
                        slot="start"
                      />
                      <AppFormItem label="アクセス可能なメンバー">
                        {project.data.memberIds.length === 0 && TEXT.EMPTY}
                        {formatMembers(
                          project.data.memberIds,
                          memberEntities
                        ).map(({ id, name }) => (
                          <AppAddComma key={id}>{name}</AppAddComma>
                        ))}
                      </AppFormItem>
                    </AppItem>
                    <AppItem>
                      <AppIcon
                        icon={peopleCircleOutline}
                        style={{ marginRight: "16px" }}
                        color="medium"
                        slot="start"
                      />
                      <AppFormItem label="アクセス可能なチーム">
                        {project.data.teamIds.length === 0 && TEXT.EMPTY}
                        {formatItems(project.data.teamIds, teams).map(
                          ({ id, name }) => (
                            <AppAddComma key={id}>{name}</AppAddComma>
                          )
                        )}
                      </AppFormItem>
                    </AppItem>
                  </>
                )}
                <AppItem>
                  <AppIcon
                    icon={checkmarkCircleOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="ステータス">
                    {taskStates.length === 0 && TEXT.EMPTY}
                    {taskStates.map(({ name, id }) => (
                      <AppAddComma key={id}>{name}</AppAddComma>
                    ))}
                  </AppFormItem>
                </AppItem>
                <AppItem>
                  <AppIcon
                    icon={calendarOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="マイルストーン">
                    {taskMilestones.length === 0 && TEXT.EMPTY}
                    {taskMilestones.map(({ name, id }) => (
                      <AppAddComma key={id}>{name}</AppAddComma>
                    ))}
                  </AppFormItem>
                </AppItem>
                <AppItem>
                  <AppIcon
                    icon={pricetagOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="タグ">
                    {taskTags.length === 0 && TEXT.EMPTY}
                    {taskTags.map(({ name, id }) => (
                      <AppAddComma key={id}>{name}</AppAddComma>
                    ))}
                  </AppFormItem>
                </AppItem>
              </AppItemGroup>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton
          defaultHref={appLinks.project(workspaceId, tabId, projectId)}
        />
        <AppActionButton
          action="ok"
          onClick={() => {
            showWriteProjectModal({
              presentingElement: routerRef,
              canDismiss: true,
            });
          }}
        >
          {i18nText.buttons.edit()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default ProjectDetailsPage;
