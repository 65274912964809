import { HTMLAttributes, FunctionComponent } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLSpanElement> {}

const AddComma = styled.span`
  &:not(:last-child) {
    margin-right: 6px;
    &::after {
      content: ",";
    }
  }
`;

const AppAddComma: FunctionComponent<Props> = ({ children, ...props }) => {
  return <AddComma {...props}>{children}</AddComma>;
};

export default AppAddComma;
