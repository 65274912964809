import {
  useIonAlert,
  useIonModal,
  useIonPopover,
  useIonToast,
  IonAvatar,
} from "@ionic/react";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppButton,
  AppButtons,
  AppContent,
  AppFabButtons,
  AppHeader,
  AppHeaderBackButton,
  AppIcon,
  AppLabel,
  AppSelectOfficeMemberTaskModal,
  AppSelectOfficeMemberTaskModalProps,
  AppPage,
  AppTitle,
  AppToolbar,
  AppWriteOfficeModal,
  AppWriteOfficeModalProps,
  AppItem,
  AppItemGroup,
  AppItemIcon,
  AppSelectPopoverProps,
  AppSelectPopover,
} from "../..";
import { appApis } from "../../../apis/Api";
import {
  useLoading,
  useError,
  useOfficeMembers,
  useTasks,
} from "../../../hooks";
import { selectAuth } from "../../../store/authSlice";
import {
  selectMembersLoading,
  selectMembersError,
  selectMembers,
  selectMemberEntities,
  selectMember,
  selectIsWorkspaceAdmin,
} from "../../../store/membersSlice";
import {
  selectOffice,
  selectOfficesError,
  selectOfficesLoading,
} from "../../../store/officesSlice";
import { i18nText, i18nRoleToText } from "../../../utilities/UtilI18nText";
import { appLinks } from "../../../utilities/UtilPage";
import { AVATAR, DAYJS, ISOMETRIC_OFFICE } from "../../../utilities/UtilStatic";
import { range } from "lodash";
import {
  formatMember,
  generateOfficeMember,
  getTimestampNow,
} from "../../../utilities/UtilFunction";
import {
  Color,
  Member,
  Office,
  OfficeMember,
  TabId,
  Task,
} from "../../../models";
import {
  ellipse,
  ellipseOutline,
  exitOutline,
  timeOutline,
  contractOutline,
  expandOutline,
  happyOutline,
  chevronDown,
  checkmarkDoneOutline,
  openOutline,
  caretForwardOutline,
} from "ionicons/icons";
import dayjs from "dayjs";
import { History } from "history";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { getColor } from "../../../utilities/UtilFunction";
import { selectTabId } from "../../../store/uiSlice";

const PADDING_TOP = 40;

const WrapIsometricContent = styled.div`
  /* border: 4px solid red; */
`;

const IsometricContent = styled.div<{
  width: number;
  height: number;
  scale: number;
}>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height, scale }) =>
    `${scale === 1 ? height + PADDING_TOP : height}px`}; // padding-top分プラス
  padding-top: ${({ scale }) => `${scale * PADDING_TOP}px`};
  transform: ${({ scale }) => `scale(${scale})`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 0.2s;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  transform-origin: 0 0;
`;

const Isometric = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: relative;
  transform-origin: center center;
  transform-style: preserve-3d;
  transform: rotateX(55deg) rotateZ(45deg);
  will-change: transform;
  box-shadow: 0px 0px 0 1px var(--app-color-grey200-rgba);
  * {
    transform-style: preserve-3d;
  }
`;

const IsometricTile = styled.div.attrs<{
  col: number;
  row: number;
  image: string | null;
}>(({ col, row, image }) => ({
  style: {
    left: `${ISOMETRIC_OFFICE.TILE_SIZE * col}px`,
    top: `${ISOMETRIC_OFFICE.TILE_SIZE * row}px`,
    backgroundImage: image ? `url(${image})` : `none`,
  },
}))<{
  col: number;
  row: number;
  image: string | null;
}>`
  width: 100px;
  height: 100px;
  position: absolute;
  background: var(--app-color-white);
  background-size: cover;
  position: absolute;
  box-shadow: 0px 0px 0 1px var(--app-color-grey200-rgba) inset;
  cursor: pointer;
  .plt-desktop &:hover::after,
  &:active::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--app-color-grey300-rgba);
    pointer-events: none;
  }
`;

const IsometricItem = styled.div.attrs<{
  col: number;
  row: number;
  image: string | null;
}>(({ col, row, image }) => ({
  style: {
    left: `${ISOMETRIC_OFFICE.TILE_SIZE * col}px`,
    top: `${ISOMETRIC_OFFICE.TILE_SIZE * row}px`,
    backgroundImage: image ? `url(${image})` : `none`,
    transform: `rotate(-45deg) scaleY(${ISOMETRIC_OFFICE.SCALE_Y}) translateZ(0.01px) scale(1)`,
  },
}))<{
  col: number;
  row: number;
  image: string | null;
}>`
  pointer-events: none;
  position: absolute;
  width: 212.1315px; // $boxSize = 150px * 1.41421（200pxはアイテムのサイズ）
  height: 212.1315px; // $boxSize = 150px * 1.41421（200pxはアイテムのサイズ）
  margin-left: -56.06575px; // -($boxSize - 100px)/2（100pxはタイルのサイズ）
  margin-top: -56.06575px; // -($boxSize - 100px)/2（100pxはタイルのサイズ）
  background-size: cover;
`;

const IsometricOfficeMember = styled.div.attrs<{
  col: number;
  row: number;
  image: string;
}>(({ col, row, image }) => ({
  style: {
    left: `${ISOMETRIC_OFFICE.TILE_SIZE * col}px`,
    top: `${ISOMETRIC_OFFICE.TILE_SIZE * row}px`,
    backgroundImage: `url(${image})`,
  },
}))<{
  col: number;
  row: number;
  image: string;
}>`
  position: absolute;
  width: 40px;
  height: 40px;
  margin-left: 20px; // ((100px - $iconSize) / 2) - 10; 30pxで中央。-10は少し中央から上にするため
  margin-top: 20px; // ((100px - $iconSize) / 2) - 10;
  will-change: transform;
  transform: ${`rotate(-45deg) scaleY(${ISOMETRIC_OFFICE.SCALE_Y}) translateZ(0.01px)`};
  cursor: pointer;
  transition: 0.4s left ease, 0.4s top ease, box-shadow 0.2s ease;
  border-radius: 50%;
  background-color: var(--app-color-blue-grey200);
  background-size: cover;
  &:hover {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.24);
  }
`;

const IsometricOfficeMemberState = styled.div.attrs<{
  bgColor: Color | null;
}>(({ bgColor }) => ({
  style: {
    background: getColor(bgColor).light,
  },
}))<{
  bgColor: Color | null;
}>`
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const OfficePage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const { workspaceId, tabId, officeId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    officeId: string;
  }>();
  const history = useHistory();
  // store
  const auth = useSelector(selectAuth);
  const member = useSelector(selectMember(auth?.id ?? null));
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const office = useSelector(selectOffice(officeId));
  const officesLoading = useSelector(selectOfficesLoading);
  const officesError = useSelector(selectOfficesError);
  const isWorkspaceAdmin = useSelector(selectIsWorkspaceAdmin);
  // hooks
  const officeMembers = useOfficeMembers({
    workspaceId,
    officeId,
  });
  // loading
  const loading = useLoading(
    membersLoading,
    officesLoading,
    officeMembers.loading
  );
  // error
  const error = useError(membersError, officesError, officeMembers.error);

  // states
  const contentRef = useRef<HTMLIonContentElement>(null);
  // 拡大縮小
  const [scaleParams, setScaleParams] = useState<{
    scale: number;
    scrollX: number;
    scrollY: number;
  }>({ scale: 1, scrollX: 0, scrollY: 0 });

  // functions
  const me = useMemo(() => {
    return officeMembers.data.find(({ id }) => id === auth?.id) ?? null;
  }, [officeMembers.data, auth]);

  const { isometricWidth, isometricHeight } = useMemo(() => {
    if (!office) return { isometricWidth: 0, isometricHeight: 0 };
    const tileWidth = ISOMETRIC_OFFICE.TILE_SIZE * office.col;
    const tileHeight = ISOMETRIC_OFFICE.TILE_SIZE * office.row;
    const isometricWidth =
      tileHeight * Math.cos(Math.PI / 4) + tileWidth * Math.cos(Math.PI / 4);
    const isometricHeight = isometricWidth / ISOMETRIC_OFFICE.SCALE_Y;
    return { isometricWidth, isometricHeight };
  }, [office]);

  const calcNextScale = useMemo(() => {
    if (contentRef.current?.offsetWidth && contentRef.current?.offsetHeight) {
      const contentWidth = contentRef.current.offsetWidth;
      const contentHeight = contentRef.current.offsetHeight;
      const widthScale = contentWidth / isometricWidth;
      const heightScale = contentHeight / isometricHeight;
      return widthScale < heightScale ? widthScale : heightScale;
    } else {
      return 1;
    }
  }, [
    contentRef.current?.offsetWidth,
    contentRef.current?.offsetHeight,
    isometricWidth,
    isometricHeight,
  ]);

  const getStateColor = useCallback(
    (memberStateId: string | null) => {
      const memberState = office?.memberStates.find(
        ({ id }) => id === memberStateId
      );
      if (memberState) {
        return memberState.color;
      } else {
        return null;
      }
    },
    [office]
  );

  // 初回読み込み時にスクロールし中央にオフィスを表示
  useEffect(() => {
    const contentWidth = contentRef.current?.offsetWidth ?? 0;
    if (!loading && contentWidth > 0 && isometricWidth > 0) {
      contentRef.current?.scrollByPoint(
        (isometricWidth - contentWidth) / 2,
        0,
        0
      );
    }
  }, [contentRef.current, isometricWidth, loading]);

  const officeLayouts = useMemo(() => {
    if (!office) return [];
    return Object.keys(office.layouts)
      .map((key) => {
        const decoration = office.layouts[key];
        const [col, row] = key.split("-").map((i) => Number(i));
        const images = decoration.items.map(
          (item) => `${ISOMETRIC_OFFICE.ITEMS_DIRECTORY}${item}.png`
        );
        return { col, row, images };
      })
      .sort((a, b) => a.row - b.row)
      .sort((a, b) => a.col - b.col)
      .map(({ col, row, images }, index) =>
        images.map((image, index2) => ({ col, row, image, index, index2 }))
      );
  }, [office?.layouts]);

  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showUpdateOfficeModal, hideUpdateOfficeModal] = useIonModal(
    AppWriteOfficeModal,
    {
      office,
      saveOffice: (form) => {
        if (!office || !auth) return;
        Promise.resolve()
          .then(() => appApis.updateOffice(form))
          .then(() => {
            showToast({
              message: i18nText.success.office.update(),
              duration: 2000,
            });
            hideUpdateOfficeModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      deleteOffice: (workspaceId, officeId) => {
        Promise.resolve()
          .then(() => appApis.deleteOffice(workspaceId, officeId))
          .then(() => {
            showToast({
              message: i18nText.success.office.delete(),
              duration: 2000,
            });
            hideUpdateOfficeModal();
            history.goBack();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideUpdateOfficeModal(),
      routerRef,
    } as AppWriteOfficeModalProps
  );

  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [showOfficeMemberPopover, hideOfficeMemberPopover] = useIonPopover(
    AppOfficeMemberPopover,
    {
      workspaceId,
      office,
      member: members.find(({ id }) => id === selectedMemberId) ?? null,
      officeMember:
        officeMembers.data.find(({ id }) => id === selectedMemberId) ?? null,
      history,
      onSelectTask: (task) => {
        history.push(
          appLinks.task(task.workspaceId, tabId, task.projectId, task.id)
        );
        hideOfficeMemberPopover();
      },
      onHide: () => {
        hideOfficeMemberPopover();
      },
      routerRef,
    } as AppOfficeMemberPopoverProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>{office && office.name}</AppTitle>
          <AppButtons slot="end">
            {office && isWorkspaceAdmin && (
              <AppButton
                color="primary"
                routerLink={appLinks.officeUpdate(workspaceId, tabId, officeId)}
              >
                編集
              </AppButton>
            )}
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent
        scrollX={scaleParams.scale === 1}
        scrollY={scaleParams.scale === 1}
        ref={contentRef}
        scrollEvents={scaleParams.scale === 1}
        onIonScroll={(e) => {
          setScaleParams({
            ...scaleParams,
            scrollX: e.detail.currentX,
            scrollY: e.detail.currentY,
          });
        }}
      >
        {!loading && !error && office && (
          <WrapIsometricContent>
            <IsometricContent
              width={isometricWidth}
              height={isometricHeight}
              scale={scaleParams.scale}
            >
              <Isometric
                width={ISOMETRIC_OFFICE.TILE_SIZE * office.col}
                height={ISOMETRIC_OFFICE.TILE_SIZE * office.row}
              >
                {range(office.col).map((col) =>
                  range(office.row).map((row) => (
                    <IsometricTile
                      key={`${col}-${row}`}
                      col={col}
                      row={row}
                      image={
                        office.layouts[`${col}-${row}`]?.tile
                          ? `${ISOMETRIC_OFFICE.TILES_DIRECTORY}${
                              office.layouts[`${col}-${row}`].tile
                            }.png`
                          : null
                      }
                      onClick={() => {
                        // 他のメンバーがいればリターン
                        const isSameCellMember = officeMembers.data.some(
                          (item) => item.col === col && item.row === row
                        );
                        if (isSameCellMember) return;
                        // 自分自身が既にそのセルにいれば更新の必要はないのでリターン
                        if (me?.officeId === officeId) {
                          if (me.col === col && me.row === row) return;
                          Promise.resolve()
                            .then(() => {
                              return appApis.updateOfficeMember({
                                workspaceId,
                                id: me.id,
                                col,
                                row,
                              });
                            })
                            .catch((error) => {
                              showAlert({
                                message: i18nErrorToString(error),
                              });
                            });
                        } else {
                          showAlert({
                            message: "オフィスに入室しますか？",
                            buttons: [
                              "キャンセル",
                              {
                                text: "Ok",
                                handler: () => {
                                  if (!member) return;
                                  Promise.resolve()
                                    .then(() => {
                                      return appApis.enterOffice({
                                        ...generateOfficeMember(
                                          workspaceId,
                                          member.id
                                        ),
                                        officeId,
                                        col,
                                        row,
                                      });
                                    })
                                    .then(() => {
                                      showToast({
                                        message:
                                          i18nText.success.office.enter(),
                                        duration: 2000,
                                      });
                                    })
                                    .catch((error) => {
                                      showAlert({
                                        message: i18nErrorToString(error),
                                      });
                                    });
                                },
                              },
                            ],
                          });
                        }
                      }}
                    />
                  ))
                )}
                {officeLayouts.map((officeLayout) =>
                  officeLayout.map(({ col, row, image, index, index2 }) => (
                    <IsometricItem
                      key={`${col}-${row}-${index}-${index2}`}
                      {...{ col, row, image }}
                    />
                  ))
                )}
                {officeMembers.data.map((officeMember) => {
                  return (
                    <IsometricOfficeMember
                      key={officeMember.id}
                      col={officeMember.col}
                      row={officeMember.row}
                      image={
                        formatMember(officeMember.id, memberEntities).avatar
                      }
                      onClick={(e) => {
                        setSelectedMemberId(officeMember.id);
                        // ポップが画面からはみ出しにくいように表示前にある程度タテ幅cssで指定
                        let contentCount = 2; // popを表示する前に大体の大きさをcssで指定（1つ40pxくらいの高さで雑に計算）
                        if (officeMember.stateId) contentCount += 1; // ステータス（仕事中など）
                        if (officeMember.taskIds.length > 0) {
                          contentCount += 2; // 「本日の作業予定」と「作業履歴」
                          contentCount += officeMember.taskIds.length; // 対応予定のタスク数
                        }
                        const cssClass: string[] = ["app-popover-width280"];
                        if (contentCount > 2) {
                          if (contentCount <= 8) {
                            cssClass.push(
                              `app-popover-min-height${contentCount * 40}`
                            );
                          } else {
                            cssClass.push("app-popover-height320");
                          }
                        } else {
                          cssClass.push("app-popover-min-height40");
                        }
                        showOfficeMemberPopover({
                          cssClass,
                          event: e.nativeEvent,
                        });
                      }}
                    >
                      {getStateColor(officeMember.stateId) && (
                        <IsometricOfficeMemberState
                          bgColor={getStateColor(officeMember.stateId)}
                        />
                      )}
                    </IsometricOfficeMember>
                  );
                })}
              </Isometric>
            </IsometricContent>
          </WrapIsometricContent>
        )}
      </AppContent>
      {calcNextScale < 1 && (
        <AppFabButtons slot="fixed" style={{ left: "6px", bottom: "4px" }}>
          <AppButton
            color="white"
            fill="clear"
            style={{ "--padding-start": "8px", "--padding-end": "8px" }}
            onClick={(e) => {
              if (scaleParams.scale === 1) {
                setScaleParams({
                  ...scaleParams,
                  scale: calcNextScale,
                });
                contentRef.current?.scrollToPoint(0, 0);
              } else {
                setScaleParams({ ...scaleParams, scale: 1 });
                contentRef.current?.scrollToPoint(
                  scaleParams.scrollX,
                  scaleParams.scrollY
                );
              }
            }}
          >
            <AppIcon
              slot="icon-only"
              icon={scaleParams.scale === 1 ? contractOutline : expandOutline}
              style={{ color: "var(--app-color-grey600-rgba)" }}
            />
          </AppButton>
        </AppFabButtons>
      )}
    </AppPage>
  );
};

export default OfficePage;

interface AppOfficeMemberPopoverProps {
  workspaceId: string;
  office: Office | null;
  member: Member | null;
  officeMember: OfficeMember | null;
  history: History;
  onSelectTask: (task: Task) => void;
  onHide: () => void;
  routerRef: HTMLElement;
}

const MemberContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
`;

const Avatar = styled(IonAvatar)`
  width: 56px;
  height: 56px;
  flex: 0 1 auto;
  margin: 8px 12px 8px 0;
`;

const MemberInfo = styled.div`
  display: flex;
  flex: 1 0 0%;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 4px 0;
`;

const MemberName = styled.div`
  font-size: 16px;
  line-height: 1.2;
`;

const MemberRole = styled.div`
  color: var(--app-color-grey600);
  font-size: 14px;
  line-height: 1.2;
`;

// popover
const AppOfficeMemberPopover: FunctionComponent<
  AppOfficeMemberPopoverProps
> = ({
  workspaceId,
  office,
  member,
  officeMember,
  history,
  onSelectTask,
  onHide,
  routerRef,
}) => {
  // store
  const auth = useSelector(selectAuth);
  const tabId = useSelector(selectTabId);
  const isWorkspaceAdmin = useSelector(selectIsWorkspaceAdmin);
  // hooks
  const todayTasks = useTasks({
    workspaceId: officeMember?.workspaceId ?? null,
    taskIds: officeMember?.taskIds ?? null,
  });
  //functions
  const officeMemberState = useMemo(() => {
    return (
      office?.memberStates.find(({ id }) => id === officeMember?.stateId) ??
      null
    );
  }, [officeMember, office]);
  const isMe = useMemo(() => {
    if (auth && officeMember) {
      return auth?.id === officeMember?.id;
    } else {
      return false;
    }
  }, [auth, officeMember]);

  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showSelectTasksModal, hideSelectTasksModal] = useIonModal(
    AppSelectOfficeMemberTaskModal,
    {
      officeMember: officeMember,
      selectOfficeMemberTask: ({
        workspaceId,
        memberId,
        taskIds,
        taskId,
        projectId,
        updatedBy,
        updatedAt,
      }) => {
        Promise.resolve()
          .then(() =>
            appApis.updateOfficeMember({
              workspaceId: workspaceId,
              id: memberId,
              taskIds,
              taskId,
              projectId,
              updatedBy,
              updatedAt,
            })
          )
          .then(() => {
            hideSelectTasksModal();
            showToast({
              message: i18nText.success.officeMemberState.update(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({
              message: i18nErrorToString(error),
            });
          });
      },
      hide: () => hideSelectTasksModal(),
    } as AppSelectOfficeMemberTaskModalProps
  );
  const [showSelectStatePopover, hideSelectStatePopover] = useIonPopover(
    AppSelectPopover,
    {
      items: office?.memberStates ?? [],
      selectedId: officeMember?.stateId,
      isSetNull: true,
      selectItem: (stateId: string | null) => {
        // hideSelectStatePopover();
        // if (stateId === officeMember?.stateId ) return;
        if (!officeMember) return;
        Promise.resolve()
          .then(() =>
            appApis.updateOfficeMember({
              workspaceId,
              id: officeMember.id,
              stateId,
              updatedBy: officeMember.id,
              updatedAt: getTimestampNow(),
            })
          )
          .then(() => {
            hideSelectStatePopover();
            showToast({
              message: i18nText.success.officeMemberState.update(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({
              message: i18nErrorToString(error),
            });
          });
      },
    } as AppSelectPopoverProps
  );

  return (
    <>
      {member && officeMember && office && (
        <>
          <AppItemGroup>
            <AppItem
              lines="full"
              button={true}
              detail={true}
              onClick={() => {
                history.push(
                  appLinks.memberDetails(workspaceId, tabId, member.id)
                );
                onHide();
              }}
            >
              <MemberContent>
                <Avatar>
                  <img src={member.avatar ?? AVATAR.DEFAULT} />
                </Avatar>
                <MemberInfo>
                  <MemberName>{member.name}</MemberName>
                  <MemberRole>{i18nRoleToText(member.role)}</MemberRole>
                </MemberInfo>
              </MemberContent>
            </AppItem>
            {/* ステータス */}
            {/* 自分のステータス */}
            {isMe && office.memberStates.length > 0 && (
              <AppItem
                lines="full"
                button={true}
                detail={false}
                onClick={(e) => {
                  showSelectStatePopover({
                    cssClass: ["app-popover-width240", "app-popover-height240"],
                    event: {
                      ...e.nativeEvent,
                      target: e.currentTarget,
                    },
                  });
                }}
              >
                {officeMemberState === null && (
                  <>
                    <AppItemIcon
                      icon={happyOutline}
                      slot="start"
                      style={{
                        color: "var(--app-color-grey600)",
                        marginRight: "16px",
                      }}
                    />
                    <AppLabel>ステータスを設定する</AppLabel>
                  </>
                )}
                {officeMemberState && (
                  <>
                    <AppItemIcon
                      icon={officeMemberState.color ? ellipse : ellipseOutline}
                      slot="start"
                      style={{
                        color: getColor(officeMemberState.color).light,
                        marginRight: "16px",
                      }}
                    />
                    <AppLabel>{officeMemberState.name}</AppLabel>
                  </>
                )}
                <AppItemIcon
                  icon={chevronDown}
                  slot="end"
                  style={{
                    marginRight: "0",
                    width: "20px",
                    height: "20px",
                    color: "var(--app-color-grey400)",
                  }}
                />
              </AppItem>
            )}
            {/* 他の人のステータス */}
            {isMe === false && officeMemberState && (
              <AppItem lines="full" button={false} detail={false}>
                <AppItemIcon
                  icon={officeMemberState?.color ? ellipse : ellipseOutline}
                  slot="start"
                  style={{
                    color: getColor(officeMemberState.color).light,
                    marginRight: "16px",
                  }}
                />
                <AppLabel>{officeMemberState.name}</AppLabel>
              </AppItem>
            )}
            {/* 本日のタスク */}
            <AppItemGroup>
              {/* 自分 */}
              {isMe && (
                <AppItem
                  button={true}
                  detail={false}
                  onClick={() => {
                    showSelectTasksModal({
                      presentingElement: routerRef,
                      canDismiss: true,
                    });
                  }}
                >
                  {todayTasks.data.length === 0 && (
                    <>
                      <AppItemIcon
                        icon={checkmarkDoneOutline}
                        slot="start"
                        style={{
                          color: "var(--app-color-grey600)",
                          marginRight: "16px",
                        }}
                      />
                      <AppLabel>作業予定のタスクを設定する</AppLabel>
                    </>
                  )}
                  {todayTasks.data.length > 0 && (
                    <>
                      <AppItemIcon
                        icon={checkmarkDoneOutline}
                        slot="start"
                        style={{ marginRight: "16px" }}
                      />
                      <AppLabel>作業予定のタスク選択</AppLabel>
                    </>
                  )}
                  <AppItemIcon
                    icon={openOutline}
                    slot="end"
                    style={{
                      marginRight: "0",
                      width: "20px",
                      height: "20px",
                      color: "var(--app-color-grey400)",
                    }}
                  />
                </AppItem>
              )}
              {/* 他人 */}
              {!isMe && (
                <AppItem button={false} detail={false}>
                  <AppItemIcon
                    icon={checkmarkDoneOutline}
                    slot="start"
                    style={{ marginRight: "16px" }}
                  />
                  <AppLabel>作業予定のタスク</AppLabel>
                </AppItem>
              )}
              {todayTasks.data.map((task) => (
                <AppItem
                  key={task.id}
                  button={true}
                  detail={true}
                  onClick={() => onSelectTask(task)}
                >
                  <AppItemIcon
                    icon={caretForwardOutline}
                    slot="start"
                    style={{
                      marginRight: "16px",
                      opacity: officeMember.taskId === task.id ? 1 : 0,
                      // color: "var(--app-color-grey400)",
                      height: "16px",
                    }}
                  />
                  <AppLabel>{task.title}</AppLabel>
                </AppItem>
              ))}
            </AppItemGroup>
            {isWorkspaceAdmin && (
              <AppItem
                lines="none"
                button={true}
                detail={true}
                style={{ borderTop: "1px solid var(--app-color-grey300)" }}
                onClick={(e) => {
                  const date = dayjs().format(DAYJS.YYYY_MM_DD);
                  history.push(
                    appLinks.dailyOfficeMemberHistories(
                      workspaceId,
                      tabId,
                      date,
                      member.id
                    )
                  );
                  onHide();
                }}
              >
                <AppItemIcon icon={timeOutline} slot="start" />
                <AppLabel>作業履歴</AppLabel>
              </AppItem>
            )}
            {isMe && (
              <AppItem
                lines="none"
                button={true}
                detail={false}
                style={{ borderTop: "1px solid var(--app-color-grey300)" }}
                onClick={() => {
                  if (!auth) return;
                  Promise.resolve()
                    .then(() => appApis.leaveOffice(workspaceId, auth.id))
                    .then(() => {
                      showToast({
                        message: i18nText.success.office.leave(),
                        duration: 2000,
                      });
                      onHide();
                    })
                    .catch((error) => {
                      showAlert({
                        message: i18nErrorToString(error),
                      });
                    });
                }}
              >
                <AppItemIcon icon={exitOutline} slot="start" />
                <AppLabel>退室</AppLabel>
              </AppItem>
            )}
          </AppItemGroup>
        </>
      )}
    </>
  );
};
