import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { addOutline, closeOutline, removeCircle } from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import { appApis } from "../../../apis/Api";
import {
  AppContent,
  AppFooterButtons,
  AppActionButton,
  AppHeader,
  AppToolbar,
  AppButtons,
  AppButton,
  AppIcon,
  AppTitle,
  AppInnerContent,
  AppPage,
  AppAddComma,
  AppCard,
  AppCardContent,
  AppCardHeader,
  AppCardHeaderText,
  AppFormButton,
  AppFormInput,
  AppItem,
  AppLabel,
  AppReorder,
  AppReorderGroup,
  AppSelectDatePopover,
  AppSelectDatePopoverProps,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppSelectsPopover,
  AppSelectsPopoverProps,
} from "../..";
import { Project } from "../../../models/Project";
import { ProjectForm } from "../../../models";
import {
  formatMembers,
  formatItems,
  generateProject,
  generateTaskMilestone,
  generateTaskState,
  generateTaskStates,
  generateTaskTag,
} from "../../../utilities/UtilFunction";
import { ACTION, TEXT } from "../../../utilities/UtilStatic";
import { useSelector } from "react-redux";
import { selectProjectTaskStates } from "../../../store/taskStatesSlice";
import { selectProjectTaskMilestones } from "../../../store/taskMilestonesSlice";
import { selectProjectTaskTags } from "../../../store/taskTagsSlice";
import { selectAuth } from "../../../store/authSlice";
import {
  selectMemberEntities,
  selectMembers,
} from "../../../store/membersSlice";
import { selectTeams } from "../../../store/teamsSlice";
import { cloneDeep } from "lodash";
import { ItemReorderEventDetail, useIonPopover } from "@ionic/react";
import dayjs from "dayjs";
import {
  generateAllowMemberIds,
  getTimestampNow,
} from "../../../utilities/UtilFunction";
import styled from "styled-components";
import { selectWorkspaceId } from "../../../store/uiSlice";

export interface AppWriteProjectModalProps {
  project: Project | null;
  deleteProject?: (workspaceId: string, projectId: string) => void;
  saveProject: (form: ProjectForm) => void;
  hide: () => void;
}

const Inputs = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding: 8px 0;
`;

const InputName = styled.div`
  flex-grow: 1;
`;

const SelectDate = styled.div`
  min-width: 160px;
`;

const WrapAddButton = styled.div`
  padding: 8px 0 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const AppWriteProjectModal: FunctionComponent<AppWriteProjectModalProps> = ({
  project,
  deleteProject,
  saveProject,
  hide,
}) => {
  const projectId = useMemo(() => project?.id ?? "", [project]);
  // store
  const workspaceId = useSelector(selectWorkspaceId);
  const auth = useSelector(selectAuth);
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  const teams = useSelector(selectTeams);
  const taskStates = useSelector(selectProjectTaskStates(projectId));
  const taskMilestones = useSelector(selectProjectTaskMilestones(projectId));
  const taskTags = useSelector(selectProjectTaskTags(projectId));
  // states
  const [form, setForm] = useState<ProjectForm | null>(null);
  // functions フォームを初期化
  useEffect(() => {
    if (!auth) return;
    if (project) {
      const data: ProjectForm = {
        action: ACTION.UPDATE,
        project: {
          ...project,
          updatedBy: auth.id,
          updatedAt: getTimestampNow(),
        },
        taskStates: taskStates.map((taskState) => {
          return {
            ...taskState,
            action: ACTION.UPDATE,
            updatedBy: auth.id,
            updatedAt: getTimestampNow(),
          };
        }),
        taskMilestones: taskMilestones.map((taskMilestone) => {
          return {
            ...taskMilestone,
            action: ACTION.UPDATE,
            updatedBy: auth.id,
            updatedAt: getTimestampNow(),
          };
        }),
        taskTags: taskTags.map((taskTag) => {
          return {
            ...taskTag,
            action: ACTION.UPDATE,
            updatedBy: auth.id,
            updatedAt: getTimestampNow(),
          };
        }),
      };
      setForm(data);
    } else {
      const projectId = appApis.createId();
      const form: ProjectForm = {
        action: ACTION.CREATE,
        project: generateProject(
          workspaceId,
          projectId,
          auth.id,
          members.map(({ id }) => id)
        ),
        taskStates: generateTaskStates(workspaceId, projectId, auth.id, [
          appApis.createId(),
          appApis.createId(),
          appApis.createId(),
        ]).map((item) => ({ ...item, action: ACTION.CREATE })),
        taskMilestones: [],
        taskTags: [],
      };
      setForm(form);
    }
  }, [project, auth]);
  // components / 公開選択
  const [showSelectPublicPopover, hideSelectPublicPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: [
        { id: true, name: "ワークスペース内全てのメンバー" },
        { id: false, name: "選択したメンバー・チームのみ" },
      ],
      selectedId: form?.project.public,
      selectItem: (_public: boolean) => {
        if (!form) return;
        const cloneProjectForm = cloneDeep(form);
        const cloneProject = cloneProjectForm.project;
        cloneProject.public = _public;
        setForm(cloneProjectForm);
        hideSelectPublicPopover();
      },
      isSetNull: false,
    } as AppSelectPopoverProps
  );
  // components / メンバー選択
  const [showSelectMembersPopover] = useIonPopover(AppSelectsPopover, {
    items: members,
    selectedIds: form?.project.memberIds,
    selectItems: (memberIds: string[]) => {
      if (!form) return;
      const cloneProjectForm = cloneDeep(form);
      cloneProjectForm.project.memberIds = memberIds;
      setForm(cloneProjectForm);
    },
    filterKeywords: ["name", "email"],
  } as AppSelectsPopoverProps);
  // components / チーム選択
  const [showSelectTeamsPopover] = useIonPopover(AppSelectsPopover, {
    items: teams,
    selectedIds: form?.project.teamIds,
    selectItems: (teamIds: string[]) => {
      if (!form) return;
      const cloneProjectForm = cloneDeep(form);
      cloneProjectForm.project.teamIds = teamIds;
      setForm(cloneProjectForm);
    },
    filterKeywords: ["name"],
  } as AppSelectsPopoverProps);
  // components / マイルストーンの日付選択
  const [selectedTaskMilestoneDate, setSelectedTaskMilestoneDate] = useState<{
    id: string;
    date: string | null;
  } | null>(null);
  const [showStartDatePopover, hideStartDatePopover] = useIonPopover(
    AppSelectDatePopover,
    {
      selectedDate: selectedTaskMilestoneDate?.date ?? null,
      selectDate: (date: string) => {
        hideStartDatePopover();
        if (!form || !selectedTaskMilestoneDate) return;
        const cloneProjectForm = cloneDeep(form);
        const cloneTaskMilestone = cloneProjectForm.taskMilestones.find(
          ({ id }) => id === selectedTaskMilestoneDate.id
        );
        if (!cloneTaskMilestone) return;
        cloneTaskMilestone.date = date;
        setForm(cloneProjectForm);
      },
      clearDate: () => {
        hideStartDatePopover();
        if (!form || !selectedTaskMilestoneDate) return;
        const cloneProjectForm = cloneDeep(form);
        const cloneTaskMilestone = cloneProjectForm.taskMilestones.find(
          ({ id }) => id === selectedTaskMilestoneDate.id
        );
        if (!cloneTaskMilestone) return;
        cloneTaskMilestone.date = null;
        setForm(cloneProjectForm);
      },
    } as AppSelectDatePopoverProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>{i18nText.features.project()}</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {auth && form && (
            <>
              <AppCard marginBottom="16px">
                <AppCardContent>
                  <AppFormInput
                    value={form.project.name}
                    onIonChange={(e) => {
                      const cloneProjectForm = cloneDeep(form);
                      cloneProjectForm.project.name = e.detail.value ?? "";
                      setForm(cloneProjectForm);
                    }}
                    type="text"
                    label="プロジェクト名"
                    marginBottom="8px"
                    name="projectName"
                  />
                  <AppFormButton
                    label="アクセス可能なメンバー"
                    onClick={(e) => {
                      showSelectPublicPopover({
                        cssClass: ["app-popover-width320"],
                        event: { ...e.nativeEvent, target: e.currentTarget },
                      });
                    }}
                    marginBottom="8px"
                  >
                    <AppLabel>
                      {form.project.public === true
                        ? "ワークスペース内全てのメンバー"
                        : "選択したメンバー・チームのみ"}
                    </AppLabel>
                  </AppFormButton>
                  {form.project.public === false && (
                    <>
                      <AppFormButton
                        label="アクセス可能なメンバー"
                        onClick={(e) => {
                          showSelectMembersPopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                        helperText="アクセス可能なメンバーを選択してください"
                        marginBottom="8px"
                      >
                        {form.project.memberIds.length === 0 && (
                          <AppLabel>{TEXT.EMPTY}</AppLabel>
                        )}
                        {formatMembers(
                          form.project.memberIds,
                          memberEntities
                        ).map(({ id, name }) => (
                          <AppAddComma key={id}>
                            <AppLabel>{name}</AppLabel>
                          </AppAddComma>
                        ))}
                      </AppFormButton>
                      <AppFormButton
                        label="アクセス可能なチーム"
                        onClick={(e) => {
                          showSelectTeamsPopover({
                            cssClass: [
                              "app-popover-width240",
                              "app-popover-height320",
                            ],
                            event: {
                              ...e.nativeEvent,
                              target: e.currentTarget,
                            },
                          });
                        }}
                        helperText="アクセス可能なチームを選択してください"
                        marginBottom="8px"
                      >
                        {form.project.teamIds.length === 0 && (
                          <AppLabel>{TEXT.EMPTY}</AppLabel>
                        )}
                        {formatItems(form.project.teamIds, teams).map(
                          ({ id, name }) => (
                            <AppAddComma key={id}>
                              <AppLabel>{name}</AppLabel>
                            </AppAddComma>
                          )
                        )}
                      </AppFormButton>
                    </>
                  )}
                </AppCardContent>
              </AppCard>
              {/* taskStates */}
              <AppCard marginBottom="16px">
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>ステータス</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={false}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    const cloneProjectForm = cloneDeep(form);
                    const activeTaskStates = cloneProjectForm.taskStates.filter(
                      ({ action }) => action !== ACTION.DELETE
                    );
                    const deleteTaskStates = cloneProjectForm.taskStates.filter(
                      ({ action }) => action === ACTION.DELETE
                    );
                    const draggedItem = activeTaskStates.splice(
                      event.detail.from,
                      1
                    )[0];
                    activeTaskStates.splice(event.detail.to, 0, draggedItem);
                    cloneProjectForm.taskStates = [
                      ...activeTaskStates.map((item, index) => ({
                        ...item,
                        order: index,
                      })),
                      ...deleteTaskStates,
                    ];
                    setForm(cloneProjectForm);
                    event.detail.complete();
                  }}
                >
                  {form.taskStates
                    .filter(({ action }) => action !== ACTION.DELETE)
                    .map(({ id, name, done }) => (
                      <AppItem key={id} lines="none">
                        <AppIcon
                          slot="start"
                          icon={removeCircle}
                          color={done ? "light" : "danger"}
                          onClick={() => {
                            // actionがcreateだと配列から削除。それ以外だとactionをdeleteに変更。
                            if (done) return;
                            const cloneProjectForm = cloneDeep(form);
                            const cloneTaskState =
                              cloneProjectForm.taskStates.find(
                                (cloneTaskState) => cloneTaskState.id === id
                              );
                            if (cloneTaskState === undefined) return;
                            if (cloneTaskState.action === ACTION.CREATE) {
                              cloneProjectForm.taskStates =
                                cloneProjectForm.taskStates.filter(
                                  ({ id }) => id !== cloneTaskState.id
                                );
                            } else {
                              cloneTaskState.action = ACTION.DELETE;
                            }
                            setForm(cloneProjectForm);
                          }}
                          style={{ marginRight: "16px" }}
                        />
                        <Inputs>
                          <InputName>
                            <AppFormInput
                              value={name}
                              onIonChange={(e) => {
                                const cloneProjectForm = cloneDeep(form);
                                const cloneTaskState =
                                  cloneProjectForm.taskStates.find(
                                    (cloneTaskState) => cloneTaskState.id === id
                                  );
                                if (cloneTaskState === undefined) return;
                                cloneTaskState.name = e.detail.value ?? "";
                                setForm(cloneProjectForm);
                              }}
                              type="text"
                              name="taskStateName"
                            />
                          </InputName>
                        </Inputs>
                        <AppReorder slot="end" style={{ marginLeft: "16px" }} />
                      </AppItem>
                    ))}
                </AppReorderGroup>
                <WrapAddButton>
                  <AppActionButton
                    onClick={() => {
                      if (!auth) return;
                      const cloneProjectForm = cloneDeep(form);
                      cloneProjectForm.taskStates.push({
                        action: ACTION.CREATE,
                        ...generateTaskState(
                          cloneProjectForm.project.workspaceId,
                          cloneProjectForm.project.id,
                          auth.id
                        ),
                      });
                      setForm(cloneProjectForm);
                    }}
                  >
                    <AppIcon slot="start" icon={addOutline} />
                    追加
                  </AppActionButton>
                </WrapAddButton>
              </AppCard>
              {/* taskMilestones */}
              <AppCard marginBottom="16px">
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>マイルストーン</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={false}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    const cloneProjectForm = cloneDeep(form);
                    const activeCloneTaskMilestones =
                      cloneProjectForm.taskMilestones.filter(
                        ({ action }) => action !== ACTION.DELETE
                      );
                    const deleteCloneTaskMilestones =
                      cloneProjectForm.taskMilestones.filter(
                        ({ action }) => action === ACTION.DELETE
                      );
                    const draggedItem = activeCloneTaskMilestones.splice(
                      event.detail.from,
                      1
                    )[0];
                    activeCloneTaskMilestones.splice(
                      event.detail.to,
                      0,
                      draggedItem
                    );
                    cloneProjectForm.taskMilestones = [
                      ...activeCloneTaskMilestones.map((item, index) => ({
                        ...item,
                        order: index,
                      })),
                      ...deleteCloneTaskMilestones,
                    ];
                    setForm(cloneProjectForm);
                    event.detail.complete();
                  }}
                >
                  {form.taskMilestones
                    .filter(({ action }) => action !== ACTION.DELETE)
                    .map(({ id, name, date }) => (
                      <AppItem key={id} lines="none">
                        <AppIcon
                          slot="start"
                          icon={removeCircle}
                          color="danger"
                          onClick={() => {
                            // actionがcreateだと配列から削除。それ以外だとactionをdeleteに変更。
                            const cloneProjectForm = cloneDeep(form);
                            const cloneTaskMilestone =
                              cloneProjectForm.taskMilestones.find(
                                (taskMilestone) => taskMilestone.id === id
                              );
                            if (cloneTaskMilestone === undefined) return;
                            if (cloneTaskMilestone.action === ACTION.CREATE) {
                              cloneProjectForm.taskMilestones =
                                cloneProjectForm.taskMilestones.filter(
                                  (taskMilestone) => taskMilestone.id !== id
                                );
                            } else {
                              cloneTaskMilestone.action = ACTION.DELETE;
                            }
                            setForm(cloneProjectForm);
                          }}
                          style={{ marginRight: "16px" }}
                        />
                        <Inputs>
                          <InputName>
                            <AppFormInput
                              value={name}
                              onIonChange={(e) => {
                                const cloneProjectForm = cloneDeep(form);
                                const cloneTaskMilestone =
                                  cloneProjectForm.taskMilestones.find(
                                    (taskMilestone) => taskMilestone.id === id
                                  );
                                if (cloneTaskMilestone === undefined) return;
                                cloneTaskMilestone.name = e.detail.value ?? "";
                                setForm(cloneProjectForm);
                              }}
                              type="text"
                              name="taskMilestoneName"
                            />
                          </InputName>
                          <SelectDate>
                            <AppFormButton
                              onClick={(e) => {
                                setSelectedTaskMilestoneDate({ id, date });
                                showStartDatePopover({
                                  cssClass: [
                                    "app-popover-width320",
                                    "app-popover-height320",
                                  ],
                                  event: {
                                    ...e.nativeEvent,
                                    target: e.currentTarget,
                                  },
                                });
                              }}
                            >
                              {!date && <AppLabel>{TEXT.EMPTY}</AppLabel>}
                              {date && (
                                <AppLabel>
                                  {dayjs(date ?? undefined).format(
                                    "YYYY-MM-DD"
                                  )}
                                </AppLabel>
                              )}
                            </AppFormButton>
                          </SelectDate>
                        </Inputs>
                        <AppReorder slot="end" style={{ marginLeft: "16px" }} />
                      </AppItem>
                    ))}
                </AppReorderGroup>
                <WrapAddButton>
                  <AppActionButton
                    onClick={() => {
                      if (!auth) return;
                      const cloneProjectForm = cloneDeep(form);
                      cloneProjectForm.taskMilestones.push({
                        action: ACTION.CREATE,
                        ...generateTaskMilestone(
                          form.project.workspaceId,
                          form.project.id,
                          auth.id
                        ),
                      });
                      setForm(cloneProjectForm);
                    }}
                  >
                    <AppIcon slot="start" icon={addOutline} />
                    追加
                  </AppActionButton>
                </WrapAddButton>
              </AppCard>
              {/* taskTags */}
              <AppCard>
                <AppCardHeader marginBottom="8px">
                  <AppCardHeaderText>タグ</AppCardHeaderText>
                </AppCardHeader>
                <AppReorderGroup
                  disabled={false}
                  onIonItemReorder={(
                    event: CustomEvent<ItemReorderEventDetail>
                  ) => {
                    const cloneProjectForm = cloneDeep(form);
                    const activeCloneTaskTags =
                      cloneProjectForm.taskTags.filter(
                        ({ action }) => action !== ACTION.DELETE
                      );
                    const deleteCloneTaskTags =
                      cloneProjectForm.taskTags.filter(
                        ({ action }) => action === ACTION.DELETE
                      );
                    const draggedItem = activeCloneTaskTags.splice(
                      event.detail.from,
                      1
                    )[0];
                    activeCloneTaskTags.splice(event.detail.to, 0, draggedItem);
                    cloneProjectForm.taskTags = [
                      ...activeCloneTaskTags.map((item, index) => ({
                        ...item,
                        order: index,
                      })),
                      ...deleteCloneTaskTags,
                    ];
                    event.detail.complete();
                  }}
                >
                  {form.taskTags
                    .filter(({ action }) => action !== ACTION.DELETE)
                    .map(({ id, name }) => (
                      <AppItem key={id} lines="none">
                        <AppIcon
                          slot="start"
                          icon={removeCircle}
                          color="danger"
                          onClick={() => {
                            // actionがcreateだと配列から削除。それ以外だとactionをdeleteに変更。
                            const cloneProjectForm = cloneDeep(form);
                            const cloneTaskTag = cloneProjectForm.taskTags.find(
                              (taskTag) => taskTag.id === id
                            );
                            if (cloneTaskTag === undefined) return;
                            if (cloneTaskTag.action === ACTION.CREATE) {
                              cloneProjectForm.taskTags =
                                cloneProjectForm.taskTags.filter(
                                  (taskTag) => taskTag.id !== id
                                );
                            } else {
                              cloneTaskTag.action = ACTION.DELETE;
                            }
                            setForm(cloneProjectForm);
                          }}
                          style={{ marginRight: "16px" }}
                        />
                        <Inputs>
                          <InputName>
                            <AppFormInput
                              value={name}
                              onIonChange={(e) => {
                                const cloneProjectForm = cloneDeep(form);
                                const cloneTaskTag =
                                  cloneProjectForm.taskTags.find(
                                    (taskTag) => taskTag.id === id
                                  );
                                if (cloneTaskTag === undefined) return;
                                cloneTaskTag.name = e.detail.value ?? "";
                                setForm(cloneProjectForm);
                              }}
                              type="text"
                              name="taskTagName"
                            />
                          </InputName>
                        </Inputs>
                        <AppReorder slot="end" style={{ marginLeft: "16px" }} />
                      </AppItem>
                    ))}
                </AppReorderGroup>
                <WrapAddButton>
                  <AppActionButton
                    onClick={() => {
                      if (!auth) return;
                      const cloneProjectForm = cloneDeep(form);
                      cloneProjectForm.taskTags.push({
                        action: ACTION.CREATE,
                        ...generateTaskTag(
                          form.project.workspaceId,
                          form.project.id,
                          auth.id
                        ),
                      });
                      setForm(cloneProjectForm);
                    }}
                  >
                    <AppIcon slot="start" icon={addOutline} />
                    追加
                  </AppActionButton>
                </WrapAddButton>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        {project && (
          <AppActionButton
            action="delete"
            onClick={() => {
              if (!form || !deleteProject) return;
              deleteProject(workspaceId, form.project.id);
            }}
          >
            {i18nText.buttons.delete()}
          </AppActionButton>
        )}
        <AppActionButton
          action="ok"
          onClick={() => {
            if (!form) return;
            const project = {
              ...form.project,
              ...generateAllowMemberIds(
                form.project.public,
                form.project.memberIds,
                form.project.teamIds,
                members,
                teams
              ),
            };
            saveProject({
              ...form,
              project,
            });
          }}
        >
          {i18nText.buttons.save()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppWriteProjectModal;
