import { ManipulateType } from "dayjs";
import { Action, Color, Plan, TabId, Timezone } from "../models";
import { Role } from "../models/types/Role";
import { isPlatform } from "@ionic/core";

// pages
export const PAGES = {
  _LANGUAGE: ":language",
  FEATURES: "features",
  PLANS: "plans",
  CONTACT: "contact",
  TEAMS: "terms",
  PRIVACY_POLICY: "privacy-policy",
  COMMERCE: "commerce",
  SIGNIN: "signin",
  SIGNUP: "signup",
  INVITATION: "invitation",
  _INVITATION_ID: ":invitationId",
  _TAB: ":tabId",
  WORKSPACE: "workspace",
  _WORKSPACE_ID: ":workspaceId",
  PROJECT: "project",
  _PROJECT_ID: ":projectId",
  UPDATE: "update",
  DETAILS: "details",
  TASK: "task",
  _TASK_ID: ":taskId",
  TASKS: "tasks",
  TASK_LIST: "list",
  COMMENT: "comment",
  _TASK_COMMENT_ID: ":taskCommentId",
  OFFICE: "office",
  _OFFICE_ID: ":officeId",
  TASK_BOOKMARKS: "task-bookmarks",
  TEAM: "team",
  _TEAM_ID: ":teamId",
  MEMBER: "member",
  _MEMBER_ID: ":memberId",
  OFFICE_MEMBER_HISTORIES: "office-member-histories",
  OFFICE_MEMBERS_HISTORIES: "office-members-histories",
  DATE: "date",
  _DATE: ":date",
  MONTH: "month",
  _MONTH: ":month",
  ACCOUNT: "account",
  EMAIL: "email",
  PASSWORD: "password",
  TABS: {
    PROJECT: "pj" as TabId,
    MYPAGE: "my" as TabId,
    OFFICE: "of" as TabId,
    MEMBER: "mb" as TabId,
    INVITATION: "iv" as TabId,
    OFFICE_MEMBERS_HISTORIES: "oh" as TabId,
    MASTER: "ms" as TabId,
    SUBSCRIPTION: "ss" as TabId,
  },
};

// firestore collections names
export const FIRESTORE = {
  // collections
  USERS: "users",
  DEVICES: "devices",
  WORKSPACES: "workspaces",
  INVITATIONS: "invitations",
  MEMBERS: "members",
  PROJECTS: "projects",
  TASKS: "tasks",
  TASK_HISTORIES: "taskHistories",
  TASK_COMMENTS: "taskComments",
  TASK_STATES: "taskStates",
  TASK_MILESTONES: "taskMilestones",
  TASK_TAGS: "taskTags",
  TASK_FILES: "taskFiles",
  CHATS: "chats",
  GROUP_CHAT: "groupChats",
  ATTENDING_GROUP_CHAT: "attendingGroupChats",
  TEAMS: "teams",
  TASK_BOOKMARKS: "taskBookmarks",
  OFFICES: "offices",
  OFFICE_MEMBERS: "officeMembers",
  OFFICE_MEMBER_HISTORIES: "officeMemberHistories",
  // app
  PUBLICS: "publics",
  APP_CONFIG: "appConfig",
  // stripe
  STRIPE_CUSTOMERS: "stripeCustomers",
  STRIPE_SUBSCRIPTIONS: "subscriptions",
  STRIPE_PRODUCTS: "stripeProducts",
  STRIPE_PRICES: "prices",
  STRIPE_CHECKOUT_SESSIONS: "checkout_sessions",
  // cloudFunctionsで使う引数
  _USER_ID: "{userId}",
  _WORKSPACE_ID: "{workspaceId}",
  _MEMBER_ID: "{memberId}",
  _OFFICE_MEMBER_ID: "{officeMemberId}",
  _TASK_ID: "{taskId}",
  _TASK_COMMENT_ID: "{taskCommentId}",
  _ID: "{id}",
  _: "{_}",
};

export const STORAGE_DIRECTORY = {
  WORKSPACES: "workspaces",
  PROJECTS: "projects",
  TASKS: "tasks",
};

// firebase cloudfunctions names
export const CLOUDFUNCTIONS = {
  CREATE_WORKSPACE: "call-createWorkspace",
  DELETE_WORKSPACE: "call-deleteWorkspace",
  UPDATE_MEMBER: "call-updateMember",
  DELETE_MEMBER: "call-deleteMember",
  DELETE_ACCOUNT: "call-deleteAccount",
  DELETE_PROJECT: "call-deleteProject",
  DELETE_TASK: "call-deleteTask",
  CREATE_INVITATIONS: "call-createInvitations",
  INVITED_USER_ADD_WORKSPACE: "call-invitedUserAddWorkspace",
  STRIPE_CREATE_PORTAL_LINK: "ext-firestore-stripe-payments-createPortalLink",
  ADD_STRIPE_CHECKOUT_SESSION: "call-addStripeCheckoutSession",
  SEND_CONTACT_EMAIL: "call-sendContactEmail",
  REGION: "asia-northeast1",
  TIMEZONE: "Asia/Tokyo",
  STRIPE_APP_VERSION: "2022-11-15" as "2022-11-15",
};

// roles
export const ROLE = {
  ADMIN: "admin" as Role,
  MEMBER: "member" as Role,
};

// plan
export const PLAN = {
  FREE: "free" as Plan,
  BUSINESS: "business" as Plan,
};

export const ACTION = {
  CREATE: "create" as Action,
  UPDATE: "update" as Action,
  DELETE: "delete" as Action,
};

// url
export const DOMAIN = "https://ject.net";

// office isometric
export const ISOMETRIC_OFFICE = {
  TILE_SIZE: 100,
  ITEMS_DIRECTORY: "/assets/images/offices/items/",
  TILES_DIRECTORY: "/assets/images/offices/tiles/",
  SCALE_Y: 1.7, // 1.743
};

// text
export const TEXT = {
  EMPTY: "-",
  DATE_DASH: "–", // ndash
  COLON: ":",
  INVISIBLE_PASSWORD: "******",
  NEW_LINE: "\n",
};

// colors
export const COLOR = {
  RED: "red" as Color,
  PINK: "pink" as Color,
  PURPLE: "purple" as Color,
  DEEP_PURPLE: "deepPurple" as Color,
  INDIGO: "indigo" as Color,
  BLUE: "blue" as Color,
  LIGHT_BLUE: "lightBlue" as Color,
  CYAN: "cyan" as Color,
  TEAL: "teal" as Color,
  GREEN: "green" as Color,
  LIGHT_GREEN: "lightGreen" as Color,
  LIME: "lime" as Color,
  YELLOW: "yellow" as Color,
  AMBER: "amber" as Color,
  ORANGE: "orange" as Color,
  DEEP_ORANGE: "deepOrange" as Color,
  BROWN: "brown" as Color,
  GREY: "grey" as Color,
  BLUE_GREY: "blueGrey" as Color,
};

export const AVATAR = {
  DEFAULT: "/assets/images/avatar.jpg",
  RECOMMEND_WIDTH: 240,
  RECOMMEND_HEIGHT: 240,
};

export const LEXICAL_THEME = {
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-tokenAttr",
    attr: "editor-tokenAttr",
    boolean: "editor-tokenProperty",
    builtin: "editor-tokenSelector",
    cdata: "editor-tokenComment",
    char: "editor-tokenSelector",
    class: "editor-tokenFunction",
    "class-name": "editor-tokenFunction",
    comment: "editor-tokenComment",
    constant: "editor-tokenProperty",
    deleted: "editor-tokenProperty",
    doctype: "editor-tokenComment",
    entity: "editor-tokenOperator",
    function: "editor-tokenFunction",
    important: "editor-tokenVariable",
    inserted: "editor-tokenSelector",
    keyword: "editor-tokenAttr",
    namespace: "editor-tokenVariable",
    number: "editor-tokenProperty",
    operator: "editor-tokenOperator",
    prolog: "editor-tokenComment",
    property: "editor-tokenProperty",
    punctuation: "editor-tokenPunctuation",
    regex: "editor-tokenVariable",
    selector: "editor-tokenSelector",
    string: "editor-tokenSelector",
    symbol: "editor-tokenProperty",
    tag: "editor-tokenProperty",
    url: "editor-tokenOperator",
    variable: "editor-tokenVariable",
  },
};

export const COLORS: Color[] = [
  "red",
  "pink",
  "purple",
  "deepPurple",
  "indigo",
  "blue",
  "lightBlue",
  "cyan",
  "teal",
  "green",
  "lightGreen",
  "lime",
  "yellow",
  "amber",
  "orange",
  "deepOrange",
  "brown",
  "grey",
  "blueGrey",
];

export const TIMEZONES: Timezone[] = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

export const VERSIONS = {
  APP: "1.0.0",
  INVITATION: 1 as 1,
  MEMBER: 1 as 1,
  PROJECT: 1 as 1,
  TASK: 1 as 1,
  TASK_HISTORY: 1 as 1,
  TASK_COMMENT: 1 as 1,
  TASK_TAG: 1 as 1,
  TASK_MILESTONE: 1 as 1,
  TASK_STATE: 1 as 1,
  TASK_FILE: 1 as 1,
  TEAM: 1 as 1,
  USER: 1 as 1,
  WORKSPACE: 1 as 1,
  TASK_BOOKMARK: 1 as 1,
  NOTIFICATION: 1 as 1,
  MEMBER_STATE: 1 as 1,
  OFFICE: 1 as 1,
  OFFICE_MEMBER: 1 as 1,
  OFFICE_MEMBER_HISTORY: 1 as 1,
  DEVICE: 1 as 1,
};

export const ERROR_CODE = {
  CANCELLED: "cancelled" as "cancelled", // 操作はキャンセルされました。
  UNKNOWN: "unknown" as "unknown", // エラーが発生しました。
  INVALID_ARGUMENT: "invalid-argument" as "invalid-argument", // 無効な引数です。
  DEADLINE_EXCEEDED: "deadline-exceeded" as "deadline-exceeded", // 期限切れです。
  NOT_FOUND: "not-found" as "not-found", // ドキュメントが見つかりませんでした。
  ALREADY_EXISTS: "already-exists" as "already-exists", // ドキュメントは作成済みです。
  PERMISSION_DENIED: "permission-denied" as "permission-denied", // 権限がありません。
  RESOURCE_EXHAUSTED: "resource-exhausted" as "resource-exhausted", // 一部のリソースが使い果たされました。システム全体の容量が不足している可能性があります。
  FAILED_PRECONDITION: "failed-precondition" as "failed-precondition", // 準備中です。
  ABORTED: "aborted" as "aborted", // 対象ドキュメントが操作中のため実行が中止されました（トランザクションエラー）。
  OUT_OF_RANGE: "out-of-range" as "out-of-range", // 有効な範囲を超えています。
  UNIMPLEMENTED: "unimplemented" as "unimplemented", // 未実装または有効化されていません。
  INTERNAL: "internal" as "internal", // 内部エラーが発生しました。
  UNAVAILABLE: "unavailable" as "unavailable", // サービスは現在利用できません。
  DATA_LOSS: "data-loss" as "data-loss", // 破損エラーが発生しました。
  UNAUTHENTICATED: "unauthenticated" as "unauthenticated", // ユーザ情報が確認できません。
  AUTH: {
    WEAK_PASSWORD: "auth/weak-password",
    INVALID_EMAIL: "auth/invalid-email",
    WRONG_PASSWORD: "auth/wrong-password",
  },
  FUNCTIONS: {
    CANCELLED: "functions/cancelled",
    UNKNOWN: "functions/unknown",
    INVALID_ARGUMENT: "functions/invalid-argument",
    DEADLINE_EXCEEDED: "functions/deadline-exceeded",
    NOT_FOUND: "functions/not-found",
    ALREADY_EXISTS: "functions/already-exists",
    PERMISSION_DENIED: "functions/permission-denied",
    RESOURCE_EXHAUSTED: "functions/resource-exhausted",
    FAILED_PRECONDITION: "functions/failed-precondition",
    ABORTED: "functions/aborted",
    OUT_OF_RANGE: "functions/out-of-range",
    UNIMPLEMENTED: "functions/unimplemented",
    INTERNAL: "functions/internal",
    UNAVAILABLE: "functions/unavailable",
    DATA_LOSS: "functions/data-loss",
    UNAUTHENTICATED: "functions/unauthenticated",
  },
};

// オフィス
export const OFFICE_DEFAULT_ITEMS: {
  ids: string[];
  categories: string[];
}[] = [
  // パソコン＆デスク＆チェア
  {
    ids: [
      "u0BGFLewNkpJMBdik2nn",
      "QqVAn6rQX2J6SwBVnf8t",
      "OQpvEr3XJlQMM9TtPQKm",
    ],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: [
      "8S5NrjHFQjgPtpKpzTba",
      "gFr7b4OfjcogAkQIO7Tz",
      "8v38dtPMZTbwq9OcLu2U",
    ],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: [
      "mjdQ9N51XEPaJEFfYsCi",
      "t0D2vc9sx2lagDovn5FV",
      "oGTxXsW3lswpuUZElygt",
    ],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: [
      "Rmc9jO6GtO8Rrb1B5SSq",
      "9Essy4q4MxDcQxKcWrqd",
      "tvjvBKfx5tpozgyFGhuk",
    ],
    categories: ["officeWork", "desk", "wood"],
  },
  // デスク
  {
    ids: ["QqVAn6rQX2J6SwBVnf8t"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["gFr7b4OfjcogAkQIO7Tz"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["mjdQ9N51XEPaJEFfYsCi"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["Rmc9jO6GtO8Rrb1B5SSq"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["mM0SpbWNeHYCHXSFSiOg"],
    categories: ["officeWork", "desk", "wood"],
  },

  // 棚
  {
    ids: ["f4STJoM6LT27bmmtQpqx"],
    categories: ["officeWork", "shelf", "wood"],
  },
  {
    ids: ["9zbrjeqrku3BQku43E2v"],
    categories: ["officeWork", "shelf", "wood"],
  },
  {
    ids: ["sFeORaEExqADaojVXtnT"],
    categories: ["officeWork", "shelf", "wood"],
  },
  {
    ids: ["NsIPihv8psWRHqfdGWVV"],
    categories: ["officeWork", "shelf", "wood"],
  },
  // 植物
  {
    ids: ["KFJWCCFzYeSFCyTI1WV0"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["jmxYAwbNbN3YzfviUveJ"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["7WVKlx564a3EapcvzSUw"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["31m0NLZsjG4GUTYCPGK2"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["0dPqPT1yRMZQCVPCQDQC"],
    categories: ["officeWork", "plant"],
  },
  // 壁
  {
    ids: ["EzbyU1Lfm8PscLHzNDWm"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["Uf7exAJIHUhrmf1eit8a"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["6xoivRNeF1NSSq6ZuBpj"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["VKLFLmaiVcJ6eaWwbuLS"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["JtkCR9mgg3vhabDYLlkK"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["vz948JpN0th9ki3d4NHC"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["57ip6P2uMnEbYCyrdDNf"],
    categories: ["officeWork", "partition", "wood"],
  },
  // 打ち合わせ 机
  {
    ids: ["lTsEYUMjSlFpBtLEXo3A"],
    categories: ["officeWork", "wood"],
  },
  // 打ち合わせ 椅子
  {
    ids: ["vrfjav8A4bhGpGEMrN1P"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["MXGHB4Hd1a1WUsMLc64w"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["xsOtmvWWQTC5WfvzKm3l"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["saAnXN8G7XJ75VEQTgj8"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["lU3LfQlCGLoJfOAbSvTR"],
    categories: ["officeWork", "chair", "wood"],
  },
  {
    ids: ["YzvyIlK6FlPGnYZXebkj"],
    categories: ["officeWork", "chair", "wood"],
  },
  {
    ids: ["erSEtb9JixqdMq2jvIdv"],
    categories: ["officeWork", "chair", "wood"],
  },
  {
    ids: ["sQmWnRKngYEjuN7z8ppR"],
    categories: ["officeWork", "chair", "wood"],
  },
];

export const OFFICE_ITEMS: {
  ids: string[];
  categories: string[];
}[] = [
  // デスク
  {
    ids: ["QqVAn6rQX2J6SwBVnf8t"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["gFr7b4OfjcogAkQIO7Tz"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["mjdQ9N51XEPaJEFfYsCi"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["Rmc9jO6GtO8Rrb1B5SSq"],
    categories: ["officeWork", "desk", "wood"],
  },
  {
    ids: ["mM0SpbWNeHYCHXSFSiOg"],
    categories: ["officeWork", "desk", "wood"],
  },
  // パソコン
  {
    ids: ["OQpvEr3XJlQMM9TtPQKm"],
    categories: ["officeWork", "computer", "black"],
  },
  {
    ids: ["8v38dtPMZTbwq9OcLu2U"],
    categories: ["officeWork", "computer", "black"],
  },
  {
    ids: ["t0D2vc9sx2lagDovn5FV"],
    categories: ["officeWork", "computer", "black"],
  },
  {
    ids: ["9Essy4q4MxDcQxKcWrqd"],
    categories: ["officeWork", "computer", "black"],
  },
  // 椅子
  {
    ids: ["u0BGFLewNkpJMBdik2nn"],
    categories: ["officeWork", "chair", "black"],
  },
  {
    ids: ["8S5NrjHFQjgPtpKpzTba"],
    categories: ["officeWork", "chair", "black"],
  },
  {
    ids: ["oGTxXsW3lswpuUZElygt"],
    categories: ["officeWork", "chair", "black"],
  },
  {
    ids: ["tvjvBKfx5tpozgyFGhuk"],
    categories: ["officeWork", "chair", "black"],
  },
  // 棚
  {
    ids: ["f4STJoM6LT27bmmtQpqx"],
    categories: ["officeWork", "shelf", "wood"],
  },
  {
    ids: ["9zbrjeqrku3BQku43E2v"],
    categories: ["officeWork", "shelf", "wood"],
  },
  {
    ids: ["sFeORaEExqADaojVXtnT"],
    categories: ["officeWork", "shelf", "wood"],
  },
  {
    ids: ["NsIPihv8psWRHqfdGWVV"],
    categories: ["officeWork", "shelf", "wood"],
  },
  // 植物
  {
    ids: ["KFJWCCFzYeSFCyTI1WV0"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["jmxYAwbNbN3YzfviUveJ"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["7WVKlx564a3EapcvzSUw"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["31m0NLZsjG4GUTYCPGK2"],
    categories: ["officeWork", "plant"],
  },
  {
    ids: ["0dPqPT1yRMZQCVPCQDQC"],
    categories: ["officeWork", "plant"],
  },
  // 壁
  {
    ids: ["EzbyU1Lfm8PscLHzNDWm"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["Uf7exAJIHUhrmf1eit8a"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["6xoivRNeF1NSSq6ZuBpj"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["VKLFLmaiVcJ6eaWwbuLS"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["JtkCR9mgg3vhabDYLlkK"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["vz948JpN0th9ki3d4NHC"],
    categories: ["officeWork", "partition", "wood"],
  },
  {
    ids: ["57ip6P2uMnEbYCyrdDNf"],
    categories: ["officeWork", "partition", "wood"],
  },
  // 打ち合わせ 机
  {
    ids: ["lTsEYUMjSlFpBtLEXo3A"],
    categories: ["officeWork", "wood"],
  },
  // 打ち合わせ 椅子
  {
    ids: ["vrfjav8A4bhGpGEMrN1P"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["MXGHB4Hd1a1WUsMLc64w"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["xsOtmvWWQTC5WfvzKm3l"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["saAnXN8G7XJ75VEQTgj8"],
    categories: ["officeWork", "chair", "white"],
  },
  {
    ids: ["lU3LfQlCGLoJfOAbSvTR"],
    categories: ["officeWork", "chair", "wood"],
  },
  {
    ids: ["YzvyIlK6FlPGnYZXebkj"],
    categories: ["officeWork", "chair", "wood"],
  },
  {
    ids: ["erSEtb9JixqdMq2jvIdv"],
    categories: ["officeWork", "chair", "wood"],
  },
  {
    ids: ["sQmWnRKngYEjuN7z8ppR"],
    categories: ["officeWork", "chair", "wood"],
  },
];

export const OFFICE_TILES: {
  id: string;
  categories: string[];
}[] = [
  // wood
  {
    id: "0PLpRK6Dkz51qSaaH3N9",
    categories: ["officeWork", "officeWork", "wood"],
  },
  {
    id: "LXFWvHl4NIN9yUN7YwFH",
    categories: ["officeWork", "officeWork", "wood"],
  },
  {
    id: "1Wn9QFxkeEiKPWTSKMIQ",
    categories: ["officeWork", "officeWork", "wood"],
  },
  // grey
  {
    id: "kgTlc5rtkaYz2bHWCju6",
    categories: ["officeWork", "officeWork", "grey"],
  },
  {
    id: "i7STN7akMHcpKFebOkMb",
    categories: ["officeWork", "officeWork", "black"],
  },
];

export const DAYJS = {
  YYYY_MM_DD_HH_mm: "YYYY-MM-DD HH:mm",
  YYYY_MM_DD: "YYYY-MM-DD",
  YYYY_MM: "YYYY-MM",
  MM_DD_HH_mm: "MM-DD HH:mm",
  HH_mm: "HH:mm",
  MONTH: "month" as ManipulateType,
  WEEK: "week" as ManipulateType,
  DAY: "day" as ManipulateType,
  SECOND: "second" as ManipulateType,
};

export const IONIC = {
  BREAKPOINT: {
    SM: "576px",
    MD: "768px",
    LG: "992px",
    XL: "1200px",
  },
};

export const LIMIT = {
  NUMBER_OF_ALLOW_CREATE_WORKSPCACES: 10,
  NUMBER_OF_ALLOW_INVITE_MEMBERS: 5,
};

export const STORAGE = {
  WORKSPACES: "workspaces",
  PROJECTS: "projects",
  TASKS: "tasks",
  KIND: {
    TASK_FILE: "taskFile",
  },
};

export const LINK = {
  APP_STORE: "https://apps.apple.com/jp/app/ject/id6446932313",
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=jp.minimaldesign.ject",
};

export const ENV = {
  FIREBASE: {
    USE: {
      DEV: process.env.REACT_APP_FIREBASE_USE === "dev",
      TEST: process.env.REACT_APP_FIREBASE_USE === "test",
      PROD: process.env.REACT_APP_FIREBASE_USE === "prod",
    },
    CONFIG: {
      DEV: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_DEV!),
      TEST: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_TEST!),
      PROD: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_PROD!),
    },
  },
};

export const PLATFORM = {
  IS_DESKTOP: isPlatform("desktop"),
  IS_IOS: isPlatform("ios"),
  IS_ANDROID: isPlatform("android"),
  IS_CAPACITOR: isPlatform("capacitor"),
};
