import { ComponentProps, forwardRef } from "react";
import { IonContent } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonContent> {
  fabButtonCount?: number;
}

const Content = styled(IonContent)<{ fabButtonCount?: number }>`
  --background: var(--app-color-blue-grey50);
  --padding-bottom: ${({ fabButtonCount }) =>
    `${fabButtonCount ? fabButtonCount * 48 + 16 : 0}px`};
`;

const AppContent = forwardRef<HTMLIonContentElement, Props>(
  ({ fabButtonCount, children, ...props }, ref) => {
    return (
      <Content fabButtonCount={fabButtonCount} {...props} ref={ref}>
        {children}
      </Content>
    );
  }
);

export default AppContent;
