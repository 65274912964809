import {
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  AppContent,
  AppFooterButtons,
  AppBackButton,
  AppActionButton,
  AppCard,
  AppErrorCard,
  AppLoadingCard,
  AppHeaderBackButton,
  AppButtons,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
  AppWriteWorkspaceModal,
  AppWriteWorkspaceModalProps,
  AppItemGroup,
  AppItem,
  AppFormItem,
  AppIcon,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useError, useLoading } from "../../../hooks";
import {
  selectWorkspace,
  selectWorkspacesError,
  selectWorkspacesLoading,
  setWorkspace,
} from "../../../store/workspacesSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { businessOutline, earth } from "ionicons/icons";
import { TEXT } from "../../../utilities/UtilStatic";
import { TabId } from "../../../models";

const WorkspaceDetailsPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // store
  const dispatch = useDispatch();
  const workspace = useSelector(selectWorkspace(workspaceId));
  const workspacesLoading = useSelector(selectWorkspacesLoading);
  const workspacesError = useSelector(selectWorkspacesError);
  const loading = useLoading(workspacesLoading);
  const error = useError(workspacesError);
  // components
  const [showLoading, hideLoading] = useIonLoading();
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showUpdateWorkspaceModal, hideUpdateWorkspaceModal] = useIonModal(
    AppWriteWorkspaceModal,
    {
      workspace,
      hide: () => hideUpdateWorkspaceModal(),
      deleteWorkspace: (workspaceId) => {
        showAlert({
          message: "ワークスペースを削除しますか？",
          buttons: [
            "キャンセル",
            {
              text: "Ok",
              handler: () => {
                showLoading({ cssClass: "app-loading", backdropDismiss: true });
                Promise.resolve()
                  .then(() => appApis.deleteWorkspace(workspaceId))
                  .then(() => {
                    showToast({
                      message: i18nText.success.workspace.delete(),
                      duration: 2000,
                    });
                    hideUpdateWorkspaceModal();
                    history.replace(appLinks.signIn());
                  })
                  .catch((error) => {
                    showAlert({ message: i18nErrorToString(error) });
                  })
                  .finally(() => hideLoading());
              },
            },
          ],
        });
      },
      saveWorkspace: (workspace) => {
        const { id, name, timezone, updatedBy, updatedAt } = workspace;
        Promise.resolve()
          .then(() =>
            appApis.updateWorkspace({
              id,
              name,
              timezone,
              updatedBy,
              updatedAt,
            })
          )
          .then(() => {
            dispatch(setWorkspace(workspace));
            hideUpdateWorkspaceModal();
            showToast({
              message: i18nText.success.workspace.update(),
              duration: 2000,
            });
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
    } as AppWriteWorkspaceModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>{i18nText.features.workspace() + "編集"}</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && workspace && (
            <AppCard marginBottom="16px">
              <AppItemGroup>
                <AppItem>
                  <AppIcon
                    icon={businessOutline}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="ワークスペース名">
                    {workspace.name}
                  </AppFormItem>
                </AppItem>
                <AppItem>
                  <AppIcon
                    icon={earth}
                    style={{ marginRight: "16px" }}
                    color="medium"
                    slot="start"
                  />
                  <AppFormItem label="タイムゾーン">
                    {workspace.timezone ?? TEXT.EMPTY}
                  </AppFormItem>
                </AppItem>
              </AppItemGroup>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
      <AppFooterButtons>
        <AppBackButton defaultHref={appLinks.tab(workspaceId, tabId)} />
        <AppActionButton
          action="ok"
          onClick={() => {
            showUpdateWorkspaceModal({
              presentingElement: routerRef,
              canDismiss: true,
            });
          }}
        >
          {i18nText.buttons.edit()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default WorkspaceDetailsPage;
