import { IonButton, IonIcon, IonTitle, useIonPopover } from "@ionic/react";
import dayjs, { Dayjs } from "dayjs";
import {
  chevronBackOutline,
  chevronForwardOutline,
  layersOutline,
  peopleOutline,
  personOutline,
} from "ionicons/icons";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppAvatar,
  AppButton,
  AppButtons,
  AppCard,
  AppContent,
  AppErrorCard,
  AppHeader,
  AppHeaderBackButton,
  AppIcon,
  AppInnerContent,
  AppItem,
  AppItemGroup,
  AppLabel,
  AppLoadingCard,
  AppPage,
  AppSelectPopover,
  AppSelectPopoverProps,
  AppToolbar,
} from "../..";
import {
  useDailyOfficeMembersHistories,
  useError,
  useLoading,
  useProjects,
} from "../../../hooks";
import { TabId } from "../../../models";
import {
  selectMemberEntities,
  selectMembers,
  selectMembersError,
  selectMembersLoading,
} from "../../../store/membersSlice";
import {
  selectTeamEntities,
  selectTeams,
  selectTeamsError,
  selectTeamsLoading,
} from "../../../store/teamsSlice";
import { formatItem, formatMember } from "../../../utilities/UtilFunction";
import { appLinks } from "../../../utilities/UtilPage";
import { DAYJS, TEXT } from "../../../utilities/UtilStatic";

interface WorkHistory {
  memberId: string;
  name: string;
  avatar: string;
  beginAt: string;
  endAt: string | null;
  order: number;
}

const Header = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

const States = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
`;

const StatesButton = styled(IonButton)`
  margin: 0;
  --border-width: 1px;
  --box-shadow: none;
  &::part(native) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StatesLabel = styled.div`
  font-size: 14px;
`;

const StatesIcon = styled(IonIcon)`
  padding: 0 4px;
`;

const TotalWorkingTime = styled.div`
  font-size: 16px;
  color: var(--app-color-grey900);
  padding: 0 1px;
`;

const DailyHistory = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px 0;
`;

const DailyHistoryMemberName = styled.div`
  font-size: 16px;
`;

const DailyHistoryWorkTime = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0 8px;
  font-size: 14px;
  color: var(--app-color-grey600);
`;

const DailyOfficeMembersHistories: FunctionComponent = () => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, date, memberId, teamId, projectId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    date: string;
    memberId?: string;
    teamId?: string;
    projectId?: string;
  }>();
  // store
  const members = useSelector(selectMembers);
  const memberEntities = useSelector(selectMemberEntities);
  const membersLoading = useSelector(selectMembersLoading);
  const membersError = useSelector(selectMembersError);
  const teams = useSelector(selectTeams);
  const teamEntities = useSelector(selectTeamEntities);
  const teamsLoading = useSelector(selectTeamsLoading);
  const teamsError = useSelector(selectTeamsError);
  // states
  const [day, setDay] = useState<Dayjs>(dayjs(date));
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(
    memberId ?? null
  );
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(
    teamId ?? null
  );
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    projectId ?? null
  );
  // functions 読み込み
  const projects = useProjects({ workspaceId });
  const officeMembersHistories = useDailyOfficeMembersHistories({
    workspaceId,
    date: day.format(DAYJS.YYYY_MM_DD),
  });
  const loading = useLoading(
    membersLoading,
    teamsLoading,
    projects.loading,
    officeMembersHistories.loading
  );
  const error = useError(
    membersError,
    teamsError,
    projects.error,
    officeMembersHistories.error
  );
  // functions 条件でフィルターした作業履歴
  const filterOfficeMembersHistories = useMemo(() => {
    return officeMembersHistories.data.filter((officeMembersHistory) => {
      if (selectedMemberId) {
        return selectedMemberId === officeMembersHistory.memberId;
      }
      if (selectedTeamId) {
        const team = teamEntities[selectedTeamId];
        if (team) {
          return team.memberIds.includes(officeMembersHistory.memberId);
        } else {
          return false;
        }
      }
      if (selectedProjectId) {
        return selectedProjectId === officeMembersHistory.projectId;
      }
      return true;
    });
  }, [
    officeMembersHistories.data,
    teamEntities,
    selectedMemberId,
    selectedTeamId,
    selectedProjectId,
  ]);

  // functions カレンダー用にデータ整形
  const dailyHistories = useMemo(() => {
    const officeMembersHistories: WorkHistory[] = [];
    filterOfficeMembersHistories.forEach((officeMembersHistory) => {
      const existsWorkHistory: WorkHistory | undefined =
        officeMembersHistories.find(
          ({ memberId }) => memberId === officeMembersHistory.memberId
        );
      if (existsWorkHistory) {
        let beginAt = existsWorkHistory.beginAt;
        let endAt = existsWorkHistory.endAt;
        const newBeginAt = dayjs(officeMembersHistory.beginAt.toDate()).format(
          DAYJS.HH_mm
        );
        const newEndAt = officeMembersHistory.endAt
          ? dayjs(officeMembersHistory.endAt.toDate()).format(DAYJS.HH_mm)
          : null;
        if (beginAt > newBeginAt) beginAt = newBeginAt;
        if (endAt && newEndAt) {
          if (endAt < newBeginAt) endAt = newEndAt;
        } else {
          endAt = null;
        }
        existsWorkHistory.beginAt = beginAt;
        existsWorkHistory.endAt = endAt;
      } else {
        const { memberId, beginAt, endAt } = officeMembersHistory;
        const { name, avatar, order } = formatMember(memberId, memberEntities);
        officeMembersHistories.push({
          memberId,
          name,
          avatar,
          beginAt: dayjs(beginAt.toDate()).format(DAYJS.HH_mm),
          endAt: endAt ? dayjs(endAt.toDate()).format(DAYJS.HH_mm) : null,
          order,
        });
      }
    });
    return officeMembersHistories.sort((a, b) => a.order - b.order);
  }, [filterOfficeMembersHistories, memberEntities]);

  const totalHours = useMemo(() => {
    let second: number = 0;
    filterOfficeMembersHistories.forEach(({ beginAt, endAt }) => {
      if (endAt) {
        second += dayjs(endAt.toDate()).diff(beginAt.toDate(), DAYJS.SECOND);
      }
    });
    return Math.floor((second / 60 / 60) * 10) / 10;
  }, [filterOfficeMembersHistories]);

  const calcTotalHours = useCallback(
    (memberId: string) => {
      let second: number = 0;
      filterOfficeMembersHistories
        .filter((item) => item.memberId === memberId)
        .forEach(({ beginAt, endAt }) => {
          if (endAt) {
            second += dayjs(endAt.toDate()).diff(
              beginAt.toDate(),
              DAYJS.SECOND
            );
          }
        });
      return Math.floor((second / 60 / 60) * 10) / 10;
    },
    [filterOfficeMembersHistories]
  );

  // components メンバー選択
  const [showSelectMemberPopover, hideSelectMemberPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: members,
      selectedId: selectedMemberId,
      filterKeywords: ["name", "email"],
      isSetNull: true,
      selectItem: (memberId: string | null) => {
        setSelectedMemberId(memberId);
        setSelectedTeamId(null);
        setSelectedProjectId(null);
        hideSelectMemberPopover();
      },
    } as AppSelectPopoverProps
  );
  // components チーム選択
  const [showSelectTeamPopover, hideSelectTeamPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: teams,
      selectedId: selectedTeamId,
      isSetNull: true,
      selectItem: (teamId: string | null) => {
        setSelectedMemberId(null);
        setSelectedTeamId(teamId);
        setSelectedProjectId(null);
        hideSelectTeamPopover();
      },
    } as AppSelectPopoverProps
  );
  // components プロジェクト選択
  const [showSelectProjectPopover, hideSelectProjectPopover] = useIonPopover(
    AppSelectPopover,
    {
      items: projects.data,
      selectedId: selectedProjectId,
      isSetNull: true,
      selectItem: (projectId: string | null) => {
        setSelectedMemberId(null);
        setSelectedTeamId(null);
        setSelectedProjectId(projectId);
        hideSelectProjectPopover();
      },
    } as AppSelectPopoverProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <IonTitle>{day.format(DAYJS.YYYY_MM_DD)}</IonTitle>
          <AppButtons slot="end">
            <AppButton
              color="primary"
              onClick={() => setDay(day.add(-1, DAYJS.DAY))}
            >
              <AppIcon slot="icon-only" icon={chevronBackOutline} />
            </AppButton>
            <AppButton
              color="primary"
              onClick={() => setDay(day.add(1, DAYJS.DAY))}
            >
              <AppIcon slot="icon-only" icon={chevronForwardOutline} />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <Header>
            <States>
              {/* メンバー */}
              <StatesButton
                fill={!selectedMemberId ? "outline" : "solid"}
                shape="round"
                size="small"
                onClick={(e) => {
                  showSelectMemberPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <StatesIcon icon={personOutline} />
                {!selectedMemberId && (
                  <StatesLabel className="ion-hide-sm-down">
                    メンバー
                  </StatesLabel>
                )}
                {selectedMemberId && (
                  <StatesLabel>
                    {formatMember(selectedMemberId, memberEntities).name}
                  </StatesLabel>
                )}
              </StatesButton>
              {/* チーム */}
              <StatesButton
                fill={!selectedTeamId ? "outline" : "solid"}
                shape="round"
                size="small"
                onClick={(e) => {
                  showSelectTeamPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <StatesIcon icon={peopleOutline} />
                {!selectedTeamId && (
                  <StatesLabel className="ion-hide-sm-down">チーム</StatesLabel>
                )}
                {selectedTeamId && (
                  <StatesLabel>
                    {formatItem(selectedTeamId, teams).name}
                  </StatesLabel>
                )}
              </StatesButton>
              {/* プロジェクト */}
              <StatesButton
                fill={!selectedProjectId ? "outline" : "solid"}
                shape="round"
                size="small"
                onClick={(e) => {
                  showSelectProjectPopover({
                    cssClass: ["app-popover-width240", "app-popover-height320"],
                    event: { ...e.nativeEvent, target: e.currentTarget },
                  });
                }}
              >
                <StatesIcon icon={layersOutline} />
                {!selectedProjectId && (
                  <StatesLabel className="ion-hide-sm-down">
                    プロジェクト
                  </StatesLabel>
                )}
                {selectedProjectId && (
                  <StatesLabel>
                    {formatItem(selectedProjectId, projects.data).name}
                  </StatesLabel>
                )}
              </StatesButton>
            </States>
            {!loading && !error && (
              <TotalWorkingTime>合計作業時間{totalHours}時間</TotalWorkingTime>
            )}
          </Header>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && dailyHistories.length > 0 && (
            <AppCard>
              <AppItemGroup>
                {dailyHistories.map(
                  ({ memberId, name, avatar, beginAt, endAt }) => (
                    <AppItem
                      key={memberId}
                      button={true}
                      detail={true}
                      onClick={() => {
                        history.push(
                          appLinks.dailyOfficeMemberHistories(
                            workspaceId,
                            tabId,
                            date,
                            memberId
                          )
                        );
                      }}
                    >
                      <AppAvatar slot="start">
                        <img src={avatar} />
                      </AppAvatar>
                      <AppLabel>
                        <DailyHistory>
                          <DailyHistoryMemberName>
                            {name}
                          </DailyHistoryMemberName>
                          <DailyHistoryWorkTime>
                            <span>
                              {`${beginAt}${TEXT.DATE_DASH}${endAt ?? ""}`}
                            </span>
                            {endAt && (
                              <span>
                                {`合計作業時間${calcTotalHours(memberId)}時間`}
                              </span>
                            )}
                          </DailyHistoryWorkTime>
                        </DailyHistory>
                      </AppLabel>
                    </AppItem>
                  )
                )}
              </AppItemGroup>
            </AppCard>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default DailyOfficeMembersHistories;
