import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "./";
import { TaskTag } from "../models/TaskTag";

interface TaskTagsState extends EntityState<TaskTag> {
  loading: boolean;
  error: string | null;
}

const adapter = createEntityAdapter<TaskTag>();

const { selectById } = adapter.getSelectors();

const initialState: TaskTagsState = adapter.getInitialState({
  loading: true,
  error: null,
});

export const taskTagsSlice = createSlice({
  name: "taskTags",
  initialState,
  reducers: {
    setTaskTags: (state, action: PayloadAction<TaskTag[]>) => {
      adapter.setAll(state, action.payload);
    },
    setTaskTagsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaskTagsError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setTaskTags, setTaskTagsLoading, setTaskTagsError } =
  taskTagsSlice.actions;

export const selectTaskTags = adapter.getSelectors(
  (state: RootState) => state.taskTags
).selectAll;

export const selectTaskTagEntities = adapter.getSelectors(
  (state: RootState) => state.taskTags
).selectEntities;

export const selectTaskTagsLoading = (state: RootState) =>
  state.taskTags.loading;

export const selectTaskTagsError = (state: RootState) => state.taskTags.error;

export const selectTaskTag = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.taskTags,
    (state) => (selectById(state, id!) ? selectById(state, id!)! : null)
  );
};

export const selectProjectTaskTags = (projectId: string | null) => {
  return createSelector(
    (state: RootState) => selectTaskTags(state),
    (state) => state.filter((item) => item.projectId === projectId)
  );
};

export default taskTagsSlice.reducer;
