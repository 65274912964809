import { FunctionComponent, HTMLAttributes } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Card = styled.div`
  width: 100%;
  background: var(--ion-color-danger);
  border-radius: 8px;
`;

const Content = styled.div`
  padding: 10px;
  color: var(--app-color-white);
  display: flex;
  justify-content: center;
`;

const AppErrorCard: FunctionComponent<Props> = ({ children, ...props }) => {
  return (
    <Card {...props}>
      <Content>{children}</Content>
    </Card>
  );
};

export default AppErrorCard;
