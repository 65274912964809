import {
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppContent,
  AppCard,
  AppItemGroup,
  AppItemIcon,
  AppItem,
  AppLabel,
  AppWriteProjectModalProps,
  AppWriteProjectModal,
  AppHeaderBackButton,
  AppButton,
  AppButtons,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useError, useLoading, useProject } from "../../../hooks";
import { selectIsWorkspaceAdmin } from "../../../store/membersSlice";
import { selectProjectTaskAndTaskCommentNotificationsCount } from "../../../store/notificationsSlice";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nText } from "../../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { TabId } from "../../../models";

const ItemBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 12px;
  min-width: 22px;
  color: var(--app-color-white);
  background: var(--app-color-notification);
  margin-right: 4px;
`;

const ProjectPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, tabId, projectId } = useParams<{
    workspaceId: string;
    tabId: TabId;
    projectId: string;
  }>();
  // store
  const projectTaskAndTaskCommentNotificationsCount = useSelector(
    selectProjectTaskAndTaskCommentNotificationsCount(projectId)
  );
  const isWorkspaceAdmin = useSelector(selectIsWorkspaceAdmin);
  // page data
  const project = useProject({ workspaceId, projectId });
  const loading = useLoading(project.loading);
  const error = useError(project.error);
  // components
  const [showLoading, hideLoading] = useIonLoading();
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [showWriteProjectModal, hideWriteProjectModal] = useIonModal(
    AppWriteProjectModal,
    {
      project: project.data,
      deleteProject: (workspaceId, projectId) => {
        showAlert({
          message: "プロジェクトを削除しますか？",
          buttons: [
            "キャンセル",
            {
              text: "Ok",
              handler: () => {
                showLoading({
                  cssClass: "app-loading",
                  backdropDismiss: true,
                });
                Promise.resolve()
                  .then(() => appApis.deleteProject(workspaceId, projectId))
                  .then(() => {
                    history.goBack();
                    hideWriteProjectModal();
                    showToast({
                      message: i18nText.success.project.delete(),
                      duration: 2000,
                    });
                  })
                  .catch((error) => {
                    showAlert({ message: i18nErrorToString(error) });
                  })
                  .finally(() => hideLoading());
              },
            },
          ],
        });
      },
      saveProject: (projectForm) => {
        Promise.resolve()
          .then(() => appApis.writeProject(projectForm))
          .then(() => {
            showToast({
              message: i18nText.success.project.update(),
              duration: 2000,
            });
            hideWriteProjectModal();
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          });
      },
      hide: () => hideWriteProjectModal(),
    } as AppWriteProjectModalProps
  );

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppHeaderBackButton
              defaultHref={appLinks.tab(workspaceId, tabId)}
            />
          </AppButtons>
          <AppTitle>{project.data?.name}</AppTitle>
          {!loading && !error && isWorkspaceAdmin && (
            <AppButtons slot="end">
              <AppButton
                color="primary"
                onClick={() =>
                  showWriteProjectModal({
                    presentingElement: routerRef,
                    canDismiss: true,
                  })
                }
              >
                編集
              </AppButton>
            </AppButtons>
          )}
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <AppCard>
            <AppItemGroup>
              <AppItem
                button={true}
                detail={true}
                routerLink={appLinks.tasks(workspaceId, tabId, projectId)}
              >
                <AppItemIcon icon={checkmarkOutline} slot="start" />
                <AppLabel>タスク</AppLabel>
                {projectTaskAndTaskCommentNotificationsCount > 0 && (
                  <ItemBadge>
                    {projectTaskAndTaskCommentNotificationsCount}
                  </ItemBadge>
                )}
              </AppItem>
            </AppItemGroup>
          </AppCard>
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default ProjectPage;
