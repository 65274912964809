import { checkmarkOutline, chevronForwardOutline } from "ionicons/icons";
import { FunctionComponent, useMemo } from "react";
import {
  AppButton,
  AppButtons,
  AppCard,
  AppCardContent,
  AppContent,
  AppErrorCard,
  AppHeader,
  AppIcon,
  AppInnerContent,
  AppLabel,
  AppLoadingCard,
  AppMenuButton,
  AppPage,
  AppTitle,
  AppToolbar,
} from "../..";
import { appApis } from "../../../apis/Api";
import {
  useError,
  useLoading,
  useStripeCustomerActiveSubscriptions,
  useStripeWorkspaceActiveSubscriptions,
} from "../../../hooks";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { useIonAlert, useIonLoading } from "@ionic/react";
import { useStripeProducts } from "../../../hooks/useStripeProducts";
import styled from "styled-components";
import { useParams } from "react-router";
import { TabId } from "../../../models";
import { ENV, PLATFORM } from "../../../utilities/UtilStatic";

const Header = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px 0;
  margin-bottom: 16px;
  padding: 0 1px;
`;

const MainTitle = styled.div`
  font-size: 24px;
`;

const Lead = styled.div`
  font-size: 16px;
`;

const Plan = styled.div`
  padding: 32px;
  border-radius: 8px;
  background: var(--ion-color-primary);
  color: var(--app-color-white);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const PlanName = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  line-height: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 4px;
`;

const PlanState = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0 4px;
  background: var(--app-color-white);
  color: var(--ion-color-primary);
  border-radius: 12px;
  padding: 0 12px;
  font-size: 14px;
  height: 24px;
`;

const PlanDescription = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

const PlanPrice = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  line-height: 1;
  font-weight: bold;
  border-bottom: 4px solid var(--app-color-white);
  padding: 0 4px 4px;
  margin-bottom: 32px;
`;

const PlanPriceCurrency = styled.div`
  font-size: 24px;
  margin-right: 8px;
`;

const PlanPriceNumeric = styled.div`
  font-size: 64px;
  letter-spacing: 0.05em;
`;

const PlanPriceMonth = styled.div`
  font-size: 20px;
`;

const Annotations = styled.ul`
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
`;

const Annotation = styled.li`
  position: relative;
  padding-left: 16px;
  &::before {
    content: "";
    border-radius: 50%;
    width: 5px;
    height: 5px;
    display: block;
    position: absolute;
    left: 2px;
    top: 0.6em;
    background: var(--app-color-grey400);
  }
`;

const TabSubscriptionPage: FunctionComponent = () => {
  // router
  const { workspaceId, tabId } = useParams<{
    workspaceId: string;
    tabId: TabId;
  }>();
  // hooks
  const stripeCustomerSubscriptions = useStripeCustomerActiveSubscriptions();
  const stripeWorkspaceSubscriptions = useStripeWorkspaceActiveSubscriptions();
  const stripeProducts = useStripeProducts();
  const loading = useLoading(
    stripeCustomerSubscriptions.loading,
    stripeWorkspaceSubscriptions.loading,
    stripeProducts.loading
  );
  const error = useError(
    stripeCustomerSubscriptions.error,
    stripeWorkspaceSubscriptions.error,
    stripeProducts.error
  );
  // memos / サブスクリプションのプライスID
  const stripeProductPriceId: string | null = useMemo(() => {
    return stripeProducts.data[0]?.prices[0]?.id ?? null;
  }, [stripeProducts.data]);
  // memos / このワークスペースが有効なサブスクリプションを持っているか
  const isBusinessPlan: boolean = useMemo(() => {
    return stripeWorkspaceSubscriptions.data.length > 0;
  }, [stripeWorkspaceSubscriptions.data]);
  // memos / このユーザーが有効なサブスクリプションを持っているか
  const hasSubscription: boolean = useMemo(() => {
    return stripeCustomerSubscriptions.data.length > 0;
  }, [stripeCustomerSubscriptions.data]);
  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppButtons slot="start">
            <AppMenuButton />
          </AppButtons>
          <AppTitle>契約</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          {loading && <AppLoadingCard />}
          {error && <AppErrorCard>{error}</AppErrorCard>}
          {!loading && !error && (
            <>
              <Header>
                <MainTitle>プランと料金</MainTitle>
                <Lead>
                  基本無料でご利用頂けます。メンバーが5名を超える場合はビジネスプランへの加入をご検討ください。ビジネスプランはメンバー1名につき毎月500円でご利用いただけます。
                </Lead>
              </Header>
              <AppCard marginBottom="16px">
                <AppCardContent>
                  <Plan>
                    <PlanName>
                      ビジネスプラン
                      {isBusinessPlan && (
                        <PlanState>
                          <AppIcon
                            icon={checkmarkOutline}
                            style={{ fontSize: "20px" }}
                          />
                          加入中
                        </PlanState>
                      )}
                    </PlanName>
                    <PlanDescription>メンバー1名につき</PlanDescription>
                    <PlanPrice>
                      <PlanPriceCurrency>¥</PlanPriceCurrency>
                      <PlanPriceNumeric>500</PlanPriceNumeric>
                      <PlanPriceMonth>/月</PlanPriceMonth>
                    </PlanPrice>

                    {isBusinessPlan && (
                      <>
                        {PLATFORM.IS_CAPACITOR && (
                          <AppButton
                            size="large"
                            shape="round"
                            disabled={true}
                            style={{
                              "--color": "var(--ion-color-primary)",
                              "--background": "var(--app-color-white)",
                              "--box-shadow": "none",
                              opacity: 1,
                            }}
                          >
                            <AppLabel style={{ lineHeight: 1.2 }}>
                              アプリからご契約の変更へは進めません
                            </AppLabel>
                            <AppIcon icon={chevronForwardOutline} slot="end" />
                          </AppButton>
                        )}
                        {!PLATFORM.IS_CAPACITOR && (
                          <>
                            {hasSubscription && (
                              <AppButton
                                size="large"
                                shape="round"
                                style={{
                                  minWidth: "240px",
                                  "--color": "var(--ion-color-primary)",
                                  "--background": "var(--app-color-white)",
                                }}
                                onClick={() => {
                                  showLoading({
                                    cssClass: "app-loading",
                                    backdropDismiss: true,
                                  });
                                  Promise.resolve()
                                    .then(() =>
                                      appApis.createStripePortalLink(
                                        workspaceId
                                      )
                                    )
                                    .then((data) => {
                                      window.location.assign(data.url);
                                    })
                                    .catch((error) => {
                                      showAlert({
                                        message: i18nErrorToString(error),
                                      });
                                    })
                                    .finally(() => hideLoading());
                                }}
                              >
                                <AppLabel>契約情報</AppLabel>
                                <AppIcon
                                  icon={chevronForwardOutline}
                                  slot="end"
                                />
                              </AppButton>
                            )}
                            {!hasSubscription && (
                              <AppButton
                                size="large"
                                shape="round"
                                disabled={true}
                                style={{
                                  minWidth: "240px",
                                  "--color": "var(--ion-color-primary)",
                                  "--background": "var(--app-color-white)",
                                  "--box-shadow": "none",
                                  opacity: 1,
                                }}
                              >
                                <AppLabel>加入中</AppLabel>
                              </AppButton>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {!isBusinessPlan && (
                      <>
                        {PLATFORM.IS_CAPACITOR && (
                          <AppButton
                            size="large"
                            shape="round"
                            disabled={true}
                            style={{
                              minWidth: "240px",
                              "--color": "var(--ion-color-primary)",
                              "--background": "var(--app-color-white)",
                              "--box-shadow": "none",
                              opacity: 1,
                            }}
                          >
                            <AppLabel style={{ lineHeight: 1.2 }}>
                              アプリからご加入手続きへは進めません
                            </AppLabel>
                            <AppIcon icon={chevronForwardOutline} slot="end" />
                          </AppButton>
                        )}
                        {!PLATFORM.IS_CAPACITOR && stripeProductPriceId && (
                          <AppButton
                            size="large"
                            shape="round"
                            style={{
                              minWidth: "240px",
                              "--color": "var(--ion-color-primary)",
                              "--background": "var(--app-color-white)",
                            }}
                            onClick={() => {
                              showLoading({
                                cssClass: "app-loading",
                                backdropDismiss: false,
                              });
                              Promise.resolve()
                                .then(() =>
                                  appApis.subscribeStripeProduct(
                                    workspaceId,
                                    stripeProductPriceId
                                  )
                                )
                                .then((url) => {
                                  if (url) window.location.assign(url);
                                })
                                .catch((error) => {
                                  showAlert({
                                    message: i18nErrorToString(error),
                                  });
                                })
                                .finally(() => hideLoading());
                            }}
                          >
                            <AppLabel>加入へ進む</AppLabel>
                            <AppIcon icon={chevronForwardOutline} slot="end" />
                          </AppButton>
                        )}
                      </>
                    )}
                  </Plan>
                  <Annotations>
                    <Annotation>1メンバー当たりの価格です。</Annotation>
                    <Annotation>
                      メンバー数 × 1GB分のディスク容量をご利用いただけます。
                    </Annotation>
                    <Annotation>
                      メンバー数 × 1000個のファイル数をご利用いただけます。
                    </Annotation>
                  </Annotations>
                </AppCardContent>
              </AppCard>
            </>
          )}
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default TabSubscriptionPage;
