import { useRef, useState, FunctionComponent } from "react";
import {
  useIonModal,
  useIonAlert,
  useIonToast,
  IonTabs,
  IonMenu,
  IonLabel,
  IonIcon,
  IonPopover,
  useIonLoading,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { i18nText } from "../../utilities/UtilI18nText";
import { i18nErrorToString } from "../../utilities/UtilI18nText";
import { useHistory } from "react-router";
import {
  selectWorkspace,
  selectWorkspaces,
  selectWorkspacesError,
  selectWorkspacesLoading,
} from "../../store/workspacesSlice";
import {
  addOutline,
  checkmarkOutline,
  chevronDownOutline,
  logOutOutline,
} from "ionicons/icons";
import { appLinks, appRouterTabPages } from "../../utilities/UtilPage";
import styled from "styled-components";
import { useLoading, useError } from "../../hooks";
import {
  AppFooter,
  AppHeader,
  AppIcon,
  AppItem,
  AppItemGroup,
  AppLabel,
  AppSpinner,
  AppToolbar,
  AppWriteWorkspaceModal,
  AppWriteWorkspaceModalProps,
} from "..";
import { selectAuth } from "../../store/authSlice";
import { selectMember } from "../../store/membersSlice";
import { appApis } from "../../apis/Api";
import {
  selectWorkspaceTaskAndTaskCommentNotificationsCount,
  selectWorkspaceNotifications,
} from "../../store/notificationsSlice";
import AppContent from "../level1-atoms/AppContent";
import {
  selectEnableSideMenu,
  selectTabId,
  selectWorkspaceId,
  setTabId,
} from "../../store/uiSlice";
import { PAGES } from "../../utilities/UtilStatic";

interface Props {
  tabRef: InstanceType<typeof IonTabs>;
  routerRef: HTMLElement;
}

const Menu = styled(IonMenu)`
  --max-width: 240px;
  --min-width: 240px;
  --width: 240px;
`;

const Popover = styled(IonPopover)`
  --min-width: 240px;
`;

const MenuIcon = styled(IonIcon)<{ isActive: boolean }>`
  margin-right: 20px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
`;

const MenuLabel = styled(IonLabel)<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
`;

const Badge = styled.div`
  padding: 2px 4px;
  background: var(--app-color-notification);
  border-radius: 12px;
  font-size: 12px;
  color: var(--app-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
`;

const Section = styled.div`
  font-size: 14px;
  padding: 0 16px;
  margin-top: 32px;
  margin-bottom: 10px;
  opacity: 0.7;
  .ios & {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const AppMenu: FunctionComponent<Props> = ({ tabRef, routerRef }) => {
  // router
  const history = useHistory();
  const menuRef: React.RefObject<HTMLIonMenuElement> =
    useRef<HTMLIonMenuElement>(null);
  // store
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const member = useSelector(selectMember(auth ? auth.id : null));
  const enableSideMenu = useSelector(selectEnableSideMenu);
  const workspaceId = useSelector(selectWorkspaceId);
  const tabId = useSelector(selectTabId);
  const workspaces = useSelector(selectWorkspaces);
  const workspace = useSelector(selectWorkspace(workspaceId));
  const workspacesLoading = useSelector(selectWorkspacesLoading);
  const workspacesError = useSelector(selectWorkspacesError);
  const workspaceTaskAndTaskCommentNotificationsCount = useSelector(
    selectWorkspaceTaskAndTaskCommentNotificationsCount(workspaceId)
  );
  const loading = useLoading(workspacesLoading);
  const error = useError(workspacesError);
  // loading
  const [showLoading, hideLoading] = useIonLoading();
  // components
  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();
  const [workspacePopover, setWorkspacePopover] = useState<{
    show: boolean;
    event?: Event;
  }>({ show: false });
  const [showAppCreateWorkspaceModal, hideAppCreateWorkspaceModal] =
    useIonModal(AppWriteWorkspaceModal, {
      workspace: null,
      saveWorkspace: (workspace) => {
        if (!auth || !member) return;
        showLoading({ cssClass: "app-loading", backdropDismiss: true });
        Promise.resolve()
          .then(() => {
            return appApis.createWorkspace(
              auth.id,
              member.name,
              workspace.id,
              workspace.name,
              workspace.timezone
            );
          })
          .then((workspaceId) => {
            hideAppCreateWorkspaceModal();
            showToast({
              message: i18nText.success.workspace.create(),
              duration: 2000,
            });
            appApis.reloadApp();
            history.replace(appLinks.tab(workspaceId, PAGES.TABS.PROJECT));
            dispatch(setTabId(PAGES.TABS.PROJECT));
          })
          .catch((error) => {
            showAlert({ message: i18nErrorToString(error) });
          })
          .finally(() => hideLoading());
      },
      hide: () => hideAppCreateWorkspaceModal(),
    } as AppWriteWorkspaceModalProps);

  return (
    <Menu
      type="overlay"
      disabled={!enableSideMenu}
      contentId="main"
      swipeGesture={true}
      ref={menuRef}
    >
      <AppHeader className="ion-no-border">
        <AppToolbar color="navy">
          <AppItem
            color="navy"
            lines="none"
            button={true}
            detail={false}
            onClick={(e) => {
              setWorkspacePopover({
                show: true,
                event: { ...e.nativeEvent, target: e.currentTarget },
              });
            }}
          >
            {loading && <AppSpinner name="dots" color="white" />}
            {!loading && !error && workspace && (
              <AppLabel>{workspace && workspace.name}</AppLabel>
            )}
            <AppIcon
              icon={chevronDownOutline}
              size="small"
              className="ion-margin-start"
              slot="end"
            />
          </AppItem>
        </AppToolbar>
      </AppHeader>
      <AppContent color="navy">
        <AppItemGroup>
          {workspaceId &&
            member &&
            appRouterTabPages().map((page, index) => {
              return (
                page.roles.includes(member.role) &&
                page.showMenu && (
                  <div key={index}>
                    {page.tab === PAGES.TABS.MEMBER && (
                      <Section>管理者メニュー</Section>
                    )}
                    <AppItem
                      color="navy"
                      button={true}
                      detail={false}
                      lines="none"
                      onClick={() => {
                        tabRef.ionTabContextState.selectTab(page.tab);
                        dispatch(setTabId(page.tab));
                        menuRef.current?.close(true);
                      }}
                    >
                      <MenuIcon
                        icon={page.tab === tabId ? page.icon : page.iconOutline}
                        isActive={page.tab === tabId}
                        slot="start"
                      />
                      <MenuLabel isActive={page.tab === tabId}>
                        {page.name}
                      </MenuLabel>
                      {page.tab === PAGES.TABS.PROJECT &&
                        workspaceTaskAndTaskCommentNotificationsCount > 0 && (
                          <Badge>
                            {workspaceTaskAndTaskCommentNotificationsCount}
                          </Badge>
                        )}
                    </AppItem>
                  </div>
                )
              );
            })}
        </AppItemGroup>
      </AppContent>
      <AppFooter>
        <AppItemGroup>
          <AppItem
            color="navy"
            button={true}
            detail={false}
            lines="none"
            onClick={() => {
              showAlert({
                header: "ログアウト",
                message: "ログアウトしますか？",
                buttons: [
                  "キャンセル",
                  {
                    text: "Ok",
                    handler: () => {
                      Promise.resolve()
                        .then(() => appApis.signOut())
                        .then(() => history.replace(appLinks.signIn()))
                        .catch((error) => {
                          showAlert({
                            message: i18nErrorToString(error),
                          });
                        });
                    },
                  },
                ],
              });
            }}
          >
            <MenuIcon icon={logOutOutline} isActive={false} slot="start" />
            <MenuLabel isActive={false}>ログアウト</MenuLabel>
          </AppItem>
        </AppItemGroup>
      </AppFooter>
      {/* workspacePopover */}
      <Popover
        isOpen={workspacePopover.show}
        event={workspacePopover.event}
        onDidDismiss={() => setWorkspacePopover({ show: false })}
        className="app-popover-width240"
      >
        <AppItemGroup>
          {workspaces.map((workspace) => {
            return (
              <AppItem
                key={workspace.id}
                button={true}
                detail={false}
                onClick={() => {
                  setWorkspacePopover({ show: false });
                  history.replace(
                    appLinks.tab(workspace.id, PAGES.TABS.PROJECT)
                  );
                  dispatch(setTabId(PAGES.TABS.PROJECT));
                }}
              >
                {workspace.id === workspaceId ? (
                  <AppIcon
                    icon={checkmarkOutline}
                    slot="start"
                    color="primary"
                    key={workspace.id}
                    style={{ marginRight: "16px" }}
                  />
                ) : (
                  <AppIcon className="ion-margin-end" slot="start" />
                )}
                <AppLabel>{workspace.name}</AppLabel>
                <WorkspaceNotificationsCountBadge workspaceId={workspace.id} />
              </AppItem>
            );
          })}
          <AppItem
            lines="none"
            button={true}
            detail={false}
            onClick={() => {
              setWorkspacePopover({ show: false });
              showAppCreateWorkspaceModal({
                presentingElement: routerRef,
                canDismiss: true,
              });
            }}
          >
            <AppIcon
              icon={addOutline}
              className="ion-margin-end"
              slot="start"
              color="medium"
            />
            <AppLabel>{i18nText.sidemenu.createWorkspace()}</AppLabel>
          </AppItem>
        </AppItemGroup>
      </Popover>
    </Menu>
  );
};

export default AppMenu;

const WorkspaceNotificationsCountBadge: FunctionComponent<{
  workspaceId: string;
}> = ({ workspaceId }) => {
  const workspaceNotifications = useSelector(
    selectWorkspaceNotifications(workspaceId)
  );
  return (
    <>
      {workspaceNotifications.length > 0 && (
        <Badge>{workspaceNotifications.length}</Badge>
      )}
    </>
  );
};
