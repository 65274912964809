import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { IonIcon, IonCheckbox, IonRadio } from "@ionic/react";
import {
  arrowDownSharp,
  arrowUpSharp,
  calendarClearOutline,
  checkmarkOutline,
  closeOutline,
  hourglassOutline,
  layersOutline,
} from "ionicons/icons";
import { i18nText } from "../../../utilities/UtilI18nText";
import { OfficeMember, Timestamp } from "../../../models";
import {
  AppContent,
  AppCard,
  AppFooterButtons,
  AppActionButton,
  AppLabel,
  AppButton,
  AppIcon,
  AppHeader,
  AppToolbar,
  AppTitle,
  AppButtons,
  AppInnerContent,
  AppItem,
  AppItemGroup,
  AppLead,
  AppRadioGroup,
  AppPage,
} from "../..";
import { useAssignedTasks } from "../../../hooks";
import dayjs from "dayjs";
import styled from "styled-components";
import { without } from "lodash";
import { getTimestampNow, typeOfString } from "../../../utilities/UtilFunction";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../store/authSlice";
import { selectProjectEntities } from "../../../store/projectsSlice";

export interface AppSelectOfficeMemberTaskModalProps {
  officeMember: OfficeMember | null;
  selectOfficeMemberTask: (data: {
    workspaceId: string;
    memberId: string;
    taskIds: string[];
    taskId: string | null;
    projectId: string | null;
    updatedBy: string;
    updatedAt: Timestamp;
  }) => void;
  hide: () => void;
}

const CheckBox = styled(IonCheckbox)`
  margin-right: 24px;
`;

const ItemStates = styled.div`
  display: flex;
  flex-flow: row wrap;
  color: var(--app-color-grey600-rgba);
`;

const ItemState = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 4px 12px 0 0;
`;

const ItemStateIcon = styled(IonIcon)`
  margin-right: 4px;
  position: relative;
  top: 1px;
  color: var(--app-color-grey600-rgba);
`;

const ItemName = styled.div`
  font-size: 16px;
  white-space: normal;
`;

const ItemStateText = styled.div`
  font-size: 12px;
  &:not(:last-child) {
    margin-right: 4px;
    &::after {
      content: ",";
    }
  }
`;

const ItemPriority = styled(IonIcon)`
  margin-right: 4px;
  position: relative;
  top: 2px;
  color: var(--app-color-grey600-rgba);
`;

const Radio = styled(IonRadio)`
  margin-right: 24px;
`;

const AppSelectOfficeMemberTaskModal: FunctionComponent<
  AppSelectOfficeMemberTaskModalProps
> = ({ officeMember, selectOfficeMemberTask, hide }) => {
  // store
  const auth = useSelector(selectAuth);
  const projectEntities = useSelector(selectProjectEntities);
  // states
  const [form, setForm] = useState<OfficeMember | null>(null);
  // hooks 自分が担当のタスク
  const assignedTasks = useAssignedTasks({
    workspaceId: officeMember?.workspaceId ?? null,
    memberId: officeMember?.id ?? null,
  });
  // functions フォーム初期化
  useEffect(() => {
    if (officeMember) setForm(officeMember);
  }, [officeMember]);
  // functions 自分が担当＆選択したタスク
  const todayTasks = useMemo(() => {
    if (!form) return [];
    return assignedTasks.data.filter(({ id }) =>
      form.taskIds.some((taskId) => taskId === id)
    );
  }, [assignedTasks.data, form]);

  // 対応予定のタスクからチェックを外した時に
  // そのタスクが対応中のタスクにセットされていたら対応中から除外する
  useEffect(() => {
    if (!form || !form.taskId) return;
    const isSome = form.taskIds.some((id) => id === form.taskId);
    if (!isSome) {
      setForm({
        ...form,
        taskId: null,
        projectId: null,
      });
    }
  }, [form]);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>タスク選択</AppTitle>
          <AppButtons slot="end">
            <AppButton onClick={() => hide()}>
              <AppIcon slot="icon-only" icon={closeOutline} color="medium" />
            </AppButton>
          </AppButtons>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        {form && (
          <AppInnerContent>
            <AppLead marginBottom="16px">
              自分が担当のタスク一覧より、これから作業予定のタスクを選択してください（複数選択可）。
            </AppLead>
            {!assignedTasks.loading && !assignedTasks.error && (
              <>
                {assignedTasks.data.length === 0 && (
                  <AppLead marginBottom="16px">
                    自身が担当のタスクがありません。対応予定のタスクの担当者を、自分自身に設定し再度お試しください。
                  </AppLead>
                )}
                {assignedTasks.data.length > 0 && (
                  <AppCard marginBottom="16px">
                    <AppItemGroup>
                      {assignedTasks.data.map((task) => {
                        const isChecked = form.taskIds.some(
                          (taskId) => taskId === task.id
                        );
                        return (
                          <AppItem
                            key={task.id}
                            button={true}
                            detail={false}
                            onClick={() => {
                              let taskIds: string[] = [];
                              if (isChecked) {
                                taskIds = without(form.taskIds, task.id);
                              } else {
                                taskIds = [...form.taskIds, task.id];
                              }
                              setForm({
                                ...form,
                                taskIds,
                              });
                            }}
                          >
                            <CheckBox
                              slot="start"
                              checked={isChecked}
                              mode="md"
                            />
                            <AppLabel>
                              <ItemName>
                                {task.priority === 1 && (
                                  <ItemPriority icon={arrowUpSharp} />
                                )}
                                {task.priority === -1 && (
                                  <ItemPriority icon={arrowDownSharp} />
                                )}
                                {task.title}
                              </ItemName>
                              <ItemStates>
                                <ItemState>
                                  <ItemStateIcon icon={layersOutline} />
                                  <ItemStateText>
                                    {projectEntities[task.projectId]?.name}
                                  </ItemStateText>
                                </ItemState>
                                {typeOfString(task.limitDate) && (
                                  <ItemState>
                                    <ItemStateIcon
                                      icon={calendarClearOutline}
                                    />
                                    <ItemStateText>
                                      {dayjs(task.limitDate as string).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </ItemStateText>
                                  </ItemState>
                                )}
                                {task.estimatedHours && (
                                  <ItemState>
                                    <ItemStateIcon icon={hourglassOutline} />
                                    <ItemStateText>
                                      {task.estimatedHours}H
                                    </ItemStateText>
                                  </ItemState>
                                )}
                                {task.mainTaskId && (
                                  <ItemState>
                                    <ItemStateIcon icon={checkmarkOutline} />
                                    <ItemStateText>サブタスク</ItemStateText>
                                  </ItemState>
                                )}
                              </ItemStates>
                            </AppLabel>
                          </AppItem>
                        );
                      })}
                    </AppItemGroup>
                  </AppCard>
                )}
              </>
            )}
            {todayTasks.length > 0 && (
              <>
                <AppLead marginBottom="16px">
                  対応中のタスクを1つ選択してください。
                </AppLead>
                <AppCard>
                  <AppRadioGroup value={form.taskId ?? ""}>
                    <AppItem
                      button={true}
                      detail={false}
                      onClick={() => {
                        setForm({
                          ...form,
                          taskId: null,
                          projectId: null,
                        });
                      }}
                    >
                      <Radio slot="start" value="" mode="md" />
                      <AppLabel>-</AppLabel>
                    </AppItem>
                    {todayTasks.map((task) => (
                      <AppItem
                        key={task.id}
                        button={true}
                        detail={false}
                        onClick={() => {
                          setForm({
                            ...form,
                            taskId: task.id,
                            projectId: task.projectId,
                          });
                        }}
                      >
                        <Radio slot="start" mode="md" value={task.id} />
                        <AppLabel>{task.title}</AppLabel>
                      </AppItem>
                    ))}
                  </AppRadioGroup>
                </AppCard>
              </>
            )}
          </AppInnerContent>
        )}
      </AppContent>
      <AppFooterButtons>
        <AppActionButton onClick={() => hide()}>
          {i18nText.buttons.cancel()}
        </AppActionButton>
        <AppActionButton
          action="ok"
          onClick={() => {
            if (!form) return;
            const { workspaceId, id, taskIds, taskId, projectId } = form;
            selectOfficeMemberTask({
              workspaceId,
              memberId: id,
              taskIds,
              taskId: taskId === "" ? null : taskId,
              projectId: projectId,
              updatedBy: id,
              updatedAt: getTimestampNow(),
            });
          }}
        >
          {i18nText.buttons.ok()}
        </AppActionButton>
      </AppFooterButtons>
    </AppPage>
  );
};

export default AppSelectOfficeMemberTaskModal;
