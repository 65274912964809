import { FunctionComponent } from "react";
import AppContent from "../../level1-atoms/AppContent";
import AppHeader from "../../level1-atoms/AppHeader";
import AppInnerContent from "../../level1-atoms/AppInnerContent";
import AppPage from "../../level1-atoms/AppPage";
import AppTitle from "../../level1-atoms/AppTitle";
import AppToolbar from "../../level1-atoms/AppToolbar";

const Error404Page: FunctionComponent = () => {
  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>404</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent></AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default Error404Page;
