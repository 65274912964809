import { ComponentProps, FunctionComponent } from "react";
import { IonTitle } from "@ionic/react";
import styled from "styled-components";

interface Props extends ComponentProps<typeof IonTitle> {}

const Title = styled(IonTitle)``;

const AppTitle: FunctionComponent<Props> = ({ children, ...props }) => {
  return <Title {...props}>{children}</Title>;
};

export default AppTitle;
