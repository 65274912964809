import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { TaskMilestone } from "../models/TaskMilestone";
import { RootState } from "./";

interface TaskMilestonesState extends EntityState<TaskMilestone> {
  loading: boolean;
  error: string | null;
}

const adapter = createEntityAdapter<TaskMilestone>();

const { selectById } = adapter.getSelectors();

const initialState: TaskMilestonesState = adapter.getInitialState({
  loading: true,
  error: null,
});

export const taskMilestonesSlice = createSlice({
  name: "taskMilestones",
  initialState,
  reducers: {
    setTaskMilestones: (state, action: PayloadAction<TaskMilestone[]>) => {
      adapter.setAll(state, action.payload);
    },
    setTaskMilestonesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaskMilestonesError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setTaskMilestones,
  setTaskMilestonesLoading,
  setTaskMilestonesError,
} = taskMilestonesSlice.actions;

export const selectTaskMilestones = adapter.getSelectors(
  (state: RootState) => state.taskMilestones
).selectAll;

export const selectTaskMilestoneEntities = adapter.getSelectors(
  (state: RootState) => state.taskMilestones
).selectEntities;

export const selectTaskMilestonesLoading = (state: RootState) =>
  state.taskMilestones.loading;

export const selectTaskMilestonesError = (state: RootState) =>
  state.taskMilestones.error;

export const selectTaskMilestone = (id: string | null) => {
  return createSelector(
    (state: RootState) => state.taskMilestones,
    (state) => (selectById(state, id!) ? selectById(state, id!)! : null)
  );
};

export const selectProjectTaskMilestones = (projectId: string | null) => {
  return createSelector(
    (state: RootState) => selectTaskMilestones(state),
    (state) => state.filter((item) => item.projectId === projectId)
  );
};

export default taskMilestonesSlice.reducer;
