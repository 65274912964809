import {
  card,
  cardOutline,
  cube,
  cubeOutline,
  layers,
  layersOutline,
  people,
  peopleOutline,
  person,
  personAdd,
  personAddOutline,
  personOutline,
  settings,
  settingsOutline,
  time,
  timeOutline,
} from "ionicons/icons";
import { Role, TabId } from "../models";
import {
  SignInPage,
  SignUpPage,
  InvitationPage,
  TabProjectPage,
  TabMypagePage,
  TabMasterPage,
  TabMemberPage,
  TabSubscriptionPage,
  ProjectPage,
  ProjectDetailsPage,
  TasksPage,
  TaskPage,
  MemberDetailsPage,
  TeamDetailsPage,
  TabInvitationsPage,
  HomePage,
  ContactPage,
  PrivacyPolicyPage,
  TermsPage,
  TabOfficePage,
  WorkspaceDetailsPage,
  UpdateAccountEmail,
  UpdateAccountPassword,
  MyTasksPage,
  OfficePage,
  OfficeDetailsPage,
  TaskBookmarks,
  AccountPage,
  TabOfficeMembersHistories,
  DailyOfficeMembersHistories,
  DailyOfficeMemberHistories,
  CommercePage,
  UpdateOfficePage,
} from "../components";
import { ENV, PAGES, PLATFORM, ROLE } from "./UtilStatic";
import { FunctionComponent } from "react";

interface AppRouterPage {
  path: string;
  component: FunctionComponent<{ routerRef: HTMLElement }>;
  exact: boolean;
}

export const appRouterPages: () => AppRouterPage[] = () => {
  return [
    {
      path: `/`,
      component: PLATFORM.IS_CAPACITOR
        ? PLATFORM.IS_IOS
          ? SignInPage
          : SignUpPage
        : HomePage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}`,
      component: HomePage,
      exact: true,
    },
    {
      path: `/${PAGES.CONTACT}`,
      component: ContactPage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.CONTACT}`,
      component: ContactPage,
      exact: true,
    },
    {
      path: `/${PAGES.TEAMS}`,
      component: TermsPage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.TEAMS}`,
      component: TermsPage,
      exact: true,
    },
    {
      path: `/${PAGES.PRIVACY_POLICY}`,
      component: PrivacyPolicyPage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.PRIVACY_POLICY}`,
      component: PrivacyPolicyPage,
      exact: true,
    },
    {
      path: `/${PAGES.COMMERCE}`,
      component: CommercePage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.COMMERCE}`,
      component: CommercePage,
      exact: true,
    },
    // 認証
    {
      path: `/${PAGES.SIGNIN}`,
      component: SignInPage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.SIGNIN}`,
      component: SignInPage,
      exact: true,
    },
    {
      path: `/${PAGES.SIGNUP}`,
      component: SignUpPage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.SIGNUP}`,
      component: SignUpPage,
      exact: true,
    },
    // 招待
    {
      path: `/${PAGES.INVITATION}/${PAGES._WORKSPACE_ID}/${PAGES._INVITATION_ID}`,
      component: InvitationPage,
      exact: true,
    },
    {
      path: `/${PAGES._LANGUAGE}/${PAGES.INVITATION}/${PAGES._WORKSPACE_ID}/${PAGES._INVITATION_ID}`,
      component: InvitationPage,
      exact: true,
    },
  ];
};

export const appIndexPath = `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}`;

interface AppRouterTabPage {
  name: string;
  path: string;
  tab: TabId;
  icon: string;
  iconOutline: string;
  component: FunctionComponent<{ routerRef: HTMLElement }>;
  showMenu: boolean;
  showTab: boolean;
  roles: Role[];
}

export const appRouterTabPages: () => AppRouterTabPage[] = () => [
  {
    name: "プロジェクト",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.PROJECT})`,
    tab: PAGES.TABS.PROJECT,
    icon: layers,
    iconOutline: layersOutline,
    component: TabProjectPage,
    showMenu: true,
    showTab: true,
    roles: [ROLE.ADMIN, ROLE.MEMBER],
  },
  {
    name: "オフィス",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.OFFICE})`,
    tab: PAGES.TABS.OFFICE,
    icon: cube,
    iconOutline: cubeOutline,
    component: TabOfficePage,
    showMenu: true,
    showTab: true,
    roles: [ROLE.ADMIN, ROLE.MEMBER],
  },
  {
    name: "マイページ",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.MYPAGE})`,
    tab: PAGES.TABS.MYPAGE,
    icon: person,
    iconOutline: personOutline,
    component: TabMypagePage,
    showMenu: true,
    showTab: true,
    roles: [ROLE.ADMIN, ROLE.MEMBER],
  },
  {
    name: "メンバー",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.MEMBER})`,
    tab: PAGES.TABS.MEMBER,
    icon: people,
    iconOutline: peopleOutline,
    component: TabMemberPage,
    showMenu: true,
    showTab: false,
    roles: [ROLE.ADMIN],
  },
  {
    name: "メンバー招待",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.INVITATION})`,
    tab: PAGES.TABS.INVITATION,
    icon: personAdd,
    iconOutline: personAddOutline,
    component: TabInvitationsPage,
    showMenu: true,
    showTab: false,
    roles: [ROLE.ADMIN],
  },
  {
    name: "作業履歴",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.OFFICE_MEMBERS_HISTORIES})`,
    tab: PAGES.TABS.OFFICE_MEMBERS_HISTORIES,
    icon: time,
    iconOutline: timeOutline,
    component: TabOfficeMembersHistories,
    showMenu: true,
    showTab: false,
    roles: [ROLE.ADMIN],
  },
  {
    name: "各種設定",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.MASTER})`,
    tab: PAGES.TABS.MASTER,
    icon: settings,
    iconOutline: settingsOutline,
    component: TabMasterPage,
    showMenu: true,
    showTab: false,
    roles: [ROLE.ADMIN],
  },
  {
    name: "契約",
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/:tabId(${PAGES.TABS.SUBSCRIPTION})`,
    tab: PAGES.TABS.SUBSCRIPTION,
    icon: card,
    iconOutline: cardOutline,
    component: TabSubscriptionPage,
    showMenu: !PLATFORM.IS_CAPACITOR,
    showTab: false,
    roles: [ROLE.ADMIN],
  },
];

export const appRouterInsideTabPages: AppRouterPage[] = [
  //  プロジェクト
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.PROJECT}/${PAGES._PROJECT_ID}`,
    component: ProjectPage,
    exact: true,
  },
  //  プロジェクト詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.PROJECT}/${PAGES.DETAILS}/${PAGES._PROJECT_ID}`,
    component: ProjectDetailsPage,
    exact: true,
  },
  // タスク一覧（リスト表示）
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.PROJECT}/${PAGES._PROJECT_ID}/${PAGES.TASKS}/${PAGES.TASK_LIST}`,
    component: TasksPage,
    exact: true,
  },
  // タスク詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.PROJECT}/${PAGES._PROJECT_ID}/${PAGES.TASK}/${PAGES._TASK_ID}`,
    component: TaskPage,
    exact: true,
  },
  // タスク詳細（対象のコメントまでスクロール）
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.PROJECT}/${PAGES._PROJECT_ID}/${PAGES.TASK}/${PAGES._TASK_ID}/${PAGES.COMMENT}/${PAGES._TASK_COMMENT_ID}`,
    component: TaskPage,
    exact: true,
  },
  // オフィス
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE}/${PAGES._OFFICE_ID}`,
    component: OfficePage,
    exact: true,
  },
  // オフィス詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE}/${PAGES.DETAILS}/${PAGES._OFFICE_ID}`,
    component: OfficeDetailsPage,
    exact: true,
  },
  // オフィス更新
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE}/${PAGES.UPDATE}/${PAGES._OFFICE_ID}`,
    component: UpdateOfficePage,
    exact: true,
  },
  // 自分のタスクブックマーク
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.TASK_BOOKMARKS}`,
    component: TaskBookmarks,
    exact: true,
  },
  // マイタスク
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.TASKS}`,
    component: MyTasksPage,
    exact: true,
  },
  // アカウント
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.ACCOUNT}`,
    component: AccountPage,
    exact: true,
  },
  // アカウント Eメール更新
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.ACCOUNT}/${PAGES.EMAIL}/${PAGES.UPDATE}`,
    component: UpdateAccountEmail,
    exact: true,
  },
  // アカウント パスワード更新
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.ACCOUNT}/${PAGES.PASSWORD}/${PAGES.UPDATE}`,
    component: UpdateAccountPassword,
    exact: true,
  },
  // ワークスペース詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.WORKSPACE}/${PAGES.DETAILS}/${PAGES._WORKSPACE_ID}`,
    component: WorkspaceDetailsPage,
    exact: true,
  },
  // プロジェクト詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.PROJECT}/${PAGES.DETAILS}/${PAGES._PROJECT_ID}`,
    component: ProjectDetailsPage,
    exact: true,
  },
  // チーム詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.TEAM}/${PAGES.DETAILS}/${PAGES._TEAM_ID}`,
    component: TeamDetailsPage,
    exact: true,
  },
  // メンバー詳細
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.MEMBER}/${PAGES.DETAILS}/${PAGES._MEMBER_ID}`,
    component: MemberDetailsPage,
    exact: true,
  },
  // 作業履歴（月）
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.MEMBER}/${PAGES._MEMBER_ID}`,
    component: TabOfficeMembersHistories,
    exact: true,
  },
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.TEAM}/${PAGES._TEAM_ID}`,
    component: TabOfficeMembersHistories,
    exact: true,
  },
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.PROJECT}/${PAGES._PROJECT_ID}`,
    component: TabOfficeMembersHistories,
    exact: true,
  },
  // 作業履歴（日）
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${PAGES._DATE}`,
    component: DailyOfficeMembersHistories,
    exact: true,
  },
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${PAGES._DATE}/${PAGES.MEMBER}/${PAGES._MEMBER_ID}`,
    component: DailyOfficeMembersHistories,
    exact: true,
  },
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${PAGES._DATE}/${PAGES.TEAM}/${PAGES._TEAM_ID}`,
    component: DailyOfficeMembersHistories,
    exact: true,
  },
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${PAGES._DATE}/${PAGES.PROJECT}/${PAGES._PROJECT_ID}`,
    component: DailyOfficeMembersHistories,
    exact: true,
  },
  // 作業履歴（日）（メンバー別）
  {
    path: `/${PAGES.WORKSPACE}/${PAGES._WORKSPACE_ID}/${PAGES._TAB}/${PAGES.OFFICE_MEMBER_HISTORIES}/${PAGES.DATE}/${PAGES._DATE}/${PAGES.MEMBER}/${PAGES._MEMBER_ID}`,
    component: DailyOfficeMemberHistories,
    exact: true,
  },
];

export const appLinks = {
  // ランディングページ
  home: (language?: string) => (language ? `/${PAGES._LANGUAGE}` : `/`),
  contact: (language?: string) =>
    language ? `/${PAGES._LANGUAGE}/${PAGES.CONTACT}` : `/${PAGES.CONTACT}`,
  terms: (language?: string) =>
    language ? `/${PAGES._LANGUAGE}/${PAGES.TEAMS}` : `/${PAGES.TEAMS}`,
  privacyPolicy: (language?: string) =>
    language
      ? `/${PAGES._LANGUAGE}/${PAGES.PRIVACY_POLICY}`
      : `/${PAGES.PRIVACY_POLICY}`,
  commerce: (language?: string) =>
    language ? `/${PAGES._LANGUAGE}/${PAGES.COMMERCE}` : `/${PAGES.COMMERCE}`,
  // 認証
  signIn: (language?: string) =>
    language ? `/${PAGES._LANGUAGE}/${PAGES.SIGNIN}` : `/${PAGES.SIGNIN}`,
  signUp: (language?: string) =>
    language ? `/${PAGES._LANGUAGE}/${PAGES.SIGNUP}` : `/${PAGES.SIGNUP}`,
  // 招待
  invitation: (workspaceId: string, invitationId: string, language?: string) =>
    language
      ? `/${language}/${PAGES.INVITATION}/${workspaceId}/${invitationId}`
      : `/${PAGES.INVITATION}/${workspaceId}/${invitationId}`,
  // アプリタブ
  tab: (workspaceId: string, tab: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tab}`,
  //  プロジェクト
  project: (workspaceId: string, tabId: TabId, projectId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.PROJECT}/${projectId}`,
  projectDetails: (workspaceId: string, tabId: TabId, projectId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.PROJECT}/${PAGES.DETAILS}/${projectId}`,
  // タスク
  tasks: (workspaceId: string, tabId: TabId, projectId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.PROJECT}/${projectId}/${PAGES.TASKS}/${PAGES.TASK_LIST}`,
  task: (
    workspaceId: string,
    tabId: TabId,
    projectId: string,
    taskId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.PROJECT}/${projectId}/${PAGES.TASK}/${taskId}`,
  taskComment: (
    workspaceId: string,
    tabId: TabId,
    projectId: string,
    taskId: string,
    taskCommentId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.PROJECT}/${projectId}/${PAGES.TASK}/${taskId}/${PAGES.COMMENT}/${taskCommentId}`,
  // マイページ
  myTaskBookmarks: (workspaceId: string, tabId: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.TASK_BOOKMARKS}`,
  myTasks: (workspaceId: string, tabId: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.TASKS}`,
  // アカウント
  account: (workspaceId: string, tabId: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.ACCOUNT}`,
  emailUpdate: (workspaceId: string, tabId: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.ACCOUNT}/${PAGES.EMAIL}/${PAGES.UPDATE}`,
  passwordUpdate: (workspaceId: string, tabId: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.ACCOUNT}/${PAGES.PASSWORD}/${PAGES.UPDATE}`,
  // メンバー
  memberDetails: (workspaceId: string, tabId: TabId, memberId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.MEMBER}/${PAGES.DETAILS}/${memberId}`,
  office: (workspaceId: string, tabId: TabId, officeId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE}/${officeId}`,
  workspaceDetails: (workspaceId: string, tabId: TabId) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.WORKSPACE}/${PAGES.DETAILS}/${workspaceId}`,
  officeDetails: (workspaceId: string, tabId: TabId, officeId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE}/${PAGES.DETAILS}/${officeId}`,
  officeUpdate: (workspaceId: string, tabId: TabId, officeId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE}/${PAGES.UPDATE}/${officeId}`,
  teamDetails: (workspaceId: string, tabId: TabId, teamId: string) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.TEAM}/${PAGES.DETAILS}/${teamId}`,
  monthlyOfficeMembersHistoriesOfMember: (
    workspaceId: string,
    tabId: TabId,
    memberId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.MEMBER}/${memberId}`,
  monthlyOfficeMembersHistoriesOfTeam: (
    workspaceId: string,
    tabId: TabId,
    teamId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.TEAM}/${teamId}`,
  monthlyOfficeMembersHistoriesOfProject: (
    workspaceId: string,
    tabId: TabId,
    projectId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.PROJECT}/${projectId}`,
  dailyOfficeMembersHistories: (
    workspaceId: string,
    tabId: TabId,
    date: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${date}`,
  dailyOfficeMembersHistoriesOfMember: (
    workspaceId: string,
    tabId: TabId,
    date: string,
    memberId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${date}/${PAGES.MEMBER}/${memberId}`,
  dailyOfficeMembersHistoriesOfTeam: (
    workspaceId: string,
    tabId: TabId,
    date: string,
    teamId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${date}/${PAGES.TEAM}/${teamId}`,
  dailyOfficeMembersHistoriesOfProject: (
    workspaceId: string,
    tabId: TabId,
    date: string,
    projectId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBERS_HISTORIES}/${PAGES.DATE}/${date}/${PAGES.PROJECT}/${projectId}`,
  dailyOfficeMemberHistories: (
    workspaceId: string,
    tabId: TabId,
    date: string,
    memberId: string
  ) =>
    `/${PAGES.WORKSPACE}/${workspaceId}/${tabId}/${PAGES.OFFICE_MEMBER_HISTORIES}/${PAGES.DATE}/${date}/${PAGES.MEMBER}/${memberId}`,
};
