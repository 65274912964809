import { HTMLAttributes, forwardRef } from "react";
import styled from "styled-components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  marginBottom?: string;
}

const Card = styled.div`
  width: 100%;
  background: var(--app-color-white);
  border-radius: 8px;
  border: 1px solid var(--app-color-grey300);
  overflow: hidden;

  ion-item-group > ion-item:first-child::part(native),
  ion-reorder-group > ion-item:first-child::part(native),
  ion-radio-group > ion-item:first-child::part(native) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  ion-item-group > ion-item:last-child::part(native),
  ion-reorder-group > ion-item:last-child::part(native),
  ion-radio-group > ion-item:last-child::part(native) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const AppCard = forwardRef<HTMLDivElement, Props>(
  ({ marginBottom, style, children, ...props }, ref) => {
    return (
      <Card {...props} style={{ marginBottom, ...style }} ref={ref}>
        {children}
      </Card>
    );
  }
);

export default AppCard;
