// firebase
import { initializeApp } from "firebase/app";
import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateEmail,
  updatePassword,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import {
  Firestore,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
  Query,
  setDoc,
  updateDoc,
  deleteDoc,
  collectionGroup,
  orderBy,
  DocumentReference,
  writeBatch,
  increment,
  limit,
  QueryConstraint,
  arrayRemove,
  CollectionReference,
  enableMultiTabIndexedDbPersistence,
} from "firebase/firestore";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";
import {
  FirebaseStorage,
  getStorage,
  ref,
  listAll,
  uploadBytes,
  uploadString,
  deleteObject,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import { Analytics, getAnalytics } from "firebase/analytics";
// rxfire
import { collectionData, docData } from "rxfire/firestore";
import { authState } from "rxfire/auth";
// rxjs
import { Observable, combineLatest, of } from "rxjs";
import { map, switchMap, catchError, tap } from "rxjs/operators";
// other
import {
  FIRESTORE,
  CLOUDFUNCTIONS,
  ACTION,
  PAGES,
  STORAGE,
  PLAN,
  ENV,
} from "../utilities/UtilStatic";
import {
  User,
  Workspace,
  Project,
  Member,
  Timezone,
  Task,
  TaskState,
  TaskTag,
  TaskMilestone,
  Team,
  Invitation,
  TaskComment,
  TaskFile,
  ProjectForm,
  TaskBookmark,
  Office,
  OfficeMember,
  TaskHistory,
  OfficeMemberHistory,
  Device,
  StripeSubscription,
  StripeSession,
  StripeProduct,
  StripePrice,
  AppConfig,
  Role,
} from "../models";
import {
  getRoot,
  removeEmailAndPassword,
  tokenize,
  typeOfString,
} from "../utilities/UtilFunction";
import { sliceByNumber, getTimezone } from "../utilities/UtilFunction";
import { cloneDeep, uniq } from "lodash";
import { generateDevice, generateUser } from "../utilities/UtilFunction";
import { i18nError } from "../utilities/UtilI18nText";
import { PushNotifications } from "@capacitor/push-notifications";
import dayjs from "dayjs";

class AppApi {
  private readonly auth: Auth;
  private readonly REGION = "asia-northeast1";
  private readonly firestore: Firestore;
  private readonly functions: Functions;
  private readonly storage: FirebaseStorage;
  private readonly analytics: Analytics;

  constructor() {
    const firebaseConfig = (() => {
      if (ENV.FIREBASE.USE.DEV) return ENV.FIREBASE.CONFIG.DEV;
      if (ENV.FIREBASE.USE.TEST) return ENV.FIREBASE.CONFIG.TEST;
      if (ENV.FIREBASE.USE.PROD) return ENV.FIREBASE.CONFIG.PROD;
    })();
    const app = initializeApp(firebaseConfig);
    initializeAuth(app, { persistence: indexedDBLocalPersistence });
    this.auth = getAuth(app);
    this.firestore = getFirestore(app);
    this.functions = getFunctions(app, this.REGION);
    this.storage = getStorage(app);
    this.analytics = getAnalytics(app);
    enableMultiTabIndexedDbPersistence(this.firestore);
  }

  // ==================================================
  //  認証系
  // ==================================================
  // サインイン
  async signIn(email: string, password: string): Promise<string> {
    const userCredential = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    if (!userCredential.user) throw Error(i18nError.unauthenticated());
    return userCredential.user.uid;
  }

  // サインアウト
  async signOut(): Promise<void> {
    await PushNotifications.removeAllListeners().catch((error) => {});
    await removeEmailAndPassword().catch((error) => {}); // サインインに使用したIDとPASSも削除
    await this.auth.signOut();
  }

  // サインアップ
  async signUp(
    email: string,
    password: string,
    language?: string
  ): Promise<void> {
    const userCredential = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    if (!userCredential.user) throw Error(i18nError.unauthenticated());
    const user = generateUser(userCredential.user.uid, getTimezone());
    if (language) {
      user.language = language;
    }
    await setDoc(
      doc(this.firestore, FIRESTORE.USERS, user.id) as DocumentReference<User>,
      user
    );
  }

  async deleteAccount(): Promise<void> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.DELETE_ACCOUNT
    );
    await callable();
  }

  $readAuthState() {
    return authState(this.auth).pipe(
      map((user) => (user ? { ...user, email: user.email ?? "" } : null))
    );
  }

  $readUserId(): Observable<string> {
    return authState(this.auth).pipe(
      map((auth) => {
        if (!auth) throw new Error(i18nError.unauthenticated());
        return auth.uid;
      })
    );
  }

  async updateEmail(email: string): Promise<void> {
    const user = await this.auth.currentUser;
    if (!user) throw i18nError.unauthenticated();
    await updateEmail(user, email);
  }

  async updatePassword(password: string): Promise<void> {
    const user = await this.auth.currentUser;
    if (!user) throw i18nError.unauthenticated();
    await updatePassword(user, password);
  }

  $readAppConfig(): Observable<AppConfig> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.PUBLICS,
        FIRESTORE.APP_CONFIG
      ) as DocumentReference<AppConfig>
    );
  }

  reloadApp(): void {
    const user = this.auth.currentUser;
    this.auth.updateCurrentUser(user).catch((error) => {});
  }

  // ==================================================
  //  User
  // ==================================================
  $readUser(): Observable<User> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        docData(
          doc(
            this.firestore,
            FIRESTORE.USERS,
            userId
          ) as DocumentReference<User>
        )
      ),
      tap((item) => {
        if (!item) throw new Error(i18nError.notFound());
      })
    );
  }

  async getUser(userId: string): Promise<User> {
    return await getDoc(
      doc(this.firestore, FIRESTORE.USERS, userId) as DocumentReference<User>
    ).then((doc) => {
      const data = doc.data();
      if (!data) throw i18nError.notFound();
      return data;
    });
  }

  // ==================================================
  //  Device
  // ==================================================
  async setDevice(token: string, platform: "ios" | "android"): Promise<void> {
    const user = this.auth.currentUser;
    if (!user) throw Error(i18nError.unauthenticated());
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.DEVICES,
        token
      ) as DocumentReference<Device>,
      generateDevice(token, user.uid, platform)
    );
  }

  // ==================================================
  //  Workspace
  // ==================================================
  async createWorkspace(
    userId: string,
    userName: string,
    workspaceId: string,
    workspaceName: string,
    timezone: Timezone | null
  ): Promise<string> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.CREATE_WORKSPACE
    );
    await callable({
      workspaceId,
      workspaceName,
      userName,
      timezone,
    });
    return workspaceId;
  }

  // $readWorkspace(workspaceId: string): Observable<Workspace> {
  //   return docData(
  //     doc(
  //       this.firestore,
  //       FIRESTORE.WORKSPACES,
  //       workspaceId
  //     ) as DocumentReference<Workspace>
  //   ).pipe(
  //     tap((data) => {
  //       if (!data) throw new Error(i18nError.notFound());
  //     })
  //   );
  // }

  async getWorkspace(workspaceId: string): Promise<Workspace> {
    return await getDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId
      ) as DocumentReference<Workspace>
    ).then((doc) => {
      const data = doc.data();
      if (!data) throw i18nError.notFound();
      return data;
    });
  }

  async getWorkspaces(memberId: string): Promise<Workspace[]> {
    const members = await getDocs(
      query(
        collectionGroup(this.firestore, FIRESTORE.MEMBERS),
        where("id", "==", memberId),
        orderBy("createdAt", "asc")
      )
    ).then(({ docs }) => docs.map((doc) => doc.data() as Member));
    const workspaces = await Promise.all(
      members.map(({ workspaceId }) =>
        this.getWorkspace(workspaceId).catch((error) => null)
      )
    );
    return workspaces.filter((item) => item !== null).map((item) => item!);
  }

  // $readMyWorkspaces(): Observable<Workspace[]> {
  //   return this.$readUserId().pipe(
  //     switchMap((userId) => {
  //       return collectionData(
  //         query(
  //           collectionGroup(this.firestore, FIRESTORE.MEMBERS) as Query<Member>,
  //           where("id", "==", userId),
  //           orderBy("createdAt", "asc")
  //         )
  //       );
  //     }),
  //     switchMap((members) => {
  //       return combineLatest(
  //         members.map(({ workspaceId }) =>
  //           this.$readWorkspace(workspaceId).pipe(
  //             catchError((error) => of(null))
  //           )
  //         )
  //       );
  //     }),
  //     map((items) => items.filter((item) => item).map((item) => item!))
  //   );
  // }

  async getMyWorkspaces(): Promise<Workspace[]> {
    const userId = this.auth.currentUser?.uid;
    if (!userId) throw new Error(i18nError.unauthenticated());
    const { docs } = await getDocs(
      query(
        collectionGroup(this.firestore, FIRESTORE.MEMBERS) as Query<Member>,
        where("id", "==", userId),
        orderBy("createdAt", "asc")
      )
    );
    const members = docs.map((doc) => doc.data());

    const workspaces = await Promise.all(
      members.map(({ workspaceId }) =>
        getDoc(
          doc(
            this.firestore,
            FIRESTORE.WORKSPACES,
            workspaceId
          ) as DocumentReference<Workspace>
        ).then((data) => data.data())
      )
    );
    return workspaces.filter((item) => item !== undefined).map((item) => item!);
  }

  async updateWorkspace(workspace: Partial<Workspace>): Promise<void> {
    if (!workspace.id) {
      throw new Error(i18nError.invalidArgument());
    }
    await updateDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspace.id
      ) as DocumentReference<Workspace>,
      workspace
    );
  }

  async deleteWorkspace(workspaceId: string): Promise<void> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.DELETE_WORKSPACE
    );
    await callable({ workspaceId });
  }

  // ==================================================
  //  Workspace / Member
  // ==================================================
  $readMembers(workspaceId: string): Observable<Member[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.MEMBERS
        ) as CollectionReference<Member>,
        orderBy("order", "asc")
      )
    );
  }

  async getMembers(workspaceId: string): Promise<Member[]> {
    const { docs } = await getDocs(
      collection(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.MEMBERS
      )
    );
    return docs.map((doc) => doc.data() as Member);
  }

  // ==================================================
  //  Workspace / Project
  // ==================================================
  $readMemberProjects(
    workspaceId: string,
    memberId: string
  ): Observable<Project[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.PROJECTS
        ) as CollectionReference<Project>,
        where("allowMemberIds", "array-contains", memberId)
      )
    ).pipe(map((items) => items.sort((a, b) => a.order - b.order)));
  }

  $readProject(workspaceId: string, projectId: string): Observable<Project> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.PROJECTS,
        projectId
      ) as DocumentReference<Project>
    ).pipe(
      tap((item) => {
        if (!item) throw new Error(i18nError.notFound());
      })
    );
  }

  $readProjects(workspaceId: string): Observable<Project[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.PROJECTS
        ) as CollectionReference<Project>
      )
    ).pipe(map((items) => items.sort((a, b) => a.order - b.order)));
  }

  async writeProject(projectForm: ProjectForm): Promise<void> {
    const action = projectForm.action;
    const project = projectForm.project;
    const taskStateForms = projectForm.taskStates;
    const taskMilestoneForms = projectForm.taskMilestones;
    const taskTagForms = projectForm.taskTags;
    const batch = writeBatch(this.firestore);
    // batch project
    const ref = doc(
      this.firestore,
      FIRESTORE.WORKSPACES,
      project.workspaceId,
      FIRESTORE.PROJECTS,
      project.id
    ) as DocumentReference<Project>;
    if (action === ACTION.CREATE) {
      batch.set(ref, project);
    }
    if (action === ACTION.UPDATE) {
      batch.update(ref, { ...project });
    }
    // batch taskStates
    for (const taskStateForm of taskStateForms) {
      const { action, ...taskState } = taskStateForm;
      const ref = doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        taskState.workspaceId,
        FIRESTORE.TASK_STATES,
        taskState.id
      ) as DocumentReference<TaskState>;
      if (action === ACTION.CREATE) batch.set(ref, taskState);
      if (action === ACTION.UPDATE) batch.update(ref, taskState);
      if (action === ACTION.DELETE) batch.delete(ref);
    }
    // batch taskMilestones
    for (const taskMilestoneForm of taskMilestoneForms) {
      const { action, ...taskMilestone } = taskMilestoneForm;
      const ref = doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        taskMilestone.workspaceId,
        FIRESTORE.TASK_MILESTONES,
        taskMilestone.id
      ) as DocumentReference<TaskMilestone>;
      if (action === ACTION.CREATE) batch.set(ref, taskMilestone);
      if (action === ACTION.UPDATE) batch.update(ref, taskMilestone);
      if (action === ACTION.DELETE) batch.delete(ref);
    }
    // batch taskTags
    for (const taskTagForm of taskTagForms) {
      const { action, ...taskTag } = taskTagForm;
      const ref = doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        taskTag.workspaceId,
        FIRESTORE.TASK_TAGS,
        taskTag.id
      ) as DocumentReference<TaskTag>;
      if (action === ACTION.CREATE) batch.set(ref, taskTag);
      if (action === ACTION.UPDATE) batch.update(ref, taskTag);
      if (action === ACTION.DELETE) batch.delete(ref);
    }
    await batch.commit();
  }

  async deleteProject(workspaceId: string, projectId: string): Promise<void> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.DELETE_PROJECT
    );
    await callable({ workspaceId, projectId });
  }

  async reorderProjects(
    workspaceId: string,
    projects: Project[],
    fromIndex: number,
    toIndex: number
  ): Promise<void> {
    const batch = this.reorderBatch(projects, fromIndex, toIndex, [
      FIRESTORE.WORKSPACES,
      workspaceId,
      FIRESTORE.PROJECTS,
    ]);
    await batch.commit();
  }

  // ==================================================
  //  Workspace / Project / Task
  // ==================================================
  async createTask(task: Task): Promise<void> {
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        task.workspaceId,
        FIRESTORE.TASKS,
        task.id
      ) as DocumentReference<Task>,
      task
    );
  }

  $readListTasks(
    workspaceId: string,
    projectId: string,
    stateId: string | "default" | "all",
    assignedMemberId: string | "all" | null,
    milestoneId: string | "all" | null,
    tagIds: string[],
    orderKey: "order" | "createdAt" | "updatedAt" | "limitDate" | "priority",
    limitNum: number
  ): Observable<Task[]> {
    const queryConstraint: QueryConstraint[] = [];
    queryConstraint.push(where("projectId", "==", projectId));
    queryConstraint.push(where("mainTaskId", "==", null));
    if (stateId === "default") {
      queryConstraint.push(where("done", "==", false));
    } else if (stateId !== "all") {
      queryConstraint.push(where("stateId", "==", stateId));
    }
    if (assignedMemberId === null) {
      queryConstraint.push(where("assignedMemberId", "==", null));
    } else if (assignedMemberId !== "all") {
      queryConstraint.push(where("assignedMemberId", "==", assignedMemberId));
    }
    if (milestoneId === null) {
      queryConstraint.push(where("milestoneId", "==", null));
    } else if (milestoneId !== "all") {
      queryConstraint.push(where("milestoneId", "==", milestoneId));
    }
    if (tagIds.length > 0) {
      queryConstraint.push(where("tagIds", "array-contains-any", tagIds));
    }
    if (orderKey === "order") {
      queryConstraint.push(orderBy("order", "asc"));
    } else if (orderKey === "createdAt") {
      queryConstraint.push(orderBy("createdAt", "desc"));
    } else if (orderKey === "updatedAt") {
      queryConstraint.push(orderBy("updatedAt", "desc"));
    } else if (orderKey === "limitDate") {
      queryConstraint.push(orderBy("limitDate", "asc"));
    } else if (orderKey === "priority") {
      queryConstraint.push(orderBy("priority", "desc"));
    }
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASKS
        ) as CollectionReference<Task>,
        ...queryConstraint,
        limit(limitNum)
      )
    );
  }

  $readTask(workspaceId: string, taskId: string): Observable<Task> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.TASKS,
        taskId
      ) as DocumentReference<Task | undefined>
    ).pipe(
      map((item) => {
        if (!item) throw new Error(i18nError.notFound());
        return item;
      })
    );
  }

  // 取得できなかったものは除外
  $readTasks(workspaceId: string, taskIds: string[]): Observable<Task[]> {
    return combineLatest(
      taskIds.map((taskId) =>
        this.$readTask(workspaceId, taskId).pipe(
          catchError((error) => of(null))
        )
      )
    ).pipe(
      map((tasks) => tasks.filter((task) => task !== null)),
      map((tasks) => tasks.map((task) => task!))
    );
  }

  $readSubTasks(
    workspaceId: string,
    projectId: string,
    taskIds: string[]
  ): Observable<Task[]> {
    const twoDimensionalArray = sliceByNumber(taskIds, 10);
    return combineLatest(
      twoDimensionalArray.map((array) => {
        return collectionData(
          query(
            collection(
              this.firestore,
              FIRESTORE.WORKSPACES,
              workspaceId,
              FIRESTORE.TASKS
            ) as CollectionReference<Task>,
            where("projectId", "==", projectId),
            where("mainTaskId", "in", array)
          )
        );
      })
    ).pipe(
      map((items) => items.flat()),
      map((items) => items.sort((a, b) => a.order - b.order)),
      map((items) =>
        items.sort((a, b) => {
          if (a.done === b.done) return 0;
          return a.done ? 1 : -1;
        })
      )
    );
  }

  $readAssignedTask(workspaceId: string, memberId: string): Observable<any> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASKS
        ) as CollectionReference<Task>,
        where("assignedMemberId", "==", memberId),
        where("done", "==", false)
      )
    ).pipe(
      map((items) =>
        items.sort((a, b) => {
          if (!typeOfString(a.limitDate)) return 0;
          if (!typeOfString(b.limitDate)) return -1;
          return (
            dayjs(a.limitDate as string).unix() -
            dayjs(b.limitDate as string).unix()
          );
        })
      )
    );
  }

  async updateTask(task: Partial<Task>): Promise<void> {
    if (!task.workspaceId || !task.id) {
      throw new Error(i18nError.invalidArgument());
    }
    await updateDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        task.workspaceId,
        FIRESTORE.TASKS,
        task.id
      ) as DocumentReference<Task>,
      task
    );
  }

  async updateTaskAlreadyRead(
    workspaceId: string,
    taskId: string
  ): Promise<void> {
    await updateDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.TASKS,
        taskId
      ) as DocumentReference<Task>,
      {
        unreadNotificationMemberId: null,
      }
    );
  }

  async deleteTask(workspaceId: string, taskId: string): Promise<void> {
    const callable = httpsCallable(this.functions, CLOUDFUNCTIONS.DELETE_TASK);
    await callable({ workspaceId, taskId });
  }

  async reorderTasks(
    workspaceId: string,
    items: Task[],
    fromIndex: number,
    toIndex: number
  ): Promise<void> {
    const batch = this.reorderBatch(items, fromIndex, toIndex, [
      FIRESTORE.WORKSPACES,
      workspaceId,
      FIRESTORE.TASKS,
    ]);
    await batch.commit();
  }

  $readTaskHistories(
    workspaceId: string,
    projectId: string,
    taskId: string
  ): Observable<TaskHistory[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASK_HISTORIES
        ) as CollectionReference<TaskHistory>,
        where("projectId", "==", projectId),
        where("taskId", "==", taskId),
        orderBy("createdAt", "asc")
      )
    );
  }

  $searchProjectTasks(
    workspaceId: string,
    projectId: string,
    word: string
  ): Observable<Task[]> {
    const queryConstraint: QueryConstraint[] = [];
    queryConstraint.push(where("projectId", "==", projectId));
    tokenize(word).forEach((token) => {
      queryConstraint.push(where(`tokenMap.${token}`, "==", true));
    });
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASKS
        ) as CollectionReference<Task>,
        ...queryConstraint
      )
    );
  }

  // ==================================================
  //  Workspace / Project / TaskComment
  // ==================================================
  async createTaskComment(taskComment: TaskComment): Promise<void> {
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        taskComment.workspaceId,
        FIRESTORE.TASK_COMMENTS,
        taskComment.id
      ) as DocumentReference<TaskComment>,
      taskComment
    );
  }

  $readTaskComments(
    workspaceId: string,
    projectId: string,
    taskId: string
  ): Observable<TaskComment[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASK_COMMENTS
        ) as CollectionReference<TaskComment>,
        where("projectId", "==", projectId),
        where("taskId", "==", taskId),
        orderBy("createdAt", "asc")
      )
    );
  }

  async updateTaskCommentAlreadyRead(
    workspaceId: string,
    taskCommentId: string
  ): Promise<void> {
    const userId = this.auth.currentUser?.uid;
    if (!userId) throw new Error(i18nError.unauthenticated());
    await updateDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.TASK_COMMENTS,
        taskCommentId
      ) as DocumentReference<TaskComment>,
      {
        unreadMemberIds: arrayRemove(userId),
      }
    );
  }

  // ==================================================
  //  CollectionGroup / TaskStates
  // ==================================================
  $readTaskStates(workspaceId: string): Observable<TaskState[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASK_STATES
        ) as CollectionReference<TaskState>,
        orderBy("order", "asc")
      )
    );
  }

  $readTaskMilestones(workspaceId: string): Observable<TaskMilestone[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASK_MILESTONES
        ) as CollectionReference<TaskMilestone>,
        orderBy("order", "asc")
      )
    );
  }

  $readTaskTags(workspaceId: string): Observable<TaskTag[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASK_TAGS
        ) as CollectionReference<TaskTag>,
        orderBy("order", "asc")
      )
    );
  }

  // ==================================================
  //  Workspace / Member
  // ==================================================
  async updateMember(member: Member): Promise<void> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.UPDATE_MEMBER
    );
    await callable(member);
  }

  async deleteMember(workspaceId: string, memberId: string): Promise<void> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.DELETE_MEMBER
    );
    await callable({ workspaceId, memberId });
  }

  async reorderMembers(
    workspaceId: string,
    members: Member[],
    fromIndex: number,
    toIndex: number
  ): Promise<void> {
    const batch = this.reorderBatch(members, fromIndex, toIndex, [
      FIRESTORE.WORKSPACES,
      workspaceId,
      FIRESTORE.MEMBERS,
    ]);
    await batch.commit();
  }

  // ==================================================
  //  Workspace / Team
  // ==================================================
  $readTeams(workspaceId: string): Observable<Team[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TEAMS
        ) as CollectionReference<Team>,
        orderBy("order", "asc")
      )
    );
  }

  async getTeams(workspaceId: string): Promise<Team[]> {
    const snapshots = await getDocs(
      collection(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.TEAMS
      )
    );
    return snapshots.docs.map((doc) => doc.data() as Team);
  }

  $readTeam(workspaceId: string, teamId: string): Observable<Team> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.TEAMS,
        teamId
      ) as DocumentReference<Team>
    ).pipe(
      tap((item) => {
        if (!item) throw new Error(i18nError.notFound());
      })
    );
  }

  async createTeam(team: Team): Promise<void> {
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        team.workspaceId,
        FIRESTORE.TEAMS,
        team.id
      ) as DocumentReference<Team>,
      team
    );
  }

  async updateTeam(team: Partial<Team>): Promise<void> {
    if (!team.workspaceId || !team.id) {
      throw new Error(i18nError.invalidArgument());
    }
    const batch = writeBatch(this.firestore);
    // 全てのチーム
    const teams: Team[] = await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          team.workspaceId,
          FIRESTORE.TEAMS
        )
      )
    ).then(({ docs }) =>
      docs.map((doc) => {
        const data = doc.data() as Team;
        const currentTeam = data.id === team.id;
        if (currentTeam) {
          return { ...data, ...team }; // 今回更新するチームは最新に差し替え
        } else {
          return data;
        }
      })
    );
    // 更新するチームIDを含んだプロジェクトを更新
    const projects: Project[] = await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          team.workspaceId,
          FIRESTORE.PROJECTS
        ),
        where("teamIds", "array-contains", team.id)
      )
    ).then((snapshots) => snapshots.docs.map((doc) => doc.data() as Project));
    for (const project of projects) {
      let allowMemberIds: string[] = project.memberIds;
      project.teamIds.forEach((teamId) => {
        const team = teams.find(({ id }) => id === teamId);
        if (team) {
          allowMemberIds = [...allowMemberIds, ...team.memberIds];
        }
      });
      allowMemberIds = uniq(allowMemberIds);
      batch.update(
        doc(
          this.firestore,
          FIRESTORE.WORKSPACES,
          project.workspaceId,
          FIRESTORE.PROJECTS,
          project.id
        ) as DocumentReference<Project>,
        {
          allowMemberIds,
        }
      );
    }
    // 更新するチームIDを含んだオフィスを更新
    const offices: Office[] = await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          team.workspaceId,
          FIRESTORE.OFFICES
        ),
        where("teamIds", "array-contains", team.id)
      )
    ).then((snapshots) => snapshots.docs.map((doc) => doc.data() as Office));
    for (const office of offices) {
      let allowMemberIds: string[] = office.memberIds;
      office.teamIds.forEach((teamId) => {
        const team = teams.find(({ id }) => id === teamId);
        if (team) {
          allowMemberIds = [...allowMemberIds, ...team.memberIds];
        }
      });
      allowMemberIds = uniq(allowMemberIds);
      batch.update(
        doc(
          this.firestore,
          FIRESTORE.WORKSPACES,
          office.workspaceId,
          FIRESTORE.OFFICES,
          office.id
        ) as DocumentReference<Office>,
        {
          allowMemberIds,
        }
      );
    }
    // チームを更新
    batch.update(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        team.workspaceId,
        FIRESTORE.TEAMS,
        team.id
      ) as DocumentReference<Team>,
      team
    );
    await batch.commit();
  }

  async deleteTeam(workspaceId: string, teamId: string): Promise<void> {
    const batch = writeBatch(this.firestore);
    // 削除するチームを除いた全てのチーム
    const teams: Team[] = await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TEAMS
        )
      )
    )
      .then((snapshots) => snapshots.docs.map((doc) => doc.data() as Team))
      .then((teams) => teams.filter((team) => team.id !== teamId));
    // 削除するチームIDを含んだプロジェクトを更新
    const projects: Project[] = await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.PROJECTS
        ),
        where("teamIds", "array-contains", teamId)
      )
    ).then((snapshots) => snapshots.docs.map((doc) => doc.data() as Project));
    for (const project of projects) {
      let allowMemberIds: string[] = project.memberIds;
      project.teamIds.forEach((teamId) => {
        const team = teams.find(({ id }) => id === teamId);
        if (team) {
          allowMemberIds = [...allowMemberIds, ...team.memberIds];
        }
      });
      allowMemberIds = uniq(allowMemberIds);
      batch.update(
        doc(
          this.firestore,
          FIRESTORE.WORKSPACES,
          project.workspaceId,
          FIRESTORE.PROJECTS,
          project.id
        ) as DocumentReference<Project>,
        {
          allowMemberIds,
        }
      );
    }
    // 更新するチームIDを含んだオフィスを更新
    const offices: Office[] = await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICES
        ),
        where("teamIds", "array-contains", teamId)
      )
    ).then((snapshots) => snapshots.docs.map((doc) => doc.data() as Office));
    for (const office of offices) {
      let allowMemberIds: string[] = office.memberIds;
      office.teamIds.forEach((teamId) => {
        const team = teams.find(({ id }) => id === teamId);
        if (team) {
          allowMemberIds = [...allowMemberIds, ...team.memberIds];
        }
      });
      allowMemberIds = uniq(allowMemberIds);
      batch.update(
        doc(
          this.firestore,
          FIRESTORE.WORKSPACES,
          office.workspaceId,
          FIRESTORE.OFFICES,
          office.id
        ) as DocumentReference<Office>,
        {
          allowMemberIds,
        }
      );
    }
    // チームを削除
    batch.delete(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.TEAMS,
        teamId
      ) as DocumentReference<Team>
    );
    await batch.commit();
  }

  async reorderTeams(
    workspaceId: string,
    teams: Team[],
    fromIndex: number,
    toIndex: number
  ): Promise<void> {
    const batch = this.reorderBatch(teams, fromIndex, toIndex, [
      FIRESTORE.WORKSPACES,
      workspaceId,
      FIRESTORE.TEAMS,
    ]);
    await batch.commit();
  }

  // ==================================================
  //  Workspace / Invitation
  // ==================================================
  async createInvitations(
    workspaceId: string,
    projectIds: string[],
    officeIds: string[],
    teamIds: string[],
    role: Role,
    dayToExpire: number,
    howMany: number
  ): Promise<void> {
    console.log("request", {
      workspaceId,
      projectIds,
      officeIds,
      teamIds,
      role,
      dayToExpire,
      howMany,
    });
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.CREATE_INVITATIONS
    );
    await callable({
      workspaceId,
      projectIds,
      officeIds,
      teamIds,
      role,
      dayToExpire,
      howMany,
    });
  }

  $readInvitation(
    workspaceId: string,
    invitationId: string
  ): Observable<Invitation> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.INVITATIONS,
        invitationId
      ) as DocumentReference<Invitation>
    ).pipe(
      tap((item) => {
        if (!item) throw new Error(i18nError.notFound());
      })
    );
  }

  $readInvitations(workspaceId: string): Observable<Invitation[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.INVITATIONS
        ) as CollectionReference<Invitation>,
        orderBy("createdAt", "asc")
      )
    );
  }

  async invitedUserAddWorkspace(
    workspaceId: string,
    invitationId: string,
    name: string
  ): Promise<string> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.INVITED_USER_ADD_WORKSPACE
    );
    const response = await callable({
      workspaceId,
      invitationId,
      name,
    });
    return String(response.data);
  }

  // ==================================================
  //  Workspace / Office
  // ==================================================
  async createOffice(
    office: Office,
    members: Member[],
    teams: Team[]
  ): Promise<void> {
    const cloneOffice = cloneDeep(office);
    if (cloneOffice.public === true) {
      cloneOffice.allowMemberIds = members.map((item) => item.id);
      cloneOffice.memberIds = [];
      cloneOffice.teamIds = [];
    } else {
      const allowMemberIds: string[] = [];
      cloneOffice.memberIds.forEach((id) => allowMemberIds.push(id));
      teams
        .filter((team) => cloneOffice.teamIds.some((id) => id === team.id))
        .forEach((team) =>
          team.memberIds.forEach((id) => allowMemberIds.push(id))
        );
      cloneOffice.allowMemberIds = uniq(allowMemberIds);
    }
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        office.workspaceId,
        FIRESTORE.OFFICES,
        office.id
      ) as DocumentReference<Office>,
      cloneOffice
    );
  }

  $readMemberOffices(
    workspaceId: string,
    memberId: string
  ): Observable<Office[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICES
        ) as CollectionReference<Office>,
        where("allowMemberIds", "array-contains", memberId)
      )
    ).pipe(map((items) => items.sort((a, b) => a.order - b.order)));
  }

  $readOffices(workspaceId: string): Observable<Office[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICES
        ) as CollectionReference<Office>
      )
    ).pipe(map((items) => items.sort((a, b) => a.order - b.order)));
  }

  $readOffice(workspaceId: string, officeId: string): Observable<Office> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.OFFICES,
        officeId
      ) as DocumentReference<Office>
    ).pipe(
      tap((item) => {
        if (!item) throw new Error(i18nError.notFound());
      })
    );
  }

  async updateOffice(office: Partial<Office>): Promise<void> {
    if (!office.workspaceId || !office.id) {
      throw new Error(i18nError.invalidArgument());
    }
    await updateDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        office.workspaceId,
        FIRESTORE.OFFICES,
        office.id
      ),
      office
    );
  }

  async deleteOffice(workspaceId: string, officeId: string): Promise<void> {
    await deleteDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.OFFICES,
        officeId
      ) as DocumentReference<Office>
    );
  }

  async reorderOffices(
    workspaceId: string,
    offices: Office[],
    fromIndex: number,
    toIndex: number
  ): Promise<void> {
    const batch = this.reorderBatch(offices, fromIndex, toIndex, [
      FIRESTORE.WORKSPACES,
      workspaceId,
      FIRESTORE.OFFICES,
    ]);
    await batch.commit();
  }

  // ==================================================
  //  Workspace / OfficeMember
  // ==================================================
  $readOfficeMembers(
    workspaceId: string,
    officeId: string
  ): Observable<OfficeMember[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICE_MEMBERS
        ) as CollectionReference<OfficeMember>,
        where("officeId", "==", officeId)
      )
    ).pipe(
      map((items) =>
        items.sort((a, b) => a.updatedAt.seconds - b.updatedAt.seconds)
      )
    );
  }

  $readOfficeMember(
    workspaceId: string,
    memberId: string
  ): Observable<OfficeMember> {
    return docData(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.OFFICE_MEMBERS,
        memberId
      ) as DocumentReference<OfficeMember | undefined>
    ).pipe(
      map((item) => {
        if (!item) throw new Error(i18nError.notFound());
        return item;
      })
    );
  }

  async updateOfficeMember(officeMember: Partial<OfficeMember>): Promise<void> {
    if (!officeMember.workspaceId || !officeMember.id) {
      throw new Error(i18nError.invalidArgument());
    }
    await updateDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        officeMember.workspaceId,
        FIRESTORE.OFFICE_MEMBERS,
        officeMember.id
      ) as DocumentReference<OfficeMember>,
      officeMember
    );
  }

  async enterOffice(officeMember: OfficeMember): Promise<void> {
    await deleteDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        officeMember.workspaceId,
        FIRESTORE.OFFICE_MEMBERS,
        officeMember.id
      ) as DocumentReference<OfficeMember>
    ); // 他のオフィスに入室中の場合は削除して入り直す
    await new Promise((resolve) => setTimeout(resolve, 500));
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        officeMember.workspaceId,
        FIRESTORE.OFFICE_MEMBERS,
        officeMember.id
      ) as DocumentReference<OfficeMember>,
      officeMember
    );
  }

  async leaveOffice(workspaceId: string, memberId: string): Promise<void> {
    await deleteDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        workspaceId,
        FIRESTORE.OFFICE_MEMBERS,
        memberId
      ) as DocumentReference<OfficeMember>
    );
  }

  // ==================================================
  //  Workspace / OfficeMemberHistory
  // ==================================================
  $readOfficeMemberHistoriesAtTask(
    workspaceId: string,
    projectId: string,
    taskId: string
  ): Observable<OfficeMemberHistory[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICE_MEMBER_HISTORIES
        ) as CollectionReference<OfficeMemberHistory>,
        where("projectId", "==", projectId),
        where("taskId", "==", taskId)
      )
    ).pipe(
      map((items) =>
        items.sort((a, b) => {
          if (!a.beginAt || !b.beginAt) return 0;
          return a.beginAt.seconds - b.beginAt.seconds;
        })
      )
    );
  }

  $readOfficeMemberHistoriesAtOffice(
    workspaceId: string,
    officeId: string,
    memberId: string,
    beginAt: Date
  ): Observable<OfficeMemberHistory[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICE_MEMBER_HISTORIES
        ) as CollectionReference<OfficeMemberHistory>,
        where("officeId", "==", officeId),
        where("memberId", "==", memberId),
        where("beginAt", ">=", beginAt),
        orderBy("beginAt", "asc")
      )
    );
  }

  $readMonthlyOfficeMemberHistories(
    workspaceId: string,
    month: string
  ): Observable<OfficeMemberHistory[]> {
    const beginAt = dayjs(month).startOf("month").toDate();
    const endAt = dayjs(month).endOf("month").toDate();
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICE_MEMBER_HISTORIES
        ) as CollectionReference<OfficeMemberHistory>,
        where("beginAt", ">=", beginAt),
        where("beginAt", "<=", endAt)
      )
    );
  }

  $readDailyOfficeMembersHistories(
    workspaceId: string,
    date: string
  ): Observable<OfficeMemberHistory[]> {
    const beginAt = dayjs(date).startOf("day").toDate();
    const endAt = dayjs(date).endOf("day").toDate();
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICE_MEMBER_HISTORIES
        ) as CollectionReference<OfficeMemberHistory>,
        where("beginAt", ">=", beginAt),
        where("beginAt", "<=", endAt)
      )
    );
  }

  $readDailyOfficeMemberHistories(
    workspaceId: string,
    date: string,
    memberId: string
  ): Observable<OfficeMemberHistory[]> {
    const beginAt = dayjs(date).startOf("day").toDate();
    const endAt = dayjs(date).endOf("day").toDate();
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.OFFICE_MEMBER_HISTORIES
        ) as CollectionReference<OfficeMemberHistory>,
        where("memberId", "==", memberId),
        where("beginAt", ">=", beginAt),
        where("beginAt", "<=", endAt),
        orderBy("beginAt", "asc")
      )
    );
  }

  // ==================================================
  //  Workspace / TaskBookmark
  // ==================================================
  async createTaskBookmark(taskBookmark: TaskBookmark): Promise<void> {
    await setDoc(
      doc(
        this.firestore,
        FIRESTORE.WORKSPACES,
        taskBookmark.workspaceId,
        FIRESTORE.TASK_BOOKMARKS,
        taskBookmark.id
      ) as DocumentReference<TaskBookmark>,
      taskBookmark
    );
  }

  $readTaskBookmarks(workspaceId: string): Observable<TaskBookmark[]> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        collectionData(
          query(
            collection(
              this.firestore,
              FIRESTORE.WORKSPACES,
              workspaceId,
              FIRESTORE.TASK_BOOKMARKS
            ) as CollectionReference<TaskBookmark>,
            where("memberId", "==", userId)
          )
        )
      ),
      map((items) => items.sort((a, b) => a.order - b.order))
    );
  }

  async deleteTaskBookmark(
    workspaceId: string,
    taskId: string,
    memberId: string
  ): Promise<void> {
    const batch = writeBatch(this.firestore);
    await getDocs(
      query(
        collection(
          this.firestore,
          FIRESTORE.WORKSPACES,
          workspaceId,
          FIRESTORE.TASK_BOOKMARKS
        ),
        where("taskId", "==", taskId),
        where("memberId", "==", memberId)
      )
    ).then((snapshots) => snapshots.docs.map((doc) => batch.delete(doc.ref)));
    await batch.commit();
  }

  async reorderTaskBookmarks(
    workspaceId: string,
    taskBookmarks: TaskBookmark[],
    fromIndex: number,
    toIndex: number
  ): Promise<void> {
    const batch = this.reorderBatch(taskBookmarks, fromIndex, toIndex, [
      FIRESTORE.WORKSPACES,
      workspaceId,
      FIRESTORE.TASK_BOOKMARKS,
    ]);
    await batch.commit();
  }

  // ==================================================
  //  Workspace / Task（Notifications）
  // ==================================================
  $readTaskNotifications(
    workspaceId: string,
    limitNum: number
  ): Observable<Task[]> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        collectionData(
          query(
            collection(
              this.firestore,
              FIRESTORE.WORKSPACES,
              workspaceId,
              FIRESTORE.TASKS
            ) as CollectionReference<Task>,
            where("assignedMemberId", "==", userId),
            orderBy("updatedAt", "desc"),
            limit(limitNum)
          )
        )
      )
    );
  }

  $readUnreadTaskNotifications(): Observable<Task[]> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        collectionData(
          query(
            collectionGroup(this.firestore, FIRESTORE.TASKS) as Query<Task>,
            where("unreadNotificationMemberId", "==", userId),
            limit(99)
          )
        )
      )
    );
  }

  // ==================================================
  //  Workspace / TaskComment（Notifications）
  // ==================================================
  $readTaskCommentNotifications(
    workspaceId: string,
    limitNum: number
  ): Observable<TaskComment[]> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        collectionData(
          query(
            collection(
              this.firestore,
              FIRESTORE.WORKSPACES,
              workspaceId,
              FIRESTORE.TASK_COMMENTS
            ) as CollectionReference<TaskComment>,
            where("notifiedMemberIds", "array-contains", userId),
            limit(limitNum),
            orderBy("updatedAt", "desc")
          )
        )
      )
    );
  }

  $readUnreadTaskCommentNotifications(): Observable<TaskComment[]> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        collectionData(
          query(
            collectionGroup(
              this.firestore,
              FIRESTORE.TASK_COMMENTS
            ) as Query<TaskComment>,
            where("unreadMemberIds", "array-contains", userId),
            limit(99)
          )
        )
      )
    );
  }

  // ==================================================
  //  Workspace / stripe
  // ==================================================
  $readStripeWorkspaceActiveSubscriptions(
    workspaceId: string
  ): Observable<StripeSubscription[]> {
    return collectionData(
      query(
        collectionGroup(
          this.firestore,
          FIRESTORE.STRIPE_SUBSCRIPTIONS
        ) as Query<StripeSubscription>,
        where("metadata.workspaceId", "==", workspaceId),
        where("status", "==", "active")
      )
    );
  }

  $readStripeCustomerActiveSubscriptions(
    workspaceId: string
  ): Observable<StripeSubscription[]> {
    return this.$readUserId().pipe(
      switchMap((userId) =>
        collectionData(
          query(
            collection(
              this.firestore,
              FIRESTORE.STRIPE_CUSTOMERS,
              userId,
              FIRESTORE.STRIPE_SUBSCRIPTIONS
            ) as CollectionReference<StripeSubscription>,
            where("metadata.workspaceId", "==", workspaceId),
            where("status", "==", "active")
          )
        )
      )
    );
  }

  $readStripeProducts(): Observable<StripeProduct[]> {
    return collectionData(
      query(
        collection(
          this.firestore,
          FIRESTORE.STRIPE_PRODUCTS
        ) as CollectionReference<StripeProduct>,
        where("active", "==", true)
      ),
      { idField: "id" }
    ).pipe(
      switchMap((products) => {
        return combineLatest(
          products.map((product) => {
            return collectionData(
              query(
                collection(
                  this.firestore,
                  FIRESTORE.STRIPE_PRODUCTS,
                  product.id,
                  FIRESTORE.STRIPE_PRICES
                ) as CollectionReference<StripePrice>,
                where("active", "==", true)
              ),
              { idField: "id" }
            ).pipe(map((prices) => ({ ...product, prices })));
          })
        );
      })
    );
  }

  async createStripePortalLink(workspaceId: string): Promise<StripeSession> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.STRIPE_CREATE_PORTAL_LINK
    );
    const { data } = await callable({
      returnUrl: `${getRoot()}/${PAGES.WORKSPACE}/${workspaceId}/${
        PAGES.TABS.SUBSCRIPTION
      }`,
    });
    return data as StripeSession;
  }

  async subscribeStripeProduct(
    workspaceId: string,
    stripePriceId: string
  ): Promise<string> {
    const user = this.auth.currentUser;
    if (!user) throw Error(i18nError.unauthenticated());
    const workspace = await this.getWorkspace(workspaceId);
    if (workspace.plan === PLAN.BUSINESS) throw Error(i18nError.cancelled());
    const url = `${getRoot()}/${PAGES.WORKSPACE}/${workspaceId}/${
      PAGES.TABS.SUBSCRIPTION
    }`;
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.ADD_STRIPE_CHECKOUT_SESSION
    );
    const { data } = await callable({ workspaceId, stripePriceId, url });
    return await new Promise((resolve, reject) => {
      const subscribe = docData(
        doc(
          this.firestore,
          FIRESTORE.STRIPE_CUSTOMERS,
          user.uid,
          FIRESTORE.STRIPE_CHECKOUT_SESSIONS,
          String(data)
        )
      ).subscribe({
        next: (doc) => {
          if (doc?.url) {
            subscribe.unsubscribe();
            resolve(doc.url);
          }
        },
        error: (error) => {
          subscribe.unsubscribe();
          reject(error);
        },
      });
    });
  }

  // async createStripePortalLink(workspaceId: string): Promise<StripeSession> {
  //   const callable = httpsCallable(
  //     this.functions,
  //     CLOUDFUNCTIONS.STRIPE_CREATE_PORTAL_LINK
  //   );
  //   const { data } = await callable({
  //     returnUrl: `${window.location.origin}/${PAGES.WORKSPACE}/${workspaceId}/${PAGES.TABS.PAYMENT}`,
  //   });
  //   return data as StripeSession;
  // }

  // async subscribeStripeProduct(
  //   workspaceId: string,
  //   stripePriceId: string,
  //   quantity: number
  // ): Promise<string> {
  //   const user = this.auth.currentUser;
  //   if (!user) {
  //     throw Error(i18nError.unauthenticated());
  //   }
  //   const url = `${window.location.origin}/${PAGES.WORKSPACE}/${workspaceId}/${PAGES.TABS.PAYMENT}`;
  //   const checkoutSession = {
  //     allow_promotion_codes: false,
  //     automatic_tax: true,
  //     cancel_url: url,
  //     // client: "web",
  //     collect_shipping_address: false,
  //     // created: getTimestampNow(),
  //     line_items: [
  //       {
  //         price: stripePriceId,
  //         quantity,
  //       },
  //     ],
  //     metadata: { workspaceId },
  //     // mode:"subscription",
  //     // sessionId:"xxxx",
  //     success_url: url,
  //     tax_id_collection: true,
  //     // url: "xxxx",
  //   };
  //   const docRef = await addDoc(
  //     collection(
  //       this.firestore,
  //       FIRESTORE.STRIPE_CUSTOMERS,
  //       user.uid,
  //       FIRESTORE.STRIPE_CHECKOUT_SESSIONS
  //     ),
  //     checkoutSession
  //   );
  //   return await new Promise((resolve, reject) => {
  //     const subscribe = docData(docRef).subscribe({
  //       next: (doc) => {
  //         if (doc?.url) {
  //           subscribe.unsubscribe();
  //           resolve(doc.url);
  //         }
  //       },
  //       error: (error) => {
  //         subscribe.unsubscribe();
  //         reject(error);
  //       },
  //     });
  //   });
  // }

  // ==================================================
  //  send mail
  // ==================================================
  async sendContactEmail(
    name: string,
    email: string,
    message: string
  ): Promise<void> {
    const callable = httpsCallable(
      this.functions,
      CLOUDFUNCTIONS.SEND_CONTACT_EMAIL
    );
    await callable({ name, email, message });
  }

  // ==================================================
  //  storage taskFile
  // ==================================================
  async putTaskFile(
    workspaceId: string,
    projectId: string,
    taskId: string,
    memberId: string,
    fileName: string,
    file: File
  ): Promise<void> {
    await uploadBytes(
      ref(
        this.storage,
        `${STORAGE.WORKSPACES}/${workspaceId}/${STORAGE.PROJECTS}/${projectId}/${STORAGE.TASKS}/${taskId}/${fileName}`
      ),
      file,
      { customMetadata: { memberId, kind: STORAGE.KIND.TASK_FILE } }
    );
  }

  async putTaskFileBase64(
    workspaceId: string,
    projectId: string,
    taskId: string,
    memberId: string,
    fileName: string,
    base64: string,
    contentType: string
  ): Promise<void> {
    await uploadString(
      ref(
        this.storage,
        `${STORAGE.WORKSPACES}/${workspaceId}/${STORAGE.PROJECTS}/${projectId}/${STORAGE.TASKS}/${taskId}/${fileName}`
      ),
      base64,
      "base64",
      {
        contentType,
        customMetadata: { memberId, kind: STORAGE.KIND.TASK_FILE },
      }
    );
  }

  async getTaskFiles(
    workspaceId: string,
    projectId: string,
    taskId: string
  ): Promise<TaskFile[]> {
    const directory = ref(
      this.storage,
      `${FIRESTORE.WORKSPACES}/${workspaceId}/${FIRESTORE.PROJECTS}/${projectId}/${FIRESTORE.TASKS}/${taskId}`
    );
    const taskFiles = await listAll(directory);
    const requests = taskFiles.items.map(async ({ fullPath }) => {
      const url = await getDownloadURL(ref(this.storage, fullPath));
      const { contentType, customMetadata, name, size, timeCreated } =
        await getMetadata(ref(this.storage, fullPath));
      return {
        name,
        url,
        path: fullPath,
        contentType: contentType ?? null,
        size,
        createdAt: new Date(timeCreated),
        createdBy: customMetadata?.memberId ?? null,
      };
    });
    return await Promise.all(requests).then((items) =>
      items.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
    );
  }

  async deleteTaskFile(path: string): Promise<void> {
    const targetRef = ref(this.storage, path);
    await deleteObject(targetRef);
  }

  // ==================================================
  //  utilities
  // ==================================================
  // IDの生成
  createId(): string {
    return doc(collection(this.firestore, "xxxx")).id;
  }

  // 並び替え
  private reorderBatch = (
    items: any[],
    fromIndex: number,
    toIndex: number,
    collectionPathSegments: string[]
  ) => {
    const fromItem = items[fromIndex];
    const toItem =
      toIndex >= items.length ? items[items.length - 1] : items[toIndex];
    const whileItems = items.filter((_, index) => {
      if (fromIndex < toIndex) {
        return index > fromIndex && index <= toIndex;
      } else {
        return index < fromIndex && index >= toIndex;
      }
    });
    const batch = writeBatch(this.firestore);
    const [pathSegment, ...pathSegments] = collectionPathSegments;
    batch.update(
      doc(this.firestore, pathSegment, ...pathSegments, fromItem.id),
      {
        order: toItem.order,
      }
    );
    whileItems.forEach((item) => {
      const incrementNum = fromIndex < toIndex ? -1 : 1;
      batch.update(doc(this.firestore, pathSegment, ...pathSegments, item.id), {
        order: increment(incrementNum),
      });
    });
    return batch;
  };
}

export const appApis = new AppApi();
