import { useIonAlert, useIonLoading, useIonModal } from "@ionic/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  AppContent,
  AppLogo,
  AppCard,
  AppFormInput,
  AppActionButton,
  AppLead,
  AppSignInModal,
  AppSignInModalProps,
  AppCardContent,
  AppHeader,
  AppPage,
  AppTitle,
  AppToolbar,
  AppInnerContent,
  AppButton,
} from "../..";
import { appApis } from "../../../apis/Api";
import { useAuth } from "../../../hooks";
import { useInvitation } from "../../../hooks";
import { appLinks } from "../../../utilities/UtilPage";
import { i18nErrorToString } from "../../../utilities/UtilI18nText";
import { PAGES } from "../../../utilities/UtilStatic";

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const Logo = styled(AppLogo)`
  margin: 32px 0 40px;
`;

const CardFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 16px;
  gap: 16px 0;
`;

const InvitationPage: FunctionComponent<{ routerRef: HTMLElement }> = ({
  routerRef,
}) => {
  // router
  const history = useHistory();
  const { workspaceId, invitationId, language } = useParams<{
    workspaceId: string;
    invitationId: string;
    language: string;
  }>();

  // store
  const auth = useAuth();
  const invitation = useInvitation({ workspaceId, invitationId });

  // components
  const [showAlert] = useIonAlert();
  const [showLoading, hideLoading] = useIonLoading();

  const [showAppSignInModal, hideAppSignInModal] = useIonModal(AppSignInModal, {
    signIn: (email, password) => {
      showLoading({
        cssClass: "app-loading",
        backdropDismiss: true,
      });
      Promise.resolve()
        .then(() => appApis.signIn(email, password))
        .then(() => {
          hideAppSignInModal();
        })
        .catch((error) => {
          showAlert({
            message: i18nErrorToString(error),
          });
        })
        .finally(() => hideLoading());
    },
    hide: () => hideAppSignInModal(),
  } as AppSignInModalProps);

  // page data
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);

  // functions
  const initializeForm = () => {
    setName("");
    setEmail("");
    setPassword("");
  };

  useEffect(() => initializeForm(), [auth]);

  return (
    <AppPage>
      <AppHeader>
        <AppToolbar>
          <AppTitle>招待</AppTitle>
        </AppToolbar>
      </AppHeader>
      <AppContent>
        <AppInnerContent>
          <Content>
            <Logo width="240px" height="60px" />
            {invitation.data && !loading && (
              <>
                {/*  サインイン状態 */}
                {auth && (
                  <>
                    <AppCard marginBottom="16px" style={{ maxWidth: "400px" }}>
                      <AppCardContent>
                        <AppLead marginBottom="8px">
                          {invitation.data.workspaceName}
                          に招待されました。
                          <br />
                          {auth.email}
                        </AppLead>
                        <AppFormInput
                          value={name}
                          onIonChange={(e) => setName(e.detail.value!)}
                          label="名前"
                          type="text"
                          helperText="名前を入力してください"
                          marginBottom="8px"
                          name="name"
                        />
                        <CardFooter>
                          <AppActionButton
                            action="ok"
                            onClick={() => {
                              if (!auth || !auth.email) return;
                              showLoading({
                                cssClass: "app-loading",
                                backdropDismiss: true,
                              });
                              Promise.resolve()
                                .then(() =>
                                  appApis.invitedUserAddWorkspace(
                                    workspaceId,
                                    invitationId,
                                    name
                                  )
                                )
                                .then((workspaceId) => {
                                  hideLoading();
                                  appApis.reloadApp();
                                  history.replace(
                                    appLinks.tab(
                                      workspaceId,
                                      PAGES.TABS.PROJECT
                                    )
                                  );
                                })
                                .catch((error) => {
                                  hideLoading();
                                  showAlert({
                                    message: i18nErrorToString(error),
                                  });
                                });
                            }}
                          >
                            参加
                          </AppActionButton>
                        </CardFooter>
                      </AppCardContent>
                    </AppCard>
                  </>
                )}

                {/*  サインイン状態でない */}
                {!auth && (
                  <>
                    <AppCard style={{ maxWidth: "400px" }} marginBottom="16px">
                      <AppCardContent>
                        <AppLead marginBottom="8px">
                          {invitation.data.workspaceName}
                          に招待されました。
                        </AppLead>
                        <AppFormInput
                          value={name}
                          onIonChange={(e) => setName(e.detail.value!)}
                          label="名前"
                          type="text"
                          helperText="名前を入力してください"
                          marginBottom="8px"
                          name="name"
                        />
                        <AppFormInput
                          value={email}
                          onIonChange={(e) => setEmail(e.detail.value!)}
                          label="Eメール"
                          type="email"
                          helperText="Eメールを入力してください"
                          marginBottom="8px"
                          name="email"
                        />
                        <AppFormInput
                          value={password}
                          onIonChange={(e) => setPassword(e.detail.value!)}
                          label="パスワード"
                          type="password"
                          helperText="パスワードは6文字以上入力してください"
                          name="password"
                        />
                        <CardFooter>
                          <AppActionButton
                            action="ok"
                            onClick={() => {
                              showLoading({
                                cssClass: "app-loading",
                                backdropDismiss: true,
                              });
                              setLoading(true);
                              Promise.resolve()
                                .then(() =>
                                  appApis.signUp(email, password, language)
                                )
                                .then(() =>
                                  appApis.invitedUserAddWorkspace(
                                    workspaceId,
                                    invitationId,
                                    name
                                  )
                                )
                                .then((workspaceId) => {
                                  setLoading(false);
                                  hideLoading();
                                  appApis.reloadApp();
                                  history.replace(
                                    appLinks.tab(
                                      workspaceId,
                                      PAGES.TABS.PROJECT
                                    )
                                  );
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  hideLoading();
                                  showAlert({
                                    message: i18nErrorToString(error),
                                  });
                                });
                            }}
                          >
                            登録
                          </AppActionButton>
                        </CardFooter>
                      </AppCardContent>
                    </AppCard>
                    <AppButton
                      fill="clear"
                      expand="block"
                      onClick={() =>
                        showAppSignInModal({
                          presentingElement: routerRef,
                          canDismiss: true,
                        })
                      }
                    >
                      アカウントをお持ちの方はこちらからサインイン
                    </AppButton>
                  </>
                )}
              </>
            )}
          </Content>
        </AppInnerContent>
      </AppContent>
    </AppPage>
  );
};

export default InvitationPage;
